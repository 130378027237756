import React from 'react';
import { SelectorField, SelectorFieldProps } from '@bxgrandcentral/controls';

type Props<T> = Omit<SelectorFieldProps, 'selectionMode' | 'selectedItems' | 'onSelectedItemsChanged'> & {
    selected?: T;
    onChange: (item?: T) => void;
};

export default function SingleSelectorField<T>({ selected, onChange, ...rest }: Props<T>) {
    return (
        <SelectorField
            selectionMode='single'
            selectedItems={[selected]}
            onSelectedItemsChanged={([newValue]) => onChange(newValue)}
            {...rest}
        />
    );
}
