import { DateTimeField, Select } from 'components/controls';
import { EffectiveDateProp, PendingDocument as PendingDocumentModel } from '../models';
import React, { useMemo } from 'react';
import { removePendingDocument, updatePendingDocument } from 'modules/LegalEntityCreation/context/actions';

import { DocumentLayout } from '../FileUpload.styled';
import FileNameField from 'components/controls/FileNameField/FileNameField';
import { KeyValue } from 'models';
import { ToolBarButton } from '@bxgrandcentral/controls';
import { VALIDATION_MESSAGES } from 'modules/LegalEntityCreation/validation/utils';
import useColors from 'api/hooks/use-theme';

type Props = {
    document: PendingDocumentModel;
    documentTypes?: KeyValue[];
    sectionName: string;
    effectiveDate: EffectiveDateProp;
    dispatch: any;
};
const PendingDocument = ({ document, documentTypes, sectionName, effectiveDate = 'none', dispatch }: Props) => {
    const { accentColor } = useColors();

    const futureDateError = useMemo(() => {
        const { effectiveDate } = document;
        if (effectiveDate && effectiveDate > new Date()) {
            return VALIDATION_MESSAGES.INVALID_FUTURE_DATE;
        }
    }, [document]);

    const hasEffectiveDate = useMemo(() => effectiveDate !== 'none', [effectiveDate]);

    return (
        <DocumentLayout hasEffectiveDate={hasEffectiveDate}>
            <FileNameField
                value={document.fileName}
                onValueChanged={(fileName) => {
                    updatePendingDocument(dispatch, sectionName, {
                        ...document,
                        fileName,
                    });
                }}
            />
            <Select
                variant='default'
                isRequired
                isEditable
                value={document.documentTypeId}
                itemsSource={documentTypes}
                onValueChanged={(newValue = 0) => {
                    updatePendingDocument(dispatch, sectionName, {
                        ...document,
                        documentTypeId: newValue,
                    });
                }}
            />
            {hasEffectiveDate && (
                <DateTimeField
                    variant='default'
                    isRequired={effectiveDate === 'required'}
                    isEditable
                    validationError={futureDateError}
                    onValueChanged={(newValue) => {
                        updatePendingDocument(dispatch, sectionName, {
                            ...document,
                            effectiveDate: newValue ? new Date(newValue).utcToLocalDate() : undefined,
                        });
                    }}
                />
            )}
            <ToolBarButton
                icon='Delete'
                toolTip='Click to delete attachment'
                fontSize={18}
                foregroundColor={accentColor}
                onClick={() => removePendingDocument(dispatch, sectionName, document.id)}
                verticalContentAlignment='top'
            />
        </DocumentLayout>
    );
};

export default PendingDocument;
