import { ELEVATIONS, SPACING } from 'Utilities/Layout';

import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';

type ColorsProp = {
    colors: ThemeColors;
};

export const Button = styled.div<ColorsProp>`
    display: flex;
    align-items: center;
    &.disabled {
        cursor: default;
    }

    &:hover:not(.disabled) {
        span {
            color: ${({ colors }) => colors.accentColor};
        }
        cursor: pointer;
    }
`;

export const DateRangePickerDropdown = styled.div<ColorsProp & { isToolbarButtonStyled: boolean }>`
    position: absolute;
    display: grid;
    z-index: ${ELEVATIONS.DASHBOARD_DROPDOWN};
    border: 1px solid ${({ colors }) => colors.normalControlBorderColor};
    background-color: ${({ colors }) => colors.backgroundColor};
    top: ${({ isToolbarButtonStyled }) => (isToolbarButtonStyled ? '41px' : '127px')};
    right: ${({ isToolbarButtonStyled }) => (isToolbarButtonStyled ? '361px' : '371px')};
    width: 400px;
`;

export const DateRangePickerDropdownFooter = styled.div<ColorsProp>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid ${({ colors }) => colors.normalControlBorderColor};
    margin-top: ${SPACING.XS}px;
    padding: ${SPACING.SM}px ${SPACING.MD}px;
`;

export const DateRangePickerDateTimeFieldContainer = styled.div`
    display: grid;
    grid-gap: ${SPACING.MD}px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr;
`;

export const Wrapper = styled.div<ColorsProp & { isOpen: boolean }>`
    position: absolute;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    z-index: ${ELEVATIONS.DASHBOARD_DROPDOWN};
    border: 1px solid ${({ colors }) => colors.normalControlBorderColor};
    background-color: ${({ colors }) => colors.backgroundColor};
    top: 92px;
    right: 45px;
    width: 120px;
`;

export const Container = styled.div<ColorsProp>`
    display: grid;
    grid-auto-flow: column;
    align-items: end;
    grid-gap: ${SPACING.SM}px;
    justify-content: start;
    padding: ${SPACING.SM}px ${SPACING.MD}px;
    background-color: ${({ colors }) => colors.backgroundColor};
    color: ${({ colors }) => colors.foregroundColor};
    &:hover {
        cursor: pointer;
        background-color: ${({ colors }) => colors.groupedBackgroundColor};
    }
`;

export const ContentBox = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    gap: ${SPACING.SM}px;
`;
