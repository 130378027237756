import { Debugger, Expander, FileUpload, Footer, Form } from 'components';
import React, { useMemo } from 'react';
import {
    getDocumentTypes,
    getDocumentTypesForTreeView,
    additionalInfoDocTypes,
} from '../LegalEntityDocumentPreparation/utils';

import ActionPanel from './components/ActionPanel/ActionPanel';
import DomesticRegistrations from './components/DomesticRegistration/DomesticRegistrations';
import InformationAddresses from './components/Addresses/InformationAddresses';
import InformationDetails from './components/InformationDetails/InformationDetails';
import { LegalEntityInformationProps } from './model';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from '../models';
import TaxCodes from './components/TaxCodes/TaxCodes';
import { useLegalEntityInformation } from '../context/Provider';
import { ContentBox } from 'modules/RequestsDashboard/components/components.styled';
import PageUpdateInfo from '../LegalEntityDissolution/components/PageUpdateInfo';
import useLastCompletedTask from '../LegalEntityCreationView/hooks/use-last-completed-task';

export default function LegalEntityInformation(props: LegalEntityInformationProps) {
    const { workItem, mode } = props;
    const context = useLegalEntityInformation();
    const {
        state: {
            data: {
                values: { entityOid, legalTypeId },
            },
        },
    } = context;

    const newDocumentTypes = useMemo(() => {
        const types = [...additionalInfoDocTypes, ...getDocumentTypes(legalTypeId)];
        return types.sort((a, b) => a.Value.localeCompare(b.Value));
    }, [legalTypeId]);
    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.INFORMATION);

    const showDocumentsInReadOnlyTreeView = props.mode === 'Readonly' && props.workItem?.workItemStatus !== 'InProcess';
    
    const lastSubmitTask = useLastCompletedTask({
        workItem: workItem,
        taskType: 'RequestTaskType'
    });

    const lastApproveTask = useLastCompletedTask({
        workItem: workItem,
        taskType: 'UpdateRequestTaskType'
    });

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Legal_Entity_Information} />
            <Form>
                <InformationDetails />
                <TaxCodes />
                <DomesticRegistrations />
                <InformationAddresses />
                <Expander
                    header={showDocumentsInReadOnlyTreeView ? 'Existing Documents' : 'Documents'}
                    requestStep={RequestStepsView.Legal_Entity_Information}
                    content={
                        <FileUpload
                            entityId={entityOid}
                            sectionName={SECTION_NAMES.INFORMATION}
                            showStampedDocuments={false}
                            documentTypes={newDocumentTypes}
                            context={context}
                            effectiveDate='required'
                            showDocumentsInReadOnlyTreeView={showDocumentsInReadOnlyTreeView}
                            treeViewDocumentTypes={treeViewDocumentTypes}
                            standaloneCreateMode={props.mode === 'Create'}
                        />
                    }
                />
                <RejectReason mode={mode} taskType='UpdateRequestTaskType' tasks={workItem?.tasks} />
                <ContentBox>
                    <PageUpdateInfo label={"Last Submitted by"} lastCompletedTask={lastSubmitTask} />
                    <PageUpdateInfo label={"Last Approved by"} lastCompletedTask={lastApproveTask} />
                </ContentBox>
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
