import { ReferenceData, referenceDataIds } from '../models/reference-data';
import {
    setLoadingState,
    unsetLoadingState,
    useCreationViewContext,
} from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

import { ILegalEntityService } from '../../services/interfaces';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

export default function useReferenceData() {
    const service = ServiceLocator.container.resolve(ILegalEntityService);
    const { dispatch } = useCreationViewContext();

    const fetch = async (): Promise<ReferenceData> => {
        return await service.getReferenceData(referenceDataIds);
    };

    const queryResult = useQuery<ReferenceData, Error>('Reference Data', fetch, {
        staleTime: 5 * 60 * 1000,
    });

    const loadingKey = 'loading ref data';

    useEffect(() => {
        if (queryResult.isLoading) {
            setLoadingState(dispatch, loadingKey);
        } else {
            unsetLoadingState(dispatch, loadingKey);
        }
    }, [queryResult.isLoading, dispatch]);

    return {
        ...queryResult,
        data: queryResult.data ?? ({} as ReferenceData),
    };
}
