import { DocumentsResponse } from '../../models/LegalEntityRequest/Documents/DocumentsResponse';
import { IDocumentService } from '../interfaces/IDocumentService';
import { LogError } from '../../models';
import { StoredDocumentModel } from 'components/controls/FileUpload/models';
import { isLowerEnvironment } from '../../Utilities/Environment';

export class DocumentServiceMock implements IDocumentService {
    lowerEnvironment: boolean = isLowerEnvironment();

    public logInfo: LogError = {
        service: 'Legal Entity Request Service',
        module: 'Global',
    };

    private logEvent(message: string, values: any): void {
        if (!this.lowerEnvironment) {
            return;
        }

        console.log(message, values);
    }

    public async UploadFile(
        entityOid: number,
        file: any, // eslint-disable-line
        sectionName: string,
        documentType: number,
        taxRegistration?: number,
        effectiveDate?: Date
    ): Promise<any> {
        this.logEvent('DocumentService Mock - UploadFile', {
            entityOid,
            sectionName,
            documentType,
            taxRegistration,
            effectiveDate,
        });

        let response = new Promise<any>((resolve) => {
            resolve('success');
        });
        return response;
    }

    public DownloadFile(entityOid: number, documentId: number): Promise<any> {
        this.logEvent('DocumentService Mock - DownloadFile', { entityOid, documentId });

        let response = new Promise<any>((resolve) => {
            resolve('success');
        });

        return response;
    }

    public GetFiles(entityOid: number): Promise<DocumentsResponse[]> {
        this.logEvent('DocumentService Mock - GetFiles', { entityOid });

        let response = new Promise<DocumentsResponse[]>((resolve) => {
            resolve([]);
        });

        return response;
    }

    public UpdateDocumentElements(document: StoredDocumentModel): Promise<any> {
        this.logEvent('DocumentService Mock - UpdateDocumentSection', { document });

        let response = new Promise<DocumentsResponse[]>((resolve) => {
            resolve([]);
        });

        return response;
    }

    public DeleteFile(entityOId: number, documentId: number): Promise<any> {
        this.logEvent('DocumentService Mock - DeleteFile', { entityOId, documentId });

        let response = new Promise<any>((resolve) => {
            resolve('success');
        });

        return response;
    }
}
