import { isNil } from 'lodash';

export function removeAt(array: Array<any> | undefined, index: number) {
    if (isNil(array)) {
        return array;
    }
    return [...array.filter((_, idx) => idx !== index)];
}

export function hasElement(array?: Array<any>, element?: any) {
    return isNil(element) || isNil(array) ? false : array?.includes(element);
}
