import { IDocumentService } from '../../services/interfaces/IDocumentService';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { StoredDocument } from '../../components/controls/FileUpload/models';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export default function useDocuments(entityId: number, key?: number | string) {
    const service = ServiceLocator.container.resolve(IDocumentService);
    const params = useMemo(() => ['Documents', entityId, key].filter((element) => element), [entityId, key]);

    const fetch = async (entityId: number): Promise<StoredDocument[]> => {
        if (!entityId) {
            return [];
        }

        return await service.GetFiles(entityId);
    };

    return useQuery(params, () => fetch(entityId), {
        refetchOnWindowFocus: false,
    });
}
