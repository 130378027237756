import { ThemeColors, WaitingIndicator } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

export default function GridLoadingOverlay() {
    const colors = useColors();
    return (
        <div className='ag-overlay-loading-center'>
            <Component colors={colors}>
                <WaitingIndicator id={'spinner'} isVisible isModalToShell={false} />
                <span>Loading Legal Entity Requests</span>
            </Component>
        </div>
    );
}

const Component = styled.div<{ colors: ThemeColors }>`
    padding: ${SPACING.SM}px;
    padding-top: ${SPACING.XXS}px;
    width: 300px;
    #spinner div:first-child {
        background: none;
    }
    & > span {
        padding: 0;
    }
`;
