import { Border, HORIZONTAL_FORM_PADDING, SPACING } from '../../../../../Utilities/Layout';
import React, { Fragment } from 'react';

import { Expander } from 'components/';
import Registration from './Registration';
import { RegistrationData } from 'modules/LegalEntityCreation/LegalEntityInformation/model';
import { StackPanel } from '@bxgrandcentral/controls';
import useColors from 'api/hooks/use-theme';

type Props = {
    registrations?: RegistrationData[];
    registrationType: 'Domestic' | 'Foreign';
};

export default function Registrations({ registrations = [], registrationType }: Props) {
    const { normalControlBorderColor } = useColors();

    return (
        <Expander
            header={`${registrationType} Registration Details`}
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL} padding={HORIZONTAL_FORM_PADDING}>
                    {registrations?.length ? (
                        registrations.map((registrationData, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Border color={normalControlBorderColor} />}
                                <Registration registrationData={registrationData} />
                            </Fragment>
                        ))
                    ) : (
                        <p>{`There is no ${registrationType} registration.`}</p>
                    )}
                </StackPanel>
            }
        />
    );
}
