import { Button, StackPanel } from '@bxgrandcentral/controls';

import { LegalEntityApprovalProps } from './model';
import React from 'react';
import { TaskActions } from '../Shared/TaskActions';
import { isOpen } from 'models/Workflow';
import { setShouldReload } from '../context/actions/form-actions';
import { useLegalEntityApproval } from '../context/Provider';

export default function ActionPanel(props: LegalEntityApprovalProps) {
    const {
        state: { canSave },
        dispatch,
        getChanges,
    } = useLegalEntityApproval();

    if (props.mode === 'Edit') {
        const {
            workItem: { tasks },
        } = props;

        const submitTask = tasks?.filter(
            ({ taskType, status }) => taskType === 'TransitionToPostProcess1TaskType' && isOpen(status)
        )?.[0];

        return submitTask ? (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    isEnabled={canSave}
                    styleName='textOnlyButtonStyle'
                    onClick={async () => {
                        await props.onSave(getChanges());
                        setShouldReload(dispatch, true);
                    }}
                />
                <TaskActions
                    task={submitTask}
                    preSubmit={() => props.onSave(getChanges())}
                    onCompleteTask={(task, exitCode, rejectReason) =>
                        props.onCompleteTask(task, exitCode as string, rejectReason)
                    }
                />
            </StackPanel>
        ) : null;
    }
    return null;
}
