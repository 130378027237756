import styled from 'styled-components';

export const FormControl = styled.div<{ height?: number }>`
    display: flex;
    flex-direction: column;
    &.form {
        height: ${({ height }) => `${height ?? 83}px`};
    }
    &.dashboard {
        & > div > div {
            background: none;
        }
    }
`;
