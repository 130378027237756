import { setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import { Capabilities } from '../RequestStepsView';
import LegalEntityDissolutionTreasurySection from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTreasury/LegalEntityDissolutionTreasurySection';
import React from 'react';
import { isEmpty } from 'lodash';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useHasCapability from '../hooks/use-has-capability';

export default function DissolutionTreasuryStepRenderer() {
    const { updateDissolutionSection, completeTask } = useApiServices();
    const { dispatch: appContextDispatch } = useAppContext();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            legalEntityDissolutionTreasuryWorkItem: workItem,
            legalEntityDissolutionParentWorkItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const hasCapability = useHasCapability();

    const commonProps = {
        data: sections.DissolutionTreasury,
        noPendingData: noPendingSections.DissolutionTreasury,
        workItem,
    };

    if (isEmpty(commonProps.data)) {
        return null;
    }

    if (
        !hasCapability(Capabilities.approveDissolutionTreasury) ||
        isWorkItemsRefreshing ||
        (!selectedPanel?.hasActions && !editWorkFlow)
    ) {
        return <LegalEntityDissolutionTreasurySection mode='Readonly' {...commonProps} />;
    }

    switch (workItem?.workItemState) {
        case 'Treasury Approval':
            return (
                <LegalEntityDissolutionTreasurySection
                    mode='Edit'
                    onSave={(dataToSave) => {
                        const data = {
                            ...sections.DissolutionTreasury,
                            ...dataToSave,
                        };
                        return updateDissolutionSection(
                            sections,
                            'DissolutionTreasury',
                            legalEntityDissolutionParentWorkItem?.workItemId!,
                            data
                        ).then((e) => e?.workflowId ?? 0);
                    }}
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        if (workItem) {
                            await completeTask(workItem, task, exitCode, 'DissolutionTreasury', rejectReason, {
                                skipRequestNotesUpdate: true,
                            });
                            setResetLegalEntityCreationForm(appContextDispatch, true);
                        }
                    }}
                    {...commonProps}
                />
            );
        default:
            return <LegalEntityDissolutionTreasurySection mode='Readonly' {...commonProps} />;
    }
}
