import { TextField as GrandCentralTextField, Grid, TextBlock, TextFieldProps } from '@bxgrandcentral/controls';
import React, { useEffect, useMemo, useState } from 'react';

import { FormControl } from '../controls.styled';
import { SPACING } from 'Utilities/Layout';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import useColors from 'api/hooks/use-theme';

type Props = Omit<TextFieldProps, 'validationError'> & {
    validationError?: string;
    version?: number;
    labelToolTip?: any;
    isPendingChange?: boolean;
    variant?: 'form' | 'dashboard' | 'default';
    debounce?: number;
};

export default function TextField({
    isEditable,
    isRequired,
    validationError,
    label,
    labelToolTip,
    value,
    version,
    isPendingChange,
    variant = 'form',
    debounce = 200,
    onValueChanged,
    canResetValue = true,
    ...rest
}: Props) {
    const [newValue, setNewValue] = useState({ value });
    const { accentColor } = useColors();

    useEffect(() => {
        const handler = setTimeout(() => {
            isEditable && onValueChanged && onValueChanged(newValue.value || undefined);
        }, debounce);
        return () => {
            clearTimeout(handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const key = useMemo(() => Math.random(), [isEditable, rest.defaultValue, version]);

    return (
        <FormControl className={variant}>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <>
                    <TextBlock
                        styleName='fieldLabelStyle'
                        text={label}
                        toolTip={labelToolTip}
                        margin={`0 0 ${SPACING.XXS}px 0`}
                        minHeight={20}
                    />
                    {!isEditable && isPendingChange && (
                        <TextBlock
                            styleName='fieldLabelStyle'
                            fontSize='16px'
                            text='PENDING'
                            foregroundColor={accentColor}
                            textAlignment='right'
                        />
                    )}
                </>
            </Grid>
            <div>
                <GrandCentralTextField
                    key={key}
                    value={value || ''}
                    isEditable={isEditable}
                    isRequired={isRequired && isEditable}
                    onValueChanged={(newValue?: string) => {
                        setNewValue({ value: newValue });
                    }}
                    canResetValue={canResetValue}
                    {...rest}
                />
                {isEditable && <ValidationMessage message={validationError} />}
            </div>
        </FormControl>
    );
}
