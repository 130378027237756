import { DateRangeLabels } from '../model';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';
import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type ButtonVariant = 'left' | 'right';

type Props = {
    label: DateRangeLabels;
    activeDateRange: DateRangeLabels | null;
    variant: ButtonVariant;
    onClick: () => void;
};

export default function ToggleButton({ label, activeDateRange, variant, onClick }: Props) {
    const isActive = activeDateRange === label;

    const colors = useColors();
    return (
        <Container isActive={isActive} colors={colors} onClick={onClick} variant={variant}>
            <Text foregroundColor={isActive ? colors.invertedForegroundColor : colors.foregroundColor}>{label}</Text>
        </Container>
    );
}

const Container = styled.div<{ isActive: boolean; colors: ThemeColors; variant: ButtonVariant }>`
    background-color: ${({ colors, isActive }) => (isActive ? colors.accentColor : 'none')};
    border: ${({ colors, isActive }) => `1px solid ${isActive ? colors.accentColor : colors.normalControlBorderColor}`};
    border-radius: ${({ variant }) => (variant === 'left' ? '10px 0 0 10px' : '0 10px 10px 0')};
    border-left-width: ${({ variant }) => (variant === 'right' ? '0px' : '1px')};
    display: flex;
    justify-content: center;
    padding: ${SPACING.XS}px;
    &:hover {
        cursor: pointer;
        border: ${({ colors }) => `1px solid ${colors.accentColor}`};
        span {
            color: ${({ colors, isActive }) => (!isActive ? colors.accentColor : colors.invertedForegroundColor)};
        }
    }
`;
