import { Countries, ValidationMessages } from '../models';
import { DocumentTypes, NewDocumentTypes } from '../models/shared/refData/DocumentType';
import { useCallback, useMemo } from 'react';

import { DocumentSection } from 'modules/LegalEntityCreation/context/model';
import { LegalTypes } from '../models/LegalEntityRequest/DefaultValues';
import { hasElement } from 'Utilities/array';
import { isNil } from 'lodash';

type DocumentRequirement = {
    legalType: LegalTypes;
    requiredTypes: DocumentTypes[];
    errorMessage: string;
    isAllRequiredTypesNeeded?: boolean;
};

type Props = {
    country?: number;
    legalType?: number;
    section?: DocumentSection;
};

export default function useDocumentPreparationRequirements({ country, legalType, section }: Props) {
    const { pendingDocuments = [], storedDocuments = [] } = section ?? {};
    const getDocumentTypeRequirements = useCallback((): DocumentRequirement[] => {
        switch (country) {
            case Countries.Luxembourg: {
                return [];
            }
            case Countries.UnitedStates: {
                return [
                    {
                        legalType: LegalTypes.Corporation,
                        requiredTypes: [
                            NewDocumentTypes.CertificateIncorporation,
                            NewDocumentTypes.ActionOfSoleIncorporator,
                        ],
                        errorMessage: ValidationMessages.IncorporationOrActionOfSole,
                    },
                    {
                        legalType: LegalTypes.LimitedPartnership,
                        requiredTypes: [NewDocumentTypes.CertificateLimitedPartnership],
                        errorMessage: ValidationMessages.CertificateLimitedPartnership,
                    },
                    {
                        legalType: LegalTypes.LimitedLiabilityCompany,
                        requiredTypes: [NewDocumentTypes.FormationDocument],
                        errorMessage: ValidationMessages.FormationDocument,
                    },
                    {
                        legalType: LegalTypes.Trust,
                        requiredTypes: [NewDocumentTypes.TrustDeed, NewDocumentTypes.TrustAgreement],
                        errorMessage: ValidationMessages.TrustLegalTypeDocumentRequired,
                    },
                ];
            }
            case Countries.CaymanIslands: {
                return [
                    {
                        legalType: LegalTypes.LimitedPartnership,
                        requiredTypes: [NewDocumentTypes.CertificateRegistration],
                        errorMessage: ValidationMessages.CertificateRegistration,
                    },
                    {
                        legalType: LegalTypes.LimitedLiabilityCompany,
                        requiredTypes: [NewDocumentTypes.CertificateRegistration, NewDocumentTypes.LpllcAgreement],
                        errorMessage: ValidationMessages.LLCAgreementAndCertificateRegistration,
                        isAllRequiredTypesNeeded: true,
                    },
                    {
                        legalType: LegalTypes.Corporation,
                        requiredTypes: [NewDocumentTypes.MemorandumArticleAssociation],
                        errorMessage: ValidationMessages.MemorandumArticleAssociation,
                    },
                    {
                        legalType: LegalTypes.Trust,
                        requiredTypes: [NewDocumentTypes.TrustDeed, NewDocumentTypes.TrustAgreement],
                        errorMessage: ValidationMessages.TrustLegalTypeDocumentRequired,
                    },
                ];
            }
            case Countries.Ireland: {
                return [
                    {
                        legalType: LegalTypes.Icav,
                        requiredTypes: [
                            NewDocumentTypes.Prospectus,
                            NewDocumentTypes.MemorandumArticleAssociation,
                            NewDocumentTypes.CertificateRegistration,
                        ],
                        errorMessage: ValidationMessages.ProspectusMemorandumAndCertificate,
                        isAllRequiredTypesNeeded: true,
                    },
                ];
            }
            default: {
                return [
                    {
                        legalType: LegalTypes.Trust,
                        requiredTypes: [NewDocumentTypes.TrustAgreement, NewDocumentTypes.TrustDeed],
                        errorMessage: ValidationMessages.TrustLegalTypeDocumentRequired,
                    },
                    {
                        legalType: LegalTypes.LimitedPartnership,
                        requiredTypes: [
                            NewDocumentTypes.CertificateLimitedPartnership,
                            NewDocumentTypes.CertificateRegistration,
                            NewDocumentTypes.ExtractsFromPublicRegister,
                            NewDocumentTypes.LpllcAgreement,
                        ],
                        errorMessage: ValidationMessages.CertificateLimitedExtracts,
                    },
                    {
                        legalType: LegalTypes.LimitedLiabilityCompany,
                        requiredTypes: [
                            NewDocumentTypes.CertificateRegistration,
                            NewDocumentTypes.ExtractsFromPublicRegister,
                            NewDocumentTypes.FormationDocument,
                            NewDocumentTypes.LpllcAgreement,
                        ],
                        errorMessage: ValidationMessages.CertificateLimitedExtractsLCC,
                    },
                    {
                        legalType: LegalTypes.Corporation,
                        requiredTypes: [
                            NewDocumentTypes.CertificateIncorporation,
                            NewDocumentTypes.ExtractsFromPublicRegister,
                        ],
                        errorMessage: ValidationMessages.CertificateIncorporationOrExtracts,
                    },
                ];
            }
        }
    }, [country]);

    const documentTypeError = useMemo(() => {
        if (isNil(country)) {
            return '';
        }
        const requirements = getDocumentTypeRequirements().find((requirement) => requirement.legalType === legalType);

        if (isNil(requirements)) {
            return '';
        }
        const { requiredTypes, errorMessage, isAllRequiredTypesNeeded } = requirements;
        if (isAllRequiredTypesNeeded) {
            const requiredDocsAttached = requiredTypes.map((type) => {
                return (
                    pendingDocuments.some(({ documentTypeId }) => documentTypeId === type) ||
                    storedDocuments.some(
                        ({ document, updates }) => document.coreDocumentTypeId === type && !updates.isDeleted
                    )
                );
            });
            return requiredDocsAttached.every(Boolean) ? '' : errorMessage;
        }
        const hasRequiredPendingDocuments =
            pendingDocuments &&
            pendingDocuments.some(({ documentTypeId }) => {
                return hasElement(requiredTypes, documentTypeId);
            });

        const hasRequiredStoredDocuments =
            storedDocuments &&
            storedDocuments.some(
                ({ document: { coreDocumentTypeId }, updates: { isDeleted } }) =>
                    !isDeleted && requiredTypes.includes(coreDocumentTypeId)
            );

        return !hasRequiredPendingDocuments && !hasRequiredStoredDocuments ? errorMessage : '';
    }, [pendingDocuments, storedDocuments, country, legalType, getDocumentTypeRequirements]);

    return { documentTypeError };
}
