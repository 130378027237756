import { DocumentSections, FormContext, FormState, ValidationErrors } from './model';
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { getKeysOf, mergeObjects } from 'Utilities/object';
import { isEqual, isEqualWith, isNil, noop } from 'lodash';
import { resetState, setIsEditable } from './actions/form-actions';
import { setCanSaveLegalEntityCreationForm, setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import AutoFill from 'components/AutoFill/AutoFIll';
import { ErrorBoundary } from 'components';
import { REQUEST_CREATION_DEFAULT_VALUES } from '../models';
import { WaitingIndicator } from '@bxgrandcentral/controls';
import { createReducer } from './reducer';
import { isEditable } from '../utils';

type ProviderProps<T> = {
    storedValues: Partial<T> | undefined;
    noPendingValues?: Partial<T>;
    calculateDefaultValues?: (data: Partial<T> | undefined) => Partial<T>;
    mode: string;
    requestStep?: string;
    children: ReactNode;
};

type GetValue<T, K extends keyof T> = {
    value?: Partial<T>[K];
    defaultValue?: Partial<T>[K];
    validationError: string | undefined;
    version: number;
    isEditable: boolean;
    onValueChanged: (newValue?: T[K]) => void;
    isPendingChange: boolean;
};

export type UseFormContext<T> = {
    state: FormState<T>;
    dispatch: any;
    getValue: <K extends keyof T>(key: K) => GetValue<T, K>;
    setValue: <K extends keyof T>(key: K, value: T[K], isUserChange?: boolean) => void;
    setValues: (values: Partial<T>, isUserChange?: boolean) => void;
    getChanges: () => Partial<T>;
};

export function createContextFactory<T>(displayName = 'Form Context') {
    const Context = createContext<FormContext<T>>({
        state: {} as FormState<T>,
        dispatch: () => noop,
    });
    Context.displayName = displayName;

    function Provider({
        storedValues = {},
        noPendingValues,
        calculateDefaultValues = (_) => ({}),
        mode,
        requestStep,
        children,
    }: ProviderProps<T>) {
        const reducer = useMemo(() => createReducer<T>(), []);
        const autoPopulatedValues = calculateDefaultValues(storedValues);
        const [state, dispatch] = useReducer(reducer, {
            data: {
                storedValues,
                noPendingValues,
                values: mergeObjects(storedValues, autoPopulatedValues),
                autoPopulatedValues,
                changes: {},
                validationErrors: {} as ValidationErrors<T>,
                calculateDefaultValues,
            },
            documents: {
                sections: {} as DocumentSections,
                canSave: true,
                canSubmit: true,
            },
            isEditable: false,
            isLoading: false,
            canSave: false,
            canSubmit: false,
            canApprove: false,
            canCreate: false,
            shouldReload: false,
            isSubmitted: false,
            shouldUpdateNewDocuments: false,
            version: 0,
        });

        const {
            data: { changes },
            shouldReload,
            isLoading,
            canSave,
        } = state;

        useEffect(() => {
            setIsEditable(dispatch, isEditable(mode));
        }, [mode, dispatch]);

        const {
            state: {
                settings: { isAutoPopulationEnabled },
                legalEntityCreation: { resetForm },
            },
            dispatch: appContextDispatch,
        } = useAppContext();

        useEffect(() => {
            if (shouldReload) {
                resetState(dispatch, storedValues, noPendingValues);
            } else if (resetForm) {
                resetState(dispatch, storedValues, noPendingValues);
                setResetLegalEntityCreationForm(appContextDispatch, false);
            }
        }, [appContextDispatch, mode, noPendingValues, resetForm, shouldReload, storedValues]);

        useEffect(() => {
            setCanSaveLegalEntityCreationForm(appContextDispatch, canSave);
        }, [appContextDispatch, canSave]);

        useEffect(() => {
            return () => setCanSaveLegalEntityCreationForm(appContextDispatch, false);
        }, [appContextDispatch]);

        return (
            <Context.Provider value={{ state, dispatch }}>
                <ErrorBoundary>
                    {children}
                    {isAutoPopulationEnabled && (
                        <AutoFill
                            requestStep={requestStep}
                            dispatch={dispatch}
                            isEnabled={
                                isEditable(mode) &&
                                !isNil(requestStep) &&
                                !isEqualWith(changes, REQUEST_CREATION_DEFAULT_VALUES[requestStep])
                            }
                        />
                    )}
                    <WaitingIndicator isVisible={isLoading} id='spinner' />
                </ErrorBoundary>
            </Context.Provider>
        );
    }

    function useFormContext(): UseFormContext<T> {
        const { state, dispatch } = useContext(Context);

        const {
            data: { values, storedValues, validationErrors, noPendingValues },
            isEditable,
            version,
        } = state;

        if (isNil(state)) {
            throw new Error('useFormContext must be used within a FormProvider');
        }

        const isPendingChanged = useCallback(
            <K extends keyof T>(key: K) => {
                return !isNil(noPendingValues) && !isEqual(storedValues[key], noPendingValues[key]);
            },
            [noPendingValues, storedValues]
        );

        const setValue = useCallback(
            <K extends keyof T>(key: K, value: T[K] | undefined, isUserChange: boolean = true) => {
                dispatch({ type: 'SET_VALUE', key, value, isUserChange });
            },
            [dispatch]
        );

        const setValues = useCallback(
            (values: Partial<T>, isUserChange: boolean = true) => {
                dispatch({ type: 'SET_VALUES', values, isUserChange });
            },
            [dispatch]
        );

        const onValueChanged = useCallback(
            <K extends keyof T>(key: K) => {
                return (newValue?: T[K]) => {
                    setValue(key, newValue);
                };
            },
            [setValue]
        );

        const getValue = useCallback(
            <K extends keyof T>(key: K): GetValue<T, K> => {
                return {
                    value: values[key],
                    defaultValue: storedValues[key],
                    isPendingChange: isPendingChanged(key),
                    validationError: validationErrors[key],
                    isEditable,
                    onValueChanged: onValueChanged(key),
                    version,
                };
            },
            [values, storedValues, isPendingChanged, validationErrors, isEditable, onValueChanged, version]
        );

        const getChanges = useCallback(() => {
            const {
                data: { changes, autoPopulatedValues, storedValues },
            } = state;

            return getKeysOf(Object.assign(changes, autoPopulatedValues)).reduce((result, key) => {
                return storedValues.hasOwnProperty(key) && isEqual(storedValues[key], values[key])
                    ? result
                    : { ...result, [key]: values[key] };
            }, {});
        }, [state, values]);

        return { state, dispatch, getValue, setValue, setValues, getChanges };
    }

    return { Context, Provider, useFormContext };
}
