export function isIn<T extends {}>(source: T, key: keyof T) {
    return key in source;
}

// https://stackoverflow.com/a/49286056
export type ValueOf<T> = T[keyof T];

export function nameOf<T extends { [key: string]: any }>(key: keyof T) {
    return key;
}

// original from https://github.com/joonhocho/tsdef
export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer I> ? Array<RecursivePartial<I>> : RecursivePartial<T[P]>;
};

export function assertNever(x: never, message: string = `'${x}' is unexpected`): never {
    throw new Error(message);
}
