import { EntityStatusId } from '../LegalEntity/EntityStatus';
import { EntitySubType } from '../LegalEntity/EntitySubType';
import { SearchEntity } from '..';
import { EntityType } from '../LegalEntity/EntityType';

type entityStatusSearchFilter = {
    field: 'entityStatusId';
    value: EntityStatusId[];
};
type entitySubTypeSearchFilter = {
    field: 'entitySubtypes';
    value: EntitySubType[];
};
type dataOwnerSearchFilter = {
    field: 'dataOwnerId';
    value: [2];
};
type businessUnitSearchFilter = {
    field: 'businessUnitIds';
    isOr?: boolean;
    value: number[];
};
type subBusinessUnitSearchFilter = {
    field: 'subBusinessUnitIds';
    isOr?: boolean;
    value: number[];
};
type shortNameSearchFilter = {
    field: 'shortName';
    value: string;
};
type entityTypeSearchFilter = {
    field: 'entityTypeId';
    value: EntityType[];
};
type searchFilter =
    | entityStatusSearchFilter
    | entitySubTypeSearchFilter
    | dataOwnerSearchFilter
    | businessUnitSearchFilter
    | entityTypeSearchFilter
    | subBusinessUnitSearchFilter
    | shortNameSearchFilter;

export enum RequestedSearchExecutionType {
    NameSearch = 'NameSearch',
    FieldSearch = 'FieldSearch',
    ExactMatchFieldSearch = 'ExactMatchFieldSearch',
}

export enum SearchOperatorType {
    Or = 'Or',
    And = 'And',
}

export enum SubfieldToSearch {
    SearchAutoCompleteTerms = 'SearchAutoCompleteTerms',
    SearchTermsMatch = 'SearchTermsMatch',
    SearchExactMatch = 'SearchExactMatch',
    SearchDefaultField = 'SearchDefaultField',
}

export enum FieldSearchType {
    MultiField_BestFields = 'MultiField_BestFields',
    MultiField_MostFields = 'MultiField_MostFields',
    MultiField_CrossOverFields = 'MultiField_CrossOverFields',
    MultiField_Phrase = 'MultiField_Phrase',
    MultiField_PhrasePrefix = 'MultiField_PhrasePrefix',
    SingleField_MatchTerm = 'SingleField_MatchTerm',
    SingleField_MatchPhrase = 'SingleField_MatchPhrase',
    SingleField_MatchPhrasePrefix = 'SingleField_MatchPhrasePrefix',
    SingleField_WildcardSearch = 'SingleField_WildcardSearch',
}

export enum SearchType {
    Include = 'Include',
    Exclude = 'Exclude',
    Should = 'Should',
}

export enum IndexType {
    MdmEntity = 'MdmEntity',
    RestrictedList = 'RestrictedList',
}

export enum SearchAnalyzerType {
    Standard = 'Standard',
    Whitespace = 'Whitespace',
    Simple = 'Simple',
}

export enum SortDirection {
    Asc = 'Asc',
    Desc = 'Desc',
}

export interface AdvancedFieldOptionItem {
    subFieldToSearch?: SubfieldToSearch;
    boostOverride?: number;
    searchInclusionTypeOverride?: string;
    searchAnalyzerTypeOverride?: string;
    fuzzinessOverride?: number;
    includeHighlightingOverride?: boolean;
    minimumShouldMatchOverride?: string;
}

export interface FieldItem {
    field: string;
    advancedFieldOptions?: AdvancedFieldOptionItem[];
    boost?: number;
    fuzzinessOverride?: number;
    searchInclusionTypeOverride?: string;
    searchAnalyzerTypeOverride?: string;
    includeHighlighting?: boolean;
    minimumShouldMatchOverride?: string;
    subFieldToSearch?: string;
}

export interface RequestedSearchItem {
    queryString: string;
    fields: FieldItem[];
    searchInclusionType?: SearchType;
    searchAnalyzerType?: SearchAnalyzerType;
    fuzziness?: number;
    minimumShouldMatch?: string;
    searchOperator?: SearchOperatorType;
    fieldSearchType?: FieldSearchType;
}

export interface RequestedSearchFilter<T = any> {
    field?: keyof T;
    value?: string[];
}

export interface RequestedIndexSearch {
    searchItems: RequestedSearchItem[];
    searchFilters?: searchFilter[];
}

export interface RequestedSort<T> {
    field?: keyof T;
    direction: SortDirection;
}

export interface RequestedSearchtResultOptions<T> {
    numberOfResults?: number;
    startingFrom?: number;
    fieldsToReturn?: (keyof T)[];
    sort?: RequestedSort<T>[];
    includeMetaData: boolean;
}

export interface EntityMasterSearchRequestV4<T> {
    indexType: IndexType;
    indexSearchRequest?: RequestedIndexSearch;
    resultOptions?: RequestedSearchtResultOptions<T>;
}

export type SearchRequest<T extends Partial<SearchEntity>> = {
    search: string;
    searchFilters: searchFilter[];
    numberOfResults: number;
    Headers: (keyof T)[];
};
