import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { useMemo } from 'react';
import { isEmpty, isNull } from 'lodash';
import { setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { LegalEntityDomesticRegistrationDataProps } from '../../model';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { TaskActions } from 'modules/LegalEntityCreation/Shared/TaskActions';
import { getSectionName } from 'components/controls/FileUpload/utils';
import { isOpen } from 'models/Workflow';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityRegistration } from 'modules/LegalEntityCreation/context/Provider';
import { validateDateTimeField } from 'modules/LegalEntityCreation/validation/utils';

export default function ActionPanel(
    props: LegalEntityDomesticRegistrationDataProps & { isDomesticRegistration?: boolean }
) {
    const { state, dispatch } = useLegalEntityRegistration();

    const {
        data: {
            values: { entityOid, key },
            changes,
        },
        documents: { sections },
        canSave,
        canSubmit,
    } = state;

    const { storedDocuments, isFetching: isDocumentsFetching } = sections[SECTION_NAMES.DOMESTIC_REGISTRATION] ?? {};

    const isValidStoredDocuments = useMemo(
        () =>
            storedDocuments && props.isDomesticRegistration
                ? storedDocuments.every(
                      ({
                          document: { effectiveDateString, documentElements },
                          updates: { effectiveDate, sectionName },
                      }) => {
                          const section = sectionName ?? getSectionName(documentElements);
                          const effectiveDateValue =
                              effectiveDate || isNull(effectiveDate) ? effectiveDate : effectiveDateString || undefined;

                          return section === SECTION_NAMES.STAMPED
                              ? !validateDateTimeField(effectiveDateValue, {
                                    isOptionalWhen: false,
                                    canBeFutureDate: false,
                                })
                              : true;
                      }
                  )
                : true,
        [props.isDomesticRegistration, storedDocuments]
    );

    const { updateDocuments } = useDocumentUpload();

    const submitTask = useMemo(
        () =>
            props.mode === 'Edit'
                ? props.workItem?.tasks?.find(
                      ({ taskType, status }) =>
                          ['submitToApprovalTaskType', 'ResubmitToApproval'].includes(taskType) && isOpen(status)
                  )
                : undefined,
        [props]
    );

    const handleSave = async () => {
        if (props.mode !== 'Edit') {
            return;
        }

        setIsLoading(dispatch, true);
        try {
            await updateDocuments(sections, entityOid);
            if (!isEmpty(changes)) {
                await props.onSave({ key, ...changes });
            }
            setShouldReload(dispatch, true);
        } catch (reason) {
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (props.mode !== 'Edit') {
        return null;
    }

    return (
        <StackPanel
            orientation='horizontal'
            horizontalAlignment='right'
            styleOverrides={{ justifyContent: 'space-between' }}>
            <Button
                content='Save'
                isEnabled={canSave}
                styleName='textOnlyButtonStyle'
                onClick={async () => {
                    await handleSave();
                }}
            />
            {submitTask && (
                <TaskActions
                    task={submitTask}
                    onCompleteTask={(task, exitCode) => {
                        props.onCompleteTask(task, exitCode as string);
                        setShouldReload(dispatch, true);
                    }}
                    preSubmit={async () => {
                        await handleSave();
                    }}
                    isSubmitEnabled={canSubmit && !isDocumentsFetching && isValidStoredDocuments}
                />
            )}
        </StackPanel>
    );
}
