import { VALIDATION_MESSAGES, validateRequiredField } from './utils';
import { useCallback, useEffect, useState } from 'react';

import { Address } from 'models';
import { ValidationErrors } from '../context/model';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityDocumentPreparation } from '../context/Provider';

export default function useDocumentPreparationRegisteredOfficeAddressValidation() {
    const {
        state: {
            data: {
                values: { registeredOfficeAddress },
            },
            isEditable,
            version,
        },
        dispatch,
    } = useLegalEntityDocumentPreparation();

    const [validations, setValidations] = useState<ValidationErrors<Address>>({});

    const validateCountryId = useCallback(() => {
        if (
            registeredOfficeAddress?.line1 ||
            registeredOfficeAddress?.line2 ||
            registeredOfficeAddress?.line3 ||
            registeredOfficeAddress?.line4 ||
            registeredOfficeAddress?.city ||
            registeredOfficeAddress?.postalCode
        ) {
            return validateRequiredField(registeredOfficeAddress?.countryId);
        }
    }, [registeredOfficeAddress]);

    useEffect(() => {
        setValidations({
            countryId: validateCountryId(),
        });
    }, [validateCountryId, dispatch]);

    useEffect(() => {
        if (!isEditable || isEmpty(validations)) {
            updateValidation(dispatch, { registeredOfficeAddress: undefined });
            return;
        }

        updateValidation(dispatch, {
            registeredOfficeAddress: Object.values(validations).some(
                (error) => error && error !== VALIDATION_MESSAGES.REQUIRED_FIELD
            )
                ? VALIDATION_MESSAGES.COMBINED_ERROR
                : VALIDATION_MESSAGES.REQUIRED_FIELD,
        });
    }, [validations, dispatch, version, isEditable]);

    return { validations };
}
