import { Button } from '@bxgrandcentral/controls';
import { GlobalState } from 'GlobalState';
import React from 'react';
import { capitalizeFirstLetter } from 'Utilities/string';
import { useAppContext } from 'context/app-context';

type Props = {
    isEnabled: boolean;
    onClick: () => void;
    label?: string;
    actionTopic?: string;
};
export default function SubmitButton({ isEnabled, onClick, label = 'Submit', actionTopic }: Props) {
    const {
        state: {
            settings: { isRequestConfirmationRequired },
        },
    } = useAppContext();

    return (
        <Button
            isEnabled={isEnabled}
            styleOverrides={{ alignSelf: 'flex-end' }}
            onClick={async () => {
                let isConfirmed;
                if (isRequestConfirmationRequired) {
                    isConfirmed = await GlobalState.openDialog({
                        title: `${label} ${actionTopic ? `${capitalizeFirstLetter(actionTopic)} ` : ''}Request`,
                        variant: 'warning',
                        content: `Are you sure you want to ${label.toLowerCase()} this ${
                            actionTopic ? `${actionTopic} ` : ''
                        }request?`,
                    });
                }
                if (isConfirmed || !isRequestConfirmationRequired) {
                    onClick();
                }
            }}>
            {label}
        </Button>
    );
}
