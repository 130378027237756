import { ColDef, ValueFormatterParams } from 'ag-grid-community';

import GridDeleteButton from './GridDeleteButton';
import GridPendingColumnRenderer from '../common/GridPendingColumnRenderer';
import { KeyValue } from 'models';
import { useReferenceData } from 'api';

type Props = {
    clearRowSelection: () => void;
    mode: string;
    isStandaloneWorkflow: boolean;
};

export default function useOwnershipDetailsColumnDefs({ clearRowSelection, mode, isStandaloneWorkflow }: Props) {
    const {
        data: { InterestTypes, Currency, AxiomShareClass },
    } = useReferenceData();

    const getValueFromKey = (arr: KeyValue[] | undefined, key: number, dataProperty: keyof KeyValue = 'Value') => {
        const foundItem = arr?.find((item) => item.Key === key);
        return foundItem ? foundItem[dataProperty] : '';
    };

    const ownershipDetailsColumnDefs: ColDef[] = [
        {
            field: 'hasPendingChange',
            headerName: 'Pending',
            cellClass: 'cell-pending',
            headerTooltip: 'Pending',
            hide: !(mode === 'Approval' && isStandaloneWorkflow),
            maxWidth: 90,
            cellRenderer: GridPendingColumnRenderer,
        },
        {
            field: 'relatedEntityName',
            headerName: 'Parent Entity',
            cellClass: 'cell-relatedEntityName',
            headerTooltip: 'Parent Entity',
        },
        {
            field: 'interestType',
            headerName: 'Interest Type',
            cellClass: 'cell-interestType',
            valueGetter: (params) => getValueFromKey(InterestTypes, params.data.interestType),
            headerTooltip: 'Interest Type',
        },
        {
            field: 'ownerShip',
            headerName: 'Ownership %',
            cellClass: 'cell-ownerShip',
            headerTooltip: 'Ownership %',
        },
        {
            field: 'currency',
            headerName: 'Currency',
            cellClass: 'cell-Currency',
            valueGetter: (params) => getValueFromKey(Currency, params.data.currency, 'ShortValue'),
            headerTooltip: 'Currency',
        },
        {
            field: 'shareClass',
            headerName: 'Share Class Type',
            cellClass: 'cell-shareClass',
            valueGetter: (params) => getValueFromKey(AxiomShareClass, params.data.shareClass),
            minWidth: 160,
            headerTooltip: 'Share Class Type',
        },
        {
            field: 'nominalValue',
            headerName: 'Nominal Value',
            cellClass: 'cell-nominalValue',
            minWidth: 160,
            headerTooltip: 'Nominal Value',
        },
        {
            field: 'numberOfUnits',
            headerName: 'Number of Units',
            cellClass: 'cell-numberOfUnits',
            minWidth: 160,
            headerTooltip: 'Number of Units',
        },
        {
            field: 'votingRight',
            headerName: 'Voting Rights',
            cellClass: 'cell-votingRight',
            headerTooltip: 'Voting Rights',
            valueFormatter: booleanToStringFormatter,
        },
        {
            field: 'isDeleted',
            headerName: 'Delete',
            cellClass: 'cell-delete',
            pinned: 'right',
            maxWidth: 85,
            cellRenderer: GridDeleteButton,
            cellRendererParams: {
                clearRowSelection,
            },
            hide: !['Create', 'Edit'].includes(mode),
        },
    ];

    return { ownershipDetailsColumnDefs };
}

const booleanToStringFormatter = ({ value }: ValueFormatterParams | { value: boolean }) => (value ? 'Yes' : 'No');
