import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { LegalEntityDissolutionLegalData } from '../model';
import { useCallback } from 'react';

export default function useDissolutionLegalAutopopulateData(data?: LegalEntityDissolutionLegalData) {
    const calculateDefaultValues = useCallback(
        () => ({
            noOutstandingFundRelatedDuties: CompletionStateKeys.Incomplete,
            noActiveFundRegistrations: CompletionStateKeys.Incomplete,
        }),
        []
    );

    return { calculateDefaultValues };
}
