export enum DocumentTypes {
    CertificateLimitedPartnership = 366,
    LpllcAgreement = 190,
    CertificateRegistration = 165,
    FormationDocument = 189,
    Bylaws = 397,
    CertificateIncorporation = 244,
    MemorandumArticleAssociation = 162,
    Resolution = 356,
    ConsentToUseName = 437,
    ApplicationAuthority = 347,
    StructureChart = 191,
    ActionOfSoleIncorporator = 5097,
    RegistrationDeclaration = 5098,
    RegisterOfMember = 5099,
    TrustAgreement = 174,
    TrustDeed = 175,
    CertificateOfNameChange = 227,
    ExtractsFromPublicRegister = 168,
    W8BENE = 5038,
    W8IMY = 5034,
    W9 = 5039,
    Form8832 = 194,
    ApprovalForm8832 = 486,
    Form8821 = 381,
    FormSS4 = 193,
    EINApplication = 350,
    EINCTBConfirmation = 195,
    CRSSelfcertification = 264,
    CertificateOfIndependentDirector = 455,
    OfficerCertificate = 442,
}

export const NewDocumentTypes = {
    CertificateLimitedPartnership: 366,
    LpllcAgreement: 190,
    CertificateRegistration: 165,
    FormationDocument: 189,
    Bylaws: 397,
    CertificateIncorporation: 244,
    MemorandumArticleAssociation: 162,
    Resolution: 356,
    ConsentToUseName: 437,
    ApplicationAuthority: 347,
    StructureChart: 191,
    ActionOfSoleIncorporator: 5097,
    RegistrationDeclaration: 5098,
    RegisterOfMember: 5099,
    TrustAgreement: 174,
    TrustDeed: 175,
    CertificateOfNameChange: 227,
    ExtractsFromPublicRegister: 168,
    W8BENE: 5038,
    W8IMY: 5034,
    W9: 5039,
    Form8832: 194,
    ApprovalForm8832: 486,
    Form8821: 381,
    FormSS4: 193,
    EINApplication: 350,
    EINCTBConfirmation: 195,
    CRSSelfcertification: 264,
    Prospectus: 504,
    ArticlesofIncorporation: 163,
    AssignmentofManagementAgreement: 418,
    CertificateofChangeofRegisteredAgent: 386,
    CertificateofRegisteredSeal: 243,
    CertificateofTrust: 341,
    DeedofVariation: 461,
    Email: 196,
    MergerDocuments: 399,
    OperatingAgreement: 241,
    Section10LPLaw: 338,
    Section9LPLaw: 339,
    ShareCharge: 363,
    ShareholdersAgreement: 373,
    SolvencyCertificate: 457,
    TrustDoc: 173,
} as const;

export const DocumentTypesData = [
    { Key: 366, Value: 'Certificate of Limited Partnership' },
    { Key: 190, Value: 'LP/LLC Agreement' },
    { Key: 165, Value: 'Certificate of Registration' },
    { Key: 189, Value: 'Formation Document' },
    { Key: 397, Value: 'Bylaws' },
    { Key: 244, Value: 'Certificate of Incorporation' },
    { Key: 5097, Value: 'Action of Sole Incorporator' },
    { Key: 5098, Value: 'Registration Declaration' },
    { Key: 162, Value: 'Memorandum Article of Association' },
    { Key: 191, Value: 'Structure Chart' },
    { Key: 356, Value: 'Resolution' },
    { Key: 437, Value: 'Consent To Use Of Name' },
    { Key: 347, Value: 'Application of Authority' },
    { Key: 5099, Value: 'Register of Member' },
    { Key: 174, Value: 'Trust Agreement' },
    { Key: 175, Value: 'Trust Deed' },
    { Key: 227, Value: 'Certificate of Name Change' },
    { Key: 168, Value: 'Extracts from Public Register' },
    { Key: 5038, Value: 'W-8BEN-E' },
    { Key: 5034, Value: 'W-8IMY' },
    { Key: 5039, Value: 'W-9' },
    { Key: 194, Value: 'Form 8832' },
    { Key: 486, Value: 'Approval of Form 8832' },
    { Key: 381, Value: 'Form 8821' },
    { Key: 193, Value: 'Form SS-4' },
    { Key: 350, Value: 'EIN Application' },
    { Key: 195, Value: 'EIN/CTB Confirmation' },
    { Key: 264, Value: 'CRS Self-certification' },
    { Key: 507, Value: 'Register of Directors and Officers' },
    { Key: 455, Value: 'Certificate of Independent Director' },
    { Key: 442, Value: 'Officer’s Certificate' },
    { Key: 504, Value: 'Prospectus' },
    { Key: 5211, Value: 'Board Minutes and Resolutions' },
    { Key: 345, Value: 'Share Transfer' },
    { Key: 377, Value: 'Share Certificate' },
    { Key: 5213, Value: 'Shareholder Minutes' },
    { Key: 236, Value: 'Power of Attorney' },
    { Key: 5212, Value: 'Regulatory Returns' },
    { Key: 337, Value: 'Notice' },
    { Key: 172, Value: 'Partnership Agreement' },
    { Key: 246, Value: 'Certificate of Good Standing' },
    { Key: 359, Value: 'Affidavit' },
    { Key: 247, Value: 'Incumbency Certificate' },
    { Key: 166, Value: 'Certificate of Continuance' },
    { Key: 440, Value: 'Certificate of De-Registration' },
    { Key: 229, Value: 'Dissolution Document' },
    { Key: 368, Value: 'Declaration of Trust' },
    { Key: 340, Value: 'Letter of Resignation' },
    { Key: 474, Value: 'Shareholder’s Register' },
    { Key: 374, Value: 'Loan Agreement' },
    { Key: 253, Value: 'Other' },
    { Key: 5210, Value: 'Annual Return' },
    { Key: 163, Value: 'Articles of Incorporation' },
    { Key: 418, Value: 'Assignment of Management Agreement' },
    { Key: 386, Value: 'Certificate of Change of Registered Agent' },
    { Key: 243, Value: 'Certificate of Registered Seal' },
    { Key: 341, Value: 'Certificate of Trust' },
    { Key: 461, Value: 'Deed of Variation' },
    { Key: 196, Value: 'Email' },
    { Key: 399, Value: 'Merger Documents' },
    { Key: 241, Value: 'Operating Agreement' },
    { Key: 338, Value: 'Section 10 LP Law' },
    { Key: 339, Value: 'Section 9 LP Law' },
    { Key: 363, Value: 'Share Charge' },
    { Key: 373, Value: 'Shareholders Agreement' },
    { Key: 457, Value: 'Solvency Certificate' },
    { Key: 173, Value: 'Trust' },
] as const;

export const supportedExtensions = [
    'zip',
    'pdf',
    'xlsx',
    'docx',
    'unknown',
    'xls',
    'xml',
    'doc',
    'ppt',
    'pptx',
    'odt',
    'odp',
    'ods',
    'txt',
    'rtf',
    'pgp',
    'csv',
    'jpg',
    'png',
    'as',
    'asl',
    'asp',
    'docm',
    'dot',
    'dotm',
    'dotx',
    'htm',
    'html',
    'pdx',
    'wpd',
    'wps',
    'wpt',
    'emz',
    'svg',
    'svgz',
    'vdx',
    'vss',
    'vst',
    'bmp',
    'cdr',
    'dcx',
    'gif',
    'ico',
    'jpeg',
    'pct',
    'pic',
    'rgb',
    'sam',
    'tga',
    'tif',
    'tiff',
    'wpg',
    'dps',
    'dpt',
    'pot',
    'potx',
    'pps',
    'pptm',
    'et',
    'ett',
    'xlsm',
    'xlt',
    'msg',
];
