import { ThemeDefinition } from '@bxgrandcentral/controls';
import styled from 'styled-components';

const DefaultButton = styled.button`
    margin: 0;
    padding: 0;
    outline: none;
    border: 0px transparent;
`;

type ThemeProps = {
    theme: ThemeDefinition;
};

type StyledProps = ThemeProps & {
    isOpen: boolean;
};

export const Button = styled(DefaultButton)<StyledProps>`
    border: 1px solid ${({ theme }) => theme.colors.accentColor};
    border-radius: 5px 5px ${({ isOpen }) => (isOpen ? '0 0' : '5px 5px')};
    padding: 7px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    cursor: pointer;
`;

export const DropdownItem = styled(DefaultButton)<ThemeProps>`
    display: flex;
    padding: 10px 15px;
    font-family: 'Helvetica Neue', 'Segoe UI', helvetica, verdana, sans-serif;
    font-size: 14px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.foregroundColor};
    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.groupedBackgroundColor};
    }
    border-bottom: 1px solid ${({ theme }) => theme.colors.systemGray3};
`;

export const Dropdown = styled.div<ThemeProps>`
    border-radius: 10px 10px;
    border-top-left-radius: 0px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    position: absolute;
    z-index: 10;
    border: 1px solid ${({ theme }) => theme.colors.systemGray3};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    width: 200px;
    box-shadow: rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px, rgb(0 0 0 / 20%) 0px 2px 4px -1px;

    ${DropdownItem}:last-child {
        border-bottom: none;
    }
`;

export const Component = styled.div`
    position: relative;
`;
