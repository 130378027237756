import LegalEntityFinanceDetails from './LegalEntityFinanceDetails';
import { LegalEntityFinanceDetailsProps } from './model';
import { LegalEntityFinanceDetailsProvider } from '../context/Provider';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import useLegalEntityFinanceDetailsAutoPopulateData from './hooks/useLegalEntityFinanceDetailsAutopopulateData';

export default function LegalEntityFinanceDetailsSection(props: LegalEntityFinanceDetailsProps) {
    const { calculateDefaultValues } = useLegalEntityFinanceDetailsAutoPopulateData();

    return (
        <LegalEntityFinanceDetailsProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Finance_Details}>
            <LegalEntityFinanceDetails {...props} />
        </LegalEntityFinanceDetailsProvider>
    );
}
