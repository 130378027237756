import { setLegalEntityOId, useCreationViewContext } from '../context/creation-view-context';

import { editLegalEntityParams } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function useLegalEntityOId() {
    const { dispatch } = useCreationViewContext();
    const legalEntityOIdFromPath = useParams<editLegalEntityParams>()?.legalEntityId;

    useEffect(() => {
        setLegalEntityOId(dispatch, Number.parseInt(legalEntityOIdFromPath) || undefined);
    }, [legalEntityOIdFromPath, dispatch]);
}
