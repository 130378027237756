import { isUnitedStates } from 'models';
import { useLegalEntityCorporateTransparency } from 'modules/LegalEntityCreation/context/Provider';
import { useMemo } from 'react';

export default function useHasUsRegistration() {
    const {
        state: {
            data: {
                storedValues: { domesticRegistration, foreignRegistrations },
            },
        },
    } = useLegalEntityCorporateTransparency();

    const hasUsRegistration = useMemo(() => {
        const registrations = [
            ...(domesticRegistration ? domesticRegistration : []),
            ...(foreignRegistrations ? foreignRegistrations : []),
        ];

        const usRegistration = registrations.find(({ domesticCountry }) => isUnitedStates(domesticCountry));

        return !!usRegistration;
    }, [domesticRegistration, foreignRegistrations]);

    return hasUsRegistration;
}
