import '../../Utilities/DateExtender';

import { Column } from 'devextreme-react/data-grid';
import { IColumnProps } from 'devextreme-react/tree-list';
import { LegalEntityRequest } from '../../models';

const columnDefinitions: Partial<IColumnProps>[] = [
    { name: 'entityOId', dataField: 'entityOId', caption: 'Entity ID', width: 100, minWidth: 100 },
    { name: 'mdmEntityOId', dataField: 'mdmEntityOId', caption: 'Legacy ID', width: 80, minWidth: 80 },
    { name: 'entityName', dataField: 'primaryName', caption: 'Entity Name' },
    { name: 'workItemType', dataField: 'workItemType', caption: 'Work Item Type' },
    { name: 'workItemState', dataField: 'workItemState', caption: 'Current State' },
    { name: 'businessDriver', dataField: 'businessDriver', caption: 'Business Driver' },
    { name: 'businessUnit', dataField: 'businessUnit', caption: 'Business Unit' },
    { name: 'subBusinessUnit', dataField: 'subBusinessUnit', caption: 'Sub-Business Unit', visible: false },
    { name: 'administeredBy', dataField: 'administeredBy', caption: 'Administered / Managed By', visible: false },
    { name: 'externalAdmin', dataField: 'externalAdmin', caption: 'External Admin', visible: false },
    { name: 'lawFirm', dataField: 'lawFirm', caption: 'Law Firm', visible: false },
    { name: 'project', dataField: 'project', caption: 'Project', visible: false },
    { name: 'requestedUser', dataField: 'requestedUser', caption: 'Request By' },
    {
        name: 'requestedDateUtc',
        dataField: 'requestedDateUtc',
        caption: 'Requested Date',
        dataType: 'date',
        calculateCellValue: ({ requestedDateUtc }: LegalEntityRequest) => convertDate(requestedDateUtc),
    },
    { name: 'lastModifiedUser', dataField: 'lastModifiedUser', caption: 'Last Updated By', visible: false },
    {
        name: 'lastModifiedDateUtc',
        dataField: 'lastModifiedDateUtc',
        caption: 'Last Updated Date',
        dataType: 'datetime',
        calculateCellValue: ({ lastModifiedDateUtc }: LegalEntityRequest) => convertDate(lastModifiedDateUtc),
    },
    { name: 'structureType', dataField: 'structureType', caption: 'Structure Type', visible: false },
    { name: 'country', dataField: 'country', caption: 'Domestic Country', visible: false },
    { name: 'state', dataField: 'state', caption: 'Domestic State', visible: false },
    { name: 'registeredAgent', dataField: 'registeredAgent', caption: 'Registered Agent', visible: false },
    {
        name: 'formationDate',
        dataField: 'formationDate',
        caption: 'Domestic Formation Date',
        visible: false,
        dataType: 'datetime',
        calculateCellValue: ({ formationDate }: LegalEntityRequest) => convertDate(formationDate),
    },
    {
        name: 'daysSinceFormationDate',
        dataField: 'daysSinceFormationDate',
        caption: 'Days since Formation',
        dataType: 'number',
    },
    {
        name: 'corporateTransparencyStatus',
        dataField: 'corporateTransparencyStatus',
        caption: 'CTA Status',
    },
    {
        name: 'reviewDate',
        dataField: 'reviewDate',
        caption: 'CTA Review Date',
        dataType: 'dateTime',
        calculateCellValue: ({ reviewDate }: LegalEntityRequest) => new Date(reviewDate).toUTCYearMonthDay(),
    },
];

export const requestDashboardConfig = columnDefinitions.map(
    (definition) =>
        new Column({
            dataField: definition.name,
            alignment: 'left',
            allowSorting: true,
            allowReordering: true,
            allowResizing: true,
            minWidth: 150,
            ...definition,
        })
);

type LegalEntityColumnNames = { [key in keyof Partial<LegalEntityRequest>]: any };

export const legalEntityColumnNames = columnDefinitions.reduce<LegalEntityColumnNames>(
    (acc, item) => ({ ...acc, [item.dataField]: item.caption }),
    {}
);

const convertDate = (date: string) => date && new Date(date).convertServerDateToLocalDate();
