import { DocumentType, documentTypes } from 'models/shared/refData/NewDocumentType';

import { LegalTypes } from 'models/LegalEntityRequest/DefaultValues';
import { documentTypesForBluePrint } from 'modules/LegalEntityCreation/LegalEntityDocumentPreparation/utils';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import { useMemo } from 'react';

type Props = {
    legalType?: number;
};

export default function useOwnershipInformationDocuments({ legalType }: Props) {
    const sharedDocTypes = [
        PowerOfAttorney,
        BoardMinutesAndResolutions,
        ShareCertificate,
        ShareTransfer,
        ShareholderMinutes,
        RegulatoryReturns,
        RegisterOfDirectorsAndOfficers,
        ...documentTypesForBluePrint,
    ];

    const documentTypes = useMemo(() => {
        let documentTypes = [];
        switch (legalType) {
            case LegalTypes.Trust: {
                documentTypes = [TrustDeed, TrustAgreement, Resolution, ...documentTypesForBluePrint];
                break;
            }
            default: {
                documentTypes = [
                    Bylaws,
                    Resolution,
                    ConsentToUseName,
                    ApplicationAuthority,
                    RegisterOfMember,
                    ...documentTypesForBluePrint,
                ];
            }
        }
        return documentTypes.sort((a: DocumentType, b: DocumentType) => {
            if (a.Value < b.Value) {
                return -1;
            }
            if (a.Value > b.Value) {
                return 1;
            }
            return 0;
        });
    }, [legalType]);

    const {
        state: { entityCreationWorkItem },
    } = useCreationViewContext();

    const documentApproveDate = useMemo(
        () =>
            entityCreationWorkItem?.tasks?.find((task) => task.taskType === 'TransitionToPostProcess1TaskType')
                ?.completed!,
        [entityCreationWorkItem]
    );

    const stampedDocumentTypes = useMemo(
        () => [
            FormationDocument,
            LpllcAgreement,
            CertificateOfIndependentDirector,
            OfficerCertificate,
            RegisterOfMember,
            Resolution,
            Bylaws,
            ...sharedDocTypes,
        ],
        []
    );

    return { documentTypes, stampedDocumentTypes, documentApproveDate };
}

const {
    FormationDocument,
    TrustDeed,
    TrustAgreement,
    Resolution,
    Bylaws,
    ConsentToUseName,
    LpllcAgreement,
    CertificateOfIndependentDirector,
    OfficerCertificate,
    RegisterOfMember,
    ApplicationAuthority,
    PowerOfAttorney,
    BoardMinutesAndResolutions,
    ShareCertificate,
    ShareTransfer,
    ShareholderMinutes,
    RegisterOfDirectorsAndOfficers,
    RegulatoryReturns,
} = documentTypes;
