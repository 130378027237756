import { validateNumberField, validateRequiredField } from './utils';

import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionDetails } from '../context/Provider';

export default function useDissolutionDetailsValidation() {
    const {
        state: {
            data: {
                values: { billingOracleNumber, billingProductNumber, billingCostCenterNumber },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionDetails();

    const billingFieldsRequired = !!(billingOracleNumber || billingProductNumber || billingCostCenterNumber);

    useEffect(() => {
        updateValidation(dispatch, {
            billingOracleNumber: validateRequiredField(billingOracleNumber, { isRequired: billingFieldsRequired }),
            billingCostCenterNumber: validateNumberField({
                value: billingCostCenterNumber,
                minimumLength: 5,
                isRequired: billingFieldsRequired,
            }),
            billingProductNumber: validateNumberField({
                value: billingProductNumber,
                minimumLength: 4,
                isRequired: billingFieldsRequired,
            }),
        });
    }, [dispatch, version, billingOracleNumber, billingProductNumber, billingCostCenterNumber, billingFieldsRequired]);
}
