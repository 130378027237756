import {
    AttributeBoolean,
    AttributeDate,
    AttributeNumeric,
    AttributePicklist,
    AttributeString,
    AttributeType,
    Entity,
    LegalEntityDTO,
} from '../../../../models';
import {
    booleanToYesNoState,
    createOrUpdateBooleanValue,
    createOrUpdateDateValue,
    createOrUpdateNumericValue,
    createOrUpdatePickListValue,
    createOrUpdateStringValue,
    getBooleanAttributes,
    getDateAttributes,
    getDomesticRegistrationDataForSection,
    getForeignRegistrationsDataForSection,
    getStringAttributes,
    setIfDefined,
    yesNoStateToBoolean,
} from './helpers';

import { LegalEntityCorporateTransparencyData } from 'modules/LegalEntityCreation/LegalEntityCorporateTransparency/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';
import { isEmpty } from 'lodash';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityCorporateTransparencyData => {
    const { entity } = entityDto;
    const {
        entityOId,
        primaryName,
        legalEntity: { legalTypeID },
        taxRegistrations,
        latestDomesticTaxRegistrationId,
        objectAttributeBooleans,
        objectAttributeStrings,
        objectAttributePicklists,
        objectAttributeNumerics,
        objectAttributeDates,
    } = entity;

    const booleanAttributes = getBooleanAttributes(objectAttributeBooleans);
    const stringAttributes = getStringAttributes(objectAttributeStrings);
    const dateAttributes = getDateAttributes(objectAttributeDates);

    const domesticRegistrationData =
        taxRegistrations === null || isEmpty(taxRegistrations)
            ? undefined
            : getDomesticRegistrationDataForSection(taxRegistrations, latestDomesticTaxRegistrationId);

    const foreignRegistrationsData =
        taxRegistrations === null || isEmpty(taxRegistrations)
            ? undefined
            : getForeignRegistrationsDataForSection(taxRegistrations);

    const corporateTransparencyStatus = objectAttributePicklists?.find(
        (a) => a.objectAttributeId === AttributeType.corporateTransparencyStatus
    )?.picklistItemId;

    return {
        isEntityOwnedByBlackstone: booleanToYesNoState(booleanAttributes[AttributeType.isEntityOwnedByBlackstone]),
        bxOwnershipPercentage: objectAttributeNumerics
            ?.find((a) => a.objectAttributeId === AttributeType.bxOwnershipPercentage)
            ?.value?.toString(),
        canBlackstoneControlOwnershipTransfer: booleanToYesNoState(
            booleanAttributes[AttributeType.canBlackstoneControlOwnershipTransfer]
        ),
        canBlackstoneEffectuateReorganization: booleanToYesNoState(
            booleanAttributes[AttributeType.canBlackstoneEffectuateReorganization]
        ),
        corporateTransparencyStatusInternal: corporateTransparencyStatus,
        corporateTransparencyStatus: corporateTransparencyStatus,
        reason: stringAttributes[AttributeType.reason],
        CTAReviewReason: stringAttributes[AttributeType.CTAReviewReason],
        CTAReviewer: stringAttributes[AttributeType.CTAReviewer],
        reviewDate: dateAttributes[AttributeType.reviewDate],
        entityOId,
        legalName: primaryName,
        legalTypeId: legalTypeID,
        domesticRegistration: domesticRegistrationData,
        foreignRegistrations: foreignRegistrationsData,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityCorporateTransparencyData>
) => {
    const entity: RecursivePartial<
        Pick<
            Entity,
            | 'objectAttributeStrings'
            | 'objectAttributeBooleans'
            | 'objectAttributeDates'
            | 'objectAttributeNumerics'
            | 'objectAttributePicklists'
            | 'objectAttributeSystemPicklists'
            | 'objectAttributeRelationships'
        >
    > = {};
    const booleanAttributes: Partial<AttributeBoolean>[] = [];
    const stringAttributes: Partial<AttributeString>[] = [];
    const numericAttributes: Partial<AttributeNumeric>[] = [];
    const pickListAttributes: Partial<AttributePicklist>[] = [];
    const dateAttributes: Partial<AttributeDate>[] = [];

    const createOrUpdateBoolean = (attributeType: AttributeType, value: boolean | undefined) =>
        createOrUpdateBooleanValue({ original, booleanAttributes, attributeType, value, checkSource: true });

    const createOrUpdateString = (attributeType: AttributeType, value: string | undefined) =>
        createOrUpdateStringValue({ original, stringAttributes, attributeType, value });

    const createOrUpdateNumeric = (attributeType: AttributeType, value: number | undefined) =>
        createOrUpdateNumericValue({ original, numericAttributes, attributeType, value });

    const createOrUpdatePickList = (attributeType: AttributeType, value: number | undefined) =>
        createOrUpdatePickListValue({ original, pickListAttributes, attributeType, value });

    const createOrUpdateDate = (attributeType: AttributeType, value: Date | undefined) =>
        createOrUpdateDateValue({ original, dateAttributes, attributeType, value });

    setIfDefined(section, 'isEntityOwnedByBlackstone', (v) =>
        createOrUpdateBoolean(AttributeType.isEntityOwnedByBlackstone, yesNoStateToBoolean(v))
    );
    setIfDefined(section, 'canBlackstoneControlOwnershipTransfer', (v) =>
        createOrUpdateBoolean(AttributeType.canBlackstoneControlOwnershipTransfer, yesNoStateToBoolean(v))
    );
    setIfDefined(section, 'canBlackstoneEffectuateReorganization', (v) =>
        createOrUpdateBoolean(AttributeType.canBlackstoneEffectuateReorganization, yesNoStateToBoolean(v))
    );

    setIfDefined(section, 'bxOwnershipPercentage', (v) =>
        createOrUpdateNumeric(AttributeType.bxOwnershipPercentage, Number(v))
    );

    setIfDefined(section, 'reason', (v) => createOrUpdateString(AttributeType.reason, v));
    setIfDefined(section, 'CTAReviewReason', (v) => createOrUpdateString(AttributeType.CTAReviewReason, v));
    setIfDefined(section, 'CTAReviewer', (v) => createOrUpdateString(AttributeType.CTAReviewer, v));

    setIfDefined(section, 'corporateTransparencyStatus', (v) =>
        createOrUpdatePickList(AttributeType.corporateTransparencyStatus, v)
    );

    setIfDefined(section, 'reviewDate', (v) => createOrUpdateDate(AttributeType.reviewDate, v));

    if (booleanAttributes.length > 0) entity.objectAttributeBooleans = booleanAttributes;
    if (stringAttributes.length > 0) entity.objectAttributeStrings = stringAttributes;
    if (pickListAttributes.length > 0) entity.objectAttributePicklists = pickListAttributes;
    if (numericAttributes.length > 0) entity.objectAttributeNumerics = numericAttributes;
    if (dateAttributes.length > 0) entity.objectAttributeDates = dateAttributes;

    return { entity };
};
