export enum OldStructureType {
    AIV = 87,
    Blocker = 88,
    CoInvest = 89,
    GeneralPartner = 90,
    HoldCo = 91,
    Main = 92,
    Other = 93,
    SBS = 94,
    Feeder = 95,
    SPVAggregator = 98,
    SPV = 99,
    BlockerLeverage = 104,
    BlockerUnLeverage = 105,
}
