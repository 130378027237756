import {
    EntityMasterSearchRequestV4,
    FieldItem,
    IndexType,
    RequestedIndexSearch,
    RequestedSearchItem,
    SearchType,
    SubfieldToSearch,
    AdvancedFieldOptionItem,
} from '../../models/LegalEntitySearch/SearchRequest';
import { useCallback, useMemo } from 'react';

import { EntityStatusId } from '../../models/LegalEntity/EntityStatus';
import { EntitySubType } from '../../models/LegalEntity/EntitySubType';
import { IEMSearchService } from '../../services/interfaces';
import { SearchEntity } from '../../models';
import { ServiceLocator } from '@bxgrandcentral/shell';

export default function useLegalEntityNames() {
    const legalEntitySearch = useMemo(() => ServiceLocator.container.resolve(IEMSearchService), []);

    const getEntitiesWithSameName = useCallback(
        (entityOId: number | undefined, entityName: string) => {
            if (!entityName) return Promise.resolve([]);
            const allFilters: RequestedIndexSearch['searchFilters'] = [
                {
                    field: 'entityStatusId',
                    value: [EntityStatusId.Active, EntityStatusId.Pending],
                },
                {
                    field: 'dataOwnerId',
                    value: [2],
                },
                {
                    field: 'entitySubtypes',
                    value: [EntitySubType.LegalEntity],
                },
            ];

            function GetFieldItem(fieldName: string, subField?: SubfieldToSearch): FieldItem[] {
                let result: FieldItem[] = [
                    {
                        field: fieldName,
                    },
                ];

                if (subField) {
                    let advancedFieldOptionItem: AdvancedFieldOptionItem[] = [
                        {
                            subFieldToSearch: subField,
                        },
                    ];
                    result[0].advancedFieldOptions = advancedFieldOptionItem;
                }

                return result;
            }

            const searchForFieldSearch: RequestedSearchItem[] = [
                {
                    queryString: entityName.trim(),
                    fuzziness: -1,
                    fields: GetFieldItem('primaryName'),
                },
            ];

            if (entityOId) {
                searchForFieldSearch.push({
                    queryString: entityOId.toString(),
                    fuzziness: -1,
                    fields: GetFieldItem('entityOId', SubfieldToSearch.SearchDefaultField),
                    searchInclusionType: SearchType.Exclude,
                });
            }

            const searchRequest: EntityMasterSearchRequestV4<
                Pick<SearchEntity, 'entityOId' | 'primaryName' | 'entityStatusId' | 'businessUnits'>
            > = {
                indexType: IndexType.MdmEntity,
                indexSearchRequest: {
                    searchItems: searchForFieldSearch,
                    searchFilters: allFilters,
                },
                resultOptions: {
                    numberOfResults: 15,
                    fieldsToReturn: ['entityOId', 'primaryName', 'entityStatusId', 'businessUnits'],
                    includeMetaData: false,
                },
            };
            return legalEntitySearch.getLegalEntitiesByField(searchRequest, 'primaryName').then((result) => {
                const searchResults = result.searchResults.map(({ data }) => data);
                return searchResults.filter(
                    ({ primaryName }) =>
                        entityName.trim().localeCompare(primaryName, 'en', { sensitivity: 'base' }) === 0
                );
            });
        },
        [legalEntitySearch]
    );

    return { getEntitiesWithSameName };
}
