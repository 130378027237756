import { FontIcon, FontIconProps } from '@bxgrandcentral/controls';

import React from 'react';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

export default function Spinner(props: FontIconProps) {
    const { accentColor } = useColors();
    return (
        <Rotate>
            <FontIcon
                iconName='Refresh'
                fontSize={18}
                foregroundColor={accentColor}
                verticalAlignment='center'
                {...props}
            />
        </Rotate>
    );
}

const Rotate = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    animation: spinner-animation infinite 1.1s cubic-bezier(0.5, 0, 0.5, 1);
`;
