import { LegalEntityOwnershipInformationData } from '../model';
import { useCallback } from 'react';

export default function useOwnershipInformationAutopopulateData(data?: LegalEntityOwnershipInformationData) {
    const calculateDefaultValues = useCallback(
        () => ({
            ownershipDetails: [{ isDeleted: false }],
        }),
        []
    );

    return { calculateDefaultValues };
}
