import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { useCallback, useContext } from 'react';
import { setEditWorkFlow, useCreationViewContext } from '../LegalEntityCreationView/context/creation-view-context';

import { GlobalState } from 'GlobalState';
import { LegalEntityCorporateTransparencyProps } from './model';
import { SPACING } from 'Utilities/Layout';
import { TaskActions } from '../Shared/TaskActions';
import { UserInfoContext } from '@bxgrandcentral/shell';
import { isOpen } from 'models/Workflow';
import { setShouldReload } from '../context/actions/form-actions';
import useApiServices from '../LegalEntityCreationView/hooks/use-api-services';
import { useAppContext } from 'context/app-context';
import useHasUsRegistration from './hooks/use-has-us-registration';
import { useLegalEntityCorporateTransparency } from '../context/Provider';

type Props = {
    customSubmitMessage?: string;
} & LegalEntityCorporateTransparencyProps;

export default function ActionPanel(props: Props) {
    const { dispatch: creationViewDispatch } = useCreationViewContext();
    const {
        state: {
            data: { values, changes },
            canSave,
            canSubmit,
        },
        dispatch,
    } = useLegalEntityCorporateTransparency();

    const {
        state: {
            settings: { isRequestConfirmationRequired },
        },
    } = useAppContext();
    const { reloadEntityAndWorkItems } = useApiServices();

    const hasUsRegistration = useHasUsRegistration();

    const userinfo = useContext(UserInfoContext);

    const handleMarkAsReviewed = useCallback(async () => {
        let canMarkAsReviewed =
            !isRequestConfirmationRequired ||
            (await GlobalState.openDialog({
                title: `Mark As Reviewed`,
                variant: 'warning',
                content: 'Are you sure you want to mark this request as Reviewed?',
            }));
        if (props.mode === 'Edit' && canMarkAsReviewed) {
            const changesToSave = {
                ...changes,
                reviewDate: new Date().convertServerDateToLocalDate(),
            };
            if (userinfo?.displayName) {
                changesToSave.CTAReviewer = userinfo?.displayName;
            }
            await props.onSave(changesToSave);
            setEditWorkFlow(creationViewDispatch, false);
            reloadEntityAndWorkItems();
        }
    }, [changes, isRequestConfirmationRequired, userinfo, creationViewDispatch, props, reloadEntityAndWorkItems]);

    if (props.mode === 'Create' && props.workItem) {
        const {
            workItem: { tasks },
        } = props;

        const submitTask = tasks?.filter(
            ({ taskType, status }) => taskType === 'CorporateTransparencyTaskType' && isOpen(status)
        )?.[0];

        return submitTask ? (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    isEnabled={canSave && hasUsRegistration}
                    styleName='textOnlyButtonStyle'
                    onClick={async () => {
                        const changesToSave = {
                            ...changes,
                            corporateTransparencyStatus: values.corporateTransparencyStatusInternal,
                        };
                        await props.onSave(changesToSave);
                        setShouldReload(dispatch, true);
                    }}
                />
                <TaskActions
                    task={submitTask}
                    preSubmit={() => {
                        if (!hasUsRegistration) {
                            // In this case we set the values below automatically in the form.
                            // And we do this as a non user change, to avoid the unsaved change popup.
                            // So the changes are empty in the context in this case, and we can get the values from the context values object.
                            return props.onSave({
                                corporateTransparencyStatus: values.corporateTransparencyStatusInternal,
                                reason: values.reason,
                            });
                        }

                        const changesToSave = {
                            ...changes,
                            corporateTransparencyStatus: values.corporateTransparencyStatusInternal,
                        };
                        return props.onSave(changesToSave);
                    }}
                    onCompleteTask={(task, exitCode, rejectReason) =>
                        props.onCompleteTask(task, exitCode as string, rejectReason)
                    }
                    isSubmitEnabled={canSubmit}
                    customMessage={props.customSubmitMessage}
                />
            </StackPanel>
        ) : null;
    }

    if (props.mode === 'Edit' && props.workItem) {
        return (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    isEnabled={canSave}
                    styleName='textOnlyButtonStyle'
                    onClick={async () => {
                        await props.onSave(changes);
                        setShouldReload(dispatch, true);
                    }}
                />
                <StackPanel itemGap={SPACING.LG} orientation='horizontal' styleOverrides={{ alignSelf: 'flex-end' }}>
                    <Button
                        content='Mark as Reviewed'
                        isEnabled={canSubmit}
                        onClick={async () => {
                            handleMarkAsReviewed();
                        }}
                    />
                </StackPanel>
            </StackPanel>
        );
    }
    return null;
}
