import { validateCompleted, validateNumberField, validateRequiredField } from './utils';

import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionFinance } from '../context/Provider';

export default function useDissolutionFinanceValidation() {
    const {
        state: {
            data: {
                values: {
                    isAuditRequired,
                    auditFinalBilling,
                    auditFinalPaymentTimeline,
                    cashPositionSettleReceivables,
                    cashPositionDeterminePaydown,
                    cashPositionProjectCash,
                    liabilities,
                    finalPaymentApproved,
                    finalPaymentDistributed,
                    intercompanyBalancesAreZero,
                    bankAccountBalancesAreZero,
                    treasuryAskedToCloseBankAccounts,
                    billingOracleNumber,
                    billingProductNumber,
                    billingCostCenterNumber,
                },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionFinance();

    const billingFieldsRequired = !!(billingOracleNumber || billingProductNumber || billingCostCenterNumber);

    useEffect(() => {
        updateValidation(dispatch, {
            auditFinalBilling: validateCompleted(auditFinalBilling, { isRequired: isAuditRequired }),
            auditFinalPaymentTimeline: validateCompleted(auditFinalPaymentTimeline, { isRequired: isAuditRequired }),
            cashPositionSettleReceivables: validateCompleted(cashPositionSettleReceivables),
            cashPositionDeterminePaydown: validateCompleted(cashPositionDeterminePaydown),
            cashPositionProjectCash: validateCompleted(cashPositionProjectCash),
            liabilities: validateCompleted(liabilities),
            finalPaymentApproved: validateCompleted(finalPaymentApproved),
            finalPaymentDistributed: validateCompleted(finalPaymentDistributed),
            intercompanyBalancesAreZero: validateCompleted(intercompanyBalancesAreZero),
            bankAccountBalancesAreZero: validateCompleted(bankAccountBalancesAreZero),
            treasuryAskedToCloseBankAccounts: validateCompleted(treasuryAskedToCloseBankAccounts),
            billingOracleNumber: validateRequiredField(billingOracleNumber, { isRequired: billingFieldsRequired }),
            billingCostCenterNumber: validateNumberField({
                value: billingCostCenterNumber,
                minimumLength: 5,
                isRequired: billingFieldsRequired,
            }),
            billingProductNumber: validateNumberField({
                value: billingProductNumber,
                minimumLength: 4,
                isRequired: billingFieldsRequired,
            }),
        });
    }, [
        dispatch,
        version,
        isAuditRequired,
        auditFinalBilling,
        auditFinalPaymentTimeline,
        cashPositionSettleReceivables,
        cashPositionDeterminePaydown,
        cashPositionProjectCash,
        liabilities,
        finalPaymentApproved,
        finalPaymentDistributed,
        intercompanyBalancesAreZero,
        bankAccountBalancesAreZero,
        treasuryAskedToCloseBankAccounts,
        billingOracleNumber,
        billingProductNumber,
        billingCostCenterNumber,
        billingFieldsRequired,
    ]);
}
