import { Border, Button, TextBlock } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { VALIDATION_MESSAGES } from 'modules/LegalEntityCreation/validation/utils';
import useColors from 'api/hooks/use-theme';

type Props = {
    onClick: () => void;
};

export default function DocumentServiceError({ onClick }: Props) {
    const { normalControlBorderColor, systemRedColor } = useColors();

    return (
        <Border
            borderThickness={1}
            borderColor={normalControlBorderColor}
            cornerRadius={8}
            itemGap={SPACING.MD}
            padding={SPACING.LG}>
            <TextBlock foregroundColor={systemRedColor} verticalAlignment='center' horizontalAlignment='center'>
                {VALIDATION_MESSAGES.DOCUMENT_SERVICE_ERROR}
            </TextBlock>
            <Button
                verticalAlignment='center'
                horizontalAlignment='center'
                styleName='textOnlyButtonStyle'
                width={100}
                fontSize={13}
                onClick={onClick}>
                Retry
            </Button>
        </Border>
    );
}
