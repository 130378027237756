import { VALIDATION_MESSAGES, validateRequiredField } from './utils';
import { useEffect, useState } from 'react';

import { Address } from 'models';
import { ValidationErrors } from '../context/model';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityDocumentPreparation } from '../context/Provider';

export default function useDocumentPreparationHeadquarterAddressValidation() {
    const {
        state: {
            data: {
                values: { headquarterAddress },
            },
            isEditable,
            version,
        },
        dispatch,
    } = useLegalEntityDocumentPreparation();

    const [validations, setValidations] = useState<ValidationErrors<Address>>({});

    useEffect(() => {
        setValidations({
            countryId: validateRequiredField(headquarterAddress?.countryId),
        });
    }, [headquarterAddress, dispatch]);

    useEffect(() => {
        if (!isEditable || isEmpty(validations)) {
            updateValidation(dispatch, { headquarterAddress: undefined });
            return;
        }

        updateValidation(dispatch, {
            headquarterAddress: Object.values(validations).some(
                (error) => error && error !== VALIDATION_MESSAGES.REQUIRED_FIELD
            )
                ? VALIDATION_MESSAGES.COMBINED_ERROR
                : VALIDATION_MESSAGES.REQUIRED_FIELD,
        });
    }, [validations, dispatch, version, isEditable]);

    return { validations };
}
