import { BooleanField, Debugger, Expander, Footer, Form, Select, Text, TextField } from 'components';
import { CompletionState, CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';
import React, { useMemo } from 'react';
import { linkToWorkflowsTool, trialAndIntercompanyBalanceDashboardLinks } from './utils';

import ActionPanel from './components/ActionPanel/ActionPanel';
import BalanceCheck from '../components/BalanceCheck';
import { DISSOLUTION_FINANCE_STATE } from 'models/LegalEntityRequest/Workflow';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import InfoBlock from 'modules/LegalEntityCreation/Shared/InfoBlock';
import { LegalEntityDissolutionFinanceProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { isNil } from 'lodash';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionFinanceValidation from 'modules/LegalEntityCreation/validation/use-dissolution-finance-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionFinance } from '../../context/Provider';

export default function LegalEntityDissolutionFinance(props: LegalEntityDissolutionFinanceProps) {
    const context = useLegalEntityDissolutionFinance();
    const {
        state: {
            data: {
                values: {
                    entityOid,
                    isAuditRequired,
                    billingOracleNumber,
                    billingProductNumber,
                    billingCostCenterNumber,
                    savedAt,
                    savedBy,
                },
            },
        },
        getValue,
        setValue,
    } = context;

    const { workItem } = props;
    const {
        state: { legalEntityDissolutionFinanceWorkItem, noPendingSections },
    } = useCreationViewContext();

    const { oracleNumber, productNumber, costCenterNumber } = noPendingSections.FinanceInformation;

    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionFinanceWorkItem,
        saved: {
            savedBy,
            savedAt,
        },
    });

    useDissolutionFinanceValidation();

    const billingFieldsRequired = useMemo(
        () => !!(billingOracleNumber || billingProductNumber || billingCostCenterNumber),
        [billingOracleNumber, billingProductNumber, billingCostCenterNumber]
    );

    const isBillingCodesMatchInitialValue = useMemo(
        () =>
            costCenterNumber === billingCostCenterNumber &&
            ((isNil(oracleNumber) && isNil(billingOracleNumber)) || oracleNumber?.toString() === billingOracleNumber) &&
            productNumber === billingProductNumber,
        [
            costCenterNumber,
            billingCostCenterNumber,
            oracleNumber,
            billingOracleNumber,
            productNumber,
            billingProductNumber,
        ]
    );

    const showSameCodeInfoBlock =
        ['Edit'].includes(props.mode) && billingFieldsRequired && isBillingCodesMatchInitialValue;

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Finance} />
            <Form>
                <Expander
                    header='Complete Liquidating Audit'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <StackPanel itemGap={SPACING.SM}>
                            <DoubleColumnLayout>
                                <BooleanField
                                    margin={`0 0 ${SPACING.MD}px 0`}
                                    label='Is final liquidating audit required?'
                                    labelToolTip={{
                                        component:
                                            'If yes, then complete final liquidating audit tasks below. If no, skip to the next section.',
                                        options: { showDuration: 5000 },
                                    }}
                                    {...getValue('isAuditRequired')}
                                    onValueChanged={(newValue) => {
                                        setValue('isAuditRequired', newValue);
                                        setValue('auditFinalBilling', CompletionStateKeys.Incomplete);
                                        setValue('auditFinalPaymentTimeline', CompletionStateKeys.Incomplete);
                                    }}
                                    isPendingChange={false}
                                />
                                <div></div>
                            </DoubleColumnLayout>

                            {isAuditRequired && (
                                <DoubleColumnLayout>
                                    <StackPanel itemGap={SPACING.SM}>
                                        <Select
                                            label='Confirm final audited financial statements and final billing'
                                            labelToolTip={{
                                                component:
                                                    'Discuss with the Audit team on final audited financial statements and final billing.',
                                            }}
                                            itemsSource={CompletionState}
                                            isRequired
                                            {...getValue('auditFinalBilling')}
                                            isPendingChange={false}
                                        />
                                    </StackPanel>
                                    <StackPanel itemGap={SPACING.SM}>
                                        <Select
                                            label='Confirm final distribution and expense repayment timeline'
                                            labelToolTip={{
                                                component:
                                                    'Discuss timeline for final distribution and expense repayment.',
                                            }}
                                            itemsSource={CompletionState}
                                            isRequired
                                            {...getValue('auditFinalPaymentTimeline')}
                                            isPendingChange={false}
                                        />
                                    </StackPanel>
                                </DoubleColumnLayout>
                            )}
                        </StackPanel>
                    }
                />
                <Expander
                    header='Cash Position and Expected Remaining Expenses'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <>
                            <DoubleColumnLayout>
                                <StackPanel itemGap={SPACING.SM}>
                                    <Select
                                        label='Settle all receivables and liabilities'
                                        labelToolTip={{
                                            component:
                                                'These include outstanding intercompany and P-code receivables/liabilities. Assess whether capital injection or debt forgiveness is needed in case the entity does not have sufficient cash to settle liabilities. Reach out to responsible parties for any outstanding balances and settle the balances as appropriate.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        {...getValue('cashPositionSettleReceivables')}
                                        isPendingChange={false}
                                    />
                                    <Select
                                        label='Determine if cash at the entity/fund level is enough to paydown actual and projected liabilities or if additional cash is to be withheld from the final distribution'
                                        labelToolTip={{
                                            component: 'Analyze the current cash position.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        height={120}
                                        {...getValue('cashPositionDeterminePaydown')}
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                                <StackPanel itemGap={SPACING.SM}>
                                    <Select
                                        label='Project cash needed for future expenses'
                                        labelToolTip={{
                                            component:
                                                'Future expenses include audit, tax, legal, wipro, bank fees, and registration filing fees.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        {...getValue('cashPositionProjectCash')}
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                            </DoubleColumnLayout>
                        </>
                    }
                />
                <Expander
                    header='Add New Billing Codes'
                    requestStep={RequestStepsView.Dissolution_Details}
                    content={
                        <>
                            <StackPanel margin={`0 0 ${SPACING.XL}px 0`}>
                                <Text horizontalAlignment='center' verticalAlignment='center' textAlignment='center'>
                                    <p>
                                        Add a new Billing Oracle Code and/or Billing Project Code in the case of any
                                        future expenses incurred.
                                    </p>
                                </Text>
                            </StackPanel>
                            <DoubleColumnLayout>
                                <StackPanel itemGap={SPACING.SM}>
                                    <TextField
                                        label='Oracle Number'
                                        value={oracleNumber?.toString()}
                                        isPendingChange={false}
                                        isReadOnly
                                    />
                                    <TextField
                                        label='Product Number'
                                        value={productNumber?.toString()}
                                        isPendingChange={false}
                                        isReadOnly
                                    />
                                    <TextField
                                        label='Cost Center Number'
                                        value={costCenterNumber?.toString()}
                                        isPendingChange={false}
                                        isReadOnly
                                    />
                                </StackPanel>
                                <StackPanel itemGap={SPACING.SM}>
                                    <TextField
                                        label='Billing Oracle Number'
                                        {...getValue('billingOracleNumber')}
                                        isPendingChange={false}
                                        isRequired={billingFieldsRequired}
                                    />
                                    <TextField
                                        label='Billing Product Number'
                                        {...getValue('billingProductNumber')}
                                        isPendingChange={false}
                                        maxLength={4}
                                        isRequired={billingFieldsRequired}
                                    />
                                    <TextField
                                        label='Billing Cost Center Number'
                                        {...getValue('billingCostCenterNumber')}
                                        maxLength={5}
                                        isPendingChange={false}
                                        isRequired={billingFieldsRequired}
                                    />
                                    <StackPanel margin={`${SPACING.MD}px 0 0 0`}>
                                        <TextField
                                            label='Billing Project Code'
                                            labelToolTip='Enter a new billing project to replace the existing billing project code.'
                                            {...getValue('billingProjectCode')}
                                            maxLength={6}
                                            isPendingChange={false}
                                        />
                                    </StackPanel>
                                </StackPanel>
                            </DoubleColumnLayout>
                            {showSameCodeInfoBlock && (
                                <StackPanel>
                                    <InfoBlock>
                                        Please note that Billing Codes match Original Oracle Codes. Please change the
                                        codes if you would like to enter a new code for the dissolving entity in the
                                        case of any future expenses.
                                    </InfoBlock>
                                </StackPanel>
                            )}
                        </>
                    }
                />
                <Expander
                    header='Liabilities'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <DoubleColumnLayout>
                            <StackPanel itemGap={SPACING.SM}>
                                <Select
                                    label='Pay down all outstanding liabilities'
                                    labelToolTip={{
                                        component:
                                            "Pay down all liabilities. All dissolved legal entities' balances should be zero. All dissolved legal entities' WSS bank account balances should be zero.",
                                    }}
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('liabilities')}
                                    isPendingChange={false}
                                />
                            </StackPanel>
                            <StackPanel itemGap={SPACING.SM}></StackPanel>
                        </DoubleColumnLayout>
                    }
                />
                <Expander
                    header='Final Payments Distribution'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <DoubleColumnLayout>
                            <StackPanel itemGap={SPACING.SM}>
                                <Select
                                    label='Draft of final distribution to be approved by all necessary parties such as regional finance team, valuations team, upper management, legal, and tax'
                                    labelToolTip={{
                                        component:
                                            'Confirm final distribution and expense repayment timeline. All necessary holdbacks at the shareholder/investor level to be taken into consideration.',
                                    }}
                                    itemsSource={CompletionState}
                                    isRequired
                                    height={100}
                                    {...getValue('finalPaymentApproved')}
                                    isPendingChange={false}
                                />
                            </StackPanel>
                            <StackPanel itemGap={SPACING.SM} margin={`${SPACING.SM}px 0 0`}>
                                <Select
                                    label='Distribute final payments'
                                    labelToolTip={{
                                        component: 'Distribute final payments to investors and shareholders.',
                                    }}
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('finalPaymentDistributed')}
                                    isPendingChange={false}
                                />
                            </StackPanel>
                        </DoubleColumnLayout>
                    }
                />
                <Expander
                    header='Zero Oracle Balances and Close Bank Accounts'
                    requestStep={RequestStepsView.Dissolution_Finance}
                    content={
                        <StackPanel>
                            <DoubleColumnLayout>
                                <StackPanel>
                                    <StackPanel margin={`0 0 ${SPACING.LG}px 0`}>
                                        <Select
                                            label='Intercompany Balances are zero'
                                            labelToolTip={{
                                                component: 'Confirm that all IC balances are 0',
                                            }}
                                            itemsSource={CompletionState}
                                            isRequired
                                            height={100}
                                            bottomDescription={trialAndIntercompanyBalanceDashboardLinks}
                                            {...getValue('intercompanyBalancesAreZero')}
                                            isPendingChange={false}
                                        />
                                    </StackPanel>
                                    <Select
                                        label='Once all balances have be zeroed, reach out to Treasury to close the bank accounts opened within and outside of BX Treasury tool'
                                        labelToolTip={{
                                            component: 'Go to the link to ask Treasury to close all bank accounts.',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        height={110}
                                        bottomDescription={linkToWorkflowsTool}
                                        {...getValue('treasuryAskedToCloseBankAccounts')}
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                                <StackPanel itemGap={SPACING.SM}>
                                    <Select
                                        label='Bank account balances are zero'
                                        labelToolTip={{
                                            component: 'Confirm that all bank account balances are zero',
                                        }}
                                        itemsSource={CompletionState}
                                        isRequired
                                        height={120}
                                        {...getValue('bankAccountBalancesAreZero')}
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                            </DoubleColumnLayout>
                            <BalanceCheck isEnabled={props.mode === 'Edit'} />
                        </StackPanel>
                    }
                />
                <Expander
                    header='Approver Notes and Status'
                    toolTip='This section is used to enter approver notes that help other approvers. It is also used to communicate in-progress status. Please enter in-progress status and hit Save during the in-progress steps.'
                    toolTipService={{ showDuration: 10000 }}
                    content={
                        <div className='text-area-hide-scrollbar'>
                            <TextField
                                minHeight={120}
                                maxHeight={240}
                                variant='default'
                                textWrapping='wrap'
                                padding={`${SPACING.XS}px 0`}
                                canUndo={false}
                                {...getValue('approverNotes')}
                                isPendingChange={false}
                            />
                        </div>
                    }
                />
                <DissolutionRejectReason
                    workflowType={WorkflowType.LegalEntityDissolutionFinanceWorkflowType}
                    currentWorkItemState={workItem?.workItemState}
                    workItemStateToExclude={DISSOLUTION_FINANCE_STATE.DISSOLUTION_APPROVED}
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode === 'Edit' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
