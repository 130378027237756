import { DateTimeField, Select, TextField } from 'components';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';
import React, { useMemo } from 'react';

import { RegistrationData } from 'modules/LegalEntityCreation/LegalEntityInformation/model';
import { StackPanel } from '@bxgrandcentral/controls';
import { useReferenceData } from 'api';

type Props = {
    registrationData: RegistrationData;
};

export default function DomesticRegistration({ registrationData }: Props) {
    const {
        data: { Country, State },
    } = useReferenceData();

    const { taxRegistrationId, domesticCountry, domesticState, formationDate, registrationNumber } = registrationData;

    const filteredStates = useMemo(
        () => State?.filter(({ ParentId }) => ParentId === `${domesticCountry}`),
        [State, domesticCountry]
    );

    return (
        <StackPanel key={taxRegistrationId} itemGap={SPACING.SM}>
            <DoubleColumnLayout>
                <StackPanel itemGap={SPACING.SM}>
                    <Select
                        label='Country'
                        labelToolTip={{ component: 'Country in which the legal entity is domiciled' }}
                        itemsSource={Country}
                        isEditable={false}
                        value={domesticCountry}
                    />
                    <Select
                        label='State'
                        labelToolTip={{ component: 'State in which the legal entity is domiciled' }}
                        itemsSource={filteredStates}
                        isEditable={false}
                        value={domesticState}
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <TextField
                        label='Registration Number'
                        labelToolTip={'Select the registered agent'}
                        value={registrationNumber}
                        isEditable={false}
                    />
                    <DateTimeField
                        label='Formation Date'
                        labelToolTip={'Date in which the entity is legally formed'}
                        value={formationDate}
                        isEditable={false}
                        onValueChanged={() => {}}
                    />
                </StackPanel>
            </DoubleColumnLayout>
        </StackPanel>
    );
}
