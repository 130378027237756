import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionTax } from '../context/Provider';
import { validateCompleted } from './utils';

export default function useDissolutionTaxValidation() {
    const {
        state: {
            data: {
                values: {
                    allTaxRelated,
                    taxInvoicesPreBilled,
                    allTaxReporting,
                    deregistrationAndNotification,
                    taxAndLiquidationSteps,
                },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionTax();

    useEffect(() => {
        updateValidation(dispatch, {
            allTaxRelated: validateCompleted(allTaxRelated),
            taxInvoicesPreBilled: validateCompleted(taxInvoicesPreBilled),
            allTaxReporting: validateCompleted(allTaxReporting),
            deregistrationAndNotification: validateCompleted(deregistrationAndNotification),
            taxAndLiquidationSteps: validateCompleted(taxAndLiquidationSteps),
        });
    }, [
        dispatch,
        allTaxRelated,
        taxInvoicesPreBilled,
        allTaxReporting,
        deregistrationAndNotification,
        taxAndLiquidationSteps,
        version,
    ]);
}
