import LegalEntityInformationSection from '../../LegalEntityInformation/LegalEntityInformationSection';
import React from 'react';
import { isEmpty } from 'lodash';
import useApiServices from '../hooks/use-api-services';
import useBackToDashboard from '../hooks/use-back-to-dashboard';
import { useCreationViewContext } from '../context/creation-view-context';
import useLegalEntityInformationAutoPopulateData from 'modules/LegalEntityCreation/LegalEntityInformation/hooks/use-legal-entity-information-auto-populate-data';
import useRemovingNegativeIds from 'modules/LegalEntityCreation/LegalEntityInformation/hooks/use-legal-entity-information-removing-negative-ids';

export default function InformationStepRenderer() {
    const { saveSection, reloadUserScopes, reloadEntityAndWorkItems, completeTask, createStandalone } =
        useApiServices();
    const backToDashboard = useBackToDashboard();
    const { calculateDefaultValues } = useLegalEntityInformationAutoPopulateData();
    const { filterNegativeIds } = useRemovingNegativeIds();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            legalEntityUpdateWorkItem: workItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const dataRaw = sections.LegalEntityInformation;
    const noPendingData = noPendingSections.LegalEntityInformation;

    const data = calculateDefaultValues(dataRaw);

    if (isEmpty(data)) {
        return null;
    }

    if (isWorkItemsRefreshing || (!selectedPanel?.hasActions && !editWorkFlow)) {
        const filteredData = filterNegativeIds(data);
        return (
            <LegalEntityInformationSection
                data={filteredData}
                mode='Readonly'
                noPendingData={noPendingData}
                workItem={workItem}
            />
        );
    }

    if (editWorkFlow && !selectedPanel?.hasActions) {
        return (
            <LegalEntityInformationSection
                mode='Create'
                data={data}
                noPendingData={noPendingData}
                onSave={(dataToSave) =>
                    saveSection(sections, 'LegalEntityInformation', dataToSave).then((e) => e?.entityOId ?? 0)
                }
                onCreateWorkItem={(entityOId, type) => createStandalone(entityOId, type)}
                workItem={workItem}
            />
        );
    }

    switch (workItem?.workItemState) {
        case 'Legal Entity Update Request':
            return (
                <LegalEntityInformationSection
                    mode='Edit'
                    data={data}
                    noPendingData={noPendingData}
                    onSave={(dataToSave) => {
                        return saveSection(sections, 'LegalEntityInformation', dataToSave).then((e) =>
                            reloadUserScopes(e.entityOId ?? 0)
                        );
                    }}
                    onCompleteTask={(task, exitCode) => {
                        if (workItem) {
                            completeTask(workItem, task, exitCode, '').then(
                                () =>
                                    exitCode === 'Withdraw' &&
                                    setTimeout(() => {
                                        backToDashboard();
                                    }, 3000)
                            );
                        }
                    }}
                    workItem={workItem}
                />
            );
        case 'Legal Entity Update Approval':
            return (
                <LegalEntityInformationSection
                    mode='Approval'
                    workItem={workItem}
                    data={data}
                    noPendingData={noPendingData}
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        const section = exitCode === 'Approve' ? '' : undefined;
                        await completeTask(workItem, task, exitCode, section, rejectReason);
                        await reloadEntityAndWorkItems(2000);
                    }}
                />
            );
        default:
            const filteredData = filterNegativeIds(data);
            return (
                <LegalEntityInformationSection
                    data={filteredData}
                    noPendingData={noPendingData}
                    mode='Readonly'
                    workItem={workItem}
                />
            );
    }
}
