import { Button, StackPanel } from '@bxgrandcentral/controls';
import { setIsEditable, setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import { Capabilities } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import React from 'react';
import { SubmitButton } from 'components';
import { TaskActions } from '../../../Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from '../../../../../models/Workflow';
import useDocumentUpload from '../../../../../hooks/use-document-upload';
import useHasCapability from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-has-capability';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';

export default function ActionPanel(props: ActionPanelProps) {
    const { entityOId } = props;

    const {
        state: {
            data: { changes },
            documents: { sections },
            canSave,
            canSubmit,
        },
        dispatch,
    } = useLegalEntityInformation();

    const hasCapability = useHasCapability();
    const { updateDocuments } = useDocumentUpload();

    const handleSave = async (isSubmit = false) => {
        if (props.mode === 'Readonly' || props.mode === 'Approval' || !entityOId) {
            return;
        }

        setIsLoading(dispatch, true);
        setIsEditable(dispatch, false);

        try {
            await updateDocuments(sections, entityOId);

            if (!isEmpty(changes)) {
                await props.onSave(changes);
            }

            if (props.mode === 'Create') {
                await props.onCreateWorkItem(entityOId, 'LegalEntityUpdateWorkflow');
            }

            setShouldReload(dispatch, true);

            if (!isSubmit) {
                setIsEditable(dispatch, true);
            }
        } catch (reason) {
            setIsEditable(dispatch, true);
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (!entityOId) {
        return null;
    }

    switch (props.mode) {
        case 'Readonly':
            return null;
        case 'Create': {
            return (
                <SubmitButton
                    isEnabled={canSave}
                    label='Save'
                    onClick={async () => {
                        await handleSave();
                    }}
                />
            );
        }
        case 'Edit': {
            const submitTask = props.workItem?.tasks?.find(
                (task) => task.taskType === 'RequestTaskType' && isOpen(task.status)
            );

            return (
                <StackPanel
                    orientation='horizontal'
                    horizontalAlignment='right'
                    styleOverrides={{ justifyContent: 'space-between' }}>
                    <Button
                        isEnabled={canSave}
                        content='Save'
                        styleName='textOnlyButtonStyle'
                        onClick={async () => {
                            await handleSave();
                        }}
                    />
                    {submitTask ? (
                        <TaskActions
                            isSubmitEnabled={canSubmit}
                            task={submitTask}
                            onCompleteTask={async (task, exitCode) => {
                                setIsLoading(dispatch, true);
                                await props.onCompleteTask(task, exitCode as string);
                                setIsLoading(dispatch, false);
                            }}
                            preSubmit={async () => {
                                await handleSave(true);
                            }}
                        />
                    ) : null}
                </StackPanel>
            );
        }
        case 'Approval': {
            const approveTask = props.workItem.tasks?.find(
                (task) => task.taskType === 'UpdateRequestTaskType' && isOpen(task.status)
            );

            return approveTask && hasCapability(Capabilities.approveLegalInformation) ? (
                <TaskActions
                    task={approveTask}
                    onCompleteTask={(task, exitCode, rejectReason) =>
                        props.onCompleteTask(task, exitCode as string, rejectReason)
                    }
                />
            ) : null;
        }
    }
}
