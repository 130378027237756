import { LegalEntityInformationData } from '../model';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useReferenceData } from 'api';

export default function useLegalEntityInformationAutoPopulateData() {
    const {
        data: { TaxCodeType },
    } = useReferenceData();

    const calculateDefaultValues = useCallback(
        (data?: LegalEntityInformationData) => {
            if (isNil(data) || !TaxCodeType) {
                return {};
            }

            const { taxCodes = [] } = data;

            return {
                ...data,
                taxCodes: taxCodes.map((taxCode) => {
                    const taxCountry = TaxCodeType?.find(({ Key }) => taxCode.taxCodeTypeId === Key)?.ParentId;
                    return {
                        ...taxCode,
                        ...(taxCountry && { taxCountry: parseInt(taxCountry) }),
                    };
                }),
            };
        },
        [TaxCodeType]
    );
    return { calculateDefaultValues };
}
