import { Button, StackPanel, WaitingIndicator } from '@bxgrandcentral/controls';
import React, { useState } from 'react';

import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';
import { TextVariant } from 'components/Text/Text';
import { isNil } from 'lodash';
import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

export type BalanceCheckResult = {
    status?: TextVariant;
    message?: string;
    isLoading?: boolean;
};

export type Props = {
    isEnabled?: boolean;
};

export default function BalanceCheck({ isEnabled }: Props) {
    const { getCanDissolveEntity } = useApiServices();
    const [balanceCheckResult, setBalanceCheckResult] = useState<BalanceCheckResult>({});

    const {
        state: { legalEntityOId },
    } = useCreationViewContext();

    const doBalanceCheck = async () => {
        if (!legalEntityOId) {
            return;
        }

        setBalanceCheckResult({
            status: undefined,
            message: undefined,
            isLoading: true,
        });

        const canDissolveEntityResponse = await getCanDissolveEntity(legalEntityOId, false);

        if (isNil(canDissolveEntityResponse) || canDissolveEntityResponse.failedToCheck) {
            setBalanceCheckResult({
                status: 'error',
                message: 'There was an error during the check of balances.',
                isLoading: false,
            });
            return;
        }

        if (canDissolveEntityResponse.icBalanceReportUrl || canDissolveEntityResponse.leBalanceReportUrl) {
            setBalanceCheckResult({
                status: 'error',
                message:
                    'There are intercompany and/or trial balances associated with this entity. Please refer to the above dashboard links for balances and fully zero out balances before moving forward.',
                isLoading: false,
            });
            return;
        }

        setBalanceCheckResult({
            status: 'success',
            message: 'Passed. There are no intercompany and trial balances associated with this entity.',
            isLoading: false,
        });
    };

    return (
        <>
            {(balanceCheckResult.message || balanceCheckResult.isLoading) && (
                <StackPanel margin={`0 0 ${SPACING.SM}px 0`}>
                    {balanceCheckResult.isLoading && (
                        <WaitingIndicator id={'spinner'} isVisible isModalToShell={false} fontSize={15} width='100%' />
                    )}
                    {balanceCheckResult.message && isEnabled && (
                        <Text
                            horizontalAlignment='center'
                            verticalAlignment='center'
                            textAlignment='center'
                            variant={balanceCheckResult.status}>
                            {balanceCheckResult.message}
                        </Text>
                    )}
                </StackPanel>
            )}
            <StackPanel orientation='horizontal' styleOverrides={{ alignSelf: 'flex-end' }}>
                <Button
                    content='Check Balances'
                    isEnabled={isEnabled && !balanceCheckResult.isLoading}
                    onClick={doBalanceCheck}
                />
            </StackPanel>
        </>
    );
}
