import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export const DISSOLUTION_TAX_STATE = {
    DISSOLUTION_REQUEST: 'Tax Request',
    DISSOLUTION_APPROVAL: 'Tax Approval',
    DISSOLUTION_APPROVED: 'Tax Approved',
    DISSOLUTION_COMPLETE: 'Tax Dissolution Setup Complete',
    SAVE_DATA: 'SaveData',
    DISSOLUTION_WITHDRAWAL: 'Tax Withdrawn',
} as const;

export type DissolutionTaxStateKeyType = keyof typeof DISSOLUTION_TAX_STATE;
export type LegalEntityDissolutionTaxState = (typeof DISSOLUTION_TAX_STATE)[DissolutionTaxStateKeyType];

type Status = 'InProcess' | 'Complete' | 'Canceled';

export type LegalEntityDissolutionTaxTaskType = 'ApprovalRequestType' | 'SubmitRequestType';
export type LegalEntityDissolutionTaxTask = Task &
    Record<keyof Pick<Task, 'taskType'>, LegalEntityDissolutionTaxTaskType>;
export type LegalEntityDissolutionTaxWorkflow = Omit<WorkItem, 'tasks'> & {
    workflowType: WorkflowType.LegalEntityDissolutionTaxWorkflowType;
    workItemState: LegalEntityDissolutionTaxState;
    workItemStatus: Status;
    tasks: LegalEntityDissolutionTaxTask[] | undefined;
};
