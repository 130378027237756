import { Debugger, Expander, Footer, Form, Select, TextField } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { CompletionState } from 'models/LegalEntityRequest/DefaultValues';
import { DISSOLUTION_LEGAL_STATE } from 'models/LegalEntityRequest/Workflow';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import { LegalEntityDissolutionLegalProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionLegalValidation from 'modules/LegalEntityCreation/validation/use-dissolution-legal-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionLegal } from '../../context/Provider';

export default function LegalEntityDissolutionLegal(props: LegalEntityDissolutionLegalProps) {
    const context = useLegalEntityDissolutionLegal();
    const {
        state: {
            data: {
                values: { entityOid, savedAt, savedBy },
            },
        },
        getValue,
    } = context;

    const { workItem } = props;
    const {
        state: { legalEntityDissolutionLegalWorkItem },
    } = useCreationViewContext();
    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionLegalWorkItem,
        saved: {
            savedBy,
            savedAt,
        },
    });

    useDissolutionLegalValidation();

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Legal} />
            <Form>
                <Expander
                    header='Legal Entity Dissolution - Legal'
                    requestStep={RequestStepsView.Dissolution_Legal}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={`${SPACING.XL}px 0 0 0`}>
                            <GridLayout columns='2fr 1fr'>
                                <StackPanel>
                                    <TextBlock
                                        text='Confirm that there are no outstanding fund related duties.'
                                        toolTip='Fund related duties include notices to L.P.s and certificates. If there are, then please reject and provide the reason to the requestor.'
                                        toolTipService={{ showDuration: 10000 }}
                                        margin={`${SPACING.XS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('noOutstandingFundRelatedDuties')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Confirm that there are no active fund level regulatory registrations.'
                                        toolTip='Fund level regulatory registrations include NFA/CFTC, CIMA, etc.'
                                        margin={`${SPACING.XXS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('noActiveFundRegistrations')}
                                    isPendingChange={false}
                                />
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <Expander
                    header='Approver Notes and Status'
                    toolTip='This section is used to enter approver notes that help other approvers. It is also used to communicate in-progress status. Please enter in-progress status and hit Save during the in-progress steps.'
                    toolTipService={{ showDuration: 10000 }}
                    content={
                        <div className='text-area-hide-scrollbar'>
                            <TextField
                                minHeight={120}
                                maxHeight={240}
                                variant='default'
                                textWrapping='wrap'
                                padding={`${SPACING.XS}px 0`}
                                canUndo={false}
                                {...getValue('approverNotes')}
                                isPendingChange={false}
                            />
                        </div>
                    }
                />
                <DissolutionRejectReason
                    workflowType={WorkflowType.LegalEntityDissolutionLegalWorkflowType}
                    currentWorkItemState={workItem?.workItemState}
                    workItemStateToExclude={DISSOLUTION_LEGAL_STATE.DISSOLUTION_APPROVED}
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode === 'Edit' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
