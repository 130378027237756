import { RefObject, useLayoutEffect, useState } from 'react';

import { isNil } from 'lodash';

export default function useElementOffset(ref: RefObject<HTMLDivElement>) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        if (!isNil(ref.current)) {
            const { offsetWidth, offsetHeight } = ref.current;
            if (width !== offsetWidth) {
                setWidth(offsetWidth);
            }
            if (height !== offsetHeight) {
                setHeight(offsetHeight);
            }
        }
    });

    return { width, height };
}
