import React, { ReactNode, useMemo } from 'react';
import { TextBlock, TextBlockProps, ThemeManager } from '@bxgrandcentral/controls';

import { FONT_FAMILIES } from '../../Utilities/Layout';

export type TextVariant =
    | 'headline'
    | 'body'
    | 'body-small'
    | 'body-small-bold'
    | 'errorBold'
    | 'error'
    | 'default'
    | 'label'
    | 'success';

type Props = TextBlockProps & {
    children: ReactNode;
    variant?: TextVariant;
};

type Variant = { [keys in TextVariant]: Partial<TextBlockProps> };

const Text = ({ variant = 'default', children, ...rest }: Props) => {
    const {
        activeTheme: {
            colors: { accentColor, foregroundColor, systemRedColor, systemGreenColor },
        },
    } = ThemeManager;

    const variants: Variant = useMemo(
        () =>
            ({
                headline: {
                    foregroundColor,
                    fontSize: 24,
                    lineHeight: 28,
                },
                body: {
                    foregroundColor,
                    fontSize: 18,
                },
                'body-small': {
                    foregroundColor,
                    fontSize: 13,
                },
                'body-small-bold': {
                    foregroundColor,
                    fontSize: 13,
                    fontWeight: 600,
                },
                errorBold: {
                    foregroundColor: systemRedColor,
                    fontSize: 13,
                    fontWeight: 400,
                    lineHeight: '16px',
                    fontFamily: FONT_FAMILIES.ERROR,
                },
                label: {
                    foregroundColor: accentColor,
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: '16px',
                    textTransform: 'uppercase',
                },
                success: {
                    foregroundColor: systemGreenColor,
                    fontSize: 16,
                    lineHeight: '20px',
                },
                error: {
                    foregroundColor: systemRedColor,
                    fontSize: 16,
                    lineHeight: '20px',
                },
                default: { foregroundColor },
            } as const),
        [accentColor, foregroundColor, systemRedColor, systemGreenColor]
    );

    return children ? (
        <TextBlock {...variants[variant]} {...rest}>
            {children}
        </TextBlock>
    ) : null;
};

export default Text;
