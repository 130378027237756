import ActionPanel from './components/ActionPanel/ActionPanel';
import { LegalEntityDomesticRegistrationDataProps } from './model';
import LegalEntityRegistration from '../LegalEntityRegistration';
import { LegalEntityRegistrationProvider } from '../../context/Provider';
import React from 'react';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';

export default function LegalEntityDomesticRegistrationSection(props: LegalEntityDomesticRegistrationDataProps) {
    const actionPanel = <ActionPanel {...props} isDomesticRegistration />;

    return (
        <LegalEntityRegistrationProvider
            storedValues={props.data}
            mode={props.mode}
            requestStep={RequestStepsView.Domestic_Registration}>
            <LegalEntityRegistration mode={props.mode} type='domestic' actionPanel={actionPanel} workitem={props?.workItem} />
        </LegalEntityRegistrationProvider>
    );
}
