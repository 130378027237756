import { Button, FontIcon, StackPanel, TextBlock } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';

type Props = {
    isDeleted?: boolean;
    isEnabled?: boolean;
    onClick: () => void;
};

export default function DeleteButton({ onClick, isEnabled = true, isDeleted = false }: Props) {
    return (
        <Button
            content={
                <StackPanel orientation='horizontal' verticalAlignment='center' itemGap={SPACING.XXS}>
                    <FontIcon iconName={isDeleted ? 'Undo' : 'Delete'} />
                    <TextBlock text={isDeleted ? 'Undo' : 'Delete'} fontSize={16} />
                </StackPanel>
            }
            styleOverrides={{ alignSelf: 'center' }}
            styleName='textOnlyButtonStyle'
            padding={`${SPACING.XS}px ${SPACING.MD}px`}
            isEnabled={isEnabled}
            onClick={onClick}
        />
    );
}
