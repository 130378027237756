import { Border, ELEVATIONS, SPACING } from 'Utilities/Layout';
import { FontIcon, StackPanel, ThemeColors } from '@bxgrandcentral/controls';
import React, { Fragment, useMemo, useRef, useState } from 'react';

import { Button } from '../components.styled';
import { Checkbox } from 'components/controls/Checkbox/Checkbox';
import { ColDef } from 'ag-grid-community';
import GhostButton from 'components/buttons/GhostButton/GhostButton';
import { Text } from 'components';
import { defaultColumnDefinition } from '../Grid/model';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import useOnClickOutside from 'hooks/use-click-outside';

type Props = {
    columnDefs: ColDef[];
    setColumnDefs: any;
    isEnabled: boolean;
};

export default function ColumnConfiguration({ columnDefs, setColumnDefs, isEnabled }: Props) {
    const colors = useColors();

    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    useOnClickOutside({ ref: [wrapperRef, buttonRef], onClickOutside: () => setIsOpen(false) });

    const isResetEnabled = useMemo(() => {
        const defaultColumnNames = defaultColumnDefinition
            .reduce((result, { hide, field }) => (!hide ? result.concat(field!) : result), [] as string[])
            .sort();
        const currentColumnNames = columnDefs
            .reduce((result, { hide, field }) => (!hide ? result.concat(field!) : result), [] as string[])
            .sort();
        return !isEqual(defaultColumnNames, currentColumnNames);
    }, [columnDefs]);

    return (
        <>
            <Button
                onClick={() => isEnabled && setIsOpen(!isOpen)}
                ref={buttonRef}
                colors={colors}
                {...(!isEnabled && { className: 'disabled' })}>
                <FontIcon
                    iconName='Settings'
                    foregroundColor={colors.foregroundColor}
                    fontSize={20}
                    toolTip={'Dashboard column configurations'}
                />
            </Button>
            <Wrapper colors={colors} isOpen={isOpen} ref={wrapperRef}>
                <Content colors={colors}>
                    <Header>
                        <Text variant='body-small-bold' styleOverrides={{ fontSize: 16 }}>
                            Column Configuration
                        </Text>
                        <Close onClick={() => setIsOpen(false)}>
                            <FontIcon iconName='ChromeClose' foregroundColor={colors.foregroundColor} fontSize={14} />
                        </Close>
                    </Header>
                    <StackPanel styleOverrides={{ overflowY: 'scroll' }}>
                        {columnDefs.map((item: any, index: number) => (
                            <Fragment key={index}>
                                <Checkbox
                                    id={item.field}
                                    value={item.headerName}
                                    isActive={!item.hide}
                                    onClick={() => {
                                        const result: ColDef[] = Array.from(columnDefs);
                                        result[index].hide = !result[index].hide;
                                        setColumnDefs(result);
                                    }}
                                />
                            </Fragment>
                        ))}
                    </StackPanel>
                    <Border color={colors.normalControlBorderColor} />
                    <StackPanel
                        padding={`${SPACING.SM}px ${SPACING.MD}px`}
                        orientation='horizontal'
                        styleOverrides={{ justifyContent: 'flex-end' }}>
                        <GhostButton
                            onClick={() => {
                                setColumnDefs(defaultColumnDefinition);
                            }}
                            isEnabled={isResetEnabled}>
                            Reset Configuration
                        </GhostButton>
                    </StackPanel>
                </Content>
            </Wrapper>
        </>
    );
}

type ColorProps = {
    colors: ThemeColors;
};

const Wrapper = styled.div<ColorProps & { isOpen: boolean }>`
    position: absolute;
    top: 92px;
    right: 15px;
    z-index: ${ELEVATIONS.DIALOG};
    border: 1px solid ${({ colors }) => colors.normalControlBorderColor};
    bottom: 19px;
    background-color: ${({ colors }) => colors.backgroundColor};
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${SPACING.MD}px;
`;

const Close = styled.div`
    &:hover {
        cursor: pointer;
        font-weight: 600;
    }
`;

const Content = styled.div<ColorProps>`
    width: 285px;
    font-size: 13px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    p {
        color: ${({ colors }) => colors.foregroundColor};
        line-height: 20px;
        white-space: pre;
    }
`;
