import LegalEntityFinanceDetailsSection from 'modules/LegalEntityCreation/LegalEntityFinanceDetails/LegalEntityFinanceDetailsSection';
import React from 'react';
import { handleObjectAttributesForSave } from 'modules/LegalEntityCreation/utils';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';

export default function FinanceDetailsStepRenderer() {
    const { saveSection, reloadEntityAndWorkItems, completeTask, createStandalone } = useApiServices();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            financeInformationWorkItem: workItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const storedData = sections.FinanceInformation;
    const noPendingStoredData = noPendingSections.FinanceInformation;

    if (isWorkItemsRefreshing || (!selectedPanel?.hasActions && !editWorkFlow)) {
        return (
            <LegalEntityFinanceDetailsSection
                workItem={workItem}
                mode='Readonly'
                data={storedData}
                noPendingData={noPendingStoredData}
            />
        );
    }

    if (!selectedPanel?.hasActions && editWorkFlow) {
        return (
            <LegalEntityFinanceDetailsSection
                mode='Create'
                data={storedData}
                noPendingData={noPendingStoredData}
                onSave={(changes) => {
                    return saveSection(
                        sections,
                        'FinanceInformation',
                        handleObjectAttributesForSave(storedData, changes)
                    ).then((e) => e?.entityOId ?? 0);
                }}
                onCreateWorkItem={(entityOId, type) => createStandalone(entityOId, type)}
                workItem={workItem}
            />
        );
    }

    switch (workItem?.workItemState) {
        case 'FinanceRequest': {
            return (
                <LegalEntityFinanceDetailsSection
                    mode='Edit'
                    data={storedData}
                    noPendingData={noPendingStoredData}
                    workItem={workItem}
                    onSave={(changes) => {
                        return saveSection(sections, 'FinanceInformation', changes).then((e) => e.entityOId ?? 0);
                    }}
                    onCompleteTask={async (t, exitCode) => {
                        await completeTask(workItem, t, exitCode, '');
                        if (exitCode?.toLowerCase() === 'withdraw' || exitCode?.toLowerCase() === 'submit') {
                            await reloadEntityAndWorkItems();
                        }
                    }}
                />
            );
        }
        case 'FinanceApproval': {
            return (
                <LegalEntityFinanceDetailsSection
                    mode='Approval'
                    workItem={workItem}
                    data={storedData}
                    noPendingData={noPendingStoredData}
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        const section = exitCode === 'Approve' ? '' : undefined;
                        await completeTask(workItem, task, exitCode, section, rejectReason);
                        await reloadEntityAndWorkItems(2000);
                    }}
                />
            );
        }
        default:
            return (
                <LegalEntityFinanceDetailsSection
                    workItem={workItem}
                    mode='Readonly'
                    data={storedData}
                    noPendingData={noPendingStoredData}
                />
            );
    }
}
