import { newStructureTypeBankAccounts, oldStructureTypeBankAccounts } from './model';

import { AdministeredBy } from 'models';

const { ExternalAdmin, PortfolioCompany } = AdministeredBy;

export function isExternalAdminOrPortfolioCompany(value?: number) {
    return value ? [ExternalAdmin, PortfolioCompany].includes(value) : false;
}

export function isStructureTypeRequiresBankAccount(structureType?: number, isOldStructureType?: boolean) {
    if (!structureType) return false;
    return isOldStructureType
        ? oldStructureTypeBankAccounts.includes(structureType)
        : newStructureTypeBankAccounts.includes(structureType);
}
