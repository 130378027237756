import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import { Button, StackPanel, TextBlock, ThemeManager, ToolBarButton } from '@bxgrandcentral/controls';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Expander, Text } from 'components';
import {
    LegalEntityAuthorizedPersonProvider,
    useLegalEntityOwnerShipInformation,
} from 'modules/LegalEntityCreation/context/Provider';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import AuthorizedPerson from './AuthorizedPerson';
import { AuthorizedPersonModel } from '../../model';
import GridNoRowsOverlay from 'modules/RequestsDashboard/components/Grid/components/GridNoRowsOverlay';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SPACING } from 'Utilities/Layout';
import { delay } from 'Utilities/Delay';
import { isEmpty } from 'Utilities/Validations';
import styled from 'styled-components';
import useAuthorizedPersonColumnDefs from './use-authorize-persons-column-def';
import useColors from 'api/hooks/use-theme';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

type Props = {
    mode: string;
    isStandaloneWorkflow: boolean;
    setFormHasChanges: (isOpen: boolean) => void;
};

export default function AuthorizedPersonsGrid({ mode, isStandaloneWorkflow, setFormHasChanges }: Props) {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
                noPendingValues,
                validationErrors,
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityOwnerShipInformation();

    const {
        state: { loadingStates },
    } = useCreationViewContext();

    const { accentColor } = useColors();

    const [selectedRowIndexes, setSelectedRowIndexes] = useState<string[]>([]);
    const [selectedRowData, setSelectedRowData] = useState<AuthorizedPersonModel | undefined>(undefined);
    const [selectedNoPendingData, setSelectedNoPendingData] = useState<AuthorizedPersonModel | undefined>(undefined);
    const [isNewFlow, setIsNewFlow] = useState(false);
    const [hideForm, setHideForm] = useState(false);

    const defaultColumnDef = useMemo<ColDef>(
        () => ({
            sortable: false,
            filter: false,
            resizable: false,
            sortingOrder: ['asc', 'desc'],
            minWidth: 150,
        }),
        []
    );

    const theme = ThemeManager.activeTheme.name === 'Dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
        params.api.setRowData(authorizedPerson);
    };

    const gridRef = useRef<AgGridReact>(null);
    const [gridApi, setGridApi] = useState<GridApi>();

    const selectRow = (rowIndex: string | number) => {
        clearRowSelection();
        setSelectedRowIndexes([String(rowIndex)]);
    };

    const onCellClicked = (params: any) => {
        if (params.column.colId === 'isDeleted') {
            params.event.stopPropagation();
        } else {
            selectRow(params.rowIndex);
            setSelectedRowData(params.data);

            if (noPendingValues?.authorizedPerson) {
                setSelectedNoPendingData(noPendingValues.authorizedPerson[params.rowIndex]);
            }
        }
    };

    const addNewItem = async (item: AuthorizedPersonModel) => {
        closeSelectedElement();

        const updated = [...authorizedPerson];
        updated.push(item);
        setValue('authorizedPerson', updated);

        setTimeout(() => {
            gridApi?.paginationGoToLastPage();
        }, 100);
    };

    const clearRowSelection = () => {
        selectedRowIndexes.forEach((id) => {
            gridApi?.getRowNode(id)?.setSelected(false);
        });
        setSelectedRowIndexes([]);

        setSelectedRowData(undefined);
        setSelectedNoPendingData(undefined);
    };

    const handleRowSelect = useCallback(() => {
        if (selectedRowIndexes.length) {
            selectedRowIndexes.forEach((id) => {
                gridApi?.getRowNode(id)?.setSelected(true);
            });
        }
    }, [selectedRowIndexes, gridApi]);

    const closeSelectedElement = () => {
        clearRowSelection();
        setFormHasChanges(false);
        setIsNewFlow(false);
    };

    const handleAddButton = () => {
        closeSelectedElement();
        setTimeout(() => {
            setIsNewFlow(true);
        }, 50);
    };

    const handleSelectedRow = useCallback(async () => {
        if (!selectedRowData) {
            return;
        }

        setHideForm(true);

        await delay(100);
        setHideForm(false);
    }, [selectedRowData]);

    useEffect(() => {
        setIsNewFlow(false);
        handleSelectedRow();
    }, [selectedRowData, handleSelectedRow]);

    useEffect(() => {
        setIsNewFlow(false);
        if (selectedRowIndexes.length) {
            handleRowSelect();
        }
    }, [selectedRowIndexes, handleRowSelect]);

    useEffect(() => {
        gridApi?.setRowData(authorizedPerson);
        handleRowSelect();
    }, [authorizedPerson]);

    useEffect(() => {
        if (!isEditable && isNewFlow) {
            setIsNewFlow(false);
        }
    }, [isEditable]);

    const selectedRowId = useMemo(() => parseInt(selectedRowIndexes[0]), [selectedRowIndexes]);

    const { authorizedPersonColumnDefs } = useAuthorizedPersonColumnDefs({
        clearRowSelection,
        mode,
        isStandaloneWorkflow,
    });

    const isFormVisible = !hideForm && isEmpty(loadingStates) && (selectedRowData || isNewFlow);

    return (
        <Wrapper>
            {isEditable && !isNewFlow && (
                <AddButtonContainer>
                    <Button content='Add Authorized Person' isEnabled={isEditable} onClick={handleAddButton} />
                </AddButtonContainer>
            )}
            <Expander
                header='Authorized Persons'
                requestStep={RequestStepsView.Ownership_Information}
                padding={0}
                content={
                    <Layout>
                        <GridContainer className={theme} id='authorized-person-table'>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={authorizedPersonColumnDefs}
                                onGridReady={onGridReady}
                                rowClass='custom-row pointer-cursor'
                                pagination={true}
                                paginationPageSize={10}
                                defaultColDef={defaultColumnDef}
                                onCellClicked={onCellClicked}
                                rowHeight={40}
                                headerHeight={35}
                                suppressCellFocus={true}
                                noRowsOverlayComponent={GridNoRowsOverlay}
                                noRowsOverlayComponentParams={{
                                    noRowsMessage: 'There are no Authorized Persons.',
                                    isError: false,
                                }}
                            />
                        </GridContainer>
                        {validationErrors.authorizedPerson && (
                            <StackPanel
                                orientation='horizontal'
                                styleOverrides={{ justifyContent: 'center' }}
                                padding={`${SPACING.MD}px 0`}>
                                <Text variant='errorBold' horizontalAlignment='center' textAlignment='center'>
                                    {validationErrors.authorizedPerson}
                                </Text>
                            </StackPanel>
                        )}
                        {isFormVisible ? (
                            <FormContainer>
                                <Divider />
                                <ToolBarButton
                                    icon='Cancel'
                                    toolTip='Unsaved changes will not be added to the table'
                                    fontSize={18}
                                    foregroundColor={accentColor}
                                    horizontalAlignment='right'
                                    onClick={closeSelectedElement}
                                />
                                <LegalEntityAuthorizedPersonProvider
                                    storedValues={selectedRowData}
                                    noPendingValues={selectedNoPendingData}
                                    mode={mode}>
                                    <AuthorizedPerson
                                        index={selectedRowId}
                                        addNewItem={addNewItem}
                                        closeSelectedElement={closeSelectedElement}
                                        isNewFlow={isNewFlow}
                                        setFormHasChanges={setFormHasChanges}
                                    />
                                </LegalEntityAuthorizedPersonProvider>
                            </FormContainer>
                        ) : (
                            !!authorizedPerson.length && (
                                <TextBlock
                                    text={
                                        mode === 'Readonly' || mode === 'Approval'
                                            ? 'Click on a row to view detailed authorized person'
                                            : 'Click on a row to edit existing authorized person'
                                    }
                                    styleName='captionStyle'
                                    horizontalAlignment='center'
                                    verticalAlignment='center'
                                    padding={`${SPACING.MD}px ${SPACING.XS}px`}
                                />
                            )
                        )}
                    </Layout>
                }
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
`;

const AddButtonContainer = styled.div`
    position: absolute;
    right: 30px;
    top: 28px;
`;

const Layout = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 0 ${SPACING.MD}px;
    min-height: 305px;
    position: relative;
`;

const GridContainer = styled.div`
    min-height: 305px;
`;

const FormContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    padding: ${SPACING.MD}px 0 0 0;
`;

const Divider = styled.div`
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
    margin: 0 0 ${SPACING.XS}px 0;
`;
