import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { useCallback, useState } from 'react';

import { GlobalState } from '../../../GlobalState';
import { RejectionDialog } from 'components';
import { SPACING } from 'Utilities/Layout';
import { Task } from '../../../models/Workflow';
import { isEmpty } from '../../../Utilities/Validations';
import { useAppContext } from 'context/app-context';

type Props<T extends Task> = {
    task: T;
    onCompleteTask: (task: T, exitCode?: string, rejectReason?: string) => void;
    preSubmit?: () => Promise<any>;
    isSubmitEnabled?: boolean;
    isApproveEnabled?: boolean;
    actionTopic?: string;
    rejectDialogMessage?: string;
    rejectDialogMessageColor?: string;
    customMessage?: string;
};

export const TaskActions = <T extends Task>({
    task,
    onCompleteTask,
    preSubmit = () => Promise.resolve(),
    isSubmitEnabled = true,
    isApproveEnabled = true,
    actionTopic = 'request',
    rejectDialogMessage,
    rejectDialogMessageColor,
    customMessage,
}: Props<T>) => {
    const [showRejectionDialog, setShowRejectionDialog] = useState(false);

    const padding = `${SPACING.XS}px ${SPACING.MD}px`;
    const {
        state: {
            settings: { isRequestConfirmationRequired },
        },
    } = useAppContext();

    type Actions = 'Submit' | 'Withdraw' | 'Approve';

    const handleSubmit = useCallback(
        async (action: Actions) => {
            let canSubmit =
                !isRequestConfirmationRequired ||
                (await GlobalState.openDialog({
                    title: `${action} Request`,
                    variant: 'warning',
                    content: customMessage
                        ? customMessage
                        : `Are you sure you want to ${action.toLowerCase()} this ${actionTopic}?`,
                }));
            canSubmit && preSubmit().then(() => onCompleteTask(task, action));
        },
        [isRequestConfirmationRequired, onCompleteTask, preSubmit, task, actionTopic, customMessage]
    );

    return isEmpty(task.exitCodes) ? (
        <Button
            isEnabled={isSubmitEnabled}
            content='Submit'
            onClick={async () => {
                handleSubmit('Submit');
            }}
        />
    ) : (
        <>
            <StackPanel itemGap={SPACING.LG} orientation='horizontal' styleOverrides={{ alignSelf: 'flex-end' }}>
                {task.exitCodes.includes('Withdraw') && (
                    <Button
                        styleName='textOnlyButtonStyle'
                        padding={padding}
                        onClick={async () => {
                            handleSubmit('Withdraw');
                        }}>
                        Withdraw
                    </Button>
                )}
                {task.exitCodes.includes('Reject') && (
                    <Button
                        styleName='textOnlyButtonStyle'
                        padding={padding}
                        onClick={() => {
                            setShowRejectionDialog(true);
                        }}>
                        Reject
                    </Button>
                )}
                {task.exitCodes.includes('Approve') && (
                    <Button
                        isEnabled={isApproveEnabled}
                        padding={padding}
                        onClick={async () => {
                            handleSubmit('Approve');
                        }}>
                        Approve
                    </Button>
                )}
                {task.exitCodes.includes('Submit') && (
                    <Button
                        isEnabled={isSubmitEnabled}
                        padding={padding}
                        onClick={async () => {
                            handleSubmit('Submit');
                        }}>
                        Submit
                    </Button>
                )}
            </StackPanel>
            <RejectionDialog
                isOpen={showRejectionDialog}
                handleConfirm={(rejectReason) => {
                    setShowRejectionDialog(false);
                    onCompleteTask(task, 'Reject', rejectReason);
                }}
                handleCancel={() => setShowRejectionDialog(false)}
                message={rejectDialogMessage}
                messageColor={rejectDialogMessageColor}
            />
        </>
    );
};
