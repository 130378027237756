import React, { useEffect } from 'react';
import {
    setLegalEntityOId,
    useCreationViewContext,
} from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

import SideBar from 'modules/LegalEntityCreation/LegalEntityCreationView/components/SideBar/SideBar';
import { WaitingIndicator } from '@bxgrandcentral/controls/dist/cjs/WaitingIndicator/WaitingIndicator';
import styled from 'styled-components';
import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';

type Props = {
    entityOId: number;
};

export default function PageOptionsPopup({ entityOId }: Props) {
    const {
        dispatch,
        state: { loadingStates },
    } = useCreationViewContext();
    const { getUserScopes } = useApiServices();

    useEffect(() => {
        // Fix if the popup is partly out of the screen, trigger position recalculation
        window.dispatchEvent(new Event('resize'));
    }, [loadingStates]);

    useEffect(() => {
        setLegalEntityOId(dispatch, entityOId || undefined);
        getUserScopes(entityOId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loadingStates['fetching permissions'] ? (
                <Container>
                    <WaitingIndicator id={'spinner'} isVisible={true} />
                </Container>
            ) : (
                <SideBar isStandalone={true} />
            )}
        </>
    );
}
const Container = styled.div`
    position: relative;
    height: 50px;
    width: 50px;
`;
