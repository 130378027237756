import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export type LegalEntityDissolutionTaskType =
    | 'SubmitRequestType'
    | 'ApprovalRequestType'
    | 'AwaitingChildApprovalType'
    | 'BdgSubmitTaskType';

export const DISSOLUTION_STATE = {
    DISSOLUTION_REQUEST: 'Dissolution Request',
    DISSOLUTION_APPROVAL: 'Dissolution Approval',
    DISSOLUTION_COMPLETE: 'Dissolution Setup Complete',
    SAVE_DATA: 'SaveData',
    DISSOLUTION_WITHDRAWAL: 'Dissolution Withdrawn',
    AWAITING_FOR_CHILD_APPROVAL: 'Awaiting For Child Approval',
    BDG_PENDING_SUBMIT: 'BDG Pending To Submit',
    BDG_SETUP_COMPLETE: 'BDG Dissolution Setup Complete',
} as const;

export const DISSOLUTION_STATUS = {
    IN_PROCESS: 'InProcess',
    COMPLETE: 'Complete',
    CANCELED: 'Canceled',
} as const;

export type DissolutionStateKeyType = keyof typeof DISSOLUTION_STATE;
export type LegalEntityDissolutionState = (typeof DISSOLUTION_STATE)[DissolutionStateKeyType];

export type LegalEntityDissolutionStatus = 'InProcess' | 'Complete' | 'Canceled';
export type LegalEntityDissolutionTask = Task & Record<keyof Pick<Task, 'taskType'>, LegalEntityDissolutionTaskType>;
export type LegalEntityDissolutionDetailsWorkflow = Omit<WorkItem, 'tasks'> & {
    workflowType: WorkflowType.LegalEntityDissolutionParentWorkflowType;
    workItemState: LegalEntityDissolutionState;
    workItemStatus: LegalEntityDissolutionStatus;
    tasks: LegalEntityDissolutionTask[] | undefined;
};
