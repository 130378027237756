import { NewStructureType, OldStructureType } from 'models';
import { TaxClassificationTask, TaxClassificationWorkflow } from 'models/LegalEntityRequest/Workflow';

import { documentTypes } from 'models/shared/refData/NewDocumentType';
import { isUnitedStates } from 'models';

type DependentData = {
    legalName?: string;
    businessUnitOId?: number;
    structureType?: number;
    isOldStructureType?: boolean;
    domesticFormationDate?: Date;
    domesticState?: number;
    domesticCountry?: number;
    entityOId?: number;
};

type FormData = {
    fatcaClassificationId?: number;
    crsClassificationId?: number;
    crsSubClassificationId?: number;
    crsSubClassificationDetailId?: number;
    usTaxClassificationTypeId?: number;
    usStateTaxClassificationTypeId?: number;
    isCtbElectionApproved?: boolean;
    isGIINRequired?: boolean;
    tax8832EffectiveDate?: Date;
    chapterThreeStatusId?: number;
    taxPreparerEntityOId?: number;
    isPortCoManaged?: boolean;
};

export type LegalEntityTaxClassificationData = FormData & DependentData;

export const oldBlockerTypes = [OldStructureType.BlockerLeverage, OldStructureType.BlockerUnLeverage];
export const newBlockerTypes = [NewStructureType.BlockerLeveraged, NewStructureType.BlockerUnleveraged];

export function isBlockerStructureType(data: DependentData) {
    const { structureType, isOldStructureType } = data;
    if (!structureType) {
        return false;
    }
    return isOldStructureType ? oldBlockerTypes.includes(structureType) : newBlockerTypes.includes(structureType);
}

export const FATCAClassifications = {
    USFI: 39,
    ReportingFIModel1IGA: 40,
    ParticipatingRreportingFIModel2IGA: 98,
    ActiveOrPassiveNFFE: 95,
    ReportingModel2FFI: 113,
    ReportingModel1FFI: 112,
    ParticipatingFFI: 111,
};

export const CRSClassifications = {
    ReportingFI: 1,
    FINonParticipatingJurisdiction: 3,
};

export const CRSSubClassifications = {
    OtherFI: 18,
};

export const CRSSubClassificationDetails = {
    NoneOfTheAbove: 3,
};

export const USTaxClassificationTypes = {
    Corporation: 1,
    Partnership: 7,
};

export const USStateTaxClassificationTypes = {
    Corporation: 1,
    Partnership: 8,
};

export const taxClassificationDocumentTypes = [
    documentTypes.W8BENE,
    documentTypes.W8IMY,
    documentTypes.W9,
    documentTypes.Form8832,
    documentTypes.ApprovalForm8832,
    documentTypes.Form8821,
    documentTypes.FormSS4,
    documentTypes.EINApplication,
    documentTypes.EINCTBConfirmation,
    documentTypes.CRSSelfcertification,
];

type LegalEntityTaxClassificationReadOnlyProps = {
    mode: 'Readonly';
    workItem?: TaxClassificationWorkflow;
};

type LegalEntityTaxClassificationCreateProps = {
    mode: 'Create';
    workItem?: TaxClassificationWorkflow;
    onSave: (data: Partial<LegalEntityTaxClassificationData>) => Promise<number>;
    onCreateWorkItem: (entityId: number, type: string, tax8832EffectiveDate?: Date) => void;
};

type LegalEntityTaxClassificationEditProps = {
    mode: 'Edit';
    workItem: TaxClassificationWorkflow;
    onSave: (data: Partial<LegalEntityTaxClassificationData>) => Promise<number>;
    onCompleteTask: (task: TaxClassificationTask, exitCode: string, rejectReason?: string) => void;
};

type LegalEntityTaxClassificationApproveProps = {
    mode: 'Approval';
    workItem: TaxClassificationWorkflow;
    onCompleteTask: (task: TaxClassificationTask, exitCode: string, rejectReason?: string) => void;
};

type BaseProps = {
    data?: LegalEntityTaxClassificationData;
    noPendingData?: LegalEntityTaxClassificationData;
};

export type LegalEntityTaxClassificationProps = BaseProps &
    (
        | LegalEntityTaxClassificationEditProps
        | LegalEntityTaxClassificationApproveProps
        | LegalEntityTaxClassificationReadOnlyProps
        | LegalEntityTaxClassificationCreateProps
    );

// These US Tax Classification and Chapter Three options are hardcoded temporarily. These will change to dynamic after the migration to Axiom (@see BXEM-13205)
export const allowedUSTaxClassificationTypeKeys = [1, 2, 5, 7, 9, 10, 11, 15, 16, 19] as const;
export const allowedChapterThreeStatusKeysDefault = [1, 3, 4, 5, 8, 10, 11, 13, 16, 17, 18, 19, 23, 25, 28, 30, 33, 34];

export type allowedUSTaxClassificationTypeKey = (typeof allowedUSTaxClassificationTypeKeys)[number];
export const usTaxChapterThreeMapper = {
    1: { us: [1], nonUs: [1] },
    9: { us: [19], nonUs: [19] },
    5: { us: [8], nonUs: [8] },
    2: { us: [5], nonUs: [5] },
    19: { us: [1], nonUs: [1] },
    16: { us: [4], nonUs: [4] },
    7: { us: [16], nonUs: [13, 25] },
    10: { us: [3, 23, 33, 34, 10], nonUs: [3, 23, 33, 34, 10] },
    11: { us: [1, 23], nonUs: [4, 3, 28, 17, 30] },
    15: { us: [1, 19, 16, 23, 33, 34, 10], nonUs: [18, 11] },
};

export const getChapterThreeOptions = (
    usTaxClassificationTypeId?: allowedUSTaxClassificationTypeKey,
    countryID?: number
) => {
    if (!usTaxClassificationTypeId || !countryID) {
        return allowedChapterThreeStatusKeysDefault;
    }

    return isUnitedStates(countryID)
        ? usTaxChapterThreeMapper[usTaxClassificationTypeId]?.us || allowedChapterThreeStatusKeysDefault
        : usTaxChapterThreeMapper[usTaxClassificationTypeId]?.nonUs || allowedChapterThreeStatusKeysDefault;
};
