import { Button, ThemeColors } from '@bxgrandcentral/controls';
import React, { useState } from 'react';

import PanelOptions from './PanelOptions';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SPACING } from 'Utilities/Layout';
import StatePanel from './StatePanel';
import Text from 'components/Text/Text';
import { Wrapper } from './Debugger.styled';
import { isLowerEnvironment } from 'Utilities/Environment';
import styled from 'styled-components';
import { useAppContext } from 'context/app-context';
import useColors from 'api/hooks/use-theme';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

type Props = {
    inputState: any;
    showUndefinedValues?: boolean;
    requestStep?: RequestStepsView;
};

export default function PageState({
    inputState,
    showUndefinedValues = true,
    requestStep = RequestStepsView.Request,
}: Props) {
    const colors = useColors();
    const { state } = useAppContext();
    const { state: creationViewState } = useCreationViewContext();

    const {
        settings: { isDebuggerToolsEnabled },
    } = state;

    const [isOpen, setIsOpen] = useState(false);

    return isLowerEnvironment() && isDebuggerToolsEnabled ? (
        <>
            <Wrapper colors={colors} isOpen={isOpen}>
                <Button id='open-panel-button' onClick={() => setIsOpen(!isOpen)} width={140} padding={SPACING.XS}>
                    <Text fontSize={14}>{`${isOpen ? 'Hide' : 'Show'} Debug Tools`}</Text>
                </Button>
                {isOpen && (
                    <ToolsContainer colors={colors}>
                        <Text variant='headline' styleOverrides={{ marginBottom: `${SPACING.LG}px`, display: 'block' }}>
                            Debugger Tools
                        </Text>
                        <PanelOptions requestStep={requestStep} />
                        <StatePanel
                            inputState={inputState}
                            state={state}
                            creationViewState={creationViewState}
                            showUndefinedValues={showUndefinedValues}
                        />
                    </ToolsContainer>
                )}
            </Wrapper>
        </>
    ) : null;
}

const ToolsContainer = styled.div<{ colors: ThemeColors }>`
    border: 1px solid ${({ colors }) => colors.normalControlBorderColor};
    background-color: ${({ colors }) => colors.backgroundColor};
    height: 100%;
    padding: ${SPACING.LG}px ${SPACING.MD}px;
`;
