import React from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useCreationViewContext } from '../context/creation-view-context';

export default function DefaultStepRenderer() {
    const {
        state: { sideBarLength, loadingStates },
    } = useCreationViewContext();

    if (!isEmpty(loadingStates)) {
        return <></>;
    }

    const message = sideBarLength ? (
        <p>Please select a step from the left menu.</p>
    ) : (
        <>
            <p>You do not have access to this entity.</p>
            <p>
                Please reach out to&nbsp;
                <a href='mailto:AppSupport@Blackstone.com'>AppSupport@Blackstone.com</a>.
            </p>
        </>
    );

    return <Wrapper>{message}</Wrapper>;
}

const Wrapper = styled.div`
    min-width: 400px;
    max-width: 1100px;
    margin: 200px auto;
    text-align: center;
    padding-right: 80px;
`;
