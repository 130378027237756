import { isCaymanIslands, isUnitedStates } from '../../../models';

import { getRegisteredAgents } from '../LegalEntityDocumentPreparation/utils';
import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDocumentPreparation } from '../context/Provider';
import { validateRequiredField } from './utils';

export default function useDocumentPreparationValidation() {
    const {
        state: {
            data: {
                values: { domesticCountry, domesticRegisteredAgent, domesticState },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDocumentPreparation();

    useEffect(() => {
        updateValidation(dispatch, {
            domesticRegisteredAgent: validateRequiredField(domesticRegisteredAgent, {
                isRequired: getRegisteredAgents(domesticCountry),
            }),
            domesticCountry: validateRequiredField(domesticCountry),
            domesticState: validateRequiredField(domesticState, {
                isRequired: isUnitedStates(domesticCountry) || isCaymanIslands(domesticCountry),
            }),
        });
    }, [domesticCountry, domesticRegisteredAgent, domesticState, version, dispatch]);
}
