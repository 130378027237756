import ApiService from 'services/ApiService';
import { constants } from 'constants/env-config';
import { useQuery } from 'react-query';

export type AssociatedDeal = {
    data: {
        entityOId: number;
        primaryName: string;
        mdmEntityOId: string;
    };
};

type SearchResult = {
    searchResults: AssociatedDeal[];
    totalMatchesFound: number;
};

const searchSettings = {
    entitySubtypes: [23],
    numberOfResults: 15,
    fieldsToReturn: ['entityOId', 'primaryName', 'axiomMDMId'],
};

export default function useAssociatedDealOptions(searchTerm = '') {
    const fetch = async (queryString = ''): Promise<AssociatedDeal[]> => {
        if (queryString.length < 2) {
            return Promise.resolve([]);
        }

        const isNumericSearch = Number.isInteger(Number(queryString));

        const searchTerm = isNumericSearch ? Number(queryString) : queryString;
        const field = isNumericSearch ? 'entityOId' : 'primaryName';
        const subFieldToSearch = isNumericSearch ? 'SearchDefaultField' : 'SearchAutoCompleteTerms';

        const logInfo = {
            service: 'Associated Deal Search',
            module: 'LegalInformation',
        };

        const searchParams = {
            indexSearchRequest: {
                searchItems: [
                    {
                        queryString: searchTerm,
                        fuzziness: -1,
                        fields: [
                            {
                                field,
                                advancedFieldOptions: [
                                    {
                                        subFieldToSearch,
                                    },
                                ],
                            },
                        ],
                        fieldSearchType: 'SingleField_MatchTerm',
                        searchAnalyzerType: 'Standard',
                        searchOperator: 'And',
                        searchInclusionType: 'Include',
                        minimumShouldMatch: '100%',
                    },
                ],
                searchFilters: [
                    {
                        field: 'entitySubtypes',
                        value: searchSettings.entitySubtypes,
                    },
                ],
            },
            resultOptions: {
                numberOfResults: searchSettings.numberOfResults,
                fieldsToReturn: searchSettings.fieldsToReturn,
                includeMetaData: false,
            },
        };

        const deals: SearchResult = await ApiService.Post(
            `${constants.ENTITY_MASTER_API}search/search-request/MdmEntity/deals`,
            logInfo,
            searchParams,
            true
        );

        return deals.searchResults;
    };

    return useQuery(['Associated Deals', searchTerm], () => fetch(searchTerm), {
        refetchOnWindowFocus: false,
    });
}
