import {
    CreateLegalEntityWorkflow,
    CreateLegalEntityWorkflowCustomProperties,
} from 'models/LegalEntityRequest/Workflow';
import {
    setEntityCreationWorkItem,
    setLoadingState,
    unsetLoadingState,
    useCreationViewContext,
} from '../context/creation-view-context';

import { Entity } from 'models/LegalEntityRequest/LegalEntityDTO';
import { GlobalState } from 'GlobalState';
import { IWorkflowService } from 'services/interfaces';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { entitySectionMapper } from '../EntityToSectionMapper';
import { isEqual } from 'lodash';
import { useRequestContext } from 'context/request-context';

export default function useUpdateForeignRegistrationProps() {
    const workflowService = ServiceLocator.container.resolve(IWorkflowService);
    const { dispatch } = useCreationViewContext();
    const {
        state: { taskComment = {} },
        isRequestNotesChanged,
    } = useRequestContext();

    const updateForeignRegistrationProps = async (workItem: CreateLegalEntityWorkflow, entity: Entity) => {
        if (workItem) {
            const loadingKey = 'foreign registration work item update';
            const registrations = entitySectionMapper.mapToForeignRegistrations(entity?.taxRegistrations ?? []);
            const { workItemId, workItemVersionNumber, customProperties } = workItem;
            const updatedCustomProperties: Partial<CreateLegalEntityWorkflowCustomProperties> = {
                ...customProperties,
                registrationID1: registrations['registrationID1'] ?? null,
                registrationID2: registrations['registrationID2'] ?? null,
                registrationID3: registrations['registrationID3'] ?? null,
                taskComment: taskComment[workItemId],
                ...(isRequestNotesChanged(workItem) && { taskComment: taskComment[workItemId] }),
            };
            try {
                if (!isEqual(customProperties, updatedCustomProperties)) {
                    setLoadingState(dispatch, loadingKey);
                    const updatedWorkItem = await workflowService.UpdateWorkItem({
                        workItemId,
                        workItemVersionNumber,
                        customProperties: updatedCustomProperties,
                    });
                    setEntityCreationWorkItem(dispatch, updatedWorkItem);
                    return updatedWorkItem.workItemId;
                } else {
                    return workItemId;
                }
            } catch (e) {
                console.error('failed to save work item', e);
                GlobalState.ShowMessageBox('ERROR', 'Failed to update Workflow');
                throw e;
            } finally {
                unsetLoadingState(dispatch, loadingKey);
            }
        }

        return 0;
    };

    return updateForeignRegistrationProps;
}
