import { setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import { Capabilities } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import React from 'react';
import { StackPanel } from '@bxgrandcentral/controls';
import { TaskActions } from '../../../../Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from '../../../../../../models/Workflow';
import useHasCapability from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-has-capability';
import { useLegalEntityDissolutionApproval } from 'modules/LegalEntityCreation/context/Provider';

export default function ActionPanel(props: ActionPanelProps) {
    const { entityOId } = props;

    const {
        state: {
            canApprove,
            data: { changes },
        },
        dispatch,
    } = useLegalEntityDissolutionApproval();

    const hasCapability = useHasCapability();

    if (!entityOId) {
        return null;
    }

    if (props.mode === 'Edit') {
        const submitTask = props.workItem?.tasks?.filter(
            ({ taskType, status }) => taskType === 'ApprovalRequestType' && isOpen(status)
        )?.[0];

        return submitTask ? (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <div></div>
                <TaskActions
                    task={submitTask}
                    isApproveEnabled={canApprove && hasCapability(Capabilities.approveDissolutionRequest)}
                    preSubmit={async () => {
                        if (!isEmpty(changes)) {
                            await props.onSave(changes);
                        }
                        setShouldReload(dispatch, true);
                    }}
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        setIsLoading(dispatch, true);
                        await props.onCompleteTask(task, exitCode as string, rejectReason);
                        setIsLoading(dispatch, false);
                    }}
                />
            </StackPanel>
        ) : null;
    }
    return null;
}
