import React, { FC } from 'react';

import Text from 'components/Text/Text';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type Props = {
    onClick: () => void;
    id?: string;
    isEnabled?: boolean;
};

const GhostButton: FC<Props> = ({ id, onClick, isEnabled = true, children }) => {
    const { foregroundColor, disabledColor } = useColors();
    return (
        <Wrapper id={id} isEnabled={isEnabled}>
            <Text
                id={id}
                foregroundColor={isEnabled ? foregroundColor : disabledColor}
                variant='body-small-bold'
                onClick={() => isEnabled && onClick()}>
                {children}
            </Text>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ isEnabled: boolean }>`
    &:hover {
        cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'inherit')};
        span {
            text-decoration: ${({ isEnabled }) => (isEnabled ? 'underline' : 'none')};
        }
    }
`;

export default GhostButton;
