import { cloneDeep } from 'lodash';

export function getKeysOf<T>(value: T) {
    return Object.keys(value) as (keyof typeof value)[];
}

export function getValuesOf<T>(value: T) {
    return Object.values(value) as any[];
}

export function isEmptyObject(object: any) {
    return Object.keys(object).length === 0;
}

export function mergeObjects<T>(source: Partial<T>, optional: Partial<T>): Partial<T> {
    return cloneDeep(
        getKeysOf({ ...source, ...optional }).reduce((result, key) => {
            return { ...result, [key]: source[key] ?? optional[key] };
        }, {})
    );
}
