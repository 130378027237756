import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionTreasury } from '../context/Provider';
import { validateCompleted } from './utils';

export default function useDissolutionTreasuryValidation() {
    const {
        state: {
            data: {
                values: {
                    allAccountsClosed,
                    outsideAccountsClosed,
                    trialBalanceZero,
                    noOpenIntercompanyBalances,
                    closeInWSS,
                },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionTreasury();

    useEffect(() => {
        updateValidation(dispatch, {
            allAccountsClosed: validateCompleted(allAccountsClosed),
            outsideAccountsClosed: validateCompleted(outsideAccountsClosed),
            trialBalanceZero: validateCompleted(trialBalanceZero),
            noOpenIntercompanyBalances: validateCompleted(noOpenIntercompanyBalances),
            closeInWSS: validateCompleted(closeInWSS),
        });
    }, [
        dispatch,
        allAccountsClosed,
        outsideAccountsClosed,
        trialBalanceZero,
        noOpenIntercompanyBalances,
        closeInWSS,
        version,
    ]);
}
