import { AuthorizedPersonModel, LegalEntityOwnershipInformationData, OwnershipDetailsModel } from '../LegalEntityOwnershipInformation/model';

import { LegalEntityApprovalData } from '../LegalEntityApproval/model';
import { LegalEntityCorporateTransparencyData } from '../LegalEntityCorporateTransparency/model';
import { LegalEntityDissolutionApprovalData } from '../LegalEntityDissolution/LegalEntityDissolutionApproval/model';
import { LegalEntityDissolutionBDGData } from '../LegalEntityDissolution/LegalEntityDissolutionBdg/model';
import { LegalEntityDissolutionFinanceData } from '../LegalEntityDissolution/LegalEntityDissolutionFinance/model';
import { LegalEntityDissolutionLegalData } from '../LegalEntityDissolution/LegalEntityDissolutionLegal/model';
import { LegalEntityDissolutionLitigationData } from '../LegalEntityDissolution/LegalEntityDissolutionLitigation/model';
import { LegalEntityDissolutionRequestData } from '../LegalEntityDissolution/LegalEntityDissolutionDetails/model';
import { LegalEntityDissolutionTaxData } from '../LegalEntityDissolution/LegalEntityDissolutionTax/model';
import { LegalEntityDissolutionTreasuryData } from '../LegalEntityDissolution/LegalEntityDissolutionTreasury/model';
import { LegalEntityDocumentPreparationData } from '../LegalEntityDocumentPreparation/model';
import { LegalEntityFinanceDetailsData } from '../LegalEntityFinanceDetails/model';
import { LegalEntityInformationData } from '../LegalEntityInformation/model';
import { LegalEntityRegistrationData } from '../LegalEntityRegistration/model';
import { LegalEntityRegistrationUpdateData } from '../LegalEntityRegistration/LegalEntityForeignRegistrationUpdate/model';
import { LegalEntityRequestData } from '../LegalEntityRequest/model';
import { LegalEntityTaxClassificationData } from '../LegalEntityTaxClassification/model';
import { createContextFactory } from './factory';

const { Provider: LegalEntityRequestProvider, useFormContext: useLegalEntityRequest } =
    createContextFactory<LegalEntityRequestData>();

const { Provider: LegalEntityDocumentPreparationProvider, useFormContext: useLegalEntityDocumentPreparation } =
    createContextFactory<LegalEntityDocumentPreparationData>();

const { Provider: LegalEntityRegistrationProvider, useFormContext: useLegalEntityRegistration } =
    createContextFactory<LegalEntityRegistrationData>();

const { Provider: LegalEntityCorporateTransparencyProvider, useFormContext: useLegalEntityCorporateTransparency } =
    createContextFactory<LegalEntityCorporateTransparencyData>();

const { Provider: LegalEntityRegistrationUpdateProvider, useFormContext: useLegalEntityRegistrationUpdate } =
    createContextFactory<LegalEntityRegistrationUpdateData>();

const { Provider: LegalEntityApprovalProvider, useFormContext: useLegalEntityApproval } =
    createContextFactory<LegalEntityApprovalData>();

const { Provider: LegalEntityTaxClassificationProvider, useFormContext: useLegalEntityTaxClassification } =
    createContextFactory<LegalEntityTaxClassificationData>();

const { Provider: LegalEntityFinanceDetailsProvider, useFormContext: useLegalEntityFinanceDetails } =
    createContextFactory<LegalEntityFinanceDetailsData>();

const { Provider: LegalEntityOwnershipInformationProvider, useFormContext: useLegalEntityOwnerShipInformation } =
    createContextFactory<LegalEntityOwnershipInformationData>();

const { Provider: LegalEntityAuthorizedPersonProvider, useFormContext: useLegalEntityAuthorizedPerson } =
    createContextFactory<AuthorizedPersonModel>();

const { Provider: LegalEntityOwnershipDetailProvider, useFormContext: useLegalEntityOwnerShipDetail } =
    createContextFactory<OwnershipDetailsModel>();

const { Provider: LegalEntityInformationProvider, useFormContext: useLegalEntityInformation } =
    createContextFactory<LegalEntityInformationData>();

const { Provider: LegalEntityDissolutionDetailsProvider, useFormContext: useLegalEntityDissolutionDetails } =
    createContextFactory<LegalEntityDissolutionRequestData>();

const { Provider: LegalEntityDissolutionApprovalProvider, useFormContext: useLegalEntityDissolutionApproval } =
    createContextFactory<LegalEntityDissolutionApprovalData>();

const { Provider: LegalEntityDissolutionLitigationProvider, useFormContext: useLegalEntityDissolutionLitigation } =
    createContextFactory<LegalEntityDissolutionLitigationData>();

const { Provider: LegalEntityDissolutionTaxProvider, useFormContext: useLegalEntityDissolutionTax } =
    createContextFactory<LegalEntityDissolutionTaxData>();

const { Provider: LegalEntityDissolutionFinanceProvider, useFormContext: useLegalEntityDissolutionFinance } =
    createContextFactory<LegalEntityDissolutionFinanceData>();

const { Provider: LegalEntityDissolutionLegalProvider, useFormContext: useLegalEntityDissolutionLegal } =
    createContextFactory<LegalEntityDissolutionLegalData>();

const { Provider: LegalEntityDissolutionTreasuryProvider, useFormContext: useLegalEntityDissolutionTreasury } =
    createContextFactory<LegalEntityDissolutionTreasuryData>();

const { Provider: LegalEntityDissolutionBDGProvider, useFormContext: useLegalEntityDissolutionBDG } =
    createContextFactory<LegalEntityDissolutionBDGData>();

export {
    LegalEntityRequestProvider,
    LegalEntityDocumentPreparationProvider,
    LegalEntityInformationProvider,
    LegalEntityRegistrationProvider,
    LegalEntityRegistrationUpdateProvider,
    LegalEntityCorporateTransparencyProvider,
    LegalEntityApprovalProvider,
    LegalEntityTaxClassificationProvider,
    LegalEntityFinanceDetailsProvider,
    LegalEntityOwnershipInformationProvider,
    LegalEntityAuthorizedPersonProvider,
    LegalEntityOwnershipDetailProvider,
    LegalEntityDissolutionDetailsProvider,
    LegalEntityDissolutionApprovalProvider,
    LegalEntityDissolutionLitigationProvider,
    LegalEntityDissolutionTaxProvider,
    LegalEntityDissolutionFinanceProvider,
    LegalEntityDissolutionLegalProvider,
    LegalEntityDissolutionTreasuryProvider,
    LegalEntityDissolutionBDGProvider,
    useLegalEntityRequest,
    useLegalEntityDocumentPreparation,
    useLegalEntityRegistration,
    useLegalEntityRegistrationUpdate,
    useLegalEntityCorporateTransparency,
    useLegalEntityApproval,
    useLegalEntityTaxClassification,
    useLegalEntityFinanceDetails,
    useLegalEntityOwnerShipInformation,
    useLegalEntityAuthorizedPerson,
    useLegalEntityOwnerShipDetail,
    useLegalEntityInformation,
    useLegalEntityDissolutionDetails,
    useLegalEntityDissolutionApproval,
    useLegalEntityDissolutionLitigation,
    useLegalEntityDissolutionTax,
    useLegalEntityDissolutionFinance,
    useLegalEntityDissolutionLegal,
    useLegalEntityDissolutionTreasury,
    useLegalEntityDissolutionBDG,
};
