import React, { useMemo } from 'react';

import LegalEntityForeignRegistrationSection from 'modules/LegalEntityCreation/LegalEntityRegistration/LegalEntityForeignRegistration/LegalEntityForeignRegistrationSection';
import LegalEntityForeignRegistrationUpdateSection from 'modules/LegalEntityCreation/LegalEntityRegistration/LegalEntityForeignRegistrationUpdate/LegalEntityForeignRegistrationUpdateSection';
import useApiServices from '../hooks/use-api-services';
import useBackToDashboard from '../hooks/use-back-to-dashboard';
import { useCreationViewContext } from '../context/creation-view-context';

export default function ForeignRegistrationStepRenderer() {
    const { saveSection, reloadUserScopes, reloadEntityAndWorkItems, completeTask, createStandalone } =
        useApiServices();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            foreignRegistrationWorkItems: workItems,
            foreignRegistrationUpdateWorkItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const backToDashboard = useBackToDashboard();

    const data = sections.ForeignRegistration;
    const noPendingUpdateData = noPendingSections.ForeignRegistrationUpdate;

    const dataForUpdate = sections.ForeignRegistrationUpdate;

    const hasOpenChildWorkitem = useMemo(() => {
        return !!workItems.find(({ workItemStatus }) => workItemStatus === 'InProcess');
    }, [workItems]);

    // Read only
    if (isWorkItemsRefreshing || (!selectedPanel?.hasActions && !editWorkFlow)) {
        if (foreignRegistrationUpdateWorkItem) {
            return (
                <LegalEntityForeignRegistrationUpdateSection
                    mode='Readonly'
                    data={dataForUpdate}
                    noPendingData={noPendingUpdateData}
                    workItem={foreignRegistrationUpdateWorkItem}
                />
            );
        }
        return (
            <LegalEntityForeignRegistrationSection data={data} mode='Readonly' workItems={workItems} hasOpenWorkitem={hasOpenChildWorkitem} />
        );
    }

    // Standalone Update - Create
    if (!selectedPanel?.hasActions && editWorkFlow) {
        return (
            <LegalEntityForeignRegistrationUpdateSection
                mode='Create'
                data={dataForUpdate}
                noPendingData={noPendingUpdateData}
                onSave={(dataToSave) => {
                    return saveSection(sections, 'ForeignRegistrationUpdate', dataToSave ?? []).then(
                        (e) => e.entityOId ?? 0
                    );
                }}
                onCreateWorkItem={async (entityOId: number, type: string) => {
                    await createStandalone(entityOId, type);
                }}
                workItem={foreignRegistrationUpdateWorkItem}
            />
        );
    }

    // Standalone Update - Edit / Approve
    if (foreignRegistrationUpdateWorkItem) {
        switch (foreignRegistrationUpdateWorkItem?.workItemState) {
            case 'ForeignRegistrationUpdateRequest':
                return (
                    <LegalEntityForeignRegistrationUpdateSection
                        mode='Edit'
                        data={dataForUpdate}
                        onSave={async (dataToSave) => {
                            const entity = await saveSection(sections, 'ForeignRegistrationUpdate', dataToSave ?? []);
                            return await reloadUserScopes(entity.entityOId ?? 0);
                        }}
                        onCompleteTask={async (task, exitCode) => {
                            if (foreignRegistrationUpdateWorkItem) {
                                await completeTask(foreignRegistrationUpdateWorkItem, task, exitCode, '');
                                if (exitCode?.toLowerCase() === 'withdraw') {
                                    backToDashboard();
                                }
                            }
                        }}
                        workItem={foreignRegistrationUpdateWorkItem}
                    />
                );
            case 'ForeignRegistrationUpdateApproval':
                return (
                    <LegalEntityForeignRegistrationUpdateSection
                        mode='Approval'
                        data={dataForUpdate}
                        noPendingData={noPendingUpdateData}
                        workItem={foreignRegistrationUpdateWorkItem}
                        onCompleteTask={async (task, exitCode, rejectReason) => {
                            const section = exitCode === 'Approve' ? '' : undefined;
                            await completeTask(
                                foreignRegistrationUpdateWorkItem,
                                task,
                                exitCode,
                                section,
                                rejectReason
                            );
                            await reloadEntityAndWorkItems();
                        }}
                    />
                );
            default:
                return (
                    <LegalEntityForeignRegistrationUpdateSection
                        mode='Readonly'
                        data={dataForUpdate}
                        workItem={foreignRegistrationUpdateWorkItem}
                    />
                );
        }
    }

    // Child workflow
    return (
        <LegalEntityForeignRegistrationSection
            data={data}
            mode='Mixed'
            workItems={workItems}
            onCompleteTask={(item, task, exitCode, rejectReason) =>
                completeTask(item, task, exitCode, '', rejectReason).then(() => {
                    if (exitCode?.toLowerCase() === 'withdraw') {
                        reloadEntityAndWorkItems(2000);
                    }
                })
            }
            onSave={async (dataToSave) => {
                const asArray = [dataToSave];
                const entityId = await saveSection(sections, 'ForeignRegistration', asArray).then(
                    (e) => e.entityOId ?? 0
                );
                await reloadEntityAndWorkItems(2000);
                return entityId;
            }}
        />
    );
}
