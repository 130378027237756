import { Button, StackPanel } from '@bxgrandcentral/controls';
import {
    removeDocumentSection,
    setIsEditable,
    setIsLoading,
    setShouldReload,
    setShouldUpdateNewDocuments,
} from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import React from 'react';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { SubmitButton } from 'components';
import { TaskActions } from 'modules/LegalEntityCreation/Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from 'models/Workflow';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityRegistrationUpdate } from 'modules/LegalEntityCreation/context/Provider';

export type OnCreateWorkItem = (entityId: number, workflowType: string) => Promise<void>;

export default function ActionPanel(props: ActionPanelProps) {
    const {
        state: { legalEntityOId: entityOId },
    } = useCreationViewContext();

    const {
        state: {
            data: { changes },
            documents: { sections },
            canSave,
            canSubmit,
        },
        dispatch,
    } = useLegalEntityRegistrationUpdate();

    const { updateDocuments, updateStoredDocuments } = useDocumentUpload();

    const handleSave = async () => {
        if (props.mode !== 'Create' && props.mode !== 'Edit') {
            return;
        }

        try {
            setIsLoading(dispatch, true);
            setIsEditable(dispatch, false);

            if (isEmpty(changes)) {
                await updateDocuments(sections, entityOId);
            } else {
                await updateStoredDocuments(sections, entityOId);
                setShouldUpdateNewDocuments(dispatch, true);
                changes.RegistrationData &&
                    changes.RegistrationData.forEach((registration, index) => {
                        if (registration.isDeleted) {
                            removeDocumentSection(
                                dispatch,
                                `${SECTION_NAMES.FOREIGN_REGISTRATION_UPDATE}${SECTION_NAME_SEPARATOR}${index}`
                            );
                        }
                    });
                await props.onSave(changes);
            }
            setShouldReload(dispatch, true);
            setIsEditable(dispatch, true);
        } catch (reason) {
            setIsEditable(dispatch, true);
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (!entityOId) {
        return null;
    }

    switch (props.mode) {
        case 'Readonly':
            return null;
        case 'Create': {
            return (
                <SubmitButton
                    isEnabled={canSave}
                    label='Save'
                    onClick={async () => {
                        await handleSave();
                        props.onCreateWorkItem(entityOId, 'ForeignRegistrationUpdateWorkflow');
                    }}
                />
            );
        }
        case 'Edit': {
            const submitTask = props.workItem?.tasks?.find(
                (task) => task.taskType === 'RequestTaskType' && isOpen(task.status)
            );

            return (
                <StackPanel
                    orientation='horizontal'
                    horizontalAlignment='right'
                    styleOverrides={{ justifyContent: 'space-between' }}>
                    <Button
                        isEnabled={canSave}
                        content='Save'
                        styleName='textOnlyButtonStyle'
                        onClick={async () => {
                            await handleSave();
                        }}
                    />
                    {submitTask ? (
                        <TaskActions
                            isSubmitEnabled={canSubmit}
                            task={submitTask}
                            onCompleteTask={(task, exitCode) => props.onCompleteTask(task, exitCode as string)}
                            preSubmit={async () => {
                                await handleSave();
                            }}
                        />
                    ) : null}
                </StackPanel>
            );
        }
        case 'Approval': {
            const approveTask = props.workItem.tasks?.find(
                (task) => task.taskType === 'UpdateRequestTaskType' && isOpen(task.status)
            );

            return approveTask ? (
                <TaskActions
                    task={approveTask}
                    onCompleteTask={(task, exitCode, rejectReason) =>
                        props.onCompleteTask(task, exitCode as string, rejectReason)
                    }
                />
            ) : null;
        }
    }
}
