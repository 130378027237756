import { AttributeType, LegalEntityDTO } from '../../../../models';

import { LegalEntityDissolutionRequestData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionDetails/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionRequestData => {
    const {
        entity: { objectAttributeStrings },
        dissolutionData,
    } = entityDto;

    const stringAttributes =
        objectAttributeStrings?.reduce((prev, curr) => {
            prev[curr.objectAttributeId] = curr.value;
            return prev;
        }, {} as { [attr in AttributeType]?: string }) ?? {};

    return {
        desiredDissolutionDate: dissolutionData?.dissolutionRequest?.desiredDissolutionDate,
        billingOracleNumber:
            dissolutionData?.dissolutionRequest?.billingOracleNumber ||
            stringAttributes[AttributeType.billingOracleNumber] !== stringAttributes[AttributeType.oracleNumber]
                ? stringAttributes[AttributeType.billingOracleNumber]
                : undefined,
        billingProductNumber:
            dissolutionData?.dissolutionRequest?.billingProductNumber ||
            stringAttributes[AttributeType.billingProductNumber] !== stringAttributes[AttributeType.productNumber]
                ? stringAttributes[AttributeType.billingProductNumber]
                : undefined,
        billingCostCenterNumber:
            dissolutionData?.dissolutionRequest?.billingCostCenterNumber ||
            stringAttributes[AttributeType.billingCostCenterNumber] !== stringAttributes[AttributeType.costCenterNumber]
                ? stringAttributes[AttributeType.billingCostCenterNumber]
                : undefined,
        billingProjectCode:
            dissolutionData?.dissolutionRequest?.billingProjectCode ||
            stringAttributes[AttributeType.billingProjectCode],
        requestNotes: dissolutionData?.dissolutionRequest?.requestNotes,
        approval: dissolutionData?.dissolutionRequest?.approval,
        savedBy: dissolutionData?.dissolutionRequest?.savedBy,
        savedAt: dissolutionData?.dissolutionRequest?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionRequestData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;
    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionRequest: {
            ...original?.dissolutionData?.dissolutionRequest,
            ...section,
        },
    };

    return toReturn;
};
