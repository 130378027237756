import { Debugger, Footer, Form, RequestNotes } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import {
    resetState,
    setIsEditable,
    setIsLoading,
    setIsSubmitted,
    setShouldUpdateNewDocuments,
} from '../context/actions/form-actions';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { AdministeredBy } from 'models';
import { DocumentSections } from '../context/model';
import DomesticRegistrationDetails from './components/DomesticRegistrationDetails/DomesticRegistrationDetails';
import ForeignRegistrationDetails from './components/ForeignRegistrationDetails/ForeignRegistrationDetails';
import LegalEntityHeadquarterAddress from './components/LegalEntityHeadquarterAddress/LegalEntityHeadquarterAddress';
import LegalEntityRegisteredOfficeAddress from './components/LegalEntityRegisteredOfficeAddress/LegalEntityRegisteredOfficeAddress';
import { LegalEntityRequestDocumentPreparationProps } from './model';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { isEditable } from '../utils';
import useDocumentPreparationValidation from '../validation/use-document-preparation-validation';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityDocumentPreparation } from '../context/Provider';

const DocumentPreparation: React.FC<LegalEntityRequestDocumentPreparationProps> = (props) => {
    const { data, mode, workItem } = props;
    const context = useLegalEntityDocumentPreparation();

    const {
        state: {
            data: {
                values: { entityOid, isBlueprintEntity, administeredBy },
            },
            documents: { sections },
            canSave,
            shouldUpdateNewDocuments,
        },
        dispatch,
    } = context;

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    useDocumentPreparationValidation();
    const { updateDocuments } = useDocumentUpload();

    useEffect(() => {
        if (data && shouldUpdateNewDocuments) {
            const updated = Object.keys(sections).reduce((result, key) => {
                const section = sections[key];
                if (key.startsWith('docPrep_foreign')) {
                    const separator = key.lastIndexOf(SECTION_NAME_SEPARATOR);
                    const [, suffix] = [key.slice(0, separator), key.slice(separator + 1)];
                    const index = parseInt(suffix);
                    section.pendingDocuments?.forEach(
                        (document) => (document.taxRegistration = data.foreignRegistrations![index].key)
                    );
                } else if (key === 'docPrep_domestic') {
                    section.pendingDocuments?.forEach((document) => (document.taxRegistration = data.key));
                }
                return { ...result, [key]: section };
            }, {} as DocumentSections);
            updateDocuments(updated, entityOid)
                .then((_) => {
                    if (canSave) {
                        if (isSubmitClicked) {
                            setIsSubmitted(dispatch, true);
                            setIsSubmitClicked(false);
                        } else {
                            resetState(dispatch, data);
                            setIsEditable(dispatch, isEditable(mode));
                            setShouldUpdateNewDocuments(dispatch, false);
                        }
                    }
                })
                .catch((e) => {
                    setIsEditable(dispatch, true);
                    setIsLoading(dispatch, false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onSubmitClicked = useCallback(() => {
        setIsSubmitClicked(true);
    }, []);

    const isRegisteredOfficeEnabled = isBlueprintEntity && administeredBy === AdministeredBy.Asia;

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Document_Preparation} />
            <Form>
                <DomesticRegistrationDetails />
                <LegalEntityHeadquarterAddress />
                {isRegisteredOfficeEnabled && <LegalEntityRegisteredOfficeAddress />}
                <ForeignRegistrationDetails />
                <RequestNotes workItem={workItem} mode={mode} />
                <RejectReason mode={mode} taskType='ReviewDocPrepTaskType' tasks={workItem?.tasks} />
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} onSubmitClicked={onSubmitClicked} {...props} />
                </Footer>
            )}
        </>
    );
};

export default DocumentPreparation;
