import { BackDrop, Border, DialogComponent, DialogFooter } from './Dialog.styles';
import { Button, FontIcon, StackPanel, TextBlock } from '@bxgrandcentral/controls';

import { GlobalDialogProps } from 'GlobalState';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import useColors from 'api/hooks/use-theme';
import useDialogState from 'hooks/use-dialog-state';

type Props = GlobalDialogProps & {
    handleOk: () => void;
    handleCancel: () => void;
};

export default function Dialog({
    title,
    content,
    variant,
    handleOk,
    handleCancel,
    isConfirmationDialog,
    okButtonLabel,
    cancelButtonLabel,
}: Props) {
    const colors = useColors();
    const { color, icon } = useDialogState({ variant });

    const { MD, LG, XL } = SPACING;

    return (
        <>
            <DialogComponent colors={colors}>
                <Border color={color} />
                <StackPanel orientation='horizontal' itemGap={MD} padding={`${XL}px ${MD}px ${XL}px ${LG}px`}>
                    <FontIcon
                        iconName={icon}
                        foregroundColor={color}
                        fontSize={48}
                        minWidth={48}
                        verticalAlignment='center'
                    />
                    <StackPanel
                        itemGap={MD}
                        orientation='vertical'
                        verticalAlignment='center'
                        horizontalAlignment='left'>
                        <TextBlock styleName='heading3Style'>{title}</TextBlock>
                        {typeof content === 'string' ? (
                            <TextBlock
                                text={content}
                                styleName='bodyTextStyle'
                                textAlignment='justify'
                                styleOverrides={{ lineHeight: '24px' }}
                            />
                        ) : (
                            <>{content}</>
                        )}
                    </StackPanel>
                </StackPanel>
                <DialogFooter>
                    {isConfirmationDialog && (
                        <Button styleName='textOnlyButtonStyle' onClick={handleCancel} minWidth={SPACING.XXL}>
                            {cancelButtonLabel}
                        </Button>
                    )}
                    <Button onClick={handleOk} minWidth={SPACING.XXXL}>
                        {okButtonLabel}
                    </Button>
                </DialogFooter>
            </DialogComponent>
            <BackDrop />
        </>
    );
}
