import LegalEntityTaxClassification from './LegalEntityTaxClassification';
import { LegalEntityTaxClassificationProps } from './model';
import { LegalEntityTaxClassificationProvider } from '../context/Provider';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import useLegalEntityTaxClassificationAutoPopulateData from './hooks/useLegalEntityTaxClassificationAutoPopulateData';

export default function LegalEntityTaxClassificationSection(props: LegalEntityTaxClassificationProps) {
    const { calculateDefaultValues } = useLegalEntityTaxClassificationAutoPopulateData();

    return (
        <LegalEntityTaxClassificationProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Tax_Classification}>
            <LegalEntityTaxClassification {...props} />
        </LegalEntityTaxClassificationProvider>
    );
}
