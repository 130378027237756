import { DeleteButton, Select, TextField } from 'components';
import { HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import React, { useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { isEqual, isNil } from 'lodash';

import { GlobalState } from 'GlobalState';
import { TaxCode as TaxCodeModel } from '../../../../../models';
import { ValidationErrors } from 'modules/LegalEntityCreation/context/model';
import { removeAt } from 'Utilities/array';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from 'api';

type Props = {
    index: number;
    validation: ValidationErrors<TaxCodeModel>;
    noPendingValues?: TaxCodeModel;
};

export default function TaxCode({ index, validation = {}, noPendingValues }: Props) {
    const {
        state: {
            isEditable,
            data: {
                values: { taxCodes = [] },
            },
        },
        setValue,
    } = useLegalEntityInformation();

    async function setIsDeleted() {
        if (taxCodes[index]?.taxCodeId) {
            taxCodes[index].isDeleted = !isDeleted;
            setValue('taxCodes', Array.of(...taxCodes));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Tax Code',
                content:
                    'Are you sure you want to remove this Tax code?  Any information on this unsaved relationship will be lost',
            }).then((response) => {
                if (response) {
                    setValue('taxCodes', removeAt(taxCodes, index));
                }
            });
        }
    }

    const {
        data: { TaxCodeCountry, TaxCodeType },
    } = useReferenceData();

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    const { taxCode, taxCodeTypeId, taxCountry, isDeleted, taxCodeId } = taxCodes[index];
    const isTaxCodeEditable = isEditable && !isDeleted;

    const taxCodesByCountry = useMemo(() => {
        return TaxCodeType?.filter(({ ParentId }) => `${taxCountry}` === ParentId);
    }, [taxCountry, TaxCodeType]);

    const onCountryChanged = (value?: number) => {
        const updated = [...taxCodes];
        updated[index].taxCountry = value;
        updated[index].taxCodeTypeId = undefined;
        setValue('taxCodes', updated);
    };

    const noPendingTaxCountry = useMemo(() => {
        const taxCountry = TaxCodeType?.find(({ Key }) => noPendingValues?.taxCodeTypeId === Key)?.ParentId;
        return taxCountry ? parseInt(taxCountry) : undefined;
    }, [noPendingValues, TaxCodeType]);

    const isNewItem = !taxCodeId || (!isEditable && !isDeleted && taxCodeId < 0);

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING} itemGap={SPACING.SM}>
            {isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Tax code
                </TextBlock>
            )}
            <StackPanel itemGap={SPACING.SM} styleOverrides={{ opacity: taxCodes[index].isDeleted ? 0.5 : 1 }}>
                <Select
                    label='Country'
                    itemsSource={TaxCodeCountry}
                    isEditable={isTaxCodeEditable}
                    value={taxCountry}
                    onValueChanged={onCountryChanged}
                    validationError={validation.taxCountry}
                    isPendingChange={isPendingChange(taxCountry, noPendingTaxCountry)}
                />
                <Select
                    label='Tax ID Type'
                    itemsSource={taxCodesByCountry}
                    isEditable={isTaxCodeEditable}
                    value={taxCodeTypeId}
                    onValueChanged={(newValue) => {
                        if (newValue || taxCodes[index].taxCodeTypeId) {
                            const updated = [...taxCodes];
                            updated[index].taxCodeTypeId = newValue;
                            setValue('taxCodes', updated);
                        }
                    }}
                    validationError={validation.taxCodeTypeId}
                    isPendingChange={isPendingChange(taxCodeTypeId, noPendingValues?.taxCodeTypeId)}
                />
                <TextField
                    label='Tax ID Value'
                    isEditable={isTaxCodeEditable}
                    value={taxCode}
                    onValueChanged={(newValue) => {
                        if (newValue || taxCodes[index].taxCode) {
                            const updated = [...taxCodes];
                            updated[index].taxCode = newValue;
                            setValue('taxCodes', updated);
                        }
                    }}
                    validationError={validation.taxCode}
                    isPendingChange={isPendingChange(taxCode, noPendingValues?.taxCode)}
                />
            </StackPanel>
            {isEditable && <DeleteButton isDeleted={isDeleted} onClick={setIsDeleted} />}
        </StackPanel>
    );
}
