import { ELEVATIONS } from 'Utilities/Layout';
import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';

type Props = {
    colors: ThemeColors;
    isOpen?: boolean;
};

export const Wrapper = styled.div<Props>`
    position: fixed;
    left: 64px;
    top: 49px;
    z-index: ${ELEVATIONS.DIALOG};
    bottom: 0;
    #open-panel-button {
        position: absolute;
        right: -140px;
        bottom: 14px;
        color: black;
        border: 1px solid ${({ colors }) => colors.normalControlBorderColor};
        border-radius: 0;
        padding: 8px;
        background-color: ${({ colors }) => colors.backgroundColor};
    }
`;

export const Content = styled.div<Props>`
    width: 389px;
    font-size: 13px;
    overflow: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    p {
        color: ${({ colors }) => colors.foregroundColor};
        line-height: 20px;
        white-space: pre;
    }
    button {
        border: 1px solid ${({ colors }) => colors.normalControlBorderColor};
        border-radius: 0;
        min-height: 40px;
        background-color: ${({ colors }) => colors.backgroundColor};
    }
`;
