import { ChildWorkflowTask } from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-last-completed-task';
import { Grid } from '@bxgrandcentral/controls';
import { LegalEntityDissolutionTask } from 'models/LegalEntityRequest/Workflow';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';

type Props = {
    lastCompletedTask?: LegalEntityDissolutionTask | ChildWorkflowTask;
    label?: string;
};

export default function PadeUpdateInfo({ lastCompletedTask, label = 'Last Updated by' }: Props) {
    if (!lastCompletedTask?.modifier || !lastCompletedTask?.modified) {
        return null;
    }

    return (
        <Grid
            horizontalAlignment='center'
            verticalAlignment='center'
            width='60%'
            columnDefinitions='150px * 100px'
            margin={'0 auto'}>
            <Text textAlignment='left' margin={`${SPACING.XXS}px`}>
                {label}
            </Text>
            <Text textAlignment='center' margin={`${SPACING.XXS}px`}>
                {lastCompletedTask?.modifier}
            </Text>
            <Text textAlignment='right' margin={`${SPACING.XXS}px`}>
                {new Date(lastCompletedTask?.modified).toUTCYearMonthDay()}
            </Text>
        </Grid>
    );
}
