import { setIsEditable, setIsLoading, setIsSubmitted } from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import React from 'react';
import { StackPanel } from '@bxgrandcentral/controls';
import { SubmitButton } from 'components';
import { TaskActions } from '../../../Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from '../../../../../models/Workflow';
import useDocumentUpload from '../../../../../hooks/use-document-upload';
import { useLegalEntityRequest } from '../../../context/Provider';

export default function ActionPanel(props: ActionPanelProps) {
    const { entityOId = 0 } = props;
    const {
        state: {
            data: { changes },
            documents: { sections },
            canSubmit,
            isSubmitted,
        },
        dispatch,
    } = useLegalEntityRequest();

    const { updateDocuments } = useDocumentUpload();

    const handleSaveAtCreate = async () => {
        if (props.mode !== 'Create') {
            return;
        }

        setIsLoading(dispatch, true);
        setIsSubmitted(dispatch, true);
        setIsEditable(dispatch, false);
        try {
            const entityId = await props.onSave(changes);
            await updateDocuments(sections, entityId);
            await props.onCreateWorkItem(entityId);
        } catch (reason) {
            setIsEditable(dispatch, true);
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    const handleSaveAtEdit = async () => {
        if (props.mode !== 'Edit' || !entityOId) {
            return;
        }

        setIsLoading(dispatch, true);
        setIsSubmitted(dispatch, true);
        setIsEditable(dispatch, false);
        try {
            await updateDocuments(sections, entityOId);
            if (!isEmpty(changes)) {
                await props.onSave(changes);
            }
        } catch (reason) {
            setIsEditable(dispatch, true);
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (isSubmitted) {
        return null;
    }
    if (props.mode === 'Create') {
        return (
            <StackPanel orientation='horizontal' styleOverrides={{ justifyContent: 'flex-end' }}>
                <SubmitButton
                    isEnabled={canSubmit}
                    onClick={async () => {
                        await handleSaveAtCreate();
                    }}
                />
            </StackPanel>
        );
    }
    if (props.mode === 'Edit') {
        const submitTask = props.workItem.tasks?.find(
            ({ taskType, status }) => taskType === 'UpdateRequestTaskType' && isOpen(status)
        );
        return submitTask ? (
            <TaskActions
                isSubmitEnabled={canSubmit}
                task={submitTask}
                onCompleteTask={(task, exitCode) => {
                    props.onCompleteTask(task, exitCode as string);
                }}
                preSubmit={async () => {
                    await handleSaveAtEdit();
                }}
            />
        ) : null;
    }
    if (props.mode === 'Approval') {
        const reviewTask = props.workItem.tasks?.find(
            ({ taskType, status }) => taskType === 'ReviewTransitionTaskType' && isOpen(status)
        );
        return reviewTask ? (
            <TaskActions
                task={reviewTask}
                onCompleteTask={(task, exitCode) => props.onCompleteTask(task, exitCode as string)}
            />
        ) : null;
    }

    return null;
}
