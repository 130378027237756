import { IEMSearchService, ILegalEntityService, IWorkflowService } from './interfaces';
import { IHostService, ServiceLocator } from '@bxgrandcentral/shell';

import { AppSettings } from '../context/model';
import { DocumentService } from './implementation/DocumentService';
import { DocumentServiceMock } from './implementation/DocumentServiceMock';
import { EMSearchService } from './implementation/EMSearchService';
import { IDocumentService } from './interfaces/IDocumentService';
import { IPermissionService } from './interfaces/IPermissionService';
import { LegalEntityService } from './implementation/LegalEntityService';
import { PermissionService } from './implementation/PermissionService';
import { WorkflowService } from './implementation/WorkflowService';

export class SettingsService extends IHostService {
    useMockData: boolean = true;

    constructor() {
        super('com.bx.bxt.bxLegalEntitiesUI.settingService');
        this.InitServices();
    }

    InitServices() {
        ServiceLocator.container.registerSingleton(ILegalEntityService, LegalEntityService);
        ServiceLocator.container.registerSingleton(IEMSearchService, EMSearchService);
        ServiceLocator.container.registerSingleton(IWorkflowService, WorkflowService);
        ServiceLocator.container.registerSingleton(IPermissionService, PermissionService);
    }

    SetUseMockData(mockDataFlag: boolean) {
        this.useMockData = mockDataFlag;
    }

    SetDocumentService(isDocumentServiceIgnored: boolean) {
        if (isDocumentServiceIgnored) {
            ServiceLocator.container.registerSingleton(IDocumentService, DocumentServiceMock);
        } else {
            ServiceLocator.container.registerSingleton(IDocumentService, DocumentService);
        }
    }

    HandleAppSettings(settings: AppSettings) {
        this.SetDocumentService(settings.isDocumentServiceIgnored);
    }
}
