import { Button, FontIcon } from '@bxgrandcentral/controls';
import React, { useCallback, useEffect } from 'react';

import { GlobalState } from 'GlobalState';
import { LegalEntityRequestPaths } from '../../modules/RequestsDashboard/LegalEntityRequestPaths';
import { StrictUnion } from 'Utilities/Types';
import { useAppContext } from 'context/app-context';
import { useHistory } from 'react-router-dom';

type K = keyof HTMLElementEventMap;

type IconProps = {
    id: string;
    iconName: string;
    path?: string;
};

type ButtonProps = {
    id: string;
    text: string;
};

type Props = StrictUnion<IconProps | ButtonProps>;

export default function NavigationButton({ id, iconName, text, path }: Props) {
    const {
        state: {
            legalEntityCreation: { canSave },
        },
    } = useAppContext();

    const historyApi = useHistory();

    const clickHandler = useCallback(
        async (event: HTMLElementEventMap[K]) => {
            if (canSave) {
                event.preventDefault();
                const isConfirmed = await GlobalState.openDialog({
                    variant: 'warning',
                    title: 'You have unsaved changes!',
                    content: 'Pending changes will not be saved. Do you want to proceed?',
                    okButtonLabel: 'Yes',
                    cancelButtonLabel: 'No',
                });

                if (isConfirmed) {
                    historyApi.push(path || LegalEntityRequestPaths.dashboard);
                }
            }
        },
        [canSave, historyApi, path]
    );

    useEffect(() => {
        const iconElement = document.getElementById(id);
        const linkElement = iconElement?.parentElement?.closest('a');

        if (!linkElement) {
            return;
        }

        linkElement.addEventListener('click', clickHandler);

        return () => {
            linkElement.removeEventListener('click', clickHandler);
        };
    }, [clickHandler, canSave, id]);

    return text ? (
        <Button
            id={id}
            fontSize={14}
            content={text}
            onClick={() => historyApi.push(LegalEntityRequestPaths.environment)}
        />
    ) : (
        <FontIcon id={id} fontSize={24} iconName={iconName} />
    );
}
