import { DialogPrompt, QueryPanel } from 'components';
import { Grid, ModuleContainer, ScrollViewer, StackPanel } from '@bxgrandcentral/controls';
import { Loader, NeedPermission, SideBar, TitleBar, ToolBarEditButton, ToolBarRefreshButton } from './components';
import React, { useEffect, useMemo } from 'react';
import { cleanCreationViewContext, useCreationViewContext } from './context/creation-view-context';
import { toggleNavigationBar, useAppContext } from 'context/app-context';

import { Capabilities } from './RequestStepsView';
import { DISSOLUTION_STATUS } from 'models/LegalEntityRequest/Workflow';
import InfoBlock from '../Shared/InfoBlock';
import { MessageBox } from '../../../components/MessageBox';
import { RequestContextProvider } from 'context/request-context';
import { RequestStepsView } from './RequestStepsView';
import { SPACING } from '../../../Utilities/Layout';
import styled from 'styled-components';
import useApiServices from './hooks/use-api-services';
import useCanCreateRequest from './hooks/use-can-create-request';
import useColors from 'api/hooks/use-theme';
import useDissolutionIsAllChildrenApproved from '../LegalEntityDissolution/hooks/use-dissolution-is-all-children-approved';
import useHasCapability from './hooks/use-has-capability';
import useLatestRejectedDissolutionWorkflow from './hooks/use-latest-reject-dissolution-workflow';
import useLegalEntityOId from './hooks/use-legal-entity-oid';
import useShowForm from './hooks/use-show-form';
import useViewport from 'hooks/use-viewport';

export const LegalEntityCreationView: React.FC = () => {
    const colors = useColors();
    const canCreateRequest = useCanCreateRequest();

    const {
        state: {
            settings: { isAutoPopulationEnabled },
        },
        dispatch: appContextDispatch,
    } = useAppContext();

    const {
        state: { legalEntityOId, isEntityDissolved, selectedPanel, legalEntityDissolutionParentWorkItem },
        dispatch: creationViewDispatch,
    } = useCreationViewContext();

    useLegalEntityOId();

    useLatestRejectedDissolutionWorkflow();

    useDissolutionIsAllChildrenApproved();

    const { reloadEntityAndWorkItems } = useApiServices();

    const hasCapability = useHasCapability();

    useEffect(() => {
        if (legalEntityOId) {
            reloadEntityAndWorkItems(0, true);
        } else {
            cleanCreationViewContext(creationViewDispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legalEntityOId]);

    const showForm = useShowForm();

    const { isSmallScreen } = useViewport();

    const sectionsToShowDissolutionInProcess: string[] = [
        RequestStepsView.Foreign_Registration,
        RequestStepsView.Tax_Classification,
        RequestStepsView.Legal_Entity_Information,
        RequestStepsView.Finance_Details,
        RequestStepsView.Ownership_Information,
        RequestStepsView.Corporate_Transparency,
    ];

    const isDissolutionInProcess = useMemo(() => {
        return legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
    }, [legalEntityDissolutionParentWorkItem]);

    const shouldInProcessMsgBeVisible = sectionsToShowDissolutionInProcess.includes(
        selectedPanel?.requestStepView || ''
    );

    return (
        <Grid>
            {canCreateRequest ? (
                <ModuleContainer
                    key={isSmallScreen.toString()}
                    leftSideBarTitle='Legal Entity Info'
                    isLeftSideBarOpen
                    onClick={() => toggleNavigationBar(appContextDispatch)}
                    isLeftSideBarDocked={!isSmallScreen}
                    backgroundColor={colors.backgroundColor}
                    leftSideBarContent={<SideBar />}
                    leftSideBarWidth={330}
                    toolBarPanelContent={
                        <Grid columnDefinitions='* auto auto auto' styleOverrides={{ paddingRight: `${SPACING.SM}px` }}>
                            <StackPanel
                                orientation='horizontal'
                                {...(isAutoPopulationEnabled && { margin: `0 50px 0 0` })}>
                                <TitleBar />
                                <ToolBarEditButton />
                                <ToolBarRefreshButton />
                            </StackPanel>
                        </Grid>
                    }>
                    <RequestContextProvider>
                        <ViewerContainer>
                            {hasCapability(Capabilities.viewDissolutionRequest) && isEntityDissolved ? (
                                <InfoBlock>
                                    This legal entity has been dissolved. No actions can be taken on this entity.
                                </InfoBlock>
                            ) : null}
                            {isDissolutionInProcess && shouldInProcessMsgBeVisible ? (
                                <InfoBlock>
                                    This entity is currently in the process of being dissolved. No further actions can
                                    be taken at this time.
                                </InfoBlock>
                            ) : null}
                            <ScrollViewer styleOverrides={{ position: 'relative' }}>{showForm()}</ScrollViewer>
                        </ViewerContainer>
                    </RequestContextProvider>
                </ModuleContainer>
            ) : (
                <NeedPermission canCreate={canCreateRequest} />
            )}
            <Loader />
            <MessageBox />
            <DialogPrompt />
            <QueryPanel />
        </Grid>
    );
};

const ViewerContainer = styled.div`
    overflow: hidden auto;
`;
