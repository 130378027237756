import { BooleanField, StackPanel, ThemeManager, WaitingIndicator } from '@bxgrandcentral/controls';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import TreeListDevExpress, { toolbarItemConfig } from '../../components/treeListDevExpress/TreeListDevExpress';
import { isEqual, isNil } from 'lodash';

import { CustomToolbarContainer } from './RequestDashboard.styled';
import DateRangePicker from './components/DateRangePicker/DateRangePicker';
import { ExportData } from '../../components/Export';
import { LegalEntityRefetch } from 'api/hooks/use-legal-entities';
import { LegalEntityRequest } from '../../models';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';
import { ThemeProvider } from 'styled-components';
import { TreeListData } from '../../components/Export/model';
import { requestDashboardConfig } from './RequestsDashboardGrid.config';
import { useAppContext } from 'context/app-context';
import { useGridAppSetting } from '../../hooks/grid-app-settings-hook';

interface Props {
    data?: LegalEntityRequest[];
    onSelectionChanged: Dispatch<SetStateAction<LegalEntityRequest | undefined>>;
    onItemDoubleClicked: () => void;
    onRefresh: LegalEntityRefetch;
    isLoading: boolean;
    isFetching: boolean;
}

const gridSettingsKey = 'legalEntitiesDashboardGridConfig';

export default function RequestsDashboardGridOld(props: Props) {
    const [isStandaloneOnly, setIsStandaloneOnly] = useState(false);
    const [numberOfEntities, setNumberOfEntities] = useState(0);
    const [treeListData, setTreeListData] = useState<TreeListData<LegalEntityRequest>>({
        nodes: [] as any,
        visibleColumns: [] as any,
        isStandaloneOnly: false,
    });
    const gridAppSettings = useGridAppSetting(requestDashboardConfig, gridSettingsKey, true);

    const {
        state: {
            dashboard: { fromDate, toDate },
        },
    } = useAppContext();

    const dataSourceFilter = useMemo(() => ['isStandalone', '=', isStandaloneOnly], [isStandaloneOnly]);

    const { format } = new Intl.DateTimeFormat('en-US');

    const onSelectionChanged = useCallback(
        (rows: LegalEntityRequest[]) => {
            props.onSelectionChanged(rows[0]);
        },
        [props]
    );

    const onItemDoubleClicked = useCallback(() => props.onItemDoubleClicked(), [props]);

    const onContentReady = (e: any) => {
        const { component } = e;
        if (!isNil(component)) {
            setNumberOfEntities(component.totalCount());
            const rootNode = component.getRootNode();
            if (rootNode) {
                const { children: nodes } = rootNode;
                const visibleColumns = component.getVisibleColumns().map(({ dataField }: any) => dataField);
                if (
                    nodes &&
                    (!isEqual(nodes, treeListData.nodes) || !isEqual(visibleColumns, treeListData.visibleColumns))
                ) {
                    setTreeListData({
                        visibleColumns,
                        isStandaloneOnly,
                        nodes,
                    });
                }
            }
        }
    };

    const additionalToolbarItems = useMemo(
        () =>
            [
                {
                    location: 'before',
                    render: () =>
                        props.data && (
                            <StackPanel orientation='horizontal' itemGap={SPACING.MD}>
                                <StackPanel orientation='horizontal' verticalAlignment='center'>
                                    <Text variant='body' fontSize={15}>
                                        Showing{' '}
                                    </Text>
                                    <Text variant='body' fontSize={15} fontWeight={600}>
                                        {
                                            props.data.filter(({ isStandalone }) => isStandalone === isStandaloneOnly)
                                                .length
                                        }{' '}
                                    </Text>
                                    <Text variant='body' fontSize={15}>
                                        of{' '}
                                    </Text>
                                    <Text variant='body' fontWeight={600} fontSize={15}>
                                        {numberOfEntities}
                                    </Text>
                                </StackPanel>
                                {!isNil(fromDate) && !isNil(toDate) && (
                                    <StackPanel orientation='horizontal' verticalAlignment='center'>
                                        <>
                                            <Text fontSize={15}>Last Updated From </Text>
                                            <Text fontSize={15} fontWeight={600}>
                                                {fromDate ? format(fromDate) : 'Not Set'}{' '}
                                            </Text>
                                            <Text fontSize={15}>to </Text>
                                            <Text fontSize={15} fontWeight={600}>
                                                {toDate ? format(toDate) : 'Not Set'}
                                            </Text>
                                        </>
                                    </StackPanel>
                                )}
                            </StackPanel>
                        ),
                },
                {
                    location: 'after',
                    render: () => (
                        <CustomToolbarContainer>
                            <BooleanField
                                label='Show Standalone Update Requests'
                                styleName='toolBarStyle'
                                value={isStandaloneOnly}
                                canUndo={false}
                                canResetValue={false}
                                onValueChanged={() => setIsStandaloneOnly(!isStandaloneOnly)}
                            />
                            <DateRangePicker onRefresh={props.onRefresh} isToolbarButtonStyled isEnabled />
                            <ExportData treeListData={treeListData} />
                        </CustomToolbarContainer>
                    ),
                },
            ] as toolbarItemConfig[],
        [format, fromDate, isStandaloneOnly, numberOfEntities, props.data, props.onRefresh, toDate, treeListData]
    );

    return (
        <ThemeProvider theme={ThemeManager.activeTheme}>
            <StackPanel padding={`${SPACING.XXL}px ${SPACING.LG}px ${SPACING.LG}px`}>
                <TreeListDevExpress
                    {...gridAppSettings}
                    dataSource={props.data || []}
                    dataSourceFilter={dataSourceFilter}
                    keyExpr='uniqueId'
                    parentIdExpr='parentEntityOId'
                    height='100%'
                    scrollingMode='virtual'
                    showLoadPanel={false}
                    showColumnChooser
                    showSearchPanel
                    showColumnHeaderSearch
                    sortingMode='single'
                    onSelectedRowKeysChanged={onSelectionChanged}
                    onItemDoubleClicked={onItemDoubleClicked}
                    additionalToolbarItems={additionalToolbarItems}
                    onContentReady={onContentReady}
                />
            </StackPanel>
            <WaitingIndicator id='spinner' isVisible={props.isLoading || props.isFetching} />
        </ThemeProvider>
    );
}
