import { Capability, LogError, User } from '../../models';
import { constants } from '../../constants/env-config';
import { IPermissionService } from '../interfaces/IPermissionService';
import ApiService from '../ApiService';

export class PermissionService implements IPermissionService {
    logInfo: LogError = {
        service: 'Permission Request Service',
        module: 'Global',
    };

    private baseURL: string = constants.ENTITY_MASTER_API;
    private baseUserURL: string = constants.USERINFO_API;
    private basePolicyServerURL = constants.POLICY_SERVER_API;
    private entityMasterBRN = constants.ENTITY_MASTER_SYSTEM_BRN;

    public GetPermissionedUser(entityOId: number): Promise<string[]> {
        this.logInfo.method = this.GetPermissionedUser.name;
        return ApiService.Get(`${this.baseURL}permissions/forEntity/${entityOId}`, this.logInfo).catch((e) => {
            return Promise.reject(e);
        });
    }

    public GetPolicyServerCapabilities(): Promise<Capability[]> {
        this.logInfo.method = this.GetPolicyServerCapabilities.name;
        return ApiService.Get(
            `${this.basePolicyServerURL}system/${this.entityMasterBRN}/user/my/capabilities`,
            this.logInfo
        );
    }

    public GetWhoAmI(): Promise<User> {
        this.logInfo.method = this.GetWhoAmI.name;
        return ApiService.Get(`${this.baseUserURL}`, this.logInfo).catch((e) => {
            return Promise.reject(e);
        });
    }
}
