import { ValidationErrors } from '../model';

type SetValue<T> = {
    type: 'SET_VALUE';
    key: keyof T;
    value: T[keyof T];
    isUserChange: boolean;
};

type SetValues<T> = {
    type: 'SET_VALUES';
    values: Partial<T>;
    isUserChange: boolean;
};

type UpdateValidation<T> = {
    type: 'UPDATE_VALIDATION';
    validationErrors: ValidationErrors<T>;
};

type ResetState<T> = {
    type: 'RESET_STATE';
    values?: Partial<T>;
    noPendingValues?: Partial<T>;
};

type SetIsLoading = {
    type: 'SET_IS_LOADING';
    value: boolean;
};

type SetIsEditable = {
    type: 'SET_IS_EDITABLE';
    value: boolean;
};

type SetShouldReload = {
    type: 'SET_SHOULD_RELOAD';
    value: boolean;
    isSubmit: boolean;
};

type SetIsSubmitted = {
    type: 'SET_IS_SUBMITTED';
    value: boolean;
};

type SetShouldUpdateNewDocuments = {
    type: 'SET_SHOULD_UPDATE_NEW_DOCUMENTS';
    value: boolean;
};

export type FormActions<T> =
    | SetValue<T>
    | SetValues<T>
    | ResetState<T>
    | UpdateValidation<T>
    | SetIsLoading
    | SetIsEditable
    | SetShouldReload
    | SetIsSubmitted
    | SetShouldUpdateNewDocuments;

export const resetState = (dispatch: any, values?: any, noPendingValues?: any) =>
    dispatch({ type: 'RESET_STATE', values, noPendingValues });

export const setValidation = (dispatch: any, key: any, value: any) => dispatch({ type: 'SET_VALIDATION', key, value });

export const updateValidation = (dispatch: any, validationErrors: any) =>
    dispatch({ type: 'UPDATE_VALIDATION', validationErrors });

export const setIsLoading = (dispatch: any, value: boolean) => dispatch({ type: 'SET_IS_LOADING', value });

export const setIsEditable = (dispatch: any, value: boolean) => dispatch({ type: 'SET_IS_EDITABLE', value });

export const setPreSubmitAction = (dispatch: any, preSubmitAction: any) =>
    dispatch({ type: 'SET_PRE_SUBMIT_ACTION', preSubmitAction });

export const setShouldReload = (dispatch: any, value: boolean) => {
    dispatch({ type: 'SET_SHOULD_RELOAD', value });
};
export const setIsSubmitted = (dispatch: any, value: boolean) => {
    dispatch({ type: 'SET_IS_SUBMITTED', value });
};
export const setShouldUpdateNewDocuments = (dispatch: any, value: boolean) => {
    dispatch({ type: 'SET_SHOULD_UPDATE_NEW_DOCUMENTS', value });
};
