import LegalEntityDissolutionTax from './LegalEntityDissolutionTax';
import { LegalEntityDissolutionTaxProps } from './model';
import { LegalEntityDissolutionTaxProvider } from '../../context/Provider';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import useDissolutionTaxAutopopulateData from './hooks/use-dissolution-tax-autopopulate-data';

export default function LegalEntityDissolutionTaxSection(props: LegalEntityDissolutionTaxProps) {
    const { calculateDefaultValues } = useDissolutionTaxAutopopulateData();

    return (
        <LegalEntityDissolutionTaxProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_Tax}>
            <LegalEntityDissolutionTax {...props} />
        </LegalEntityDissolutionTaxProvider>
    );
}
