import { LegalEntityRequest } from 'models';
import { getParentProcessValue } from './components/Grid/model';

export const preProcessFields = (data: LegalEntityRequest[]) => {
    const ids = data.reduce(
        (acc, curr) => {
            if (curr.isParent) {
                acc.parentIDs.push(curr.entityOId);
            } else {
                acc.childIDS.push(curr.entityOId);
            }
            return acc;
        },
        {
            parentIDs: [] as Number[],
            childIDS: [] as Number[],
        }
    );

    return data.map((entityRequest) => {
        return {
            ...entityRequest,
            parentProcess: getParentProcessValue({
                value: entityRequest.isStandalone,
                data: entityRequest,
            }),
            isParent:
                entityRequest.workItemType === 'LegalEntityCreationWorkflow' ||
                entityRequest.workItemType === 'DissolutionParentWorkflow',
            hasChildren: entityRequest.isParent && ids.childIDS.includes(entityRequest.entityOId),
            isChild:
                ids.parentIDs.includes(entityRequest.parentEntityOId) ||
                (entityRequest.isStandalone && ids.parentIDs.includes(entityRequest.entityOId)),
        };
    });
};
