import { DocumentSection, DocumentUpdate } from '../model';
import { PendingDocument, StoredDocument } from '../../../../components/controls/FileUpload/models';

import { SectionName } from 'modules/LegalEntityCreation/models';

type UpdateDocumentSection = {
    type: 'UPDATE_DOCUMENT_SECTION';
    sectionName: SectionName;
    values: Partial<DocumentSection>;
};

type UpdatePendingDocument = {
    type: 'UPDATE_PENDING_DOCUMENT';
    sectionName: SectionName;
    document: PendingDocument;
};

type AddStoredDocuments = {
    type: 'ADD_STORED_DOCUMENTS';
    sectionName: SectionName;
    value: StoredDocument[];
};

type UpdateStoredDocument = {
    type: 'UPDATE_STORED_DOCUMENT';
    sectionName: SectionName;
    id: number;
    update: DocumentUpdate;
};

type AddPendingDocuments = {
    type: 'ADD_PENDING_DOCUMENTS';
    sectionName: SectionName;
    documents: PendingDocument[];
};

type RemovePendingDocument = {
    type: 'REMOVE_PENDING_DOCUMENT';
    sectionName: SectionName;
    id: number;
};

type RemoveDocumentSection = {
    type: 'REMOVE_DOCUMENT_SECTION';
    sectionName: string;
};

export type DocumentActions =
    | UpdateDocumentSection
    | RemoveDocumentSection
    | UpdateStoredDocument
    | AddStoredDocuments
    | UpdatePendingDocument
    | AddPendingDocuments
    | RemovePendingDocument;

export const updateDocumentSection = (dispatch: any, sectionName: SectionName, values: Partial<DocumentSection>) => {
    dispatch({ type: 'UPDATE_DOCUMENT_SECTION', sectionName, values });
};
export const removeDocumentSection = (dispatch: any, sectionName: SectionName, index?: number) => {
    dispatch({ type: 'REMOVE_DOCUMENT_SECTION', sectionName, index });
};
export const updateStoredDocument = (dispatch: any, sectionName: SectionName, id: number, update: DocumentUpdate) => {
    dispatch({ type: 'UPDATE_STORED_DOCUMENT', sectionName, id, update });
};
export const addStoredDocuments = (dispatch: any, sectionName: SectionName, value: StoredDocument[] = []) => {
    dispatch({ type: 'ADD_STORED_DOCUMENTS', sectionName, value });
};
export const updatePendingDocument = (dispatch: any, sectionName: SectionName, document: PendingDocument) => {
    dispatch({ type: 'UPDATE_PENDING_DOCUMENT', sectionName, document });
};
export const addPendingDocuments = (dispatch: any, sectionName: SectionName, documents: PendingDocument[]) => {
    dispatch({ type: 'ADD_PENDING_DOCUMENTS', sectionName, documents });
};
export const removePendingDocument = (dispatch: any, sectionName: SectionName, id: number) => {
    dispatch({ type: 'REMOVE_PENDING_DOCUMENT', sectionName, id });
};
