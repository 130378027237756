import React, { useMemo } from 'react';

import { WaitingIndicator } from '@bxgrandcentral/controls';
import { isEmpty } from 'Utilities/Validations';
import { useCreationViewContext } from '../../context/creation-view-context';

export default function Loader() {
    const {
        state: { loadingStates },
    } = useCreationViewContext();

    const isVisible = useMemo(() => {
        return !isEmpty(Object.keys(loadingStates));
    }, [loadingStates]);

    return <WaitingIndicator id={'spinner'} isModalToShell={true} isVisible={isVisible} />;
}
