import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';

type Props = {
    reviewerUser?: String;
    reviewDate?: Date;
};

export default function ReviewInfo({ reviewerUser, reviewDate }: Props) {
    if (!reviewDate) {
        return null;
    }

    return (
        <Text
            horizontalAlignment='center'
            verticalAlignment='center'
            textAlignment='center'
            margin={`${SPACING.XXL}px 0 0 0`}>
            Last Reviewed by <strong>{reviewerUser}</strong> on{' '}
            <strong>{new Date(reviewDate).toUTCYearMonthDay()}</strong>
        </Text>
    );
}
