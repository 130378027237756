import LegalEntityDissolutionBDG from './LegalEntityDissolutionBdg';
import { LegalEntityDissolutionBDGProps } from './model';
import { LegalEntityDissolutionBDGProvider } from '../../context/Provider';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import useDissolutionBDGAutopopulateData from './hooks/use-dissolution-bdg-autopopulate-data';

export default function LegalEntityDissolutionBDGSection(props: LegalEntityDissolutionBDGProps) {
    const { calculateDefaultValues } = useDissolutionBDGAutopopulateData();

    return (
        <LegalEntityDissolutionBDGProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_BDG}>
            <LegalEntityDissolutionBDG {...props} />
        </LegalEntityDissolutionBDGProvider>
    );
}
