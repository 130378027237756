import {
    AdministeredBy,
    BusinessUnit,
    ConsolidatingStatusInOracle,
    Investran,
    OracleLineOfBusiness,
    OracleLocations,
    SubBusinessUnit,
    TreasuryLineOfBusiness,
    WssPaymentType,
} from 'models';
import { CostCenterNumbers, LegalEntityFinanceDetailsData, ProductNumbers } from '../model';

import { PrimaryLedger } from 'models/shared/refData/PrimaryLedger';
import { getDefaultInvestranDomain } from '../utils';
import { hasElement } from 'Utilities/array';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export default function useLegalEntityFinanceDetailsAutoPopulateData(data?: LegalEntityFinanceDetailsData) {
    const populatePrivateEquity = useCallback(
        () => ({
            productNumber: ProductNumbers.PE.toString(),
            billingProductNumber: ProductNumbers.PE.toString(),
            costCenterNumber: CostCenterNumbers.PE.toString(),
            billingCostCenterNumber: CostCenterNumbers.PE.toString(),
            oracleLineOfBusinessId: OracleLineOfBusiness.PE_MBE,
            treasuryLineOfBusinessId: TreasuryLineOfBusiness.Fund,
        }),
        []
    );

    const populateRealEstate = useCallback((data: LegalEntityFinanceDetailsData) => {
        const { administeredBy, subBusinessUnitOId } = data;

        if (subBusinessUnitOId === SubBusinessUnit.BREP) {
            return {
                productNumber: ProductNumbers.RE_BREP.toString(),
                billingProductNumber: ProductNumbers.RE_BREP.toString(),
                costCenterNumber: CostCenterNumbers.RE_BREP.toString(),
                billingCostCenterNumber: CostCenterNumbers.RE_BREP.toString(),
                oracleLineOfBusinessId: OracleLineOfBusiness.RE,
                treasuryLineOfBusinessId: TreasuryLineOfBusiness.Fund,
            };
        }
        if (subBusinessUnitOId === SubBusinessUnit.BREDS) {
            return {
                productNumber: ProductNumbers.RE_BREDS.toString(),
                billingProductNumber: ProductNumbers.RE_BREDS.toString(),
                costCenterNumber: CostCenterNumbers.RE_BREDS.toString(),
                billingCostCenterNumber: CostCenterNumbers.RE_BREDS.toString(),
                oracleLineOfBusinessId: OracleLineOfBusiness.RE,
                treasuryLineOfBusinessId: TreasuryLineOfBusiness.Fund,
            };
        }
        if (subBusinessUnitOId === SubBusinessUnit.BPP) {
            if (administeredBy === AdministeredBy.NewYork) {
                return {
                    productNumber: ProductNumbers.RE_BPP_US.toString(),
                    billingProductNumber: ProductNumbers.RE_BPP_US.toString(),
                    costCenterNumber: CostCenterNumbers.RE_BPP_US.toString(),
                    billingCostCenterNumber: CostCenterNumbers.RE_BPP_US.toString(),
                };
            }
            if (administeredBy === AdministeredBy.Asia) {
                return {
                    productNumber: ProductNumbers.RE_BPP_Asia.toString(),
                    billingProductNumber: ProductNumbers.RE_BPP_Asia.toString(),
                    costCenterNumber: CostCenterNumbers.RE_BPP_Asia.toString(),
                    billingCostCenterNumber: CostCenterNumbers.RE_BPP_Asia.toString(),
                };
            }
            if (hasElement([AdministeredBy.Europe, AdministeredBy.Luxembourg], administeredBy))
                return {
                    productNumber: ProductNumbers.RE_BPP_EU.toString(),
                    billingProductNumber: ProductNumbers.RE_BPP_EU.toString(),
                    costCenterNumber: CostCenterNumbers.RE_BPP_EU.toString(),
                    billingCostCenterNumber: CostCenterNumbers.RE_BPP_EU.toString(),
                };
        }
    }, []);

    const populateSP = useCallback(
        () => ({
            productNumber: ProductNumbers.SP.toString(),
            billingProductNumber: ProductNumbers.SP.toString(),
            oracleLineOfBusinessId: OracleLineOfBusiness.SP,
            consolidatingStatusInOracleId: ConsolidatingStatusInOracle.NonConsolidating,
            treasuryLineOfBusinessId: TreasuryLineOfBusiness.Fund,
            wssPaymentTypeId: WssPaymentType.WIRE,
            // Static RepaymentGroupIDs doesn't match api RepaymentGroupIds, fix it when new api is available
            // repaymentGroupId: RepaymentGroup.SP,
            paymentsToWss: true,
            isAgisRequired: true,
        }),
        []
    );

    const defaultValues = useCallback(
        () => ({
            oracleLocationCodeId: OracleLocations.BX_US_CORP_LOC,
            isAgisRequired: true,
            consolidatingStatusInOracleId: ConsolidatingStatusInOracle.NonConsolidating,
            treasuryLineOfBusinessId: TreasuryLineOfBusiness.Fund,
            primaryLedgerId: PrimaryLedger.BX_US_USD_PL,
            paymentsToWss: true,
            wssPaymentTypeId: WssPaymentType.WIRE,
        }),
        []
    );

    const populateBto = useCallback(
        () => ({
            productNumber: ProductNumbers.BTO.toString(),
            billingProductNumber: ProductNumbers.BTO.toString(),
            oracleLineOfBusinessId: OracleLineOfBusiness.BTO,
            costCenterNumber: CostCenterNumbers.BTO.toString(),
            billingCostCenterNumber: CostCenterNumbers.BTO.toString(),
            // repaymentGroupId: RepaymentGroup.BTO,
            ...defaultValues(),
        }),
        [defaultValues]
    );

    const populateBxg = useCallback(
        () => ({
            productNumber: ProductNumbers.BXG.toString(),
            billingProductNumber: ProductNumbers.BXG.toString(),
            oracleLineOfBusinessId: OracleLineOfBusiness.BXG,
            costCenterNumber: CostCenterNumbers.BXG.toString(),
            billingCostCenterNumber: CostCenterNumbers.BXG.toString(),
            // repaymentGroupId: RepaymentGroup.BXG,
            ...defaultValues(),
        }),
        [defaultValues]
    );

    const populateLs = useCallback(
        () => ({
            productNumber: ProductNumbers.LS.toString(),
            billingProductNumber: ProductNumbers.LS.toString(),
            oracleLineOfBusinessId: OracleLineOfBusiness.LS,
            costCenterNumber: CostCenterNumbers.LS.toString(),
            billingCostCenterNumber: CostCenterNumbers.LS.toString(),
            // repaymentGroupId: RepaymentGroup.LS,
            ...defaultValues(),
        }),
        [defaultValues]
    );

    const populateInfra = useCallback(
        () => ({
            productNumber: ProductNumbers.INFRA.toString(),
            billingProductNumber: ProductNumbers.INFRA.toString(),
            oracleLineOfBusinessId: OracleLineOfBusiness.INFRA,
            costCenterNumber: CostCenterNumbers.INFRA.toString(),
            billingCostCenterNumber: CostCenterNumbers.INFRA.toString(),
            // repaymentGroupId: RepaymentGroup.INFRA,
            ...defaultValues(),
        }),
        [defaultValues]
    );

    const calculateDefaultValues = useCallback(
        (data?: LegalEntityFinanceDetailsData) => {
            if (isNil(data)) {
                return {};
            }

            const { businessUnitOId, subBusinessUnitOId } = data;

            return {
                pushToOracle: true,
                paymentsToWss: false,
                isAgisRequired: false,
                investranId: Investran.Investran,
                investranDomain: getDefaultInvestranDomain(businessUnitOId, subBusinessUnitOId),
                ...(businessUnitOId === BusinessUnit.PrivateEquity && populatePrivateEquity()),
                ...(businessUnitOId === BusinessUnit.RealEstate && populateRealEstate(data)),
                ...(businessUnitOId === BusinessUnit.SP && populateSP()),
                ...(businessUnitOId === BusinessUnit.BTO && populateBto()),
                ...(businessUnitOId === BusinessUnit.BXG && populateBxg()),
                ...(businessUnitOId === BusinessUnit.LS && populateLs()),
                ...(businessUnitOId === BusinessUnit.INFRA && populateInfra()),
            };
        },
        [populatePrivateEquity, populateRealEstate, populateSP, populateBto, populateBxg, populateLs, populateInfra]
    );

    return { calculateDefaultValues };
}
