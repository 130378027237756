import React from 'react';
import { ToolBarButton } from '@bxgrandcentral/controls';
import { useAppContext } from 'context/app-context';
import useBackToDashboard from '../../hooks/use-back-to-dashboard';
import useGenerateKey from 'hooks/use-generate-key';
import useUnsavedChangesConfirmation from '../../hooks/use-unsaved-changes-confirmation';

export default function ToolBarBackButton() {
    const {
        state: {
            legalEntityCreation: { canSave },
        },
    } = useAppContext();
    const getUnsavedChangesConfirmation = useUnsavedChangesConfirmation(canSave);
    const backToDashboard = useBackToDashboard();

    const handleClick = async () => {
        const confirmation = await getUnsavedChangesConfirmation();
        if (confirmation) {
            backToDashboard();
        }
    };

    const backButtonKey = useGenerateKey(canSave);

    return (
        <ToolBarButton
            key={backButtonKey}
            icon='Back'
            toolTip='Back'
            gridColumn={0}
            horizontalAlignment='left'
            verticalAlignment='center'
            onClick={handleClick}
        />
    );
}
