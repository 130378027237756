import styled, { createGlobalStyle } from 'styled-components';
import { ThemeDefinition } from '@bxgrandcentral/controls';

type Props = {
    theme: ThemeDefinition;
};

export const GlobalStyle = createGlobalStyle<Props>`
  body {
    .dx-popup-wrapper > .dx-overlay-content {
        background-color:  ${({ theme }) => theme.colors.backgroundColor}; 
        border: 1px solid ${({ theme }) => theme.colors.normalControlBorderColor}; 
        &.dx-state-focused {
            border: 1px solid ${({ theme }) => theme.colors.normalControlBorderColor}; 
        }
    }

    .dx-checkbox-indeterminate .dx-checkbox-icon::before {
        background-color: ${({ theme }) => theme.colors.accentColor}
    }

    .dx-treeview-item-with-checkbox.dx-state-focused > .dx-checkbox .dx-checkbox-icon {
        border-color: ${({ theme }) => theme.colors.accentColor};
    }

    .dx-list-item, .dx-list-select-all {
        border-color: ${({ theme }) => theme.colors.normalControlBorderColor};
        &.dx-state-hover, &.dx-state-focused {
            .dx-checkbox-icon {
                border-color: ${({ theme }) => theme.colors.accentColor}!important;
            }
        }
    }

    .dx-scrollview-content {
        .dx-empty-message {
            color:  ${({ theme }) => theme.colors.foregroundColor};
        }
        & > div {
        color:  ${({ theme }) => theme.colors.foregroundColor};
        }
    }

    .dx-popup-content {
        .dx-texteditor.dx-editor-outlined {
            background-color:  ${({ theme }) => theme.colors.backgroundColor};
            border-color:  ${({ theme }) => theme.colors.normalControlBorderColor};
        }
        .dx-state-hover {
            border-color: ${({ theme }) => theme.colors.normalControlBorderColor};
        }
        .dx-texteditor-input {
            color: ${({ theme }) => theme.colors.foregroundColor};
        }
        .dx-checkbox-icon {
            background: none;
            color: ${({ theme }) => theme.colors.foregroundColor};
        }
        .dx-button {
            border: 1px solid ${({ theme }) => theme.colors.foregroundColor};
        }
    }

    .dx-button {  
        border: 1px solid ${({ theme }) => theme.colors.foregroundColor};
        border-radius: 5px;
        background: none;  
        color: ${({ theme }) => theme.colors.foregroundColor}; 
        &.dx-state-hover {
            background: none; 
        }
    }

    .dx-toolbar-items-container {
        color: ${({ theme }) => theme.colors.foregroundColor}; 
        .dx-toolbar-after {
            .dx-button.dx-state-hover {
            background-color: ${({ theme }) => theme.colors.foregroundColor}; 
            }
            .dx-button.dx-treelist-column-chooser-button.dx-state-hover {
                background: none;
            }
            .dx-button.dx-closebutton.dx-state-hover {
                background: none;
            }
        }
        .dx-button { 
            border: 1px solid ${({ theme }) => theme.colors.foregroundColor}; 
            &.dx-state-hover {
                background-color: ${({ theme }) => theme.colors.foregroundColor}; 
                color: ${({ theme }) => theme.colors.backgroundColor};
            }
            &.dx-closebutton {
                border: none;
                &.dx-state-hover {
                    background-color: none;
                }
                i::before {
                    color: ${({ theme }) => theme.colors.foregroundColor};
                }
            }
        }
        .dx-button-mode-text.dx-icon { 
            color: ${({ theme }) => theme.colors.foregroundColor};
        }
    }

    .dx-treeview-item-with-checkbox .dx-treeview-item {
        color: ${({ theme }) => theme.colors.foregroundColor};
    }

    .dx-menu-base {
        border-radius: 5px;
        .dx-submenu {
            background-color:  ${({ theme }) => theme.colors.backgroundColor};
            border-color:  ${({ theme }) => theme.colors.normalControlBorderColor};
            li {
                span, i::before {
                    color: ${({ theme }) => theme.colors.foregroundColor};
                }
            }
        }
    }

    .dx-treeview-select-all-item {
        color: ${({ theme }) => theme.colors.foregroundColor};
        border-bottom: 1px solid ${({ theme }) => theme.colors.normalControlBorderColor};
    }
`;

export const TreeList = styled.div<Props>`
    overflow: hidden;
    height: 100%;
    .dx-treelist-container {
        overflow: hidden;
    }

    .treeList {
        backgroundcolor: ${({ theme }) => theme.colors.backgroundColor};
        .dx-treelist-container,
        .dx-treelist-header-panel,
        .dx-toolbar {
            background: inherit;
        }
        .dx-icon-column-chooser::before {
            color: ${({ theme }) => theme.colors.backgroundColor};
        }

        .dx-treelist-container .dx-header-filter-empty {
            color: ${({ theme }) => theme.colors.normalControlBorderColor};
        }

        .dx-treelist-headers .dx-treelist-table .dx-row > td {
            border-bottom: none;
            color: ${({ theme }) => theme.colors.accentColor};
            text-transform: uppercase;
            font-weight: 500;
            padding: 12px 8px;
        }
        .dx-treelist-borders {
            border-color: ${({ theme }) => theme.colors.normalControlBorderColor};
            & > .dx-treelist-headers {
                border: 1px solid ${({ theme }) => theme.colors.normalControlBorderColor};
                border-bottom: none;
            }
        }

        .dx-treelist-rowsview {
            border-color: ${({ theme }) => theme.colors.normalControlBorderColor};
            tr:not(.dx-row-focused) .dx-treelist-empty-space {
                color: ${({ theme }) => theme.colors.normalControlBorderColor};
            }
        }

        tbody {
            color: ${({ theme }) => theme.colors.foregroundColor};
            td {
                padding: 12px 8px;
                border-color: ${({ theme }) => theme.colors.normalControlBorderColor};
            }
        }

        .dx-texteditor.dx-editor-outlined {
            background: none;
            border-color: ${({ theme }) => theme.colors.accentColor};
            input {
                color: ${({ theme }) => theme.colors.accentColor};
            }

            .dx-placeholder,
            .dx-icon-search::before {
                color: ${({ theme }) => theme.colors.accentColor};
            }
        }

        .dx-button-mode-contained {
            background: none;
            border-color: ${({ theme }) => theme.colors.accentColor};
        }

        .dx-icon-column-chooser::before {
            color: ${({ theme }) => theme.colors.accentColor};
        }
    }

    .dx-loadpanel-content {
        background-color: ${({ theme }) => theme.colors.backgroundColor};
        border-color: ${({ theme }) => theme.colors.accentColor};
        .dx-loadindicator-segment {
            color: ${({ theme }) => theme.colors.accentColor};
        }
        .dx-loadpanel-message {
            color: ${({ theme }) => theme.colors.foregroundColor};
        }
    }

    .dx-popup-wrapper > .dx-overlay-content {
        background-color: ${({ theme }) => theme.colors.backgroundColor};
        border: ${({ theme }) => `1px solid ${theme.colors.normalControlBorderColor}`};
    }
`;
