import { BooleanField, Debugger, Expander, Footer, Form } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import React, { useEffect } from 'react';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { LegalEntityDissolutionApprovalProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { VALIDATION_MESSAGES } from 'modules/LegalEntityCreation/validation/utils';
import { updateValidation } from 'modules/LegalEntityCreation/context/actions/form-actions';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionApproval } from '../../context/Provider';

export default function LegalEntityDissolutionApproval(props: LegalEntityDissolutionApprovalProps) {
    const context = useLegalEntityDissolutionApproval();
    const {
        getValue,
        state: {
            data: {
                values: { approveDissolution, approveSoftClosure },
            },
            version,
        },
        dispatch,
    } = context;
    const {
        state: { legalEntityOId, legalEntityDissolutionParentWorkItem },
    } = useCreationViewContext();
    const lastCompletedTask = useLastCompletedTask({ workItem: legalEntityDissolutionParentWorkItem });

    useEffect(() => {
        if (!approveDissolution || !approveSoftClosure) {
            updateValidation(dispatch, {
                LegalEntityDissolutionApproval: VALIDATION_MESSAGES.REQUIRED_FIELD,
            });
        } else {
            updateValidation(dispatch, {
                LegalEntityDissolutionApproval: undefined,
            });
        }
    }, [approveDissolution, approveSoftClosure, dispatch, version]);

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Approval} />
            <Form>
                <Expander
                    header='Legal Entity Dissolution Approval'
                    requestStep={RequestStepsView.Dissolution_Treasury}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={`0 0 0 0`}>
                            <GridLayout columns='1fr 1fr'>
                                <StackPanel>
                                    <BooleanField
                                        isRequired
                                        label='Approve the dissolution of this legal entity'
                                        labelToolTip={{
                                            component:
                                                "By checking 'Yes', I have reviewed the information and approve the dissolution of this entity.",
                                            options: { showDuration: 5000 },
                                        }}
                                        height={100}
                                        titleHeight={30}
                                        {...getValue('approveDissolution')}
                                    />
                                </StackPanel>
                                <StackPanel>
                                    <BooleanField
                                        isRequired
                                        label='Approve the soft closure of this entity on Oracle, and approve the closure of this entity on Coupa and Concur'
                                        labelToolTip={{
                                            component:
                                                "By checking 'Yes', I approve AppSupport “soft” closing this entity on Oracle, Coupa, and Concur.  I understand that allocation expenses may still be incurred on this entity.",
                                            options: { showDuration: 8000 },
                                        }}
                                        bottomDescription='NOTE: Expenses that are allocated such as bank fees, tax preparations, and tax payments can still be incurred on the General Ledger - Oracle.'
                                        height={100}
                                        titleHeight={30}
                                        {...getValue('approveSoftClosure')}
                                    />
                                </StackPanel>
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={legalEntityOId} {...props} />
                </Footer>
            )}
        </>
    );
}
