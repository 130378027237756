import DocumentPreparation from './DocumentPreparation';
import { LegalEntityDocumentPreparationProvider } from '../context/Provider';
import { LegalEntityRequestDocumentPreparationProps } from './model';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityDocumentPreparationSection(props: LegalEntityRequestDocumentPreparationProps) {
    return (
        <LegalEntityDocumentPreparationProvider
            storedValues={props.data}
            mode={props.mode}
            requestStep={RequestStepsView.Document_Preparation}>
            <DocumentPreparation {...props} />
        </LegalEntityDocumentPreparationProvider>
    );
}
