import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export const DISSOLUTION_TREASURY_STATE = {
    DISSOLUTION_REQUEST: 'Treasury Request',
    DISSOLUTION_APPROVAL: 'Treasury Approval',
    DISSOLUTION_APPROVED: 'Treasury Approved',
    DISSOLUTION_COMPLETE: 'Treasury Dissolution Setup Complete',
    SAVE_DATA: 'SaveData',
    DISSOLUTION_WITHDRAWAL: 'Treasury Withdrawn',
} as const;
type Status = 'InProcess' | 'Complete' | 'Canceled';

export type DissolutionTreasuryStateKeyType = keyof typeof DISSOLUTION_TREASURY_STATE;
export type LegalEntityDissolutionTreasuryState = (typeof DISSOLUTION_TREASURY_STATE)[DissolutionTreasuryStateKeyType];

export type LegalEntityDissolutionTreasuryTaskType = 'ApprovalRequestType' | 'SubmitRequestType';
export type LegalEntityDissolutionTreasuryTask = Task &
    Record<keyof Pick<Task, 'taskType'>, LegalEntityDissolutionTreasuryTaskType>;
export type LegalEntityDissolutionTreasuryWorkflow = Omit<WorkItem, 'tasks'> & {
    workflowType: WorkflowType.LegalEntityDissolutionTreasuryWorkflowType;
    workItemState: LegalEntityDissolutionTreasuryState;
    workItemStatus: Status;
    tasks: LegalEntityDissolutionTreasuryTask[] | undefined;
};
