import { VALIDATION_MESSAGES, validateDateTimeField, validateRequiredField } from './utils';
import { isCaymanIslands, isLuxembourg, isUnitedStates } from 'models';
import { useCallback, useEffect, useState } from 'react';

import { RegistrationData as RegistrationDataModel } from '../LegalEntityInformation/model';
import { ValidationErrors } from '../context/model';
import { getRegisteredAgents } from '../LegalEntityDocumentPreparation/utils';
import { isEmpty } from '../../../Utilities/Validations';
import { isNil } from 'lodash';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityInformation } from '../context/Provider';

export default function useInformationDomesticRegistrationValidation() {
    const {
        state: {
            data: {
                values: { RegistrationData = [] },
            },
            isEditable,
            version,
        },
        dispatch,
    } = useLegalEntityInformation();

    const [validations, setValidations] = useState<ValidationErrors<RegistrationDataModel>[]>([]);

    const checkDissolutionDate = useCallback(
        (checkedIndex: number) => {
            const { dissolutionDate, formationDate } = RegistrationData[checkedIndex];

            if (
                checkedIndex === 0 &&
                isNil(dissolutionDate) &&
                RegistrationData.filter(({ isDeleted }) => !isDeleted).length === 2
            ) {
                return 'You cannot add a new domestic registration until the existing one has been dissolved';
            }

            if (dissolutionDate && formationDate && new Date(dissolutionDate) < new Date(formationDate)) {
                return 'Date Dissolved must be on or after the Date Formed.';
            }

            return undefined;
        },
        [RegistrationData]
    );

    useEffect(() => {
        setValidations(
            RegistrationData.map((registration, index) => {
                return registration.isDeleted
                    ? {}
                    : {
                          domesticCountry: validateRequiredField(registration.domesticCountry),
                          formationDate: validateDateTimeField(registration.formationDate, {
                              canBeFutureDate: false,
                              isOptionalWhen: isLuxembourg(registration.domesticCountry),
                          }),
                          domesticRegisteredAgent: validateRequiredField(registration.domesticRegisteredAgent, {
                              isRequired: getRegisteredAgents(registration.domesticCountry),
                          }),
                          registrationNumber: validateRequiredField(registration.registrationNumber, {
                              isRequired: !isLuxembourg(registration.domesticCountry),
                          }),
                          domesticState: validateRequiredField(registration.domesticState, {
                              isRequired:
                                  isUnitedStates(registration.domesticCountry) ||
                                  isCaymanIslands(registration.domesticCountry),
                          }),
                          dissolutionDate: checkDissolutionDate(index),
                      };
            })
        );
    }, [dispatch, RegistrationData, checkDissolutionDate]);

    useEffect(() => {
        if (!isEditable || isEmpty(validations)) {
            updateValidation(dispatch, { RegistrationData: undefined });
            return;
        }

        updateValidation(dispatch, {
            RegistrationData: validations.some((validation) => Object.values(validation).some((value) => value))
                ? VALIDATION_MESSAGES.COMBINED_ERROR
                : undefined,
        });
    }, [validations, dispatch, version, isEditable]);

    return { validations };
}
