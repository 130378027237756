import React, { useEffect } from 'react';
import { setRequestNotes, useRequestContext } from 'context/request-context';

import { Expander } from 'components';
import { SPACING } from 'Utilities/Layout';
import { TextField } from '@bxgrandcentral/controls';
import { WorkItem } from 'models/Workflow';

type Props = {
    workItem?: WorkItem;
    mode?: string;
};

export default function RequestNotes({ workItem, mode = 'Readonly' }: Props) {
    const {
        state: { taskComment = {} },
        dispatch,
    } = useRequestContext();

    const { workItemId = 0, customProperties } = workItem ?? {};

    useEffect(() => {
        workItem && setRequestNotes(dispatch, workItemId, customProperties?.taskComment);
    }, [customProperties, workItem, workItemId, dispatch]);

    return workItem ? (
        <Expander
            header='Request Notes'
            content={
                <div className='text-area-hide-scrollbar'>
                    <TextField
                        value={taskComment[workItemId] ?? customProperties?.taskComment}
                        onValueChanged={(newValue = customProperties?.taskComment) => {
                            setRequestNotes(dispatch, workItemId, newValue);
                        }}
                        canUndo={false}
                        minHeight={120}
                        maxHeight={240}
                        isEditable={mode === 'Edit'}
                        textWrapping='wrap'
                        canResetValue
                        padding={`${SPACING.XS}px 0`}
                    />
                </div>
            }
        />
    ) : null;
}
