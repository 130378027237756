import { setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import React from 'react';
import { ToolBarButton } from '@bxgrandcentral/controls';
import { isNil } from 'lodash';
import useApiServices from '../../hooks/use-api-services';
import { useCreationViewContext } from '../../context/creation-view-context';
import useGenerateKey from 'hooks/use-generate-key';
import useUnsavedChangesConfirmation from '../../hooks/use-unsaved-changes-confirmation';

export default function ToolBarRefreshButton() {
    const { reloadEntityAndWorkItems } = useApiServices();
    const {
        state: {
            legalEntityCreation: { canSave },
        },
        dispatch,
    } = useAppContext();

    const {
        state: { legalEntityOId, isWorkItemsRefreshing },
    } = useCreationViewContext();
    const getUnsavedChangesConfirmation = useUnsavedChangesConfirmation(canSave);

    const handleClick = async () => {
        const confirmation = isWorkItemsRefreshing || (await getUnsavedChangesConfirmation());

        if (confirmation) {
            await reloadEntityAndWorkItems();
            setResetLegalEntityCreationForm(dispatch, true);
        }
    };

    const refreshButtonKey = useGenerateKey(canSave);

    if (isNil(legalEntityOId)) {
        return null;
    }

    return (
        <ToolBarButton
            key={refreshButtonKey}
            icon='Refresh'
            toolTip='Refresh'
            label='Refresh'
            verticalAlignment='center'
            showLabel
            isEnabled={!isNil(legalEntityOId)}
            onClick={handleClick}
        />
    );
}
