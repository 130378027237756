import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export const DISSOLUTION_LITIGATION_STATE = {
    DISSOLUTION_REQUEST: 'Litigation Request',
    DISSOLUTION_APPROVAL: 'Litigation Approval',
    DISSOLUTION_APPROVED: 'Litigation Approved',

    DISSOLUTION_COMPLETE: 'Litigation Dissolution Setup Complete',
    SAVE_DATA: 'SaveData',
    DISSOLUTION_WITHDRAWAL: 'Litigation Withdrawn',
} as const;
export type DissolutionLitigationStateKeyType = keyof typeof DISSOLUTION_LITIGATION_STATE;
export type LegalEntityDissolutionLitigationState =
    (typeof DISSOLUTION_LITIGATION_STATE)[DissolutionLitigationStateKeyType];

type Status = 'InProcess' | 'Complete' | 'Canceled';

export type LegalEntityDissolutionLitigationTaskType = 'ApprovalRequestType' | 'SubmitRequestType';
export type LegalEntityDissolutionLitigationTask = Task &
    Record<keyof Pick<Task, 'taskType'>, LegalEntityDissolutionLitigationTaskType>;
export type LegalEntityDissolutionLitigationWorkflow = Omit<WorkItem, 'tasks'> & {
    workflowType: WorkflowType.LegalEntityDissolutionLitigationWorkflowType;
    workItemState: LegalEntityDissolutionLitigationState;
    workItemStatus: Status;
    tasks: LegalEntityDissolutionLitigationTask[] | undefined;
};
