import {
    CREATE_ENTITY_STATE,
    DISSOLUTION_STATUS,
    FinanceInfoWorkflow,
    LegalEntityDissolutionDetailsWorkflow,
    LegalEntityUpdateWorkflow,
    OwnershipWorkflow,
    TaskStatus,
    TaxClassificationWorkflow,
} from 'models/LegalEntityRequest/Workflow';
import { Capabilities, RequestStepsView } from '../../RequestStepsView';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    setEditWorkFlow,
    setIsEntityDissolvable,
    setLoadingState,
    unsetLoadingState,
    useCreationViewContext,
} from '../../context/creation-view-context';
import { setForceEditedEntityId, setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import { ToolBarButton } from '@bxgrandcentral/controls';
import { hasElement } from 'Utilities/array';
import { isEmpty } from 'Utilities/Validations';
import { isNil } from 'lodash';
import useHasCapability from '../../hooks/use-has-capability';
import useUnsavedChangesConfirmation from '../../hooks/use-unsaved-changes-confirmation';

export default function ToolBarEditButton() {
    const {
        state: {
            legalEntityOId,
            selectedPanel,
            entityCreationWorkItem,
            taxClassificationWorkItem,
            ownershipWorkItem,
            financeInformationWorkItem,
            legalEntityUpdateWorkItem,
            foreignRegistrationWorkItems,
            foreignRegistrationUpdateWorkItem,
            legalEntityDissolutionParentWorkItem,
            editWorkFlow,
            isWorkItemsRefreshing,
            isCTAReview,
            isEntityDissolved,
        },
        dispatch,
    } = useCreationViewContext();

    const {
        state: {
            legalEntityCreation: { canSave, forceEditEntity },
        },
        dispatch: appDispatch,
    } = useAppContext();
    const hasCapability = useHasCapability();
    const [isForceEditActive, setIsForceEditActive] = useState<boolean>(false);

    const forceEditablePages = [RequestStepsView.Dissolution_Details];

    const isStandaloneWorkflowsEnabled = useMemo(
        () =>
            !entityCreationWorkItem ||
            !!entityCreationWorkItem?.tasks?.some(
                (task) => task.taskType === 'ActivateEntity' && task.status === 'Complete'
            ),
        [entityCreationWorkItem]
    );

    const isChildProcessDone = useCallback(
        (taskKeyToFind: string): boolean => {
            if (!entityCreationWorkItem?.tasks) {
                return true;
            }

            return !entityCreationWorkItem?.tasks.some(
                ({ taskKey, status }) => taskKey === taskKeyToFind && status !== 'Complete'
            );
        },

        [entityCreationWorkItem]
    );

    const isApprovedLegalEntity = useMemo(
        () =>
            (isNil(entityCreationWorkItem) && !isNil(legalEntityOId)) ||
            !!entityCreationWorkItem?.tasks?.some(
                ({ taskType, status }) =>
                    taskType === 'TransitionToPostProcess1TaskType' && status === TaskStatus.Complete
            ),
        [entityCreationWorkItem, legalEntityOId]
    );

    const isWorkItemNotInProcess = useCallback(
        (
            workItem?:
                | TaxClassificationWorkflow
                | FinanceInfoWorkflow
                | OwnershipWorkflow
                | LegalEntityUpdateWorkflow
                | LegalEntityDissolutionDetailsWorkflow
        ) => isStandaloneWorkflowsEnabled && (workItem?.workItemStatus !== 'InProcess' || isEmpty(workItem)),
        [isStandaloneWorkflowsEnabled]
    );

    const isForeignRegistrationsEditable = useMemo(() => {
        const isWorkItemNotInProcess =
            !foreignRegistrationWorkItems.filter(
                ({ workItemStatus }) => workItemStatus !== 'Complete' && workItemStatus !== 'Canceled'
            ).length && foreignRegistrationUpdateWorkItem?.workItemStatus !== 'InProcess';

        const hasNecessaryCapabilities =
            hasCapability(Capabilities.createForeignRegistration) ||
            (hasCapability(Capabilities.updateForeignRegistration) && isStandaloneWorkflowsEnabled);

        return isWorkItemNotInProcess && hasNecessaryCapabilities;
    }, [foreignRegistrationWorkItems, foreignRegistrationUpdateWorkItem, isStandaloneWorkflowsEnabled, hasCapability]);

    const isCTAEditable =
        !isWorkItemsRefreshing &&
        selectedPanel?.requestStepView === RequestStepsView.Corporate_Transparency &&
        hasCapability(Capabilities.approveCorporateTransparency) &&
        !!entityCreationWorkItem?.tasks?.some(
            (task) => task.taskType === 'CTANotificationTaskType' && task.status === 'Complete'
        );

    // Elliot confirmed entity can be dissolved regardless of CTA review status
    const isEntityDissolvable = useMemo(() => {
        return (
            (isNil(entityCreationWorkItem) ||
                entityCreationWorkItem?.workItemState === CREATE_ENTITY_STATE.LEGAL_ENTITY_SETUP_COMPLETE) &&
            foreignRegistrationUpdateWorkItem?.workItemStatus !== 'InProcess' &&
            financeInformationWorkItem?.workItemStatus !== 'InProcess' &&
            taxClassificationWorkItem?.workItemStatus !== 'InProcess' &&
            legalEntityUpdateWorkItem?.workItemStatus !== 'InProcess' &&
            ownershipWorkItem?.workItemStatus !== 'InProcess'
        );
    }, [
        entityCreationWorkItem,
        financeInformationWorkItem,
        foreignRegistrationUpdateWorkItem,
        legalEntityUpdateWorkItem,
        ownershipWorkItem,
        taxClassificationWorkItem,
    ]);

    useEffect(() => {
        setIsEntityDissolvable(dispatch, isEntityDissolvable);
    }, [dispatch, isEntityDissolvable]);

    const isDissolutionInProcess = useMemo(() => {
        return legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
    }, [legalEntityDissolutionParentWorkItem]);

    const isEditButtonEnabled = useMemo(() => {
        if (isEntityDissolved) {
            return false;
        }

        return !isWorkItemsRefreshing && isApprovedLegalEntity && !isDissolutionInProcess
            ? (selectedPanel?.requestStepView === RequestStepsView.Foreign_Registration &&
                  isForeignRegistrationsEditable) ||
                  (selectedPanel?.requestStepView === RequestStepsView.Tax_Classification &&
                      isWorkItemNotInProcess(taxClassificationWorkItem) &&
                      isChildProcessDone('taxChildProcess') &&
                      hasCapability(Capabilities.createTaxClassification)) ||
                  (selectedPanel?.requestStepView === RequestStepsView.Finance_Details &&
                      isWorkItemNotInProcess(financeInformationWorkItem) &&
                      isChildProcessDone('financeChildProcess') &&
                      hasCapability(Capabilities.createFinanceInformation)) ||
                  (selectedPanel?.requestStepView === RequestStepsView.Ownership_Information &&
                      isWorkItemNotInProcess(ownershipWorkItem) &&
                      isChildProcessDone('ownershipInfoChildProcess') &&
                      hasCapability(Capabilities.createOwnershipInformation)) ||
                  (selectedPanel?.requestStepView === RequestStepsView.Legal_Entity_Information &&
                      isWorkItemNotInProcess(legalEntityUpdateWorkItem) &&
                      hasCapability(Capabilities.createLegalInformation)) ||
                  (selectedPanel?.requestStepView === RequestStepsView.Dissolution_Details &&
                      isWorkItemNotInProcess(legalEntityDissolutionParentWorkItem) &&
                      hasCapability(Capabilities.createDissolutionRequest)) ||
                  (selectedPanel?.requestStepView === RequestStepsView.Corporate_Transparency &&
                      hasCapability(Capabilities.approveCorporateTransparency) &&
                      isCTAEditable &&
                      !isCTAReview)
            : false;
    }, [
        isApprovedLegalEntity,
        isForeignRegistrationsEditable,
        taxClassificationWorkItem,
        selectedPanel,
        financeInformationWorkItem,
        ownershipWorkItem,
        legalEntityUpdateWorkItem,
        legalEntityDissolutionParentWorkItem,
        isWorkItemNotInProcess,
        hasCapability,
        isChildProcessDone,
        isWorkItemsRefreshing,
        isCTAEditable,
        isCTAReview,
        editWorkFlow,
    ]);

    const editButton = useMemo(
        () => (isEditButtonEnabled && editWorkFlow ? 'ChromeClose' : 'Edit'),
        [editWorkFlow, isEditButtonEnabled]
    );

    const changeEditModeFlag = () => {
        const loadingKey = 'Changing to edit mode';
        setLoadingState(dispatch, loadingKey);
        setTimeout(() => {
            const isEditWorkFlowState = !editWorkFlow;
            setEditWorkFlow(dispatch, isEditWorkFlowState);
            unsetLoadingState(dispatch, loadingKey);
            if (!isEditWorkFlowState) {
                setResetLegalEntityCreationForm(appDispatch, true);
            }
        }, 1500);
    };

    const isEditableStandAloneRequest = useMemo(() => {
        const sections: string[] = [
            ...(!isDissolutionInProcess
                ? [
                      RequestStepsView.Foreign_Registration,
                      RequestStepsView.Tax_Classification,
                      RequestStepsView.Legal_Entity_Information,
                      RequestStepsView.Finance_Details,
                      RequestStepsView.Ownership_Information,
                      RequestStepsView.Corporate_Transparency,
                  ]
                : []),
            ...(isEntityDissolvable ? [RequestStepsView.Dissolution_Details] : []),
        ];

        return hasElement(sections, selectedPanel?.requestStepView) && !isEntityDissolved;
    }, [isDissolutionInProcess, isEntityDissolvable, selectedPanel, isEntityDissolved]);

    useEffect(() => {
        if (
            forceEditablePages.includes(selectedPanel?.requestStepView!) &&
            !isForceEditActive &&
            forceEditEntity?.entityId === legalEntityOId &&
            forceEditEntity?.section === selectedPanel?.requestStepView &&
            isEditableStandAloneRequest &&
            isEditButtonEnabled
        ) {
            setIsForceEditActive(true);
            setEditWorkFlow(dispatch, true);
        }

        if (isForceEditActive && forceEditEntity && forceEditEntity?.section !== selectedPanel?.requestStepView) {
            setIsForceEditActive(false);
            setForceEditedEntityId(appDispatch, undefined);
        }
    }, [
        forceEditEntity,
        legalEntityOId,
        isEditableStandAloneRequest,
        isEditButtonEnabled,
        selectedPanel,
        dispatch,
        appDispatch,
        isForceEditActive,
        forceEditablePages,
    ]);

    useEffect(() => {
        if (selectedPanel?.requestStepView === RequestStepsView.Corporate_Transparency) {
            setEditWorkFlow(dispatch, isCTAReview);
        }
    }, [dispatch, isCTAReview, selectedPanel]);

    const getUnsavedChangesConfirmation = useUnsavedChangesConfirmation(canSave);

    if (!isEditableStandAloneRequest || isForceEditActive) {
        return null;
    }
    return (
        <ToolBarButton
            width={50}
            icon={editButton}
            toolTip={editButton}
            label={isEditButtonEnabled && editWorkFlow ? 'Close' : 'Edit'}
            showLabel
            verticalAlignment='center'
            isEnabled={isEditButtonEnabled}
            onClick={async () => {
                if (isEditButtonEnabled && editWorkFlow) {
                    if (await getUnsavedChangesConfirmation()) {
                        changeEditModeFlag();
                    }
                } else {
                    changeEditModeFlag();
                }
            }}
        />
    );
}
