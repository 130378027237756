import { ColorManager, ThemeColors, ThemeManager } from '@bxgrandcentral/controls';
import React, { ReactNode } from 'react';

import { SPACING } from 'Utilities/Layout';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type Props = {
    children: ReactNode;
};

export default function InfoBlock({ children }: Props) {
    const isDarkTheme = ThemeManager.activeTheme.name === 'Dark';
    const colors = useColors();

    return (
        <Container isDark={isDarkTheme} colors={colors}>
            {children}
        </Container>
    );
}

const Container = styled.div<{ isDark: boolean; colors: ThemeColors }>`
    display: flex;
    height: fit-content;
    padding: ${SPACING.MD}px ${SPACING.MD}px;
    margin: ${SPACING.MD}px;
    background-color: ${({ colors }) => ColorManager.fade(colors.systemCyanColor, 0.2)};
    border: ${({ colors }) => `1px solid ${colors.systemBlueColor}`};
    border-left: ${({ colors }) => `4px solid ${colors.systemBlueColor}`};
    color: ${({ isDark }) => `${isDark ? '#ffffff' : '#000000'}`};
`;
