import { Border, SPACING } from 'Utilities/Layout';
import React, { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import FilterItem from './FilterItem';
import GhostButton from 'components/buttons/GhostButton/GhostButton';
import { StackPanel } from '@bxgrandcentral/controls';
import { Text } from 'components';
import { ValueFormatterParams } from 'ag-grid-community';
import { fieldsWithRawValues } from 'hooks/use-column-configuration';
import { getFriendlyValues } from '../Grid/model';
import { removeAt } from 'Utilities/array';
import { sortBy } from 'lodash';
import useColors from 'api/hooks/use-theme';

export default forwardRef((props: any, ref) => {
    const {
        values,
        colDef: { field = '', headerName = '' },
    } = props;

    const [filterState, setFilterState] = useState<string[]>([]);

    useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return filterState.length > 0;
            },
            doesFilterPass(params: any) {
                return filterState.includes(params.data[field]);
            },
            getModel() {
                if (filterState.length === 0) {
                    return undefined;
                }
                return {
                    state: filterState,
                };
            },
            setModel(model: any) {
                if (model === null) {
                    setFilterState([]);
                } else {
                    setFilterState(model.state);
                }
            },
            getModelAsString() {
                return filterState.length === 0 ? '' : filterState;
            },
        };
    });

    useEffect(() => {
        props.filterChangedCallback();
    }, [filterState.length, props]);

    const { backgroundColor, normalControlBorderColor } = useColors();

    const onFilterItemClicked = (value: string) => {
        setFilterState((previous) => {
            const index = previous.indexOf(value);
            return index === -1 ? previous.concat(value) : removeAt(previous, index) ?? [];
        });
    };

    const getFilterTitle = useCallback(
        (value: any) => {
            if (fieldsWithRawValues.includes(field)) {
                return getFriendlyValues({ value: value } as ValueFormatterParams);
            }

            return value;
        },
        [field]
    );

    const valuesSortedByTitle = useMemo(() => {
        const withTitle = values.map((value: any) => {
            return {
                value,
                title: getFilterTitle(value),
            };
        });

        return sortBy(withTitle, (item) => item.title);
    }, [values, getFilterTitle]);

    return (
        <StackPanel backgroundColor={backgroundColor}>
            <StackPanel padding={SPACING.MD}>
                <Text variant='body-small-bold' styleOverrides={{ fontSize: 16 }}>
                    {headerName}
                </Text>
            </StackPanel>
            <StackPanel styleOverrides={{ overflowY: 'auto', maxHeight: '350px' }}>
                {valuesSortedByTitle.map((item: any, index: number) => (
                    <Fragment key={index}>
                        <FilterItem
                            value={item.value}
                            title={item.title}
                            isActive={filterState.includes(item.value)}
                            onClick={onFilterItemClicked}
                        />
                    </Fragment>
                ))}
            </StackPanel>
            <Border color={normalControlBorderColor} />
            <StackPanel
                padding={`${SPACING.SM}px ${SPACING.MD}px`}
                orientation='horizontal'
                styleOverrides={{ justifyContent: 'flex-end' }}>
                <GhostButton onClick={() => setFilterState([])}>Clear selection</GhostButton>
            </StackPanel>
        </StackPanel>
    );
});
