import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionLegal } from '../context/Provider';
import { validateCompleted } from './utils';

export default function useDissolutionLegalValidation() {
    const {
        state: {
            data: {
                values: { noOutstandingFundRelatedDuties, noActiveFundRegistrations },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionLegal();

    useEffect(() => {
        updateValidation(dispatch, {
            noOutstandingFundRelatedDuties: validateCompleted(noOutstandingFundRelatedDuties),
            noActiveFundRegistrations: validateCompleted(noActiveFundRegistrations),
        });
    }, [dispatch, noOutstandingFundRelatedDuties, noActiveFundRegistrations, version]);
}
