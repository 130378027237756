import { DateTimeField, ElasticSearchField, Select, Text } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { isEqual, isNil } from 'lodash';
import {
    useLegalEntityAuthorizedPerson,
    useLegalEntityOwnerShipInformation,
} from 'modules/LegalEntityCreation/context/Provider';

import { AuthorizedPersonModel } from '../../model';
import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { FormControl } from 'components/controls/controls.styled';
import UpdateButton from '../OwnershipDetails/UpdateButton';
import { convertToLocalDate } from 'Utilities/date';
import { getValuesOf } from 'Utilities/object';
import { isEmpty } from 'Utilities/Validations';
import { resetState } from 'modules/LegalEntityCreation/context/actions';
import useAuthorizedPersonEditValidation from 'modules/LegalEntityCreation/validation/use-authorized-person-edit-validation';
import { useReferenceData } from 'api';

type Props = {
    index: number;
    addNewItem: (item: AuthorizedPersonModel) => void;
    closeSelectedElement: () => void;
    isNewFlow: boolean;
    setFormHasChanges: (isOpen: boolean) => void;
};

export default function AuthorizedPerson({
    index,
    addNewItem,
    closeSelectedElement,
    isNewFlow,
    setFormHasChanges,
}: Props) {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
            },
            isEditable,
            version,
        },
        setValue: setAuthorizedPersonValue,
    } = useLegalEntityOwnerShipInformation();

    const {
        state: {
            data: { values, noPendingValues, validationErrors, changes },
        },
        setValue,
        setValues,
        getValue,
        dispatch,
    } = useLegalEntityAuthorizedPerson();

    useAuthorizedPersonEditValidation({ index });

    const {
        data: { SignatoryTitles },
    } = useReferenceData();

    const { key, name, title, appointmentDate, resignationDate, isDeleted, hasPendingChange } = values;

    const isEmptyAuthorizedPerson = useMemo(
        () =>
            getValuesOf({
                name,
                title,
                appointmentDate,
                resignationDate,
            }).every((value) => isNil(value)),
        [name, title, appointmentDate, resignationDate]
    );

    const hasNoValidationError = useMemo(
        () =>
            isNil(validationErrors.name) &&
            isNil(validationErrors.title) &&
            isNil(validationErrors.appointmentDate) &&
            isNil(validationErrors.resignationDate),
        [validationErrors]
    );

    const isUnfinishedRegistration = useMemo(
        () => isNil(name) || isNil(title) || isNil(appointmentDate),
        [name, title, appointmentDate]
    );

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    const addChanges = () => {
        const updated = [...authorizedPerson];
        if (values.key || !isNewFlow) {
            updated[index] = values;
            setAuthorizedPersonValue('authorizedPerson', updated);
            closeSelectedElement();
        } else {
            addNewItem(values);
        }
        resetState(dispatch);
    };

    useEffect(() => {
        setFormHasChanges(!isEmpty(changes));
    }, [changes, values]);

    const updateButtonAvailable = isEditable && !isDeleted;
    const isNewItem = !key || (hasPendingChange && !noPendingValues);

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING}>
            {isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Person
                </TextBlock>
            )}
            <DoubleColumnLayout isDisabled={authorizedPerson[index]?.isDeleted}>
                <StackPanel itemGap={SPACING.SM}>
                    <FormControl className='form'>
                        <ElasticSearchField
                            label='Name'
                            labelToolTip='Enter the name of the authorized person'
                            searchType={EntitySubType.ThirdParty}
                            selectedEntityOId={name}
                            onEntitySelected={(result) => {
                                setValues({
                                    name: result?.entityOId,
                                    relatedEntityName: result?.primaryName,
                                });
                            }}
                            canClearSelection
                            isReadOnly={!isEditable}
                            isPendingChange={isPendingChange(name, noPendingValues?.name)}
                            isRequired
                            {...(isEditable && { validationError: validationErrors.name })}
                        />
                    </FormControl>
                    <Select
                        label='Title'
                        labelToolTip={{ component: 'Select the authorized person’s title' }}
                        itemsSource={SignatoryTitles}
                        isRequired
                        {...getValue('title')}
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <DateTimeField
                        label='Appointment Date'
                        labelToolTip='Date in which authorized person was appointed'
                        value={appointmentDate}
                        isEditable={isEditable}
                        version={version}
                        validationError={validationErrors.appointmentDate}
                        onValueChanged={(newValue) => {
                            setValue('appointmentDate', convertToLocalDate(newValue));
                        }}
                        isPendingChange={isPendingChange(
                            appointmentDate?.toString(),
                            noPendingValues?.appointmentDate?.toString()
                        )}
                        isRequired
                    />
                    <DateTimeField
                        label='Resignation Date'
                        labelToolTip='Date of resignation'
                        value={resignationDate}
                        isEditable={isEditable}
                        version={version}
                        validationError={validationErrors.resignationDate}
                        onValueChanged={(newValue) => {
                            setValue('resignationDate', convertToLocalDate(newValue));
                        }}
                        isPendingChange={isPendingChange(
                            resignationDate?.toString(),
                            noPendingValues?.resignationDate?.toString()
                        )}
                    />
                </StackPanel>
            </DoubleColumnLayout>
            <StackPanel itemGap={SPACING.MD}>
                {isUnfinishedRegistration && (
                    <Text
                        horizontalAlignment='center'
                        verticalAlignment='center'
                        textAlignment='center'
                        variant='errorBold'>
                        Unfinished Authorized Person registration can't be saved, please set name, title and appointment
                        date
                    </Text>
                )}
                {updateButtonAvailable && (
                    <StackPanel
                        itemGap={SPACING.LG}
                        orientation='horizontal'
                        styleOverrides={{ alignSelf: 'flex-end' }}>
                        <UpdateButton
                            addChanges={addChanges}
                            isEnabled={hasNoValidationError && !isEmptyAuthorizedPerson}
                            text={isNewFlow ? 'Add' : 'Update Authorized Person'}
                        />
                    </StackPanel>
                )}
            </StackPanel>
        </StackPanel>
    );
}
