import LegalEntityInformation from './LegalEntityInformation';
import { LegalEntityInformationProps } from './model';
import { LegalEntityInformationProvider } from '../context/Provider';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityInformationSection(props: LegalEntityInformationProps) {
    return (
        <LegalEntityInformationProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            requestStep={RequestStepsView.Legal_Entity_Information}>
            <LegalEntityInformation {...props} />
        </LegalEntityInformationProvider>
    );
}
