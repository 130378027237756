import { DissolutionWorkflow, GetWorkItemForEntityResponse } from 'services/interfaces';

import { WorkItem } from 'models/Workflow';
import { WorkflowType } from './WorkflowTypes';

type workItemsByType = { [workflowType in WorkflowType]?: WorkItem[] };

export const getChildItemsFromGetWorkItemsResponse = (workItems: GetWorkItemForEntityResponse) => {
    const childItems = workItems.childItems.reduce((agg, workItem) => {
        const type = workItem.workflowType as WorkflowType;
        agg[type] = (agg[type] ?? []).concat(workItem);
        return agg;
    }, {} as workItemsByType);

    return childItems;
};

export const getStandaloneItemsFromGetWorkItemsResponse = (workItems: GetWorkItemForEntityResponse) => {
    const standaloneItems =
        workItems.standaloneItems?.reduce((agg, workItem) => {
            const type = workItem.workflowType as WorkflowType;
            agg[type] = (agg[type] ?? []).concat(workItem);
            return agg;
        }, {} as workItemsByType) ?? [];

    return standaloneItems;
};

export const getChildItemsFromDissolutionResponse = (workItems: DissolutionWorkflow) => {
    const dissolutionItems =
        workItems?.childItems?.reduce((agg, workItem) => {
            const type = workItem.workflowType as WorkflowType;
            agg[type] = (agg[type] ?? []).concat(workItem);
            return agg;
        }, {} as workItemsByType) ?? [];

    return dissolutionItems;
};
