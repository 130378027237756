import { Button, StackPanel } from '@bxgrandcentral/controls';
import {
    getBalanceCheckInfoMessage,
    showNoBalancesConfirmationPopup,
} from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTreasury/utils';
import { isEmpty, isNil } from 'lodash';
import { setIsEditable, setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import { CanDissolveEntityResponse } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTreasury/model';
import { DISSOLUTION_REJECT_DIALOG_MESSAGE } from 'modules/LegalEntityCreation/validation/utils';
import { GlobalState } from 'GlobalState';
import React from 'react';
import { TaskActions } from '../../../../Shared/TaskActions';
import { isOpen } from '../../../../../../models/Workflow';
import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';
import useColors from 'api/hooks/use-theme';
import { useLegalEntityDissolutionFinance } from 'modules/LegalEntityCreation/context/Provider';

export default function ActionPanel(props: ActionPanelProps) {
    const { entityOId } = props;
    const colors = useColors();

    const { getCanDissolveEntity, reloadEntityAndWorkItems } = useApiServices();

    const {
        state: {
            data: { changes },
            canSave,
            canApprove,
        },
        dispatch,
    } = useLegalEntityDissolutionFinance();

    const handleCantDissolve = (canDissolveEntityResponse: CanDissolveEntityResponse) => {
        const failedToCheckErrorMessage = 'There was an error during the check of balances.';
        const confirmButtonTitle = 'Acknowledge';

        if (canDissolveEntityResponse.failedToCheck) {
            GlobalState.ShowMessageBox('ERROR', failedToCheckErrorMessage, confirmButtonTitle);
        } else if (canDissolveEntityResponse.icBalanceReportUrl || canDissolveEntityResponse.leBalanceReportUrl) {
            const message = getBalanceCheckInfoMessage(canDissolveEntityResponse);
            GlobalState.ShowMessageBox('ERROR', message, confirmButtonTitle, 'Failed Validation');
        }

        setIsEditable(dispatch, true);
    };

    const handleSave = async (isSubmit = false) => {
        if (props.mode === 'Readonly' || props.mode === 'Approval' || props.mode === 'Create' || !entityOId) {
            return;
        }

        setIsLoading(dispatch, true);
        setIsEditable(dispatch, false);

        try {
            if (!isEmpty(changes)) {
                await props.onSave(changes);
                await props.onSaveEntityRelatedData(changes);
            }

            await reloadEntityAndWorkItems();

            setShouldReload(dispatch, true);

            if (!isSubmit) {
                setIsEditable(dispatch, true);
            }
        } catch (reason) {
            setIsEditable(dispatch, true);
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (!entityOId) {
        return null;
    }

    switch (props.mode) {
        case 'Edit': {
            const approvalTask = props.workItem?.tasks?.find(
                (task) => task.taskType === 'ApprovalRequestType' && isOpen(task.status)
            );

            return (
                <StackPanel
                    orientation='horizontal'
                    horizontalAlignment='right'
                    styleOverrides={{ justifyContent: 'space-between' }}>
                    <Button
                        isEnabled={canSave}
                        content='Save'
                        styleName='textOnlyButtonStyle'
                        onClick={async () => {
                            await handleSave();
                        }}
                    />
                    {approvalTask ? (
                        <TaskActions
                            isApproveEnabled={canApprove}
                            task={approvalTask}
                            onCompleteTask={async (task, exitCode, rejectReason) => {
                                setIsLoading(dispatch, true);
                                if (exitCode === 'Reject') {
                                    await props.onCompleteTask(task, exitCode as string, rejectReason);
                                    setIsLoading(dispatch, false);
                                    return;
                                }

                                const canDissolveEntityResponse = await getCanDissolveEntity(entityOId);

                                if (isNil(canDissolveEntityResponse)) {
                                    return;
                                }

                                if (canDissolveEntityResponse?.isAllowed) {
                                    await props.onCompleteTask(task, exitCode as string, rejectReason);
                                    showNoBalancesConfirmationPopup();
                                } else {
                                    handleCantDissolve(canDissolveEntityResponse);
                                }

                                setIsLoading(dispatch, false);
                            }}
                            preSubmit={async () => {
                                await handleSave(true);
                            }}
                            rejectDialogMessage={DISSOLUTION_REJECT_DIALOG_MESSAGE}
                            rejectDialogMessageColor={colors.systemRedColor}
                        />
                    ) : null}
                </StackPanel>
            );
        }
        default:
            return null;
    }
}
