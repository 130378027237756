export {};

declare global {
    interface Date {
        utcToLocaleDateString(noShowSeconds?: boolean): string;
        convertServerDateToLocalDate(): Date;
        utcToLocalDate(): Date;
        convertStrToUtc(): Date;
        getYearMonthDay(): string;
        toUTCYearMonthDay(): string;
    }
}

(function () {
    // eslint-disable-next-line
    Date.prototype.utcToLocaleDateString = function (noShowSeconds?: boolean) {
        return noShowSeconds
            ? this.toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
              })
            : this.toLocaleString();
    };

    // eslint-disable-next-line
    Date.prototype.utcToLocalDate = function () {
        return new Date(this.getTime() + Math.abs(this.getTimezoneOffset() * 60000));
    };

    // eslint-disable-next-line
    Date.prototype.convertStrToUtc = function () {
        let dateToConvert = new Date(this);
        let offset = dateToConvert.getTimezoneOffset() / 60;
        dateToConvert.setHours(dateToConvert.getHours() + offset);
        return dateToConvert;
    };

    // eslint-disable-next-line
    Date.prototype.convertServerDateToLocalDate = function () {
        return new Date(`${this.toString()}.000Z`);
    };

    Date.prototype.getYearMonthDay = function () {
        let dd = this.getDate();
        let mm = this.getMonth() + 1;
        let yy = this.getFullYear();

        return `${mm}/${dd}/${yy}`;
    };

    Date.prototype.toUTCYearMonthDay = function () {
        let dd = this.getUTCDate();
        let mm = (this.getUTCMonth() + 1);
        let yy = this.getUTCFullYear();

        return `${mm}/${dd}/${yy}`;
    };
})();
