import ApiService from 'services/ApiService';
import { constants } from 'constants/env-config';
import { useQuery } from 'react-query';

export type AssociatedDeal = {
    data: {
        entityOId: number;
        primaryName: string;
        mdmEntityOId: string;
    };
};

type SearchResult = {
    searchResults: AssociatedDeal[];
    totalMatchesFound: number;
};

const searchSettings = {
    entitySubtypes: [23],
    numberOfResults: 1,
    fieldsToReturn: ['entityOId', 'primaryName', 'axiomMDMId'],
};

export default function useAssociatedDeal(associatedDealId = '') {
    const fetch = async (id = ''): Promise<AssociatedDeal[]> => {
        if (!id) {
            return Promise.resolve([]);
        }

        const logInfo = {
            service: 'Associated Deal Data Fetch',
            module: 'LegalInformation',
        };

        const searchParams = {
            indexSearchRequest: {
                searchItems: [
                    {
                        queryString: String(id),
                        fuzziness: -1,
                        fields: [
                            {
                                field: 'entityOId',
                                advancedFieldOptions: [
                                    {
                                        subFieldToSearch: 'SearchDefaultField',
                                    },
                                ],
                            },
                        ],
                        fieldSearchType: 'SingleField_MatchTerm',
                        searchAnalyzerType: 'Standard',
                        searchOperator: 'And',
                        searchInclusionType: 'Include',
                        minimumShouldMatch: '100%',
                    },
                ],
                searchFilters: [
                    {
                        field: 'entitySubtypes',
                        value: searchSettings.entitySubtypes,
                    },
                ],
            },
            resultOptions: {
                numberOfResults: searchSettings.numberOfResults,
                fieldsToReturn: searchSettings.fieldsToReturn,
                includeMetaData: false,
            },
        };

        const deals: SearchResult = await ApiService.Post(
            `${constants.ENTITY_MASTER_API}search/search-request/MdmEntity/deals`,
            logInfo,
            searchParams,
            true
        );

        return deals.searchResults;
    };

    return useQuery(['Associated Deals', associatedDealId], () => fetch(associatedDealId), {
        refetchOnWindowFocus: false,
    });
}
