/**
 * A function that resolves after specified milliseconds.
 * @param {number} milliseconds the number of milliseconds to delay by.
 * @returns {Promise} A promise that resolves after the number of milliseconds.
 */
export function delay(milliseconds: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}
