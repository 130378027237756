import { LegalEntityDissolutionLitigationData, LegalEntityDissolutionLitigationProps } from './model';
import React, { useCallback } from 'react';

import { CompletionState } from 'models/LegalEntityRequest/DefaultValues';
import LegalEntityDissolutionLitigation from './LegalEntityDissolutionLitigation';
import { LegalEntityDissolutionLitigationProvider } from 'modules/LegalEntityCreation/context/Provider';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityDissolutionLitigationSection(props: LegalEntityDissolutionLitigationProps) {
    const calculateDefaultValues = useCallback(
        (data?: LegalEntityDissolutionLitigationData) => ({
            noOutstandingLegalMatters: CompletionState[0].Key,
        }),
        []
    );

    return (
        <LegalEntityDissolutionLitigationProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_Litigation}>
            <LegalEntityDissolutionLitigation {...props} />
        </LegalEntityDissolutionLitigationProvider>
    );
}
