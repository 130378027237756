import { InterestTypes, LegalTypes } from 'models/LegalEntityRequest/DefaultValues';
import { VALIDATION_MESSAGES, validateNumberField } from './utils';
import { useCallback, useEffect, useState } from 'react';
import { useLegalEntityOwnerShipDetail, useLegalEntityOwnerShipInformation } from '../context/Provider';

import { OwnershipDetails } from '../LegalEntityOwnershipInformation/model';
import { ValidationErrors } from '../context/model';
import { isNil } from 'lodash';
import { updateValidation } from '../context/actions/form-actions';

type Props = {
    legalType?: number;
    index?: number;
};

export default function useOwnershipEditedDetailValidation({ legalType, index }: Props) {
    const {
        state: {
            data: {
                values: { ownershipDetails = [] },
            },
            version,
        },
    } = useLegalEntityOwnerShipInformation();

    const {
        state: {
            data: { values: editedOwnership },
        },
        dispatch,
    } = useLegalEntityOwnerShipDetail();

    const [validations, setValidations] = useState<ValidationErrors<OwnershipDetails>>({});

    const validateInterestType = useCallback(() => {
        const interestTypes =
            ownershipDetails?.reduce((result: number[], current, itemIndex) => {
                const { isDeleted, interestType } = current;
                return !isDeleted && itemIndex !== index && interestType ? [...result, interestType] : result;
            }, []) ?? [];

        const { interestType } = editedOwnership;

        if (isNil(interestType) && !interestTypes?.includes(SoleMember)) {
            return REQUIRED_FIELD;
        }

        if (interestType) {
            interestTypes.push(interestType);
        }

        switch (legalType) {
            case SCSp:
            case LimitedPartnership: {
                return interestTypes.includes(LimitedPartner) && !interestTypes.includes(GeneralPartner)
                    ? GENERAL_TYPE_REQUIRED
                    : undefined;
            }
            case LimitedLiabilityCompany: {
                const onlySoleMember = interestTypes.length > 1 && interestTypes.includes(SoleMember);
                return onlySoleMember ? INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER : undefined;
            }
        }
    }, [legalType, ownershipDetails, editedOwnership, index]);

    const validateOwnership = useCallback(() => {
        const { ownerShip } = editedOwnership;
        const error = validateNumberField({ value: ownerShip, isRequired: false });
        if (error) {
            return error;
        }
        let totalOwnership =
            ownershipDetails?.reduce((result, { isDeleted, ownerShip }, itemIndex) => {
                return !isDeleted && itemIndex !== index ? result + parseFloat(ownerShip ?? '0') : result;
            }, 0) ?? 0;

        if (editedOwnership?.ownerShip) {
            totalOwnership += parseFloat(editedOwnership.ownerShip);
        }

        if (totalOwnership > 100) {
            return TOTAL_OWNERSHIP_EXCEEDS;
        }
    }, [ownershipDetails, editedOwnership, index]);

    const validateParentEntity = useCallback(() => {
        const { parentEntity: editedParentEntity, interestType: editedInterestType } = editedOwnership;

        if (isNil(editedParentEntity)) {
            return REQUIRED_FIELD;
        }

        const ownershipsWithSameParentEntity = ownershipDetails.filter(
            (details, detailsIndex) =>
                index !== detailsIndex && !details.isDeleted && details.parentEntity === editedParentEntity
        );

        const thereIsSameParentEntityWithDifferentInterest = ownershipsWithSameParentEntity.some(
            (details) => details.interestType !== editedInterestType
        );

        return (editedInterestType !== InterestTypes.Shareholder && ownershipsWithSameParentEntity.length) ||
            (editedInterestType === InterestTypes.Shareholder && thereIsSameParentEntityWithDifferentInterest)
            ? INVALID_UNIQUE_OWNERSHIP_PARENT
            : undefined;
    }, [ownershipDetails, editedOwnership, index]);

    useEffect(() => {
        const { isDeleted, numberOfUnits } = editedOwnership;

        const validationResult = isDeleted
            ? {}
            : {
                  parentEntity: validateParentEntity(),
                  interestType: validateInterestType(),
                  numberOfUnits: validateNumberField({ value: numberOfUnits, isRequired: false }),
                  ownerShip: validateOwnership(),
              };

        setValidations(validationResult);
    }, [
        editedOwnership,
        ownershipDetails,
        version,
        dispatch,
        validateInterestType,
        validateOwnership,
        validateParentEntity,
    ]);

    useEffect(() => {
        updateValidation(dispatch, validations);
    }, [validations, version, dispatch]);

    return { validations };
}

const {
    TOTAL_OWNERSHIP_EXCEEDS,
    REQUIRED_FIELD,
    GENERAL_TYPE_REQUIRED,
    INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER,
    INVALID_UNIQUE_OWNERSHIP_PARENT,
} = VALIDATION_MESSAGES;

const { SoleMember, LimitedPartner, GeneralPartner } = InterestTypes;
const { SCSp, LimitedPartnership, LimitedLiabilityCompany } = LegalTypes;
