import { FontIcon, ThemeColors } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import Text from 'components/Text/Text';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type Props = {
    id: string;
    value: string;
    isActive: boolean;
    onClick: (value: string) => void;
};

export const Checkbox = ({ value, isActive, onClick, ...rest }: Props) => {
    const colors = useColors();
    const id = `column-configuration-${rest.id}`;

    return (
        <div>
            <input
                type='checkbox'
                id={id}
                {...(isActive && { className: 'checked' })}
                defaultChecked={false}
                style={{ display: 'none' }}
            />
            <label htmlFor={id}>
                <Container colors={colors} onClick={() => onClick(value)}>
                    <FontIcon
                        iconName={isActive ? 'CheckboxComposite' : 'Checkbox'}
                        styleOverrides={{ fontSize: 16, fontWeight: 600 }}
                    />
                    <Text variant='body-small'>{value || 'Blank Field'}</Text>
                </Container>
            </label>
        </div>
    );
};

const Container = styled.div<{ colors: ThemeColors }>`
    display: grid;
    grid-auto-flow: column;
    align-items: end;
    grid-gap: ${SPACING.SM}px;
    justify-content: start;
    padding: ${SPACING.SM}px ${SPACING.MD}px;
    background-color: ${({ colors }) => colors.backgroundColor};
    color: ${({ colors }) => colors.foregroundColor};
    &:hover {
        cursor: pointer;
        background-color: ${({ colors }) => colors.groupedBackgroundColor};
    }
`;
