import { AdministeredBy as AdministeredByModel, KeyValueData } from '../../../../../models';
import { AdministeredByTooltip, StructureTypeTooltip } from 'modules/LegalEntityCreation/Shared/Tooltips';
import { BooleanField, DateTimeField, ElasticSearchField, Expander, Select, TextField } from 'components';
import { DoubleColumnLayout, SPACING } from '../../../../../Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';
import { isExternalAdmin, isHedgeFund, isPortfolioCompany } from '../../../utils';

import AssociatedDealSelect from 'components/controls/AssociatedDealSelect/AssociatedDealSelect';
import { EntitySubType } from '../../../../../models/LegalEntity/EntitySubType';
import { FormControl } from '../../../../../components/controls/controls.styled';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { convertToLocalDate } from '../../../../../Utilities/date';
import { shouldHaveSubBusinessUnit } from '../../../utils';
import useInformationValidation from 'modules/LegalEntityCreation/validation/use-information-validation';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from '../../../../../api';

export default function InformationDetails() {
    const {
        state: {
            isEditable,
            data: {
                values: { administeredBy, businessUnitOId, projectEntityOId, isBlueprintEntity },
                storedValues: { associatedDeal },
                noPendingValues,
            },
        },
        getValue,
        setValue,
        setValues,
    } = useLegalEntityInformation();

    useInformationValidation();

    const {
        data: {
            BusinessUnit,
            ExternalAdmin,
            LegalType,
            LegalStatus,
            Portfolio,
            SubBusinessUnit,
            NewStructureType,
            AdministeredBy,
            AdministratorHKAPAC,
            TaxAuditor,
        },
    } = useReferenceData();

    const subBusinessUnits = useMemo(
        () => SubBusinessUnit?.filter(({ ParentId }) => ParentId === `${businessUnitOId}`),
        [SubBusinessUnit, businessUnitOId]
    );

    const structureTypes = useMemo(
        () =>
            businessUnitOId === KeyValueData.BTO
                ? NewStructureType
                : NewStructureType?.filter(({ Key }) => Key !== KeyValueData.Sleeve),
        [NewStructureType, businessUnitOId]
    );

    const isExternalAdminSelected = isExternalAdmin(administeredBy);
    const isPortfolioCompanySelected = isPortfolioCompany(administeredBy);
    const isAdministratorHKAPACEnabled = isBlueprintEntity && administeredBy === AdministeredByModel.Asia;
    const isExternalAdminDropdownEnabled =
        isExternalAdmin(administeredBy) || administeredBy === AdministeredByModel.Asia;

    const isAssociatedDealEnabled = isBlueprintEntity && administeredBy === AdministeredByModel.Asia;

    useEffect(() => {
        if (!isAssociatedDealEnabled) {
            setValue('associatedDeal', undefined);
        }
    }, [isAssociatedDealEnabled, setValue]);

    const onBusinessUnitIdChanged = (value?: number) => {
        setValues({
            businessUnitOId: value,
            subBusinessUnitOId: undefined,
            projectEntityOId: undefined,
            hedgehogID: undefined,
            shortName: undefined,
        });
    };

    const onAdministeredByChanged = (value?: number) => {
        setValues({
            administeredBy: value,
            externalAdminOId: undefined,
            administratorHKAPACOId: undefined,
        });
    };

    const onExternalAdminIdChanged = (value?: number) => {
        setValues({
            externalAdminOId: value,
            externalAdminContact: undefined,
        });
    };

    const onIsBlueprintEntityChanged = (value?: boolean) => {
        setValues({
            isBlueprintEntity: value,
            isOrderChop: value ? false : undefined,
            isOrderSeal: value ? false : undefined,
            remarks: undefined,
            financialYearEndDate: undefined,
            administratorHKAPACOId: undefined,
        });
    };

    const onFinancialYearEndDateChanged = (value?: Date | string | undefined) => {
        setValue('financialYearEndDate', convertToLocalDate(value));
    };

    const isBlueprintPending = useMemo(() => {
        if (isNil(noPendingValues)) return false;

        const dataBlueprint = isBlueprintEntity ?? false;
        const pendingBlueprint = noPendingValues?.isBlueprintEntity ?? false;

        return !isEqual(dataBlueprint, pendingBlueprint);
    }, [isBlueprintEntity, noPendingValues]);

    return (
        <Expander
            header='Legal Entity Details'
            requestStep={RequestStepsView.Legal_Entity_Information}
            content={
                <StackPanel itemGap={SPACING.SM}>
                    <TextField
                        label='Legal Name'
                        labelToolTip="Legal entity's full legal name that should be unique"
                        {...getValue('legalName')}
                        isEditable={false}
                    />
                    {isHedgeFund(businessUnitOId) && (
                        <TextField
                            label='Short Name'
                            labelToolTip='Name used for display purposes'
                            {...getValue('shortName')}
                        />
                    )}
                    <DoubleColumnLayout>
                        <StackPanel itemGap={SPACING.SM}>
                            <Select
                                label='Business Unit'
                                labelToolTip={{ component: "Legal entity's business unit that governs the entity" }}
                                itemsSource={BusinessUnit}
                                isRequired
                                {...getValue('businessUnitOId')}
                                onValueChanged={onBusinessUnitIdChanged}
                            />
                            {!isEmpty(subBusinessUnits) && (
                                <Select
                                    label='Sub-Business Unit'
                                    labelToolTip={{
                                        component:
                                            "Legal entity's sub-business unit if BU = Credit, Hedge Fund Solutions, Real Estate, and General Partners Accounting",
                                    }}
                                    itemsSource={subBusinessUnits}
                                    isRequired={shouldHaveSubBusinessUnit(businessUnitOId)}
                                    {...getValue('subBusinessUnitOId')}
                                />
                            )}
                            <FormControl className='form'>
                                <ElasticSearchField
                                    label='Project'
                                    labelToolTip="Apologies for the inconvenience. 'Project' is currently unavailable. If you need to tag a project to the entity, please contact Data Governance for assistance."
                                    isEnabled={false}
                                    searchType={EntitySubType.Project}
                                    restrictTo={{
                                        businessUnitIds: !businessUnitOId ? undefined : [businessUnitOId],
                                    }}
                                    selectedEntityOId={projectEntityOId}
                                    onEntitySelected={(result) => setValue('projectEntityOId', result?.entityOId)}
                                    isReadOnly={!isEditable || !businessUnitOId}
                                    isPendingChange={
                                        !isEditable &&
                                        !isNil(noPendingValues) &&
                                        !isEqual(projectEntityOId, noPendingValues?.projectEntityOId)
                                    }
                                />
                            </FormControl>
                            <BooleanField
                                label='Is Blueprint Entity'
                                labelToolTip={{
                                    component: 'Indicates whether entity exists in Blueprint. Will create if not',
                                }}
                                {...getValue('isBlueprintEntity')}
                                isPendingChange={isBlueprintPending}
                                onValueChanged={(newValue) => onIsBlueprintEntityChanged(newValue)}
                            />
                            {isBlueprintEntity && (
                                <>
                                    <BooleanField
                                        label='Order chop'
                                        labelToolTip={{
                                            component: 'Has a registered Chop been requested',
                                        }}
                                        {...getValue('isOrderChop')}
                                    />
                                    <BooleanField
                                        label='Order Seal'
                                        labelToolTip={{
                                            component: 'Has a registered Seal been requested',
                                        }}
                                        {...getValue('isOrderSeal')}
                                    />
                                    <TextField
                                        label='Remarks'
                                        labelToolTip='Commentary on Chop or Seal registration and location'
                                        {...getValue('remarks')}
                                    />
                                    <DateTimeField
                                        label='Financial Year End (FYE Date)'
                                        labelToolTip='Ending date of financial year of an entity'
                                        {...getValue('financialYearEndDate')}
                                        onValueChanged={onFinancialYearEndDateChanged}
                                    />
                                </>
                            )}
                        </StackPanel>
                        <StackPanel itemGap={SPACING.SM}>
                            <Select
                                label='Legal Type'
                                labelToolTip={{ component: 'Business legal structure of the entity' }}
                                itemsSource={LegalType}
                                isRequired
                                {...getValue('legalTypeId')}
                            />
                            <Select
                                label='Structure Type'
                                labelToolTip={{ component: StructureTypeTooltip(), options: { showDuration: 60000 } }}
                                itemsSource={structureTypes}
                                isRequired
                                {...getValue('structureType')}
                            />
                            <Select
                                label='Administered / Managed By'
                                labelToolTip={{ component: AdministeredByTooltip() }}
                                itemsSource={AdministeredBy}
                                {...getValue('administeredBy')}
                                isRequired
                                onValueChanged={onAdministeredByChanged}
                            />
                            {isAdministratorHKAPACEnabled && (
                                <Select
                                    label='Administrator - HK APAC'
                                    itemsSource={AdministratorHKAPAC}
                                    {...getValue('administratorHKAPACOId')}
                                />
                            )}
                            {isExternalAdminDropdownEnabled && (
                                <Select
                                    label='External Admin'
                                    labelToolTip={{
                                        component:
                                            'When the entity is managed by an external financial institution and is not a BX Entity',
                                    }}
                                    itemsSource={ExternalAdmin}
                                    {...getValue('externalAdminOId')}
                                    isRequired={isExternalAdminSelected}
                                    onValueChanged={onExternalAdminIdChanged}
                                />
                            )}
                            {isPortfolioCompanySelected && (
                                <Select
                                    label='External Admin'
                                    labelToolTip={{
                                        component:
                                            'When the entity is managed by an external financial institution and is not a BX Entity',
                                    }}
                                    itemsSource={Portfolio}
                                    {...getValue('portfolioId')}
                                />
                            )}
                            {isAssociatedDealEnabled && (
                                <AssociatedDealSelect
                                    label='Associated Deal'
                                    {...getValue('associatedDeal')}
                                    isReadOnly={!isEditable}
                                    storedValue={associatedDeal}
                                />
                            )}
                            <Select
                                label='Tax Auditor'
                                labelToolTip={{ component: 'Select third party tax auditor' }}
                                itemsSource={TaxAuditor}
                                {...getValue('taxAuditorOId')}
                            />
                            <Select
                                label='Legal Status'
                                labelToolTip={{ component: 'Select the legal status for this legal entity.' }}
                                itemsSource={LegalStatus}
                                {...getValue('legalStatusId')}
                            />
                        </StackPanel>
                    </DoubleColumnLayout>
                </StackPanel>
            }
        />
    );
}
