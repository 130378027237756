import { AttributeString, AttributeType, Entity, LegalEntityDTO } from '../../../../models';
import { createOrUpdateStringValue, setIfDefined } from './helpers';

import { DissolutionEntityRelatedData } from 'modules/LegalEntityCreation/LegalEntityDissolution/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): DissolutionEntityRelatedData => {
    const {
        entity: { objectAttributeStrings },
    } = entityDto;

    const stringAttributes =
        objectAttributeStrings?.reduce((prev, curr) => {
            prev[curr.objectAttributeId] = curr.value;
            return prev;
        }, {} as { [attr in AttributeType]?: string }) ?? {};

    return {
        billingOracleNumber: stringAttributes[AttributeType.billingOracleNumber],
        billingProductNumber: stringAttributes[AttributeType.billingProductNumber],
        billingCostCenterNumber: stringAttributes[AttributeType.billingCostCenterNumber],
        billingProjectCode: stringAttributes[AttributeType.billingProjectCode],
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<DissolutionEntityRelatedData>
) => {
    const entity: RecursivePartial<Pick<Entity, 'objectAttributeStrings'>> = {};
    const stringAttributes: Partial<AttributeString>[] = [];

    const createOrUpdateString = (attributeType: AttributeType, value: string | undefined) =>
        createOrUpdateStringValue({ original, stringAttributes, attributeType, value });

    setIfDefined(section, 'billingOracleNumber', (v) => createOrUpdateString(AttributeType.billingOracleNumber, v));
    setIfDefined(section, 'billingProductNumber', (v) => createOrUpdateString(AttributeType.billingProductNumber, v));
    setIfDefined(section, 'billingCostCenterNumber', (v) =>
        createOrUpdateString(AttributeType.billingCostCenterNumber, v)
    );
    setIfDefined(section, 'billingProjectCode', (v) => createOrUpdateString(AttributeType.billingProjectCode, v));

    if (stringAttributes.length > 0) entity.objectAttributeStrings = stringAttributes;

    return { entity };
};
