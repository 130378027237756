import { useEffect, useCallback, RefObject } from 'react';

type Props = {
    ref: RefObject<HTMLElement> | RefObject<HTMLElement>[];
    onClickOutside: () => void;
};

export default function useOnClickOutside({ ref, onClickOutside }: Props) {
    const handleClickOutside = useCallback(
        (event) => {
            if (
                Array.of(ref)
                    .flat()
                    .every((element) => element.current && !element.current.contains(event.target))
            ) {
                onClickOutside();
            }
        },
        [ref, onClickOutside]
    );

    useEffect(() => {
        if (!ref) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ref, handleClickOutside]);
}
