import { Debugger, Expander, FileUpload, Footer, Form } from 'components';
import React, { useMemo } from 'react';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { documentTypes as DocumentTypes } from 'models/shared/refData/NewDocumentType';
import LegalEntityBackground from './components/LegalEntityBackground/LegalEntityBackground';
import LegalEntityDetails from './components/LegalEntityDetails/LegalEntityDetails';
import { LegalEntityRequestProps } from './model';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from '../models';
import { useLegalEntityRequest } from '../context/Provider';
import useRequestValidation from '../validation/use-request-validation';

export default function LegalEntityRequest(props: LegalEntityRequestProps) {
    const { mode, workItem } = props;
    const context = useLegalEntityRequest();
    const {
        state: {
            data: {
                values: { entityOid },
            },
        },
    } = context;

    useRequestValidation();

    const documentTypes = useMemo(() => [DocumentTypes.StructureChart], []);

    return (
        <>
            <Form>
                <Debugger inputState={context.state} requestStep={RequestStepsView.Request} />
                <LegalEntityBackground />
                <LegalEntityDetails />
                <Expander
                    header='Documents'
                    requestStep={RequestStepsView.Request}
                    content={
                        <FileUpload
                            isRequired
                            entityId={entityOid}
                            sectionName={SECTION_NAMES.REQUEST}
                            documentTypes={documentTypes}
                            showStoredDocuments={props.mode !== 'Create'}
                            context={context}
                        />
                    }
                />
                <RejectReason mode={mode} taskType='ReviewDocPrepTaskType' tasks={workItem?.tasks} />
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
