import { Expander, TextField } from 'components';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { StackPanel } from '@bxgrandcentral/controls';

type Props = {
    legalName?: string;
};

export default function EntityDetails({ legalName }: Props) {
    return (
        <Expander
            header='Legal Entity Details'
            content={
                <StackPanel itemGap={SPACING.XL}>
                    <TextField label='Legal Name' isEditable={false} value={legalName} />
                </StackPanel>
            }
        />
    );
}
