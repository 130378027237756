import { FontIcon, StackPanel } from '@bxgrandcentral/controls';
import React, { useMemo } from 'react';
import { updateDashboardFilters, useAppContext } from 'context/app-context';

import { ICellRendererParams } from 'ag-grid-community';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';
import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { useHistory } from 'react-router-dom';
import useNavigation from 'hooks/use-navigation';

interface EntityOIdCellRendererProps extends ICellRendererParams {
    redirectTo: string;
}

export default function EntityOIdCellRenderer({ redirectTo, value, data }: EntityOIdCellRendererProps) {
    const { entityOId, isChild, isParent, hasChildren } = data;

    const {
        state: {
            dashboard: { searchCriteria, collapsedEntities = [], groupByParentEntities, dashboardView, isCtaReview },
        },
        dispatch,
    } = useAppContext();
    const { navigateToRequest } = useNavigation();
    const history = useHistory();

    const colors = useColors();

    const onClick = () => {
        const index = collapsedEntities.indexOf(entityOId);
        let result = [];
        if (index > -1) {
            result = [...collapsedEntities.slice(0, index), ...collapsedEntities.slice(index + 1)];
        } else {
            result = [...collapsedEntities, entityOId];
        }

        updateDashboardFilters(dispatch, {
            collapsedEntities: result,
            searchCriteria,
            groupByParentEntities,
            dashboardView,
            isCtaReview,
        });
    };

    function redirect() {
        redirectTo ? history.push(`${entityOId}/${redirectTo}`) : navigateToRequest(data);
    }

    const shouldAlignLeft = useMemo(
        () => (isParent || !isChild) && groupByParentEntities,
        [isParent, isChild, groupByParentEntities]
    );
    const shouldPaddingLeft = useMemo(
        () => (!isChild && !isParent) || (isParent && !hasChildren),
        [isParent, isChild, hasChildren]
    );

    return (
        <StackPanel orientation='horizontal' verticalAlignment='center' itemGap={SPACING.SM} width='100%'>
            {isParent && hasChildren && groupByParentEntities && (
                <IconWrapper onClick={onClick}>
                    <FontIcon
                        verticalAlignment='center'
                        fontSize={12}
                        iconName={collapsedEntities.includes(entityOId) ? 'ChevronRightMed' : 'ChevronDownMed'}
                    />
                </IconWrapper>
            )}
            <Text
                width='100%'
                styleOverrides={{
                    textAlign: shouldAlignLeft ? 'left' : 'right',
                    paddingLeft: shouldPaddingLeft ? SPACING.XL : 0,
                }}>
                <Button id='cell-entityOId' colors={colors} onClick={() => redirect()}>
                    {value}
                </Button>
            </Text>
        </StackPanel>
    );
}

const IconWrapper = styled.div`
    padding: ${SPACING.XXS}px;
    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
`;

const Button = styled.button<{ colors: ThemeColors }>`
    padding: ${SPACING.XXS}px;
    border: none;
    background: none;
    color: ${({ colors }) => colors.accentColor};
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
