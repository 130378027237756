import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export const DISSOLUTION_LEGAL_STATE = {
    DISSOLUTION_REQUEST: 'Legal Request',
    DISSOLUTION_APPROVAL: 'Legal Approval',
    DISSOLUTION_APPROVED: 'Legal Approved',

    DISSOLUTION_COMPLETE: 'Legal Dissolution Setup Complete',
    SAVE_DATA: 'SaveData',
    DISSOLUTION_WITHDRAWAL: 'Legal Withdrawn',
} as const;
export type DissolutionLegalStateKeyType = keyof typeof DISSOLUTION_LEGAL_STATE;
export type LegalEntityDissolutionLegalState = (typeof DISSOLUTION_LEGAL_STATE)[DissolutionLegalStateKeyType];

type Status = 'InProcess' | 'Complete' | 'Canceled';

export type LegalEntityDissolutionLegalTaskType = 'ApprovalRequestType' | 'SubmitRequestType';
export type LegalEntityDissolutionLegalTask = Task &
    Record<keyof Pick<Task, 'taskType'>, LegalEntityDissolutionLegalTaskType>;
export type LegalEntityDissolutionLegalWorkflow = Omit<WorkItem, 'tasks'> & {
    workflowType: WorkflowType.LegalEntityDissolutionLegalWorkflowType;
    workItemState: LegalEntityDissolutionLegalState;
    workItemStatus: Status;
    tasks: LegalEntityDissolutionLegalTask[] | undefined;
};
