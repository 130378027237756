import { CreateLegalEntityWorkflow } from 'models/LegalEntityRequest/Workflow';
import { Capabilities } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import useHasCapability from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-has-capability';
import { useMemo } from 'react';

export default function useOnlyCtaReviewer(reviewDate?: string | undefined, workItem?: CreateLegalEntityWorkflow | undefined) {
    const hasCapability = useHasCapability();
    
    const isCTAReview = useMemo(() => {
        return (
            !!workItem?.tasks?.some((task) => task.taskType === 'CTANotificationTaskType' && task.status === 'Complete') &&
            !reviewDate &&
            hasCapability(Capabilities.approveCorporateTransparency)
        );
    }, [hasCapability, reviewDate, workItem]);

    return { isOnlyCtaReviewer: isCTAReview };
}
