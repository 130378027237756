export const referenceData = {
    AdministeredBy: 356,
    BusinessDriver: 355,
    BusinessUnit: 6,
    ContactEmail: 357,
    AdministratorHKAPAC: 241,
    ExternalAdmin: 241,
    Portfolio: 241,
    LawFirm: 241,
    TaxPreparer: 241,
    TaxAuditor: 241,
    LegalType: 366,
    SubBusinessUnit: 248,
    StructureType: 33,
    NewStructureType: 377,
    Country: 1,
    State: 13,
    RegisteredAgent: 115,
    PrimaryLedger: 98,
    Investran: 98,
    TreasuryLineOfBusiness: 98,
    OracleLineOfBusiness: 210,
    FATCAClassification: 14,
    CRSClassifications: 107,
    CRSSubClassification: 109,
    CRSSubClassificationDetails: 253,
    USTaxClassificationType: 48,
    USStateTaxClassificationType: 157,
    ChapterThreeStatus: 85,
    SignatoryTitles: 156,
    InterestUnitType: 359,
    InterestClassType: 360,
    ReportingType: 183,
    InterestTypes: 184,
    InvestranFundFamily: 98,
    OracleGSO: 98,
    GPInterestType: 98,
    GPEntityTier: 98,
    InvestranDomain: 244,
    InvestranGPFundFamily: 165,
    ConsolidatingStatusInOracle: 98,
    WssPaymentType: 98,
    OracleLocation: 211,
    TaxCodeType: 30,
    TaxCodeCountry: 132,
    LegalStatus: 361,
    CorporateTransparencyStatus: 98,
    Currency: 4,
    AxiomShareClass: 386,
    RepaymentGroup: 385,
} as const;

export type ReferenceDataType = keyof typeof referenceData;
export type ReferenceDataId = (typeof referenceData)[ReferenceDataType];
export type ReferenceData = Record<ReferenceDataType, KeyValue[] | undefined>;

export const referenceDataIds: ReferenceDataId[] = Object.values(referenceData);
export interface KeyValue {
    Key: number;
    Value: string;
    ShortValue?: string;
    Description?: string;
    ParentId?: string;
    DisplayOrder?: string;
    IsDirect?: boolean;
}
