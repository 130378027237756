import { AuthorizedPersonModel, LegalEntityOwnershipInformationData, OwnershipDetailsModel } from '../model';
import { isEqual, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';

type Props = {
    data: LegalEntityOwnershipInformationData;
    noPendingData?: LegalEntityOwnershipInformationData;
};

export default function useOwnershipWithIsPending({ data, noPendingData }: Props) {
    const isPendingChange = useCallback(
        (newValue?: any, noPendingValue?: any) => {
            return !isNil(noPendingData) ? !isEqual(newValue, noPendingValue) : undefined;
        },
        [noPendingData]
    );

    const hasAuthorizedPersonsPendingChanges = useCallback(
        (authorizedPerson: AuthorizedPersonModel, index: number) => {
            const noPendingValues = noPendingData?.authorizedPerson;

            if (!noPendingValues) {
                return false;
            }

            if (!noPendingValues[index]) {
                return true; // new item
            }

            return (
                isPendingChange(authorizedPerson?.name, noPendingValues[index]?.name) ||
                isPendingChange(authorizedPerson?.title, noPendingValues[index]?.title) ||
                isPendingChange(authorizedPerson?.appointmentDate, noPendingValues[index]?.appointmentDate) ||
                isPendingChange(authorizedPerson?.resignationDate, noPendingValues[index]?.resignationDate)
            );
        },
        [noPendingData, isPendingChange]
    );

    const hasOwnershipDetailsPendingChanges = useCallback(
        (ownershipDetail: OwnershipDetailsModel, index: number) => {
            const noPendingValues = noPendingData?.ownershipDetails;

            if (!noPendingValues) {
                return false;
            }

            if (!noPendingValues[index]) {
                return true; // new item
            }

            return (
                isPendingChange(ownershipDetail?.parentEntity, noPendingValues[index]?.parentEntity) ||
                isPendingChange(ownershipDetail?.interestUnit, noPendingValues[index]?.interestUnit) ||
                isPendingChange(ownershipDetail?.interestClass, noPendingValues[index]?.interestClass) ||
                isPendingChange(ownershipDetail?.numberOfUnits, noPendingValues[index]?.numberOfUnits) ||
                isPendingChange(ownershipDetail?.ownerShip, noPendingValues[index]?.ownerShip) ||
                isPendingChange(ownershipDetail?.reportingType, noPendingValues[index]?.reportingType) ||
                isPendingChange(ownershipDetail?.interestType, noPendingValues[index]?.interestType) ||
                isPendingChange(ownershipDetail?.shareClass, noPendingValues[index]?.shareClass) ||
                isPendingChange(ownershipDetail?.currency, noPendingValues[index]?.currency) ||
                isPendingChange(ownershipDetail?.votingRight, noPendingValues[index]?.votingRight) ||
                isPendingChange(ownershipDetail?.nominalValue, noPendingValues[index]?.nominalValue)
            );
        },
        [noPendingData, isPendingChange]
    );

    const ownerShipWithIsPending = useMemo(() => {
        const dataWithIsPending = { ...data };

        if (data.authorizedPerson) {
            dataWithIsPending.authorizedPerson = data.authorizedPerson.map((authPerson, index) => {
                const item = { ...authPerson };
                item.hasPendingChange = hasAuthorizedPersonsPendingChanges(item, index);

                return item;
            });
        }

        if (data.ownershipDetails) {
            dataWithIsPending.ownershipDetails = data.ownershipDetails.map((ownershipDetail, index) => {
                const item = { ...ownershipDetail };
                item.hasPendingChange = hasOwnershipDetailsPendingChanges(item, index);

                return item;
            });
        }

        return dataWithIsPending;
    }, [data, hasAuthorizedPersonsPendingChanges, hasOwnershipDetailsPendingChanges]);

    return ownerShipWithIsPending;
}
