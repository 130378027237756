import { setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import { Capabilities } from '../RequestStepsView';
import LegalEntityDissolutionFinanceSection from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionFinance/LegalEntityDissolutionFinanceSection';
import React from 'react';
import { handleObjectAttributesForSave } from 'modules/LegalEntityCreation/utils';
import { isEmpty } from 'lodash';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useHasCapability from '../hooks/use-has-capability';

export default function DissolutionFinanceStepRenderer() {
    const { updateDissolutionSection, completeTask, saveSection } = useApiServices();

    const { dispatch: appContextDispatch } = useAppContext();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            legalEntityDissolutionFinanceWorkItem: workItem,
            legalEntityDissolutionParentWorkItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const hasCapability = useHasCapability();

    const dataRaw = sections.DissolutionFinance;
    const noPendingData = noPendingSections.DissolutionFinance;
    const entityRelatedStoredData = sections.Dissolution;

    const data = dataRaw;

    if (isEmpty(data)) {
        return null;
    }

    if (
        !hasCapability(Capabilities.approveDissolutionFinance) ||
        isWorkItemsRefreshing ||
        (!selectedPanel?.hasActions && !editWorkFlow)
    ) {
        return (
            <LegalEntityDissolutionFinanceSection
                data={data}
                mode='Readonly'
                noPendingData={noPendingData}
                workItem={workItem}
            />
        );
    }

    switch (workItem?.workItemState) {
        case 'Finance Approval':
            return (
                <LegalEntityDissolutionFinanceSection
                    mode='Edit'
                    data={data}
                    noPendingData={noPendingData}
                    onSave={(dataToSave) => {
                        const data = {
                            ...sections.DissolutionFinance,
                            ...dataToSave,
                        };
                        return updateDissolutionSection(
                            sections,
                            'DissolutionFinance',
                            legalEntityDissolutionParentWorkItem?.workItemId!,
                            data
                        ).then((e) => e?.workflowId ?? 0);
                    }}
                    onSaveEntityRelatedData={async (changes) => {
                        return saveSection(
                            sections,
                            'DissolutionFinance',
                            handleObjectAttributesForSave(entityRelatedStoredData, changes)
                        ).then((e) => e?.entityOId ?? 0);
                    }}
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        if (workItem) {
                            await completeTask(workItem, task, exitCode, 'DissolutionFinance', rejectReason, {
                                skipRequestNotesUpdate: true,
                            });
                            setResetLegalEntityCreationForm(appContextDispatch, true);
                        }
                    }}
                    workItem={workItem}
                />
            );
        default:
            return (
                <LegalEntityDissolutionFinanceSection
                    data={data}
                    noPendingData={noPendingData}
                    mode='Readonly'
                    workItem={workItem}
                />
            );
    }
}
