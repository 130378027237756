export enum BusinessUnit {
    PrivateEquity = 100000,
    RealEstate = 100001,
    PE = 57087,
    Hedgehog = 100003,
    BIS = 14266842,
    INFRA = 13933972,
    TS = 2214703,
    SP = 2430504,
    BTO = 7869009,
    BXG = 18741817,
    LS = 15715285,
}

export const BUDetails = [
    {
        businessUnitId: 100000,
        businessUnitName: 'Private Equity',
        businessUnitShortName: 'PE',
        apiName: 'private_equity',
        displayIndex: 1,
    },
    {
        businessUnitId: 100001,
        businessUnitName: 'Real Estate',
        businessUnitShortName: 'RE',
        apiName: 'real_estate',
        displayIndex: 2,
    },
    { businessUnitId: 100002, buName: 'Credit', businessUnitShortName: 'CREDIT', apiName: 'credit', displayIndex: 3 },
    {
        businessUnitId: 100003,
        businessUnitName: 'Hedge Fund Solutions',
        businessUnitShortName: 'BAAM',
        apiName: 'hedge_fund_solutions',
        displayIndex: 4,
    },
    { businessUnitId: 100004, buName: 'Other', businessUnitShortName: 'Other', apiName: 'other', displayIndex: 5 },
    {
        sinessUnitbusinessUnitId: 999998,
        buName: 'Corporate',
        businessUnitShortName: 'Corp',
        apiName: 'corporate',
        displayIndex: 6,
    },
    {
        businessUnitId: 999999,
        businessUnitName: 'General Partners Accounting',
        businessUnitShortName: 'GPA',
        apiName: 'general_partners_accounting',
        displayIndex: 7,
    },
    {
        businessUnitId: 2214703,
        buName: 'Treasury Solutions',
        businessUnitShortName: 'TS',
        apiName: 'treasury_solutions',
        displayIndex: 8,
    },
    {
        sinessUnitbusinessUnitId: 2430504,
        buName: 'Strategic Partners',
        businessUnitShortName: 'SP',
        apiName: 'strategic_partners',
        displayIndex: 9,
    },
    {
        sinessUnitbusinessUnitId: 4045337,
        buName: 'Patria',
        businessUnitShortName: 'Patria',
        apiName: 'patria',
        displayIndex: 10,
    },
    {
        businessUnitId: 4545308,
        businessUnitName: 'Private Wealth Management',
        businessUnitShortName: 'PWM',
        apiName: 'private_wealth_management',
        displayIndex: 11,
    },
    {
        businessUnitId: 4599023,
        buName: 'Multi-Strategy',
        businessUnitShortName: 'MS',
        apiName: 'multi-strategy',
        displayIndex: 12,
    },
    {
        sinessUnitbusinessUnitId: 4614188,
        buName: 'Innovations',
        businessUnitShortName: 'BXII',
        apiName: 'innovations',
        displayIndex: 12,
    },
    {
        sinessUnitbusinessUnitId: 5265205,
        buName: 'Endowment',
        businessUnitShortName: 'END',
        apiName: 'endowment',
        displayIndex: 12,
    },
    {
        businessUnitId: 7869009,
        businessUnitName: 'Tactical Opportunities',
        businessUnitShortName: 'BTO',
        apiName: 'tactical_opportunities',
        displayIndex: 12,
    },
    {
        businessUnitId: 6184285,
        businessUnitName: 'Stephen A. Schwarzman',
        businessUnitShortName: 'SAS',
        apiName: 'stephen_a._schwarzman',
        displayIndex: 13,
    },
    {
        businessUnitId: 13933972,
        buName: 'Infrastructure',
        businessUnitShortName: 'INFRA',
        apiName: 'infrastructure',
        displayIndex: 14,
    },
    {
        sinessUnitbusinessUnitId: 13934001,
        buName: 'Harvest',
        businessUnitShortName: 'HARV',
        apiName: 'harvest',
        displayIndex: 15,
    },
    {
        businessUnitId: 14266842,
        businessUnitName: 'Blackstone Insurance Solutions',
        businessUnitShortName: 'BIS',
        apiName: 'blackstone_insurance_solutions',
        displayIndex: 16,
    },
    {
        businessUnitId: 15715285,
        buName: 'Life Sciences',
        businessUnitShortName: 'LS',
        apiName: 'life_sciences',
        displayIndex: 17,
    },
    {
        sinessUnitbusinessUnitId: 18741817,
        buName: 'Growth Equity',
        businessUnitShortName: 'BXG',
        apiName: 'growth_equity',
        displayIndex: 18,
    },
    {
        businessUnitId: 29710353,
        businessUnitName: 'BX Private Equity Strategies',
        businessUnitShortName: 'BXPE',
        apiName: 'bx_private_equity_strategies',
        displayIndex: 19,
    },
];
