import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export type LegalEntityDissolutionFinanceTaskType = 'ApprovalRequestType' | 'SubmitRequestType';

export const DISSOLUTION_FINANCE_STATE = {
    DISSOLUTION_REQUEST: 'Finance Request',
    DISSOLUTION_APPROVAL: 'Finance Approval',
    DISSOLUTION_APPROVED: 'Finance Approved',
    DISSOLUTION_COMPLETE: 'Finance Dissolution Setup Complete',
    SAVE_DATA: 'SaveData',
    DISSOLUTION_WITHDRAWAL: 'Finance Withdrawn',
} as const;

export type DissolutionFinanceStateKeyType = keyof typeof DISSOLUTION_FINANCE_STATE;
export type LegalEntityDissolutionFinanceState = (typeof DISSOLUTION_FINANCE_STATE)[DissolutionFinanceStateKeyType];

type LegalEntityDissolutionFinanceStatus = 'InProcess' | 'Complete' | 'Canceled';
export type LegalEntityDissolutionFinanceTask = Task &
    Record<keyof Pick<Task, 'taskType'>, LegalEntityDissolutionFinanceTaskType>;
export type LegalEntityDissolutionFinanceWorkflow = Omit<WorkItem, 'tasks'> & {
    workflowType: WorkflowType.LegalEntityDissolutionFinanceWorkflowType;
    workItemState: LegalEntityDissolutionFinanceState;
    workItemStatus: LegalEntityDissolutionFinanceStatus;
    tasks: LegalEntityDissolutionFinanceTask[] | undefined;
};
