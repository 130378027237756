import React, { useMemo } from 'react';
import { StackPanel, TextBlock, ThemeManager, ToolBarButton } from '@bxgrandcentral/controls';
import { isNull, isUndefined } from 'lodash';

import DateTimeField from 'components/controls/DateTimeField/DateTimeField';
import { DocumentTypesData } from 'models/shared/refData/DocumentType';
import { FileLayout } from '../FileUpload.styled';
import { FileListProps } from '../models';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SPACING } from 'Utilities/Layout';
import { VALIDATION_MESSAGES } from 'modules/LegalEntityCreation/validation/utils';
import { getSectionName } from '../utils';

type Props = FileListProps & {
    index: number;
    hideDocumentType?: boolean;
};

export default function StoredDocument({
    index,
    documents,
    useEffectiveDatePlaceholder,
    documentApproveDate,
    isStampedDocumentUpload,
    isEditable,
    onDocumentStateChanged,
    hideEffectiveDate,
    hideDocumentType,
}: Props) {
    const {
        document: {
            documentElements,
            documentOId: { value },
            originalFileName,
            coreDocumentTypeId,
            effectiveDateString,
            createdDateUTC,
        },
        updates: { isDeleted, sectionName: updatedSectionName, effectiveDate: updatedEffectiveDate },
    } = documents[index];

    const {
        activeTheme: {
            colors: { accentColor, foregroundColor, secondaryForegroundColor, disabledColor },
        },
    } = ThemeManager;

    const hasAnyDocumentEffectiveDate = useMemo(() => {
        if (hideEffectiveDate) {
            return false;
        }

        return (
            documents.some(({ document: { effectiveDateString } }) => effectiveDateString) ||
            useEffectiveDatePlaceholder
        );
    }, [documents, useEffectiveDatePlaceholder, hideEffectiveDate]);

    const isApprovedDocument = useMemo(
        () => (documentApproveDate ? documentApproveDate > createdDateUTC : false),
        [createdDateUTC, documentApproveDate]
    );

    const sectionName = updatedSectionName ?? getSectionName(documentElements);
    const isStampedDocument = sectionName === SECTION_NAMES.STAMPED;

    const effectiveDateStringValue = useMemo(() => {
        if (updatedEffectiveDate === null) {
            return undefined;
        }
        return updatedEffectiveDate || effectiveDateString;
    }, [updatedEffectiveDate, effectiveDateString]);

    const validationError = useMemo(() => {
        const date = updatedEffectiveDate ? updatedEffectiveDate : effectiveDateString;
        if (date > new Date()) {
            return VALIDATION_MESSAGES.INVALID_FUTURE_DATE;
        }
        if (isStampedDocument) {
            if (isNull(updatedEffectiveDate)) {
                return VALIDATION_MESSAGES.REQUIRED_FIELD;
            }
            return isUndefined(updatedEffectiveDate) && !effectiveDateString ? VALIDATION_MESSAGES.REQUIRED_FIELD : '';
        }
    }, [effectiveDateString, isStampedDocument, updatedEffectiveDate]);

    return (
        <>
            <FileLayout hasEffectiveDate={hasAnyDocumentEffectiveDate} isEditable={isEditable}>
                <TextBlock
                    text={originalFileName}
                    foregroundColor={isDeleted ? disabledColor : foregroundColor}
                    styleName='bodyTextStyle'
                    fontSize={14}
                    padding={`${SPACING.XS}px 0`}
                    styleOverrides={{ alignSelf: 'flex-start' }}
                />
                <StackPanel orientation='horizontal'>
                    {!hideDocumentType && (
                        <TextBlock
                            text={DocumentTypesData.find(({ Key }) => Key === coreDocumentTypeId)?.Value}
                            foregroundColor={isDeleted ? disabledColor : secondaryForegroundColor}
                            styleName='bodyTextStyle'
                            fontSize={13}
                            horizontalAlignment='right'
                            textAlignment='right'
                            padding={`${SPACING.XS}px 0`}
                            styleOverrides={{ alignSelf: 'flex-start', width: '100%' }}
                        />
                    )}
                </StackPanel>
                {hasAnyDocumentEffectiveDate && (
                    <>
                        {isEditable && !isDeleted && isStampedDocumentUpload ? (
                            <DateTimeField
                                variant='default'
                                value={effectiveDateStringValue}
                                isEditable={isEditable}
                                isRequired={isStampedDocument}
                                onValueChanged={(newValue = '') => {
                                    onDocumentStateChanged(value, {
                                        effectiveDate: newValue ? new Date(newValue).utcToLocalDate() : null,
                                    });
                                }}
                                validationError={validationError}
                            />
                        ) : (
                            <TextBlock
                                text={effectiveDateString ? new Date(effectiveDateString).toDateString() : ''}
                                foregroundColor={isDeleted ? disabledColor : secondaryForegroundColor}
                                fontSize={13}
                                styleName='bodyTextStyle'
                                verticalAlignment='center'
                                horizontalAlignment='right'
                                textAlignment='right'
                            />
                        )}
                    </>
                )}
            </FileLayout>
            {isEditable && !isApprovedDocument && (
                <ToolBarButton
                    icon={isDeleted ? 'Undo' : 'Delete'}
                    toolTip={isDeleted ? 'Click to revert attachment' : 'Click to delete attachment'}
                    padding={`${SPACING.XS}px 0`}
                    styleOverrides={{ alignSelf: 'flex-start' }}
                    fontSize={18}
                    foregroundColor={accentColor}
                    onClick={() => onDocumentStateChanged(value, { isDeleted: !isDeleted })}
                />
            )}
        </>
    );
}
