import { Grid, ModuleContainer, StackPanel, TextBlock, ThemeManager } from '@bxgrandcentral/controls';
import { QueryPanel, Select } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { updateDashboardFilters, useAppContext } from 'context/app-context';

import { CREATE_ENTITY_STATE } from 'models/LegalEntityRequest/Workflow';
import CreateRequestDialog from './CreateRequestDialog';
import { DashboardViewOptions } from './models';
import { LegalEntityRequest } from '../../models';
import RequestsDashboardGridNew from './RequestsDashboardGridNew';
import RequestsDashboardGridOld from './RequestDashboardGridOld';
import { SPACING } from 'Utilities/Layout';
import { useLegalEntities } from 'api';
import useNavigation from '../../hooks/use-navigation';

export default function RequestDashboard() {
    const [selectedRequest, setSelectedRequest] = useState<LegalEntityRequest>();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState<LegalEntityRequest[] | undefined>(undefined);

    const { data, isLoading, refetch, isFetching, isError } = useLegalEntities();

    const { navigateToRequest } = useNavigation();

    const {
        state: {
            settings: { isNewDashboardActive },
            dashboard: { dashboardView, searchCriteria, collapsedEntities, groupByParentEntities, isCtaReview },
        },
        dispatch,
    } = useAppContext();

    const {
        activeTheme: {
            colors: { backgroundColor },
        },
    } = ThemeManager;

    const onItemDoubleClicked = useCallback(() => {
        navigateToRequest(selectedRequest);
    }, [navigateToRequest, selectedRequest]);

    const filterCtaReview = useCallback(
        (data: LegalEntityRequest[]) => {
            if (!isCtaReview) {
                return data;
            }
            return data.filter(
                ({ isCTAReview, reviewDate }) =>
                    isCTAReview && !reviewDate
            );
        },
        [isCtaReview]
    );

    useEffect(() => {
        if (data) {
            setDashboardData(isCtaReview ? filterCtaReview(data) : data);
        }
    }, [data, filterCtaReview, isCtaReview]);

    return (
        <>
            <Grid>
                <ModuleContainer
                    backgroundColor={backgroundColor}
                    toolBarPanelContent={
                        <Grid columnDefinitions='* auto' rowDefinitions={'48px'} padding={`0 ${SPACING.MD}px 0 0`}>
                            <StackPanel
                                itemGap={SPACING.XXS}
                                padding={'0 0 0 400px'}
                                orientation='horizontal'
                                verticalAlignment='center'
                                horizontalAlignment='center'>
                                <TextBlock textAlignment='center' fontSize={18} textWrapping='wrap'>
                                    Entity Requests
                                </TextBlock>
                            </StackPanel>
                            <StackPanel
                                orientation='horizontal'
                                verticalAlignment='center'
                                horizontalAlignment='right'
                                {...((isLoading || isFetching) && { opacity: 0.5 })}
                                styleOverrides={{ justifyContent: 'right' }}
                                width={400}
                                height={48}>
                                <TextBlock
                                    verticalAlignment='center'
                                    fontSize={13}
                                    textWrapping='wrap'
                                    toolTip={
                                        'Select different dashboard views to filter to all, open, and closed requests'
                                    }
                                    padding={`0 ${SPACING.XS}px`}>
                                    Dashboard view:
                                </TextBlock>
                                <StackPanel styleOverrides={{ alignSelf: 'center' }}>
                                    <Select
                                        canResetValue={false}
                                        canUndo={false}
                                        canClearSelection={false}
                                        width={210}
                                        height={32}
                                        value={dashboardView}
                                        isEditable={true}
                                        isReadOnly={isLoading || isFetching}
                                        itemsSource={DashboardViewOptions}
                                        onValueChanged={(newValue) => {
                                            updateDashboardFilters(dispatch, {
                                                collapsedEntities,
                                                searchCriteria,
                                                groupByParentEntities,
                                                dashboardView: newValue,
                                                isCtaReview,
                                            });
                                        }}
                                    />
                                </StackPanel>
                            </StackPanel>
                        </Grid>
                    }>
                    {isNewDashboardActive ? (
                        <RequestsDashboardGridNew
                            data={dashboardData}
                            onCellClick={setSelectedRequest}
                            onRefresh={refetch}
                            isLoading={isLoading || isFetching}
                            isError={isError}
                        />
                    ) : (
                        <RequestsDashboardGridOld
                            data={dashboardData}
                            onSelectionChanged={setSelectedRequest}
                            onItemDoubleClicked={onItemDoubleClicked}
                            onRefresh={refetch}
                            isLoading={isLoading}
                            isFetching={isFetching}
                        />
                    )}
                </ModuleContainer>
                <CreateRequestDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
            </Grid>
            <QueryPanel />
        </>
    );
}
