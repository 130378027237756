import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionTaxData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTax/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionTaxData => {
    const { dissolutionData, entity } = entityDto;

    return {
        entityOid: entity.entityOId,
        allTaxRelated: dissolutionData?.dissolutionTax?.allTaxRelated,
        taxInvoicesPreBilled: dissolutionData?.dissolutionTax?.taxInvoicesPreBilled,
        allTaxReporting: dissolutionData?.dissolutionTax?.allTaxReporting,
        deregistrationAndNotification: dissolutionData?.dissolutionTax?.deregistrationAndNotification,
        taxAndLiquidationSteps: dissolutionData?.dissolutionTax?.taxAndLiquidationSteps,
        approverNotes: dissolutionData?.dissolutionTax?.approverNotes,
        savedBy: dissolutionData?.dissolutionTax?.savedBy,
        savedAt: dissolutionData?.dissolutionTax?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionTaxData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;

    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionTax: {
            ...original?.dissolutionData?.dissolutionTax,
            ...section,
        },
    };

    return toReturn;
};
