import {
    DISSOLUTION_FINANCE_STATE,
    DISSOLUTION_LEGAL_STATE,
    DISSOLUTION_LITIGATION_STATE,
    DISSOLUTION_STATE,
    DISSOLUTION_TAX_STATE,
    DISSOLUTION_TREASURY_STATE,
    LegalEntityDissolutionFinanceState,
    LegalEntityDissolutionLegalState,
    LegalEntityDissolutionLitigationState,
    LegalEntityDissolutionTaxState,
    LegalEntityDissolutionTreasuryState,
} from 'models/LegalEntityRequest/Workflow';
import { useEffect, useMemo } from 'react';

import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';

export default function useDissolutionIsAllChildrenApproved() {
    const { reloadEntityAndWorkItems } = useApiServices();
    const {
        state: {
            legalEntityDissolutionTreasuryWorkItem: { workItemState: treasuryWorkItemState } = {
                workItemState: undefined,
            },
            legalEntityDissolutionLitigationWorkItem: { workItemState: litigationWorkItemState } = {
                workItemState: undefined,
            },
            legalEntityDissolutionFinanceWorkItem: { workItemState: financeWorkItemState } = {
                workItemState: undefined,
            },
            legalEntityDissolutionLegalWorkItem: { workItemState: legalWorkItemState } = {
                workItemState: undefined,
            },
            legalEntityDissolutionTaxWorkItem: { workItemState: taxWorkItemState } = {
                workItemState: undefined,
            },
            legalEntityDissolutionParentWorkItem,
        },
    } = useCreationViewContext();
    const isAllDissolutionChildStepsCompleted = useMemo(() => {
        return (
            (
                [
                    DISSOLUTION_LITIGATION_STATE.DISSOLUTION_APPROVED,
                    DISSOLUTION_LITIGATION_STATE.DISSOLUTION_COMPLETE,
                ] as (LegalEntityDissolutionLitigationState | undefined)[]
            ).includes(litigationWorkItemState) &&
            (
                [DISSOLUTION_TAX_STATE.DISSOLUTION_APPROVED, DISSOLUTION_TAX_STATE.DISSOLUTION_COMPLETE] as (
                    | LegalEntityDissolutionTaxState
                    | undefined
                )[]
            ).includes(taxWorkItemState) &&
            (
                [DISSOLUTION_FINANCE_STATE.DISSOLUTION_APPROVED, DISSOLUTION_FINANCE_STATE.DISSOLUTION_COMPLETE] as (
                    | LegalEntityDissolutionFinanceState
                    | undefined
                )[]
            ).includes(financeWorkItemState) &&
            (
                [DISSOLUTION_TREASURY_STATE.DISSOLUTION_APPROVED, DISSOLUTION_TREASURY_STATE.DISSOLUTION_COMPLETE] as (
                    | LegalEntityDissolutionTreasuryState
                    | undefined
                )[]
            ).includes(treasuryWorkItemState) &&
            (
                [DISSOLUTION_LEGAL_STATE.DISSOLUTION_APPROVED, DISSOLUTION_LEGAL_STATE.DISSOLUTION_COMPLETE] as (
                    | LegalEntityDissolutionLegalState
                    | undefined
                )[]
            ).includes(legalWorkItemState)
        );
    }, [litigationWorkItemState, taxWorkItemState, financeWorkItemState, treasuryWorkItemState, legalWorkItemState]);

    useEffect(() => {
        if (
            isAllDissolutionChildStepsCompleted &&
            legalEntityDissolutionParentWorkItem?.workItemState === DISSOLUTION_STATE.AWAITING_FOR_CHILD_APPROVAL
        ) {
            reloadEntityAndWorkItems();
        }
    }, [isAllDissolutionChildStepsCompleted, legalEntityDissolutionParentWorkItem, reloadEntityAndWorkItems]);

    return isAllDissolutionChildStepsCompleted;
}
