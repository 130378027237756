import { setIsWorkItemsRefreshing, useCreationViewContext } from '../context/creation-view-context';

import { LegalEntityRequestPaths } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function useReloadPage() {
    const { editLegalEntityRequestPattern, editLegalEntityRequest } = LegalEntityRequestPaths;
    const { dispatch } = useCreationViewContext();
    const historyApi = useHistory();

    const reloadPage = useCallback(
        async (legalEntityId: number, delay: number, section?: string, isWIRefreshing?: boolean): Promise<void> => {
            setIsWorkItemsRefreshing(dispatch, isWIRefreshing ?? true);

            return new Promise((resolve) => {
                setTimeout(() => {
                    historyApi.push(
                        editLegalEntityRequest(
                            `${editLegalEntityRequestPattern}/${section?.replace(/\s/g, '') ?? ''}`,
                            {
                                legalEntityId: legalEntityId.toString(),
                            }
                        )
                    );
                    resolve();
                }, delay);
            });
        },
        [dispatch, editLegalEntityRequestPattern, editLegalEntityRequest, historyApi]
    );

    return reloadPage;
}
