import { ColDef } from 'ag-grid-community';
import DissolveCellRenderer from 'modules/RequestsDashboard/components/Grid/components/DissolveCellRenderer';
import EditCellRenderer from 'modules/RequestsDashboard/components/Grid/components/EditCellRenderer';
import EntityOIdCellRenderer from 'modules/RequestsDashboard/components/Grid/components/EntityOIdCellRenderer';
import { SearchEntity } from 'models';
import { constants } from 'constants/env-config';

export const matchLegalEntitiesColumnDefs: ColDef[] = [
    {
        field: 'edit',
        headerName: 'Edit',
        suppressMovable: true,
        filter: false,
        resizable: false,
        minWidth: 70,
        maxWidth: 70,
        cellRenderer: EditCellRenderer,
    },
    {
        field: 'entityOId',
        headerName: 'Entity ID',
        suppressMovable: true,
        filter: false,
        resizable: false,
        minWidth: 120,
        maxWidth: 120,
        sort: 'desc',
        sortable: true,
        cellRenderer: EntityOIdCellRenderer,
        cellRendererParams: {
            redirectTo: 'LegalEntityInformation',
        },
    },
    {
        field: 'mdmEntityOId',
        headerName: 'Legacy ID',
        suppressMovable: true,
        filter: false,
        resizable: false,
        minWidth: 100,
        maxWidth: 100,
        sortable: false,
        cellClass: 'cell-mdmEntityOId',
    },
    { field: 'primaryName', headerName: 'Entity Name', cellClass: 'cell-primaryName', sortable: true, resizable: true },
    {
        field: 'shortName',
        headerName: 'Entity Short Name',
        cellClass: 'cell-shortName',
        sortable: true,
        resizable: true,
    },
    {
        field: 'businessUnits',
        headerName: 'Business Unit',
        cellClass: 'cell-businessUnit',
        sortable: true,
        resizable: true,
    },
    {
        field: 'subBusinessUnits',
        headerName: 'Sub-Business Unit',
        cellClass: 'cell-subBusinessUnit',
        sortable: true,
        resizable: true,
    },
    {
        field: 'hqAddress',
        headerName: 'Headquarter Address',
        sortable: true,
        resizable: true,
        cellClass: 'cell-hqAddress',
    },
];

if (constants.IS_DISSOLUTION_ENABLED === 'true') {
    matchLegalEntitiesColumnDefs.splice(1, 0, {
        field: 'Dissolve',
        headerName: 'Dissolve',
        suppressMovable: true,
        filter: false,
        resizable: false,
        minWidth: 90,
        maxWidth: 90,
        cellRenderer: DissolveCellRenderer,
    });
}

export type SearchResultItem = Pick<
    SearchEntity,
    | 'entityOId'
    | 'primaryName'
    | 'shortName'
    | 'businessUnits'
    | 'subBusinessUnits'
    | 'hqAddress'
    | 'hqAddressLine1'
    | 'hqAddressLine2'
    | 'hqAddressLine3'
    | 'hqAddressLine4'
    | 'hqAddressCity'
    | 'hqAddressState'
    | 'hqAddressCountry'
    | 'hqAddressZipCode'
    | 'businessUnitIds'
    | 'subBusinessUnitIds'
>;
