import { LegalEntityApprovalData, LegalEntityApprovalProps } from './model';
import React, { useCallback } from 'react';
import { isExternalAdminOrPortfolioCompany, isStructureTypeRequiresBankAccount } from './utils';

import LegalEntityApproval from './LegalEntityApproval';
import { LegalEntityApprovalProvider } from '../context/Provider';

export default function LegalEntityApprovalSection(props: LegalEntityApprovalProps) {
    const calculateDefaultValues = useCallback(
        (data?: LegalEntityApprovalData) => ({
            ownershipInfoRequired: true,
            EINAndGIINCreationRequired: true,
            taxClassificationRequired: true,
            consolidationRequired: !isExternalAdminOrPortfolioCompany(data?.administeredBy),
            financeInformationRequired: !isExternalAdminOrPortfolioCompany(data?.administeredBy),
            bankAccountRequired:
                isStructureTypeRequiresBankAccount(data?.structureType, data?.isOldStructureType) &&
                !isExternalAdminOrPortfolioCompany(data?.administeredBy),
        }),
        []
    );

    return (
        <LegalEntityApprovalProvider
            storedValues={props.data}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}>
            <LegalEntityApproval {...props} />
        </LegalEntityApprovalProvider>
    );
}
