import { IModule } from '@bxgrandcentral/shell';
import { LegalEntityRequestPaths } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { NavigationButton } from 'components';
import React from 'react';
import { RequestsDashboardModule } from '../modules/RequestsDashboard/RequestsDashboardModule';

export const modules: IModule[] = [
    {
        icon: <NavigationButton iconName='Home' id='side-bar-nav-home' />,
        displayName: 'Dashboard',
        routingPath: '/*',
        component: RequestsDashboardModule,
        toolTip: 'Pending Requests Dashboard',
    },
    {
        icon: (
            <NavigationButton
                iconName='Add'
                id='side-bar-nav-add'
                path={LegalEntityRequestPaths.newLegalEntityRequest}
            />
        ),
        displayName: 'Create New Request',
        routingPath: `${LegalEntityRequestPaths.newLegalEntityRequest}`,
        toolTip: 'Create new request',
    },
    {
        icon: <NavigationButton iconName='Search' id='side-bar-nav-search' path={LegalEntityRequestPaths.search} />,
        displayName: 'Search Entity Information',
        routingPath: `${LegalEntityRequestPaths.search}`,
        toolTip: 'Search by Legal Entity Information',
    },
];
