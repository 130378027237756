import {
    DateTimeFieldProps,
    DateTimeField as GrandCentralDateTimeField,
    Grid,
    TextBlock,
} from '@bxgrandcentral/controls';
import React, { useMemo } from 'react';

import { FormControl } from '../controls.styled';
import { SPACING } from 'Utilities/Layout';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import useColors from 'api/hooks/use-theme';

type Props = {
    height?: number;
    validationError?: string;
    validationWarning?: string;
    version?: number;
    labelToolTip?: any;
    isPendingChange?: boolean;
    variant?: 'form' | 'dashboard' | 'default';
    onValueChanged: (newValue?: string | Date) => void;
};
export default function DateTimeField({
    isEditable,
    isRequired,
    validationError,
    validationWarning,
    value,
    label,
    version,
    labelToolTip,
    variant = 'form',
    isPendingChange,
    height,
    onValueChanged,
    ...rest
}: DateTimeFieldProps & Props) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const key = useMemo(() => Math.random(), [isEditable, rest.defaultValue]);

    const { accentColor } = useColors();

    return (
        <FormControl height={height} className={variant}>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <TextBlock
                    styleName='fieldLabelStyle'
                    toolTip={labelToolTip}
                    text={label}
                    margin={`0 0 ${SPACING.XXS}px 0`}
                />
                {!isEditable && isPendingChange && (
                    <>
                        <TextBlock
                            styleName='fieldLabelStyle'
                            fontSize='16px'
                            text='PENDING'
                            foregroundColor={accentColor}
                            textAlignment='right'
                        />
                    </>
                )}
            </Grid>
            <GrandCentralDateTimeField
                key={key}
                iconName='Calendar'
                isEditable={isEditable}
                isRequired={isRequired && isEditable}
                canResetValue
                value={value ?? ''}
                onValueChanged={(newValue) =>
                    onValueChanged(
                        newValue && value && new Date(newValue).toDateString() === new Date(value).toDateString()
                            ? undefined
                            : newValue
                    )
                }
                {...rest}
            />
            {isEditable && <ValidationMessage message={validationError} />}
            {isEditable && !validationError && <ValidationMessage variant='warning' message={validationWarning} />}
        </FormControl>
    );
}
