import { DocumentSection, DocumentSections } from '../modules/LegalEntityCreation/context/model';
import { isNil, isNull, isUndefined } from 'lodash';

import { GlobalState } from '../GlobalState';
import { IDocumentService } from '../services/interfaces/IDocumentService';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { isEmpty } from 'Utilities/Validations';
import { useCallback } from 'react';

export default function useDocumentUpload() {
    const service = ServiceLocator.container.resolve(IDocumentService);

    const savePendingDocuments = useCallback(
        async (sections: DocumentSections, entityId?: number) => {
            const documents = Object.values(sections)
                ?.map(({ pendingDocuments = [] }) => pendingDocuments)
                .flat();

            if (!isEmpty(documents) && entityId) {
                try {
                    await Promise.all(
                        documents.map(
                            ({ documentData, sectionName, fileName, documentTypeId, taxRegistration, effectiveDate }) =>
                                service.UploadFile(
                                    entityId,
                                    new File([documentData], fileName, { type: documentData.type }),
                                    sectionName,
                                    documentTypeId,
                                    taxRegistration,
                                    effectiveDate
                                )
                        )
                    );
                } catch (error) {
                    GlobalState.ShowMessageBox(
                        'ERROR',
                        'An error occurred updating documents. Please contact the System Administrator to review your permissions.'
                    );
                    return Promise.reject('File upload failed');
                }
            }
        },
        [service]
    );

    const withdrawDocuments = useCallback(
        async (section: DocumentSection, entityId?: number) => {
            const { storedDocuments = [] } = section;

            if (entityId && storedDocuments.length) {
                await Promise.all(
                    storedDocuments.map(async (document) => {
                        const {
                            document: {
                                documentOId: { value },
                            },
                        } = document;
                        try {
                            await service.DeleteFile(entityId, value);
                        } catch {
                            GlobalState.ShowMessageBox(
                                'ERROR',
                                'An error occurred updating documents. Please contact the System Administrator to review your permissions.'
                            );
                            return Promise.reject('Filed to delete file');
                        }
                        return Promise.resolve();
                    })
                );
            }
        },
        [service]
    );

    const updateStoredDocuments = useCallback(
        async (sections: DocumentSections, entityId?: number) => {
            const documents = Object.values(sections)
                .map(({ storedDocuments = [] }) => storedDocuments)
                .flat();
            if (!isEmpty(documents) && entityId) {
                await Promise.all(
                    documents.map(async (document) => {
                        const {
                            document: {
                                effectiveDateString,
                                documentOId: { value },
                            },
                            updates: { isDeleted, sectionName, effectiveDate },
                        } = document;
                        try {
                            if (isDeleted) {
                                return await service.DeleteFile(entityId, value);
                            } else if (
                                !isNil(sectionName) ||
                                !isUndefined(effectiveDate) ||
                                (isNull(effectiveDate) && !!effectiveDateString)
                            ) {
                                return await service.UpdateDocumentElements(document);
                            }
                        } catch {
                            GlobalState.ShowMessageBox(
                                'ERROR',
                                'An error occurred updating documents. Please contact the System Administrator to review your permissions.'
                            );
                            return Promise.reject('Filed to delete file');
                        }
                        return Promise.resolve();
                    })
                );
            }
        },
        [service]
    );

    const updateDocuments = useCallback(
        async (sections: DocumentSections, entityId?: number) => {
            await savePendingDocuments(sections, entityId);
            await updateStoredDocuments(sections, entityId);
        },
        [updateStoredDocuments, savePendingDocuments]
    );

    return { updateDocuments, updateStoredDocuments, withdrawDocuments };
}
