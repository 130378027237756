import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { useMemo } from 'react';
import { isEmpty, isNull } from 'lodash';
import { setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { ForeignRegistrationTask } from 'models/LegalEntityRequest/Workflow';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { Task } from 'models/Workflow';
import { TaskActions } from 'modules/LegalEntityCreation/Shared/TaskActions';
import { getSectionName } from 'components/controls/FileUpload/utils';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityRegistration } from 'modules/LegalEntityCreation/context/Provider';
import { validateDateTimeField } from 'modules/LegalEntityCreation/validation/utils';

type Props = {
    task?: ForeignRegistrationTask;
    onCompleteTask?: (task: Task, exitCode?: string, rejectReason?: string) => void;
    mode: 'Readonly' | 'Edit' | 'Approval';
    onSave?: any;
};

export default function ActionPanel({ mode = 'Readonly', task, onCompleteTask, onSave }: any) {
    const { state, dispatch } = useLegalEntityRegistration();

    const {
        data: {
            values: { entityOid, key },
            changes,
        },
        documents: { sections },
        canSave,
        canSubmit,
    } = state;

    const { storedDocuments, isFetching: isDocumentsFetching } = sections[SECTION_NAMES.FOREIGN_REGISTRATION] ?? {};

    const isValidStoredDocuments = useMemo(
        () =>
            storedDocuments
                ? storedDocuments.every(
                      ({
                          document: { effectiveDateString, documentElements },
                          updates: { effectiveDate, sectionName },
                      }) => {
                          const section = sectionName ?? getSectionName(documentElements);
                          const effectiveDateValue =
                              effectiveDate || isNull(effectiveDate) ? effectiveDate : effectiveDateString || undefined;

                          return section === SECTION_NAMES.STAMPED
                              ? !validateDateTimeField(effectiveDateValue, {
                                    isOptionalWhen: false,
                                    canBeFutureDate: false,
                                })
                              : true;
                      }
                  )
                : true,
        [storedDocuments]
    );

    const { updateDocuments } = useDocumentUpload();

    const handleSave = async () => {
        setIsLoading(dispatch, true);
        try {
            await updateDocuments(sections, entityOid);
            if (!isEmpty(changes)) {
                await onSave({ key, ...changes });
            }
            setShouldReload(dispatch, true);
        } catch (reason) {
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (mode === 'Readonly') {
        return null;
    }

    return (
        <StackPanel
            orientation='horizontal'
            horizontalAlignment='right'
            {...(mode === 'Edit' && { width: '100%' })}
            styleOverrides={{ justifyContent: 'space-between' }}>
            {mode === 'Edit' && (
                <Button
                    content='Save'
                    styleName='textOnlyButtonStyle'
                    isEnabled={canSave}
                    onClick={async () => {
                        await handleSave();
                    }}
                />
            )}
            {task && onCompleteTask && (
                <TaskActions
                    task={task}
                    onCompleteTask={(task, exitCode, rejectReason) => {
                        onCompleteTask(task, exitCode as string, rejectReason);
                        setShouldReload(dispatch, true);
                    }}
                    preSubmit={async () => {
                        await handleSave();
                    }}
                    isSubmitEnabled={canSubmit && !isDocumentsFetching && isValidStoredDocuments}
                />
            )}
        </StackPanel>
    );
}
