import { BackDrop, DialogComponent, DialogContent, DialogFooter } from './RejectionDialog.styles';
import { Button, TextBlock } from '@bxgrandcentral/controls';
import React, { useState } from 'react';

import { SPACING } from 'Utilities/Layout';
import { TextField } from '@bxgrandcentral/controls';
import useColors from 'api/hooks/use-theme';

interface Props {
    isOpen: boolean;
    handleConfirm: (content: string) => void;
    handleCancel: () => void;
    header?: string;
    maxLength?: number;
    submitButtonTitle?: string;
    submitButtonStyle?: string;
    message?: string;
    messageColor?: string;
}

export default function RejectDialog({
    isOpen = true,
    handleConfirm,
    handleCancel,
    header = 'Reason For Rejection',
    maxLength = 200,
    message,
    messageColor,
}: Props) {
    const [content, setContent] = useState('');
    const colors = useColors();

    const onCancel = () => {
        setContent('');
        handleCancel();
    };

    const isTooManyCharacters = content.length > maxLength;

    return isOpen ? (
        <>
            <DialogComponent colors={colors}>
                <DialogContent>
                    <TextBlock styleName='heading3Style'>{header}</TextBlock>
                    {message && <TextBlock foregroundColor={messageColor}>{message}</TextBlock>}
                    <div className='text-area-hide-scrollbar'>
                        <TextField
                            onValueChanged={(newValue = '') => setContent(newValue)}
                            canUndo={false}
                            minHeight={120}
                            maxHeight={120}
                            canResetValue
                            textWrapping='wrap'
                            isRequired
                            {...(isTooManyCharacters && {
                                validationError: `Max length is ${maxLength} character`,
                            })}
                        />
                    </div>
                </DialogContent>
                <DialogFooter>
                    <Button styleName='textOnlyButtonStyle' onClick={onCancel} minWidth={SPACING.XXL}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleConfirm(content)} isEnabled={!isTooManyCharacters && !!content}>
                        Confirm
                    </Button>
                </DialogFooter>
            </DialogComponent>
            <BackDrop />
        </>
    ) : null;
}
