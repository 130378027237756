export enum ReferencedDataTypeId {
    AdministeredBy = 356,
    BusinessDriver = 355,
    BusinessUnit = 6,
    ContactEmail = 357,
    ExternalAdmin = 241,
    Portfolio = 241,
    LawFirm = 241,
    TaxPreparer = 241,
    LegalType = 366,
    SubBusinessUnit = 248,
    StructureType = 33,
    NewStructureType = 377,
    Country = 1,
    State = 13,
    RegisteredAgent = 115,
    PrimaryLedger = 98,
    Investran = 98,
    TreasuryLineOfBusiness = 98,
    OracleLineOfBusiness = 210,
    FATCAClassification = 14,
    CRSClassifications = 107,
    CRSSubClassification = 109,
    CRSSubClassificationDetails = 253,
    USTaxClassificationType = 48,
    USStateTaxClassificationType = 157,
    ChapterThreeStatus = 85,
    SignatoryTitles = 156,
    InterestUnitType = 359,
    InterestClassType = 360,
    ReportingType = 183,
    InterestTypes = 184,
    InvestranGPFundFamily = 165,
    InvestranFundFamily = 98,
    InvestranDomain = 244,
    ConsolidatingStatusInOracle = 98,
    RepaymentGroup = 98,
    WssPaymentType = 98,
    OracleLocation = 211,
}

export type ReferenceData = Record<keyof typeof ReferencedDataTypeId, KeyValue[] | undefined>;

export interface KeyValue {
    Key: number;
    Value: string;
    ShortValue?: string;
    Description?: string;
    ParentId?: string;
    DisplayOrder?: string;
    IsDirect?: boolean;
}
