import { IPermissionService } from '../interfaces/IPermissionService';
import { LegalEntityRequestPaths } from '../../modules/RequestsDashboard/LegalEntityRequestPaths';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { useHistory } from 'react-router-dom';

const createCapability = 'createlegalentity';
const createEntityBrn = 'legal-entity:request';
const godRoleBrn = 'legal-entity:god';

export default function useNewRequestService() {
    const permissionService = ServiceLocator.container.resolve(IPermissionService);
    const historyApi = useHistory();

    function* filterActions(createBrns: Set<string>) {
        if (createBrns.has(createEntityBrn) || createBrns.has(godRoleBrn))
            yield {
                displayName: 'New Legal Entity Request',
                action: () => {
                    historyApi.push(LegalEntityRequestPaths.newLegalEntityRequest);
                },
            };
        // add more actions here
    }

    const getNewRequestActions = async () => {
        const createPermissions = await permissionService.GetPolicyServerCapabilities();
        const createBrns = new Set(
            createPermissions
                .filter((p) => p.capability?.toLowerCase() === createCapability || p.brn?.toLowerCase() === godRoleBrn)
                .map((p) => p.brn?.toLowerCase())
                .filter((brn): brn is string => brn !== undefined)
        );
        return Array.from(filterActions(createBrns));
    };
    return [getNewRequestActions];
}
