import React from 'react';

type Props = {
    condition?: boolean;
    wrapper: (children: React.ReactNode) => JSX.Element;
};

const ConditionalWrapper: React.FC<Props> = ({ condition = false, wrapper, children }) =>
    condition ? wrapper(children) : <>{children}</>;

export default ConditionalWrapper;
