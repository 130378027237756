import * as XLSX from 'xlsx';

import FileSaver from 'file-saver';
import { isNil } from 'lodash';
import { useCallback } from 'react';

type Data = { [key: string]: unknown };

type SaveParameters = {
    data: Data[];
    header?: string[];
    fileName?: string;
    fileExtension?: 'xls' | 'xlsx';
    workSheetName?: string;
};

const {
    utils: { json_to_sheet: createWorkSheet },
    write,
} = XLSX;

const { saveAs } = FileSaver;

const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export default function useExport() {
    const save = useCallback(
        ({
            data,
            header,
            fileName = 'Entity Requests',
            fileExtension = 'xlsx',
            workSheetName = 'Entity Requests',
        }: SaveParameters) => {
            if (isNil(data) || !data.length) {
                return;
            }

            const opts = header ? { header } : undefined;
            const workSheet = createWorkSheet(data, opts);
            const workBook = { Sheets: { [`${workSheetName}`]: workSheet }, SheetNames: [workSheetName] };
            const excelBuffer = write(workBook, { bookType: fileExtension, type: 'array' });
            const result = new Blob([excelBuffer], { type });

            const dateNow = new Date();
            saveAs(
                result,
                `${fileName}-${
                    String(dateNow.getMonth() + 1).padStart(2, '0') +
                    '_' +
                    String(dateNow.getDate()).padStart(2, '0') +
                    '_' +
                    dateNow.getFullYear() +
                    '-' +
                    String(dateNow.getHours()).padStart(2, '0') +
                    '_' +
                    String(dateNow.getMinutes()).padStart(2, '0') +
                    '_' +
                    String(dateNow.getSeconds()).padStart(2, '0')
                }.${fileExtension}`
            );
        },
        []
    );

    return { save };
}
