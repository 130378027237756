import React, { createContext, useContext, useReducer } from 'react';
import { isEqual, isNil, noop } from 'lodash';

import { ProviderProps } from './model';
import { WorkItem } from 'models/Workflow';

type RequestContextState = {
    taskComment?: { [key: string]: string };
};

type RequestContextModel = {
    state: RequestContextState;
    dispatch: any;
};

const RequestContext = createContext<RequestContextModel>({
    state: {} as RequestContextState,
    dispatch: () => noop,
});
RequestContext.displayName = 'RequestContext';

function reducer(state: RequestContextState, action: any) {
    switch (action.type) {
        case 'SET_REQUEST_NOTES': {
            return {
                ...state,
                taskComment: {
                    ...state.taskComment,
                    [action.workItemId]: action.value,
                },
            };
        }
        default: {
            return state;
        }
    }
}

function RequestContextProvider({ children }: ProviderProps) {
    const [state, dispatch] = useReducer(reducer, { taskComment: {} });
    const value = { state, dispatch };

    return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
}

function useRequestContext() {
    const context = useContext(RequestContext);
    if (isNil(context)) {
        throw new Error('useRequestContext must be used within an RequestContextProvider');
    }

    const isRequestNotesChanged = ({ customProperties: { taskComment }, workItemId }: WorkItem) =>
        !isEqual(taskComment, context.state?.taskComment?.[workItemId]);

    return {
        ...context,
        isRequestNotesChanged,
    };
}

const setRequestNotes = (dispatch: any, workItemId: number, value?: string) => {
    dispatch({ type: 'SET_REQUEST_NOTES', workItemId, value });
};

export { RequestContextProvider, useRequestContext, setRequestNotes };
