import { Expander, TextField } from 'components';
import React, { useMemo } from 'react';

import { SPACING } from 'Utilities/Layout';
import { Task } from '../../../models/Workflow';
import { TaskTypes } from 'models/LegalEntityRequest/Workflow/TaskTypes';
import { getRejectReason } from '../utils';

type Props = {
    header?: string;
    mode: string;
    taskType: TaskTypes;
    tasks?: Task[];
    isExpandable?: boolean;
    allowedModes?: string[];
};

export default function RejectReason({
    mode,
    taskType,
    tasks,
    header = 'Reason For Rejection',
    isExpandable = true,
    allowedModes = ['edit', 'mixed'],
}: Props) {
    const rejectReason = useMemo(() => getRejectReason(taskType, tasks), [taskType, tasks]);

    if (!allowedModes.includes(mode.toLowerCase()) || !rejectReason) {
        return null;
    }

    return isExpandable ? (
        <Expander
            header={header}
            content={
                <div className='text-area-hide-scrollbar'>
                    <TextField
                        value={rejectReason}
                        isEnabled={false}
                        minHeight={120}
                        maxHeight={240}
                        variant='default'
                        textWrapping='wrap'
                        padding={`${SPACING.XS}px 0`}
                    />
                </div>
            }
        />
    ) : (
        <div className='text-area-hide-scrollbar'>
            <TextField
                label='Reason For Rejection'
                variant='default'
                value={rejectReason}
                isEnabled={false}
                minHeight={120}
                maxHeight={240}
                textWrapping='wrap'
                padding={`${SPACING.XS}px 0`}
            />
        </div>
    );
}
