import { DIALOG_DROP_SHADOW, ELEVATIONS, SPACING } from '../../Utilities/Layout';

import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';

const { MD, XL, LG } = SPACING;

export const DialogFooter = styled.div`
    display: grid;
    grid-gap: ${MD}px;
    grid-auto-flow: column;
    justify-content: end;
    align-items: center;
    padding: ${MD}px;
`;

type DialogComponentProps = {
    colors: ThemeColors;
};

export const DialogComponent = styled.div<DialogComponentProps>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: ${DIALOG_DROP_SHADOW};
    border: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};
    border-radius: 8px;
    background-color: ${({ colors }) => colors.backgroundColor};
    z-index: ${ELEVATIONS.DIALOG};
    width: 500px;

    ${DialogFooter} {
        border-top: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};
    }
`;

export const DialogContent = styled.div`
    display: grid;
    grid-gap: ${LG}px;
    padding: ${XL}px;

    span:last-child {
        line-height: 28px;
    }

    textarea {
        padding: 10px 5px;
    }
`;

export const BackDrop = styled.div`
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px) saturate(125%);
`;
