import { ColDef } from 'ag-grid-community';
import GridDeleteButton from './GridDeleteButton';
import GridPendingColumnRenderer from '../common/GridPendingColumnRenderer';
import { KeyValue } from 'models';
import { dateFormatter } from 'modules/RequestsDashboard/components/Grid/model';
import { useReferenceData } from 'api';

type Props = {
    clearRowSelection: () => void;
    mode: string;
    isStandaloneWorkflow: boolean;
};

export default function useAuthorizePersonColumnDefs({ clearRowSelection, mode, isStandaloneWorkflow }: Props) {
    const {
        data: { SignatoryTitles },
    } = useReferenceData();

    const getValueFromKey = (arr: KeyValue[] | undefined, key: number, dataProperty: keyof KeyValue = 'Value') => {
        const foundItem = arr?.find((item) => item.Key === key);
        return foundItem ? foundItem[dataProperty] : '';
    };

    const authorizedPersonColumnDefs: ColDef[] = [
        {
            field: 'hasPendingChange',
            headerName: 'Pending',
            cellClass: 'cell-pending',
            headerTooltip: 'Pending',
            hide: !(mode === 'Approval' && isStandaloneWorkflow),
            maxWidth: 90,
            cellRenderer: GridPendingColumnRenderer,
        },
        {
            field: 'relatedEntityName',
            headerName: 'Name',
            cellClass: 'cell-relatedEntityName',
            minWidth: 160,
            headerTooltip: 'Name',
        },
        {
            field: 'title',
            headerName: 'Title',
            cellClass: 'cell-title',
            valueGetter: (params) => getValueFromKey(SignatoryTitles, params.data.title),
            headerTooltip: 'Title',
        },
        {
            field: 'appointmentDate',
            headerName: 'Appointment Date',
            cellClass: 'cell-appointmentDate',
            valueFormatter: dateFormatter,
            headerTooltip: 'Appointment Date',
            maxWidth: 160,
        },
        {
            field: 'resignationDate',
            headerName: 'Resignation Date',
            cellClass: 'cell-resignationDate',
            valueFormatter: dateFormatter,
            headerTooltip: 'Resignation Date',
            minWidth: 160,
        },
        {
            field: 'isDeleted',
            headerName: 'Delete',
            cellClass: 'cell-delete',
            pinned: 'right',
            maxWidth: 85,
            cellRenderer: GridDeleteButton,
            cellRendererParams: {
                clearRowSelection,
            },
            hide: !['Create', 'Edit'].includes(mode),
        },
    ];

    return { authorizedPersonColumnDefs };
}
