import { Grid, SelectorField, StackPanel, TextBlock, WaitingIndicator } from '@bxgrandcentral/controls';
import React, { useCallback, useEffect, useState } from 'react';
import useAssociatedDealOptions, { AssociatedDeal } from 'hooks/use-associated-deal-options';

import { FormControl } from '../controls.styled';
import { SPACING } from 'Utilities/Layout';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import { isEqual } from 'lodash';
import useAssociatedDeal from 'hooks/use-associated-deal';
import useColors from 'api/hooks/use-theme';

export default function AssociatedDealSelect({ validationError, ...props }: any) {
    const [filterValue, setFilterValue] = useState<string | undefined>();
    const { data: associatedDealOptionsData, isLoading: isLoadingAssociatedDealOptions } = useAssociatedDealOptions(
        filterValue ?? ''
    );
    const { data: storedAssociatedDealData, isLoading: isLoadingStoredAssociatedDealData } = useAssociatedDeal(
        props?.storedValue
    );

    const [filteredData, setFilteredData] = useState<AssociatedDeal[]>();
    const [availableOptions, setAvailableOptions] = useState<AssociatedDeal[]>();
    const { accentColor } = useColors();

    const itemTemplate = useCallback(
        ({ data: { primaryName, entityOId } }: AssociatedDeal) => (
            <StackPanel>
                <TextBlock text={`${primaryName}`} styleName='fieldValueStyle' />
                <TextBlock styleName='captionStyle' text={`ID: ${entityOId}`} />
            </StackPanel>
        ),
        []
    );

    const searchFilter = useCallback((value = '') => {
        setFilterValue(value);
        return [];
    }, []);

    useEffect(() => {
        setFilteredData(associatedDealOptionsData);
    }, [associatedDealOptionsData]);

    useEffect(() => {
        setFilteredData(storedAssociatedDealData);
    }, [storedAssociatedDealData]);

    useEffect(() => {
        let options = [] as AssociatedDeal[];

        if (storedAssociatedDealData?.length) {
            options = [...storedAssociatedDealData];
        }

        if (associatedDealOptionsData?.length) {
            options = [...options, ...associatedDealOptionsData];
        }
        setAvailableOptions(options);
    }, [storedAssociatedDealData, associatedDealOptionsData]);

    return (
        <FormControl height={80} className='form'>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <TextBlock
                    styleName='fieldLabelStyle'
                    text={props.label}
                    toolTip={props.labelToolTip}
                    margin={`0 0 ${SPACING.XXS}px 0`}
                />
                {(isLoadingAssociatedDealOptions || isLoadingStoredAssociatedDealData) && (
                    <WaitingIndicator
                        id={'spinner'}
                        isVisible
                        isModalToShell={false}
                        fontSize={10}
                        width={40}
                        margin={`-${SPACING.XS}px 0 0 0`}
                    />
                )}
                {props.isReadOnly && props.isPendingChange && (
                    <TextBlock
                        styleName='fieldLabelStyle'
                        fontSize='16px'
                        text='PENDING'
                        foregroundColor={accentColor}
                        textAlignment='right'
                    />
                )}
            </Grid>
            <SelectorField
                itemTemplate={itemTemplate}
                itemsSource={filteredData}
                showSearchBox
                searchFilter={searchFilter}
                canResetValue
                maxDropDownHeight={275}
                value={
                    props.value && availableOptions
                        ? [availableOptions.find((item) => isEqual(item.data.entityOId, props.value))]
                        : []
                }
                onValueChanged={(selectedValues: AssociatedDeal[]) => {
                    const [value] = selectedValues;
                    props.onValueChanged(value?.data.entityOId ?? null);
                }}
                isEnabled={props.isEnabled}
                isReadOnly={props.isReadOnly || isLoadingStoredAssociatedDealData}
                isRequired={props.isRequired}
                validationError={props.validationError}
            />
            {props.isEditable && <ValidationMessage message={validationError} />}
        </FormControl>
    );
}
