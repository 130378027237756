import styled from 'styled-components';

export type StateVariant = 'warning' | 'info' | 'error';

export const SPACING = {
    XXS: 4,
    XS: 8,
    SM: 12,
    MD: 16,
    LG: 24,
    XL: 32,
    XXL: 48,
    XXXL: 70,
};

export const ELEVATIONS = {
    DIALOG: 100,
    DASHBOARD_DROPDOWN: 10,
};

export const HORIZONTAL_FORM_PADDING = `0 ${SPACING.XXXL}px`;

export const FONT_FAMILIES = { ERROR: `"Guardian Sans", Roboto, Helvetica, Arial, "sans serif"` };
export const DIALOG_DROP_SHADOW = `rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px, rgb(0 0 0 / 20%) 0px 11px 15px -7px`;
export const MEDIUM_SCREEN_WIDTH = 1200;
export const SMALL_SCREEN_WIDTH = 900;

type BorderProps = {
    color?: string;
    margin?: number;
};

export const Border = styled.div<BorderProps>`
    width: 100%;
    height: 1px;
    border-top: ${({ color = '#ccd1d9' }) => `1px solid ${color}`};
    margin: ${({ margin = 0 }) => `${margin}px 0`};
`;

export const DoubleColumnLayout = styled.div<{ isDisabled?: boolean }>`
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: minmax(0, 1fr);
    grid-gap: ${SPACING.SM}px;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

    @media screen and (min-width: ${MEDIUM_SCREEN_WIDTH}px) {
        grid-auto-flow: column;
        grid-gap: ${SPACING.XXL}px;
    }
`;

export const GridLayout = styled.div<{ isDisabled?: boolean; columns?: string }>`
    display: grid;
    grid-gap: ${SPACING.SM}px;
    grid-template-columns: 1fr;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

    @media screen and (min-width: ${MEDIUM_SCREEN_WIDTH}px) {
        grid-template-columns: ${({ columns = '1fr 1fr' }) => columns};
        grid-gap: ${SPACING.XXL}px;
    }
`;

export const LEGAL_ENTITY_CREATION_HEADER_TOOLBAR = 'legal-entity-creation-header-toolbar';
