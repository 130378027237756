import { BusinessUnit, KeyValue, SubBusinessUnit } from 'models';

import { InvestranDomain } from 'models/shared/refData/InvestranDomain';
import { isNil } from 'lodash';

export function getDefaultInvestranDomain(businessUnitOId?: number, subBusinessUnitOId?: number) {
    switch (businessUnitOId) {
        case BusinessUnit.TS: {
            return InvestranDomain.BLS;
        }
        case BusinessUnit.PrivateEquity: {
            return InvestranDomain.PE;
        }
        case BusinessUnit.RealEstate: {
            switch (subBusinessUnitOId) {
                case SubBusinessUnit.BREP:
                case SubBusinessUnit.BREIT: {
                    return InvestranDomain.RE;
                }
                case SubBusinessUnit.BREDS: {
                    return InvestranDomain.BREDS;
                }
                case SubBusinessUnit.BPP: {
                    return InvestranDomain.BPP;
                }
            }
        }
    }
}

export function getBillingValue(
    fieldName: string,
    value?: number | string,
    billingNumber?: string,
    isEditable?: boolean
) {
    if (isNil(value) && isNil(billingNumber)) {
        return undefined;
    }
    if (!isEditable && ((value && isNil(billingNumber)) || value?.toString() === billingNumber)) {
        return `Same as ${fieldName}`;
    }
    return billingNumber;
}

export function getRepaymentGroupByBUId(businessUnitOId: number | undefined, repaymentGroupList: KeyValue[] | undefined) {
    const filteredRepaymentGroup = repaymentGroupList
        ?.filter((rg) => rg.ParentId == businessUnitOId) ?? [];

    return filteredRepaymentGroup;
}
