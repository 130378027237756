import { CreationViewState } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/model';

function processWorkItemData(workItem: any) {
    if (!workItem) {
        return null;
    }

    return {
        workItemId: workItem?.workItemId,
        workflowType: workItem?.workflowType,
        workItemState: workItem?.workItemState,
        workItemStatus: workItem?.workItemStatus,
        created: workItem?.created,
        modified: workItem?.modified,
        completed: workItem?.completed,
        isStandalone: workItem?.isStandalone,
        task: workItem.tasks?.filter(
            (item: any) =>
                item?.status === 'InProcess' || (item?.status === 'New' && item?.taskKey !== 'BXWorkflowsChildProcess')
        ),
    };
}

export function getWorkitemsFromState(state: CreationViewState) {
    return {
        entityCreationWorkItem: processWorkItemData(state.entityCreationWorkItem),
        foreignRegistrationWorkItems: state.foreignRegistrationWorkItems.map((workItem) =>
            processWorkItemData(workItem)
        ),
        foreignRegistrationUpdateWorkItem: processWorkItemData(state.foreignRegistrationUpdateWorkItem),
        financeInformationWorkItem: processWorkItemData(state.financeInformationWorkItem),
        taxClassificationWorkItem: processWorkItemData(state.taxClassificationWorkItem),
        legalEntityUpdateWorkItem: processWorkItemData(state.legalEntityUpdateWorkItem),
        ownershipWorkItem: processWorkItemData(state.ownershipWorkItem),
        dissolutionParentWorkItem: processWorkItemData(state.legalEntityDissolutionParentWorkItem),
        dissolutionLitigationWorkItem: processWorkItemData(state.legalEntityDissolutionLitigationWorkItem),
        dissolutionTaxWorkItem: processWorkItemData(state.legalEntityDissolutionTaxWorkItem),
        dissolutionFinanceWorkItem: processWorkItemData(state.legalEntityDissolutionFinanceWorkItem),
        dissolutionLegalWorkItem: processWorkItemData(state.legalEntityDissolutionLegalWorkItem),
        dissolutionTreasuryWorkItem: processWorkItemData(state.legalEntityDissolutionTreasuryWorkItem),
    };
}
