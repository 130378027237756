import { CreateLegalEntityWorkflow } from '../../LegalEntityRequest/Workflow';
import { EntityStatusId } from '../../LegalEntity/EntityStatus';
import { isEmpty } from '../../../Utilities/Validations';

type identicalEntityNameArg = {
    entityStatusId?: EntityStatusId;
    businessUnits?: string[];
    entityOId: number;
};
export class ValidationMessages {
    public static readonly RequiredField: string = 'Field is required';
    public static readonly InvalidNumber: string = 'Field is not a valid number';
    public static readonly InterestTypeNotSupport: string =
        'The entity’s legal type does not support the selected interest type';
    public static readonly InterestTypeRequires: string =
        'The entity’s legal type requires 1 general partner and 1 limited partner';
    public static readonly GeneralTypeRequires: string = 'This entity’s legal type requires 1 general partner';
    public static readonly InterestTypeOnlySoleMember: string =
        'Parent entity with interest type Sole Member should be the only parent entity';
    public static async IdenticalEntityName(
        entity: identicalEntityNameArg,
        getWorkitem: Promise<CreateLegalEntityWorkflow | undefined>
    ) {
        const { entityStatusId, businessUnits = [] } = entity;
        const withWorkitem = 'A legal entity workflow with identical name is in progress.';
        let statusDisplay;
        let result;
        if (entityStatusId) {
            if (EntityStatusId[entityStatusId] === 'Pending') {
                result = await getWorkitem;
                statusDisplay = result ? withWorkitem : ' ' + EntityStatusId[entityStatusId] + ' ';
            } else {
                statusDisplay = EntityStatusId[entityStatusId] + ' ';
            }
        } else {
            statusDisplay = '';
        }
        const businessUnitDisplay = isEmpty(businessUnits)
            ? ''
            : businessUnits.length === 1
            ? `, Business Unit: ${businessUnits[0]}`
            : `, Business Units: ${businessUnits.join(', ')}`;
        return result
            ? `${statusDisplay}(Entity ID: ${entity.entityOId}${businessUnitDisplay})`
            : `${statusDisplay}legal entity with identical name already exists. (Entity ID: ${entity.entityOId}${businessUnitDisplay})`;
    }
    public static readonly totalOwnership: string = 'Total Ownership % cannot be greater than 100%';
    public static readonly FutureDate: string = 'Date must not be a future date.';
    public static readonly AppointmentAndResignationDate = 'Resignation Date cannot be prior to Appointment Date';
    public static readonly UniqueThirdPartyTitle = 'This title has already been used for this third party.';
    public static readonly UniqueOwnershipParentEntity =
        'This entity has already been used as an owner for this entity.';
    public static NumLength(requiredLength: number) {
        return `Field must contain ${requiredLength} digits`;
    }
    public static readonly CertificateLimitedPartnership = 'Certificate of Limited Partnership document missing';
    public static readonly TrustLegalTypeDocumentRequired = 'Trust Agreement or Trust Deed document missing';
    public static readonly TrustLegalTypeDocumentRequiredAtOwnershipInformation =
        'Trust Agreement, Trust Deed or Resolution document missing';
    public static readonly CertificateRegistration = 'Certificate of Registration document missing';
    public static readonly FormationDocument = 'Formation Document missing';
    public static readonly LLCAgreementAndCertificateRegistration =
        'LP/LLC Agreement and Certificate of Registration documents missing';
    public static readonly IncorporationOrActionOfSole =
        'Certificate of Incorporation or Action of Sole Incorporator document missing';
    public static readonly MemorandumArticleAssociation = 'Memorandum Article of Association document missing';
    public static readonly ByLawsResolutionAndConsent = 'Bylaws, Resolution or Consent to Use of Name document missing';
    public static readonly RegisterMember = 'Register of Member document missing';
    public static readonly CertificateLimitedExtracts =
        'Certificate of Limited Partnership, LP/LLC Agreement, Certificate of Registration or Extracts from Public Register missing';
    public static readonly CertificateLimitedExtractsLCC =
        'Certificate of Registration, Formation Document, LP/LLC Agreement or Extracts from Public Register missing';
    public static readonly CertificateIncorporationOrExtracts =
        'Certificate of Incorporation or Extracts from Public Register missing';
    public static readonly ProspectusMemorandumAndCertificate =
        'Certificate of Registration, Memorandum Article of Association and Prospectus missing';
    public static readonly OneDocPerDocTypeMessage = 'Only one document per document type can be uploaded';
}
