import LegalEntityDissolutionDetails from './LegalEntityDissolutionDetails';
import { LegalEntityDissolutionDetailsProps } from './model';
import { LegalEntityDissolutionDetailsProvider } from '../../context/Provider';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityDissolutionDetailsSection(props: LegalEntityDissolutionDetailsProps) {
    return (
        <LegalEntityDissolutionDetailsProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            requestStep={RequestStepsView.Dissolution_Details}>
            <LegalEntityDissolutionDetails {...props} />
        </LegalEntityDissolutionDetailsProvider>
    );
}
