import './App.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { GrandCentralApp, IAppConfigEditor, IAppConfigEditorService, ServiceLocator } from '@bxgrandcentral/shell';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect, useState } from 'react';

import { AppContextProvider } from './context/app-context';
import ApplicationSettings from 'settings/ApplicationSettings';
import { DeveloperSettingsEditor } from './settings/DeveloperSettingsEditor';
import { SettingsService } from './services/SettingsService';
import { ThemeManager } from '@bxgrandcentral/controls';
import { ThemeProvider } from 'react-jss';
import { appConfig } from './configs/App.config';
import { isLowerEnvironment } from './Utilities/Environment';
import { modules } from './configs/Modules.config';

const appSettings: IAppConfigEditor = {
    editorID: 'com.bx.bxt.bxLegalEntitiesUI.configEditors.AboutSettings',
    displayName: 'Settings',
    component: <ApplicationSettings />,
} as const;

const developerSettings: IAppConfigEditor = {
    editorID: 'com.bx.bxt.bxLegalEntitiesUI.configEditors.developerSettingsEditor',
    displayName: 'Developer Settings',
    component: <DeveloperSettingsEditor />,
} as const;

const configEditors: IAppConfigEditor[] = isLowerEnvironment() ? [developerSettings, appSettings] : [appSettings];

const queryClient = new QueryClient();

const App = () => {
    const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false);
    const { container, resolve } = ServiceLocator;

    useEffect(() => {
        const configEditorService = resolve(IAppConfigEditorService);
        configEditorService.editors.push(...configEditors);
    }, [resolve]);

    const handleLoginStatusChanged = (isLoginSuccessful: boolean) => {
        if (isLoginSuccessful && !isAlreadyLoggedIn) {
            container.registerSingleton(SettingsService);
            container.resolve(SettingsService);
            setIsAlreadyLoggedIn(true);
        }
    };

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={ThemeManager.activeTheme}>
                <AppContextProvider>
                    <GrandCentralApp
                        modules={modules}
                        config={appConfig}
                        onLoginStatusChanged={handleLoginStatusChanged}
                    />
                </AppContextProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

// Comment to trigger build for icon hotfix

export default App;
