import { FileField, FileList } from './components';
import { FileListPanel, FileUploadBorder } from './FileUpload.styled';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, WaitingIndicator } from '@bxgrandcentral/controls';
import { updateDocumentSection, updateStoredDocument } from 'modules/LegalEntityCreation/context/actions';

import DocumentServiceError from './components/DocumentServiceError';
import FileListTreeView from './components/FileListTreeView';
import { FileUploadProps } from './models';
import { FormState } from '../../../modules/LegalEntityCreation/context/model';
import { LegalEntityDocumentPreparationData } from '../../../modules/LegalEntityCreation/LegalEntityDocumentPreparation/model';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SPACING } from '../../../Utilities/Layout';
import Text from '../../Text/Text';
import { VALIDATION_MESSAGES } from 'modules/LegalEntityCreation/validation/utils';
import { documentTypes } from 'models/shared/refData/NewDocumentType';
import { getSectionName } from './utils';
import { getValuesOf } from 'Utilities/object';
import { hasElement } from 'Utilities/array';
import { isNil } from 'lodash';
import useColors from 'api/hooks/use-theme';
import useDocuments from '../../../api/hooks/use-documents';
import useFileUpload from './hooks/use-file-upload';
import { usePrevious } from 'api/hooks/use-previous';

export default function FileUpload(props: FileUploadProps) {
    const {
        entityId = 0,
        sectionName,
        documentTypes: documentTypesProp = [],
        stampedDocumentTypes: stampedDocumentTypesProp = [],
        taxRegistrationId,
        isRequired = false,
        isDisabled = false,
        showStoredDocuments = true,
        showStampedDocuments = true,
        isStampedDocumentUpload = false,
        effectiveDate = 'none',
        onlyOneDocumentPerTypeAllowed = false,
        keepDocumentTypes = false,
        documentApproveDate,
        hideEffectiveDate = false,
        context: { state, dispatch },
        showDocumentsInReadOnlyTreeView,
        standaloneCreateMode,
    } = props;

    const {
        documents: { sections = {} },
        isEditable,
        isLoading,
        version,
    }: FormState<LegalEntityDocumentPreparationData> = state;

    const colors = useColors();

    const {
        data = [],
        isFetching: isDocumentsFetching,
        error,
        refetch,
    } = useDocuments(entityId, taxRegistrationId ?? sectionName);

    const {
        pendingDocuments,
        storedDocuments,
        stampedDocuments,
        allDocuments,
        storedDocumentTypes,
        pendingDocumentTypes,
        section: { isFetching, errorMessage, additionalErrorMessage, hasAnyDocumentEffectiveDate },
        filterDocuments,
    } = useFileUpload(props);

    const documentListHeader = useMemo(
        () => (isStampedDocumentUpload ? 'Previous' : 'Stored'),
        [isStampedDocumentUpload]
    );

    const approvedDocuments = useMemo(
        () =>
            documentApproveDate
                ? allDocuments.filter((document) => document.document.createdDateUTC < documentApproveDate)
                : [],
        [allDocuments, documentApproveDate]
    );

    const approvedDocumentTypes = useMemo(
        () =>
            documentApproveDate
                ? approvedDocuments.map(({ document: { coreDocumentTypeId } }) => coreDocumentTypeId)
                : [],
        [approvedDocuments, documentApproveDate]
    );

    const availableDocumentTypes = useMemo(() => {
        if (!keepDocumentTypes && documentApproveDate && approvedDocuments.length) {
            return getValuesOf(documentTypes).filter(({ Key }) => approvedDocumentTypes.includes(Key));
        }
        return documentTypesProp;
    }, [approvedDocumentTypes, approvedDocuments.length, documentApproveDate, documentTypesProp, keepDocumentTypes]);

    const missingDocumentTypes = useMemo(() => {
        if (!documentApproveDate || !approvedDocuments.length) {
            return '';
        } else {
            return availableDocumentTypes
                .filter(({ Key }) => {
                    return (
                        !pendingDocumentTypes.has(Key) &&
                        !allDocuments.filter(
                            ({
                                document: { coreDocumentTypeId, documentElements },
                                updates: { sectionName, isDeleted },
                            }) => {
                                const section = sectionName ?? getSectionName(documentElements);
                                return !isDeleted && coreDocumentTypeId === Key && section === SECTION_NAMES.STAMPED;
                            }
                        ).length
                    );
                })
                .map(({ Value }) => Value)
                .sort()
                .join(', ');
        }
    }, [allDocuments, approvedDocuments.length, availableDocumentTypes, documentApproveDate, pendingDocumentTypes]);

    const isEditableFileUpload = useMemo(() => isEditable && !isDisabled, [isDisabled, isEditable]);

    const previousVersion = usePrevious(version) ?? 0;

    if (version > previousVersion) {
        refetch().then(({ data }) => {
            filterDocuments(data);
        });
    }

    useEffect(() => {
        updateDocumentSection(dispatch, sectionName, {
            isRequired: isStampedDocumentUpload ? !isFetching && storedDocuments.length > 0 : isRequired,
            effectiveDate,
            isFetching: isDocumentsFetching,
        });
    }, [
        isRequired,
        dispatch,
        sectionName,
        effectiveDate,
        isDocumentsFetching,
        isStampedDocumentUpload,
        isFetching,
        storedDocuments.length,
    ]);

    useEffect(() => {
        if (isStampedDocumentUpload) {
            updateDocumentSection(dispatch, sectionName, {
                additionalErrorMessage: missingDocumentTypes
                    ? DOCUMENTS_STAMPED_DOCUMENT_MISSING
                    : additionalErrorMessage !== DOCUMENTS_STAMPED_DOCUMENT_MISSING
                    ? additionalErrorMessage
                    : '',
            });
        }
    }, [additionalErrorMessage, isStampedDocumentUpload, missingDocumentTypes, sectionName, dispatch]);

    useEffect(() => {
        if (onlyOneDocumentPerTypeAllowed) {
            const { length: numberOfDocuments } = [
                ...storedDocuments.filter(({ updates: { isDeleted } }) => !isDeleted),
                ...pendingDocuments.filter(({ documentTypeId }) => documentTypeId),
            ];
            const { size: numerOfDocumentTypes } = new Set([...storedDocumentTypes, ...pendingDocumentTypes]);

            let message = numberOfDocuments > numerOfDocumentTypes ? DOCUMENTS_INVALID_MULTIPLE_DOCUMENTS : '';
            if (additionalErrorMessage !== message) {
                updateDocumentSection(dispatch, sectionName, {
                    additionalErrorMessage: message,
                });
            }
        }
    }, [
        additionalErrorMessage,
        dispatch,
        onlyOneDocumentPerTypeAllowed,
        pendingDocumentTypes,
        pendingDocuments,
        sectionName,
        storedDocumentTypes,
        storedDocuments,
    ]);

    useEffect(() => {
        filterDocuments(data);
    }, [filterDocuments, data]);

    if (isNil(sections)) {
        return null;
    }

    if (error) {
        return (
            <DocumentServiceError
                onClick={() =>
                    refetch().then(({ data }) => {
                        filterDocuments(data);
                    })
                }
            />
        );
    }

    return (
        <>
            <FileUploadBorder colors={colors}>
                {showStoredDocuments && (
                    <FileListPanel
                        colors={colors}
                        style={{ borderBottomWidth: +(isEditableFileUpload && !pendingDocuments.length) }}>
                        {isFetching && !isLoading && (
                            <WaitingIndicator
                                id='spinner'
                                content='Loading documents'
                                isVisible
                                isModalToShell={false}
                            />
                        )}
                        {isStampedDocumentUpload ? (
                            <>
                                <FileList
                                    entityOId={entityId}
                                    documents={approvedDocuments}
                                    isEditable={isEditable}
                                    isFetching={isFetching}
                                    sectionName={sectionName}
                                    dispatch={dispatch}
                                    header='Approved documents'
                                    useEffectiveDatePlaceholder
                                    isStampedDocumentUpload
                                    documentApproveDate={documentApproveDate}
                                    onDocumentStateChanged={(id, update) => {
                                        updateStoredDocument(dispatch, sectionName, id, update);
                                    }}
                                    {...(allDocuments.length === 0 && {
                                        noDocumentLabel: 'There are no documents for this section',
                                    })}
                                />
                                {allDocuments.length > 0 && (
                                    <FileList
                                        entityOId={entityId}
                                        documents={allDocuments.filter(
                                            (document) => document.document.createdDateUTC > documentApproveDate!
                                        )}
                                        isEditable={isEditable}
                                        isFetching={isFetching}
                                        sectionName={sectionName}
                                        dispatch={dispatch}
                                        header='Unapproved documents'
                                        useEffectiveDatePlaceholder
                                        isStampedDocumentUpload
                                        documentApproveDate={documentApproveDate}
                                        onDocumentStateChanged={(id, update) => {
                                            updateStoredDocument(dispatch, sectionName, id, update);
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {showDocumentsInReadOnlyTreeView ? (
                                    <FileListTreeView
                                        entityOId={entityId}
                                        documents={storedDocuments}
                                        isFetching={isFetching}
                                        sectionName={sectionName}
                                        useEffectiveDatePlaceholder={hasAnyDocumentEffectiveDate}
                                        documentApproveDate={documentApproveDate}
                                        hideEffectiveDate={hideEffectiveDate}
                                        onDocumentStateChanged={(id, update) =>
                                            updateStoredDocument(dispatch, sectionName, id, update)
                                        }
                                    />
                                ) : (
                                    <FileList
                                        entityOId={entityId}
                                        documents={!standaloneCreateMode ? storedDocuments : []}
                                        isEditable={isEditable && !isDisabled && !isStampedDocumentUpload}
                                        isFetching={isFetching}
                                        sectionName={sectionName}
                                        header={`${documentListHeader} documents`}
                                        useEffectiveDatePlaceholder={hasAnyDocumentEffectiveDate}
                                        documentApproveDate={documentApproveDate}
                                        hideEffectiveDate={hideEffectiveDate}
                                        onDocumentStateChanged={(id, update) =>
                                            updateStoredDocument(dispatch, sectionName, id, update)
                                        }
                                        {...(!stampedDocumentTypesProp ||
                                            (stampedDocumentTypesProp &&
                                                !stampedDocuments.length && {
                                                    noDocumentLabel: 'There are no documents for this section',
                                                }))}
                                    />
                                )}
                                {showStampedDocuments && (
                                    <FileList
                                        header='Stamped Documents'
                                        entityOId={entityId}
                                        documents={stampedDocuments}
                                        isFetching={isFetching}
                                        isEditable={isEditable}
                                        sectionName={sectionName}
                                        isStampedDocumentUpload={isStampedDocumentUpload}
                                        useEffectiveDatePlaceholder={hasAnyDocumentEffectiveDate}
                                        documentApproveDate={documentApproveDate}
                                        onDocumentStateChanged={(id, update) =>
                                            updateStoredDocument(dispatch, sectionName, id, update)
                                        }
                                        {...(isStampedDocumentUpload &&
                                            storedDocuments.length > 0 && {
                                                noDocumentLabel: 'There are no stamped documents for this section',
                                            })}
                                    />
                                )}
                            </>
                        )}
                    </FileListPanel>
                )}
                {isEditableFileUpload && (
                    <FileField
                        documents={pendingDocuments}
                        dispatch={dispatch}
                        documentTypes={documentTypesProp}
                        entityOid={entityId}
                        sectionName={sectionName}
                        isStampedDocumentUpload={isStampedDocumentUpload}
                        isEditable={isEditable}
                        taxRegistrationId={taxRegistrationId}
                        effectiveDate={effectiveDate}
                        showStoredDocuments={showStoredDocuments}
                        {...(hasElement(
                            [DOCUMENTS_REQUIRED_FIELDS_ARE_MISSING, DOCUMENTS_REQUIRED_FIELDS_ARE_NOT_SET_PROPERLY],
                            errorMessage
                        ) &&
                            isEditableFileUpload && {
                                errorMessage,
                            })}
                    />
                )}
            </FileUploadBorder>
            {isEditableFileUpload && !isFetching && (
                <StackPanel itemGap={SPACING.SM} horizontalAlignment='center' margin={`${SPACING.LG}px 0 0`}>
                    <Text variant='errorBold' textAlignment='center' horizontalAlignment='center'>
                        {errorMessage === DOCUMENTS_MANDATORY_FIELD ? errorMessage : additionalErrorMessage}
                    </Text>
                    {additionalErrorMessage === DOCUMENTS_STAMPED_DOCUMENT_MISSING && (
                        <StackPanel orientation='horizontal' styleOverrides={{ justifyContent: 'center' }}>
                            <Text variant='errorBold' horizontalAlignment='center' textAlignment='center'>
                                {missingDocumentTypes}
                            </Text>
                        </StackPanel>
                    )}
                </StackPanel>
            )}
        </>
    );
}

const {
    DOCUMENTS_STAMPED_DOCUMENT_MISSING,
    DOCUMENTS_INVALID_MULTIPLE_DOCUMENTS,
    DOCUMENTS_REQUIRED_FIELDS_ARE_MISSING,
    DOCUMENTS_REQUIRED_FIELDS_ARE_NOT_SET_PROPERLY,
    DOCUMENTS_MANDATORY_FIELD,
} = VALIDATION_MESSAGES;
