import { FontIcon, ThemeColors } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { Text } from 'components';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type Props = {
    value: string;
    title?: string;
    isActive: boolean;
    onClick: (value: string) => void;
};

export default function FilterItem({ value, title, isActive, onClick }: Props) {
    const colors = useColors();
    return (
        <Container colors={colors} onClick={() => onClick(value)}>
            <FontIcon
                iconName={isActive ? 'CheckboxComposite' : 'Checkbox'}
                styleOverrides={{ fontSize: 16, fontWeight: 600 }}
            />
            <Text variant='body-small'>{title || value || 'Blank Field'}</Text>
        </Container>
    );
}

type StyledProps = {
    colors: ThemeColors;
};

const Container = styled.div<StyledProps>`
    display: grid;
    grid-auto-flow: column;
    align-items: end;
    grid-gap: ${SPACING.SM}px;
    justify-content: start;
    padding: ${SPACING.SM}px ${SPACING.MD}px;
    background-color: ${({ colors }) => colors.backgroundColor};
    color: ${({ colors }) => colors.foregroundColor};
    &:hover {
        cursor: pointer;
        background-color: ${({ colors }) => colors.groupedBackgroundColor};
    }
`;
