export enum RepaymentGroup {
    SP = 15,
    BTO = 23,
    BXG = 73,
    LS = 55,
    INFRA = 43,
}

export const repaymentGroupList = [
    {
        repaymentGroupId: 1,
        repaymentGroupName: 'BAAM',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 2,
        repaymentGroupName: 'IIS',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 3,
        repaymentGroupName: 'SAF',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 4,
        repaymentGroupName: 'Direct Trading',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 5,
        repaymentGroupName: 'BPS',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 6,
        repaymentGroupName: 'BGEN',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 7,
        repaymentGroupName: 'BSCH',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 8,
        repaymentGroupName: 'SAS',
        businessUnitOId: 100003, // Hedge Fund Solutions
    },
    {
        repaymentGroupId: 9,
        repaymentGroupName: 'CLO',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 10,
        repaymentGroupName: 'CCS',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 11,
        repaymentGroupName: 'Drawdown Funds',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 12,
        repaymentGroupName: 'Credit Alpha',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 13,
        repaymentGroupName: 'Hedge Funds',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 14,
        repaymentGroupName: 'BGSL',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 15,
        repaymentGroupName: 'LCS',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 16,
        repaymentGroupName: 'LCS RICs',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 17,
        repaymentGroupName: 'Systematic Strategies',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 18,
        repaymentGroupName: 'Strategic Partners',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 19,
        repaymentGroupName: 'SLP',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 20,
        repaymentGroupName: 'BREP VIII',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 21,
        repaymentGroupName: 'BPP',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 22,
        repaymentGroupName: 'BREP VI',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 23,
        repaymentGroupName: 'BREDS HG Funds',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 24,
        repaymentGroupName: 'BREP IV',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 25,
        repaymentGroupName: 'BREP V',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 26,
        repaymentGroupName: 'BREP Asia',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 27,
        repaymentGroupName: 'BREP VII',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 28,
        repaymentGroupName: 'Co-Invest',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 29,
        repaymentGroupName: 'CORE',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 30,
        repaymentGroupName: 'BREDS Liquids',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 31,
        repaymentGroupName: 'BREP III',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 32,
        repaymentGroupName: 'BREP Europe IV',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 33,
        repaymentGroupName: 'BREP Europe V',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 34,
        repaymentGroupName: 'BREP IX',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 35,
        repaymentGroupName: 'BREDS IV',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 36,
        repaymentGroupName: 'BREP Europe VI',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 37,
        repaymentGroupName: 'BREP Asia III',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 38,
        repaymentGroupName: 'BREP Asia II',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 39,
        repaymentGroupName: 'BREDS III',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 40,
        repaymentGroupName: 'BREP Europe III',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 41,
        repaymentGroupName: 'BREP X',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 42,
        repaymentGroupName: 'BPP Canada',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 43,
        repaymentGroupName: 'BREDS V',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 44,
        repaymentGroupName: 'BTAS',
        businessUnitOId: 4599023, // Multi-Strategy
    },
    {
        repaymentGroupId: 45,
        repaymentGroupName: 'BCOM',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 46,
        repaymentGroupName: 'BCP IV',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 47,
        repaymentGroupName: 'BCTP',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 48,
        repaymentGroupName: 'BCP VII',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 49,
        repaymentGroupName: 'BEP II',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 50,
        repaymentGroupName: 'BEP',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 51,
        repaymentGroupName: 'BCP V',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 52,
        repaymentGroupName: 'BCP Asia',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 53,
        repaymentGroupName: 'BEP III',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 54,
        repaymentGroupName: 'Co-Invest',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 55,
        repaymentGroupName: 'BEP Shared Feeder',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 56,
        repaymentGroupName: 'BCEP',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 57,
        repaymentGroupName: 'CORE',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 58,
        repaymentGroupName: 'BCP VIII',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 59,
        repaymentGroupName: 'BCEP II',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 60,
        repaymentGroupName: 'BCP Asia II',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 61,
        repaymentGroupName: 'BCP VI',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 62,
        repaymentGroupName: 'BCP IX',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 63,
        repaymentGroupName: 'BEP IV',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 64,
        repaymentGroupName: 'BTO',
        businessUnitOId: 7869009, // Tactical Opportunities
    },
    {
        repaymentGroupId: 65,
        repaymentGroupName: 'Infrastructure',
        businessUnitOId: 13933972, // Infrastructure
    },
    {
        repaymentGroupId: 66,
        repaymentGroupName: 'Infrastructure Europe',
        businessUnitOId: 13933972, // Infrastructure
    },
    {
        repaymentGroupId: 67,
        repaymentGroupName: 'Harvest',
        businessUnitOId: 13934001, // Harvest
    },
    {
        repaymentGroupId: 68,
        repaymentGroupName: 'BIS',
        businessUnitOId: 14266842, // Blackstone Insurance Solutions
    },
    {
        repaymentGroupId: 69,
        repaymentGroupName: 'Life Sciences',
        businessUnitOId: 15715285, // Life Sciences
    },
    {
        repaymentGroupId: 70,
        repaymentGroupName: 'BLS',
        businessUnitOId: 2214703, // Treasury Solutions
    },
    {
        repaymentGroupId: 71,
        repaymentGroupName: 'GSO',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 72,
        repaymentGroupName: 'BAAM',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 73,
        repaymentGroupName: 'BTAS',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 74,
        repaymentGroupName: 'BTO',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 75,
        repaymentGroupName: 'OTHER',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 76,
        repaymentGroupName: 'PE',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 77,
        repaymentGroupName: 'RE',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 78,
        repaymentGroupName: 'LS',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 79,
        repaymentGroupName: 'BXG',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 80,
        repaymentGroupName: 'SP SLP',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 81,
        repaymentGroupName: 'BXG',
        businessUnitOId: 18741817, // Growth Equity
    },
    {
        repaymentGroupId: 82,
        repaymentGroupName: '70039 TBGAPL',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 83,
        repaymentGroupName: '70091 BREA',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 84,
        repaymentGroupName: '70038 BTA',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 85,
        repaymentGroupName: '70037 BSPL',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 86,
        repaymentGroupName: '70030 TBGHK',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 87,
        repaymentGroupName: 'Supplemental Accounts',
        businessUnitOId: 4599023, // Multi-Strategy
    },
    {
        repaymentGroupId: 88,
        repaymentGroupName: 'BREDS Resi',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 89,
        repaymentGroupName: 'BETP IV',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 90,
        repaymentGroupName: 'SP GPS',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 91,
        repaymentGroupName: 'SPRA II',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 92,
        repaymentGroupName: 'SP INFRA III',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 93,
        repaymentGroupName: 'SP IX',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 94,
        repaymentGroupName: 'SP VII',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 95,
        repaymentGroupName: 'SP VIII',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 96,
        repaymentGroupName: 'SP VI',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 97,
        repaymentGroupName: 'SP VI RE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 98,
        repaymentGroupName: 'SP IV',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 99,
        repaymentGroupName: 'SP VII RE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 100,
        repaymentGroupName: 'SP V',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 101,
        repaymentGroupName: 'SP III',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 102,
        repaymentGroupName: 'SP VIII RE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 103,
        repaymentGroupName: 'SPNC',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 104,
        repaymentGroupName: 'SP 2007',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 105,
        repaymentGroupName: 'SP IMPACT',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 106,
        repaymentGroupName: 'SP DE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 107,
        repaymentGroupName: 'SP Primary',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 108,
        repaymentGroupName: 'BPP LS',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 109,
        repaymentGroupName: 'BPPE Coinvest',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 110,
        repaymentGroupName: 'BPP US Coinvest',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 111,
        repaymentGroupName: 'BPP US',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 112,
        repaymentGroupName: 'BPPI',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 113,
        repaymentGroupName: 'BPPE',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 114,
        repaymentGroupName: 'BPPA',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 115,
        repaymentGroupName: 'BPPA Coinvest- JPY',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 116,
        repaymentGroupName: 'BPP-L',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 117,
        repaymentGroupName: 'BEPIF',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 118,
        repaymentGroupName: 'BPP US CAD',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 119,
        repaymentGroupName: 'COF',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 120,
        repaymentGroupName: 'COF - COF II',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 121,
        repaymentGroupName: 'COF - COF III Co-Inv',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 122,
        repaymentGroupName: 'COF - Co-Inv D',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 123,
        repaymentGroupName: 'COF - Jasmine',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 124,
        repaymentGroupName: 'DLF',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 125,
        repaymentGroupName: 'DLF - AXA',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 126,
        repaymentGroupName: 'Energy',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 127,
        repaymentGroupName: 'Energy - 3 Bear',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 128,
        repaymentGroupName: 'Energy - Alta',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 129,
        repaymentGroupName: 'Energy - Altus',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 130,
        repaymentGroupName: 'Energy - Bakken',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 131,
        repaymentGroupName: 'Energy - BGREEN III',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 132,
        repaymentGroupName: 'Energy - Blazer',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 133,
        repaymentGroupName: 'Energy - CDCG',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 134,
        repaymentGroupName: 'Energy - CHK',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 135,
        repaymentGroupName: 'Energy - ClearGen',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 136,
        repaymentGroupName: 'Energy - Credit Liquidity',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 137,
        repaymentGroupName: 'Energy - Drillco II',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 138,
        repaymentGroupName: 'Energy - E and P I',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 139,
        repaymentGroupName: 'Energy - Eclipse',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 140,
        repaymentGroupName: 'Energy - EMOF',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 141,
        repaymentGroupName: 'Energy - EP4',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 142,
        repaymentGroupName: 'Energy - EPD',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 143,
        repaymentGroupName: 'Energy - EPE Family',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 144,
        repaymentGroupName: 'Energy - EQM',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 145,
        repaymentGroupName: 'Energy - ESOF',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 146,
        repaymentGroupName: 'Energy - ESOF II',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 147,
        repaymentGroupName: 'Energy - GEPH',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 148,
        repaymentGroupName: 'Energy - Indigo',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 149,
        repaymentGroupName: 'Energy - Loanpal',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 150,
        repaymentGroupName: 'Energy - M5',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 151,
        repaymentGroupName: 'Energy - Master Liquidity',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 152,
        repaymentGroupName: 'Energy - Miami Aggregators',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 153,
        repaymentGroupName: 'Energy - Palmetto',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 154,
        repaymentGroupName: 'Energy - Service Finance',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 155,
        repaymentGroupName: 'Energy - SMAS',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 156,
        repaymentGroupName: 'Energy - Tetra',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 157,
        repaymentGroupName: 'Energy - WPX',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 158,
        repaymentGroupName: 'ESDF',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 159,
        repaymentGroupName: 'Insurance DLF',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 160,
        repaymentGroupName: 'Solutions',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 161,
        repaymentGroupName: 'Solutions - CSF I',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 162,
        repaymentGroupName: 'Solutions - CSF III',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 163,
        repaymentGroupName: 'Solutions - Tiger',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 164,
        repaymentGroupName: 'Solutions 2',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 165,
        repaymentGroupName: 'Solutions CSF III Coinvest',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 166,
        repaymentGroupName: 'Whitney',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 167,
        repaymentGroupName: 'SAS Scholars',
        businessUnitOId: 14266842, // Blackstone Insurance Solutions
    },
    {
        repaymentGroupId: 168,
        repaymentGroupName: 'SLP',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 169,
        repaymentGroupName: 'BOPF',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 170,
        repaymentGroupName: 'BREP Europe VII',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 171,
        repaymentGroupName: 'BXG II',
        businessUnitOId: 18741817, // Growth Equity
    },
    {
        repaymentGroupId: 172,
        repaymentGroupName: 'ECRED',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 173,
        repaymentGroupName: 'SP INFRA IV',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 174,
        repaymentGroupName: 'Lux GPs',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 175,
        repaymentGroupName: 'Pearl SPV',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 176,
        repaymentGroupName: 'BREIT',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 177,
        repaymentGroupName: '70008 BGIH',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 178,
        repaymentGroupName: '70032 BGIP',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 179,
        repaymentGroupName: '70036 TBGG',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 180,
        repaymentGroupName: '70046 BIL',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 181,
        repaymentGroupName: '70057 TBGS',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 182,
        repaymentGroupName: '70077 BIFML',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 183,
        repaymentGroupName: '70085 BREDS',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 184,
        repaymentGroupName: '70094 BEFM',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 185,
        repaymentGroupName: '70098 BREDS III',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 186,
        repaymentGroupName: '70130 BREDS IV',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 187,
        repaymentGroupName: '72903 BEFM France',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 188,
        repaymentGroupName: '72906 BCIL',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 189,
        repaymentGroupName: '72907 BSGH',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 190,
        repaymentGroupName: '72917 BEFM Italy',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 191,
        repaymentGroupName: 'SP Summer',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 196,
        repaymentGroupName: 'UK Finance Group',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 197,
        repaymentGroupName: 'Dublin Finance Group',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 198,
        repaymentGroupName: 'Lux Finance Group',
        businessUnitOId: 999998, // Corporate
    },
    {
        repaymentGroupId: 199,
        repaymentGroupName: 'BXPE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 200,
        repaymentGroupName: 'UK Finance Group',
        businessUnitOId: 100000, // Private Equity
    },
    {
        repaymentGroupId: 202,
        repaymentGroupName: 'UK Finance Group',
        businessUnitOId: 999999, // General Partners Accounting
    },
    {
        repaymentGroupId: 201,
        repaymentGroupName: 'UK Finance Group',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 203,
        repaymentGroupName: 'GP Stakes',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 204,
        repaymentGroupName: 'Private Multi-Asset',
        businessUnitOId: 100002, // Credit
    },
    {
        repaymentGroupId: 205,
        repaymentGroupName: 'PE-BXPE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 206,
        repaymentGroupName: 'PE-BXPE',
        businessUnitOId: 29710353, // BX Private Equity Strategies
    },
    {
        repaymentGroupId: 207,
        repaymentGroupName: 'BPP BAL',
        businessUnitOId: 100001, // Real Estate
    },
    {
        repaymentGroupId: 208,
        repaymentGroupName: 'SP BXPE',
        businessUnitOId: 2430504, // Strategic Partners
    },
    {
        repaymentGroupId: 209,
        repaymentGroupName: 'BOPF',
        businessUnitOId: 100002, // Credit
    },
];
