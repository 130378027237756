import {
    setLoadingState,
    setUserScopes,
    unsetLoadingState,
    useCreationViewContext,
} from '../context/creation-view-context';
import { useEffect, useState } from 'react';

import { IPermissionService } from 'services/interfaces/IPermissionService';
import { LegalEntityRequestPaths } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { filterScopes } from '../LegalEntityCreationViewHelpers';
import { useLocation } from 'react-router-dom';

export default function useCanCreateRequest() {
    const userRequest = ServiceLocator.container.resolve(IPermissionService);
    const { pathname } = useLocation();
    const [canCreateRequest, setCanCreateRequest] = useState<boolean>();
    const { dispatch } = useCreationViewContext();

    useEffect(() => {
        if (pathname === LegalEntityRequestPaths.newLegalEntityRequest) {
            const loadingKey = 'fetching permissions';
            setLoadingState(dispatch, loadingKey);
            userRequest
                .GetPermissionedUser(0)
                .then((response) => {
                    setUserScopes(dispatch, response);

                    const canCreate = filterScopes('Create', response).some((elem: string) => elem === 'Details');
                    setCanCreateRequest(canCreate);

                    unsetLoadingState(dispatch, loadingKey);
                })
                .catch((e) => {
                    unsetLoadingState(dispatch, loadingKey);
                });
        } else {
            setCanCreateRequest(true);
        }
    }, [dispatch, pathname, userRequest]);

    return canCreateRequest;
}
