import { GPInterestType, Investran, KeyValueData } from 'models';
import { useEffect, useMemo } from 'react';
import { validateNumberField, validateRequiredField } from './utils';

import { getRepaymentGroupByBUId } from '../LegalEntityFinanceDetails/utils';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityFinanceDetails } from '../context/Provider';
import { useReferenceData } from 'api';

type Props = {
    isBillingCodesMatch?: boolean;
    isEntityTierVisible?: boolean;
    isGPFundFamilyVisible?: boolean;
    isFundFamilyVisible?: boolean;
};

export default function useFinanceDetailsValidation({
    isBillingCodesMatch,
    isEntityTierVisible,
    isGPFundFamilyVisible,
    isFundFamilyVisible,
}: Props) {
    const {
        state: {
            data: {
                values: {
                    billingCostCenterNumber,
                    businessUnitOId,
                    billingProductNumber,
                    consolidatingStatusInOracleId,
                    costCenterNumber,
                    entityController,
                    investranDomain,
                    investranId,
                    investranFundFamily,
                    investranParentFund,
                    isAgisRequired,
                    pushToOracle = false,
                    oracleGSO,
                    oracleLocationCodeId,
                    oracleLineOfBusinessId,
                    primaryLedgerId,
                    productNumber,
                    repaymentGroupId,
                    treasuryLineOfBusinessId,
                    wssPaymentTypeId,
                    gpEntityTier,
                    gpInterestType,
                    investranGPFundFamily,
                },
                noPendingValues,
            },
            version,
        },
        dispatch,
    } = useLegalEntityFinanceDetails();

    const { data } = useReferenceData();

    const {
        RepaymentGroup,
    } = data;

    const repaymentGroupByBUId = useMemo(
        () => getRepaymentGroupByBUId(noPendingValues?.businessUnitOId, RepaymentGroup),
        [noPendingValues, RepaymentGroup]
    );

    useEffect(() => {
        function validateRepaymentGroupId(repaymentGroupId: number | undefined) {
            const hasRepaymentGroup = repaymentGroupByBUId.length > 0;
            if (
                hasRepaymentGroup &&
                repaymentGroupId &&
                !repaymentGroupByBUId.find((rg) => rg.Key === repaymentGroupId) &&
                businessUnitOId
            ) {
                return 'Business Unit for this entity has changed. Please update to a new Repayment Group.';
            }
            return validateRequiredField(repaymentGroupId, {
                isRequired: pushToOracle && hasRepaymentGroup,
            });
        }

        updateValidation(dispatch, {
            billingCostCenterNumber: validateNumberField({
                value: billingCostCenterNumber,
                minimumLength: 5,
                isRequired: !isBillingCodesMatch,
            }),
            billingProductNumber: validateNumberField({
                value: billingProductNumber,
                minimumLength: 4,
                isRequired: !isBillingCodesMatch,
            }),
            consolidatingStatusInOracleId: pushToOracle
                ? validateRequiredField(consolidatingStatusInOracleId)
                : undefined,
            costCenterNumber: pushToOracle
                ? validateNumberField({ value: costCenterNumber, minimumLength: 5 })
                : undefined,
            entityController: validateRequiredField(entityController, {
                isRequired: pushToOracle,
            }),
            oracleGSO: validateRequiredField(oracleGSO, {
                isRequired: pushToOracle && businessUnitOId === KeyValueData.CreditGSO,
            }),
            investranId: validateRequiredField(investranId),
            investranDomain: validateRequiredField(investranDomain, {
                isRequired: investranId !== Investran.NonInvestran,
            }),
            investranFundFamily: validateRequiredField(investranFundFamily, {
                isRequired: investranId !== Investran.NonInvestran && isFundFamilyVisible,
            }),
            investranParentFund: validateRequiredField(investranParentFund, {
                isRequired: investranId !== Investran.NonInvestran && !investranParentFund,
            }),
            isAgisRequired: validateRequiredField(isAgisRequired),
            oracleLocationCodeId: pushToOracle ? validateRequiredField(oracleLocationCodeId) : undefined,
            oracleLineOfBusinessId: pushToOracle ? validateRequiredField(oracleLineOfBusinessId) : undefined,
            paymentsToWss: pushToOracle ? validateRequiredField(oracleLineOfBusinessId) : undefined,
            primaryLedgerId: pushToOracle ? validateRequiredField(primaryLedgerId) : undefined,
            productNumber: pushToOracle ? validateNumberField({ value: productNumber, minimumLength: 4 }) : undefined,
            repaymentGroupId: validateRepaymentGroupId(repaymentGroupId),
            treasuryLineOfBusinessId: pushToOracle ? validateRequiredField(treasuryLineOfBusinessId) : undefined,
            wssPaymentTypeId: pushToOracle ? validateRequiredField(wssPaymentTypeId) : undefined,
            gpEntityTier: validateRequiredField(gpEntityTier, {
                isRequired: isEntityTierVisible,
            }),
            gpInterestType: validateRequiredField(gpInterestType, {
                isRequired: investranId === Investran.Investran,
            }),
            investranGPFundFamily: validateRequiredField(investranGPFundFamily, {
                isRequired:
                    investranId !== Investran.NonInvestran &&
                    isGPFundFamilyVisible &&
                    gpInterestType !== GPInterestType.SideBySide,
            }),
        });
    }, [
        billingCostCenterNumber,
        billingProductNumber,
        consolidatingStatusInOracleId,
        costCenterNumber,
        dispatch,
        entityController,
        investranDomain,
        investranFundFamily,
        investranId,
        investranParentFund,
        isAgisRequired,
        isBillingCodesMatch,
        oracleLineOfBusinessId,
        oracleLocationCodeId,
        primaryLedgerId,
        productNumber,
        repaymentGroupId,
        treasuryLineOfBusinessId,
        wssPaymentTypeId,
        version,
        oracleGSO,
        pushToOracle,
        businessUnitOId,
        repaymentGroupByBUId,
        gpEntityTier,
        gpInterestType,
        investranGPFundFamily,
        isFundFamilyVisible,
        isEntityTierVisible,
        isGPFundFamilyVisible,
    ]);
}
