import { Expander, Select, TextField } from 'components/';
import React, { useEffect, useMemo } from 'react';

import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SPACING } from '../../../../../Utilities/Layout';
import { StackPanel } from '@bxgrandcentral/controls';
import { isHedgeFund } from '../../../utils';
import { useLegalEntityRequest } from '../../../context/Provider';
import useLegalNameValidation from '../../../validation/use-legal-name-validation';
import { useReferenceData } from '../../../../../api';

export default function LegalEntityBackground() {
    const {
        state: {
            isEditable,
            data: {
                values: { businessUnitOId, entityOid, legalName, shortName },
            },
        },
        dispatch,
        getValue,
        setValue,
    } = useLegalEntityRequest();

    const {
        data: { BusinessDriver },
    } = useReferenceData();

    const isHedgeFundSelected = useMemo(() => isHedgeFund(businessUnitOId), [businessUnitOId]);

    useLegalNameValidation({ entityOid, legalName, dispatch, isEditable });

    useEffect(() => {
        if (isEditable && !isHedgeFundSelected) {
            setValue('shortName', undefined);
        }
    }, [isEditable, isHedgeFundSelected, shortName, setValue]);

    return (
        <Expander
            header='Background'
            requestStep={RequestStepsView.Request}
            content={
                <StackPanel itemGap={SPACING.SM}>
                    <Select
                        label='Business Driver'
                        labelToolTip={{ component: 'Purpose of legal entity creation' }}
                        itemsSource={BusinessDriver}
                        {...getValue('businessDriverId')}
                        isRequired
                    />
                    <TextField
                        label='Legal Name'
                        labelToolTip="Legal entity's full legal name that should be unique"
                        isRequired
                        {...getValue('legalName')}
                    />
                    {isHedgeFundSelected && (
                        <TextField
                            label='Short Name'
                            labelToolTip='Name used for display purposes'
                            isRequired
                            {...getValue('shortName')}
                        />
                    )}
                </StackPanel>
            }
        />
    );
}
