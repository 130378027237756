import { Border, SPACING } from '../../../../Utilities/Layout';
import { FileListLayout, FileListStampedHeader } from '../FileUpload.styled';
import { Grid, StackPanel, TextBlock, ThemeManager, ToolBarButton } from '@bxgrandcentral/controls';
import React, { Fragment, useCallback, useState } from 'react';
import { SECTION_NAMES, SectionName } from 'modules/LegalEntityCreation/models';

import { BooleanField } from 'components/controls';
import { FileListProps } from '../models';
import { GlobalState } from '../../../../GlobalState';
import { IDocumentService } from '../../../../services/interfaces/IDocumentService';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { Spinner } from 'components';
import StoredDocument from './StoredDocument';
import { getSectionName } from '../utils';
import { isEmpty } from '../../../../Utilities/Validations';
import { noop } from 'lodash';
import { saveAs } from 'file-saver';

export default function FileList(props: FileListProps) {
    const {
        entityOId,
        isEditable = false,
        documents = [],
        header,
        noDocumentLabel,
        sectionName,
        isFetching,
        isStampedDocumentUpload,
        onDocumentStateChanged = noop,
    } = props;

    const service = ServiceLocator.container.resolve(IDocumentService);

    const [clickedDocumentIds, setClickedDocumentIds] = useState<number[]>([]);

    async function downloadDocument(documentId: number, fileName: string) {
        setClickedDocumentIds((current) => current.concat(documentId));
        await service
            .DownloadFile(entityOId, documentId)
            .then((response) => {
                setClickedDocumentIds((current) => current.filter((id) => id !== documentId));
                const blob = new Blob([response], { type: 'application/octet-stream' });
                saveAs(blob, fileName);
            })
            .catch(() => {
                GlobalState.ShowMessageBox('ERROR', 'Failed to download documents.');
            });
    }

    const {
        activeTheme: {
            colors: { accentColor, disabledColor, normalControlBorderColor },
        },
    } = ThemeManager;

    const getUpdatedSectionName = useCallback(
        (value: SectionName) => {
            return value === SECTION_NAMES.STAMPED ? sectionName : SECTION_NAMES.STAMPED;
        },
        [sectionName]
    );

    if (isFetching) {
        return null;
    }

    if (isEmpty(documents)) {
        return noDocumentLabel ? (
            <TextBlock
                text={noDocumentLabel}
                styleName='captionStyle'
                verticalAlignment='center'
                textAlignment='center'
            />
        ) : null;
    }

    return (
        <Grid itemGap={SPACING.MD}>
            <TextBlock text={header} styleName='captionStyle' />
            {isStampedDocumentUpload && (
                <FileListStampedHeader isEditable={isEditable}>
                    <TextBlock
                        text='Stamped'
                        styleName='captionStyle'
                        styleOverrides={{ marginBottom: `-${SPACING.XS}px` }}
                    />
                    <TextBlock
                        text='Effective Date'
                        styleName='captionStyle'
                        styleOverrides={{ marginBottom: `-${SPACING.XS}px`, textAlign: isEditable ? 'left' : 'right' }}
                    />
                </FileListStampedHeader>
            )}
            <Grid itemGap={SPACING.XS}>
                {documents.map(
                    (
                        {
                            document: {
                                documentElements,
                                documentOId: { value },
                                originalFileName,
                            },
                            updates: { isDeleted, sectionName: updatedSectionName },
                        },
                        index
                    ) => (
                        <Fragment key={index}>
                            {index > 0 && !isStampedDocumentUpload && (
                                <Border margin={SPACING.XXS} color={normalControlBorderColor} />
                            )}
                            <FileListLayout isEditable={isEditable}>
                                {clickedDocumentIds.find((id) => id === value) ? (
                                    <Spinner />
                                ) : (
                                    <StackPanel orientation='horizontal'>
                                        <ToolBarButton
                                            icon='Download'
                                            fontSize={18}
                                            width={34}
                                            isEnabled={!isDeleted}
                                            foregroundColor={isDeleted ? disabledColor : accentColor}
                                            toolTip='Click to download attachment'
                                            padding={`${SPACING.XS}px 0`}
                                            styleOverrides={{ alignSelf: 'flex-start' }}
                                            onClick={() => downloadDocument(value, originalFileName)}
                                        />
                                        {isStampedDocumentUpload && (
                                            <BooleanField
                                                toolTip='Is stamped document'
                                                value={
                                                    (updatedSectionName ?? getSectionName(documentElements)) ===
                                                    SECTION_NAMES.STAMPED
                                                }
                                                trueValueLabel=''
                                                falseValueLabel=''
                                                variant='dashboard'
                                                canUndo={false}
                                                isEditable={isEditable && !isDeleted}
                                                onValueChanged={() => {
                                                    onDocumentStateChanged(value, {
                                                        sectionName: getUpdatedSectionName(
                                                            updatedSectionName ?? getSectionName(documentElements)
                                                        ),
                                                    });
                                                }}
                                            />
                                        )}
                                    </StackPanel>
                                )}
                                <StoredDocument index={index} {...props} />
                            </FileListLayout>
                        </Fragment>
                    )
                )}
            </Grid>
        </Grid>
    );
}
