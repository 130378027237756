import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CreationViewContextProvider } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import EnvironmentView from 'modules/EnvironmentView/EnvironmentView';
import { LegalEntityCreationView } from '../LegalEntityCreation/LegalEntityCreationView/LegalEntityCreationView';
import { LegalEntityRequestPaths } from './LegalEntityRequestPaths';
import { MatchLegalEntitiesView } from '../LegalEntityCreation/MatchEntities/MatchLegalEntitiesView';
import RequestDashboard from './RequestDashboard';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { SettingsService } from '../../services/SettingsService';
import { isLowerEnvironment } from 'Utilities/Environment';
import { useAppContext } from '../../context/app-context';
import useControlLeftNavBar from 'hooks/use-control-left-navbar';

export const RequestsDashboardModule: React.FC = () => {
    const { state } = useAppContext();

    const settingsService = ServiceLocator.container.resolve(SettingsService);

    useControlLeftNavBar();

    useEffect(() => {
        settingsService.HandleAppSettings(state.settings);
    }, [settingsService, state.settings]);

    return (
        <Switch>
            <Route exact path={LegalEntityRequestPaths.dashboard}>
                <RequestDashboard />
            </Route>
            <Route exact path={LegalEntityRequestPaths.newLegalEntityRequest}>
                <CreationViewContextProvider>
                    <LegalEntityCreationView />
                </CreationViewContextProvider>
            </Route>
            <Route path={LegalEntityRequestPaths.editLegalEntityRequestPattern}>
                <CreationViewContextProvider>
                    <LegalEntityCreationView />
                </CreationViewContextProvider>
            </Route>
            <Route path={LegalEntityRequestPaths.search}>
                <MatchLegalEntitiesView />
            </Route>
            {isLowerEnvironment() && (
                <Route path={LegalEntityRequestPaths.environment}>
                    <EnvironmentView />
                </Route>
            )}
            <Route>
                <Redirect to={LegalEntityRequestPaths.dashboard} />
            </Route>
        </Switch>
    );
};
