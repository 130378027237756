import React, { useContext } from 'react';

import { REQUEST_CREATION_DEFAULT_VALUES } from 'modules/LegalEntityCreation/models';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { ToolBarButton } from '@bxgrandcentral/controls';
import { UserInfoContext } from '@bxgrandcentral/shell';
import { generateLegalNameValue } from 'modules/LegalEntityCreation/utils';
import styled from 'styled-components';

type Props = {
    requestStep?: string;
    isEnabled: boolean;
    dispatch: any;
};

export default function AutoFill({ requestStep = '', isEnabled, dispatch }: Props) {
    const userinfo = useContext(UserInfoContext);

    const handleClick = () => {
        const values = REQUEST_CREATION_DEFAULT_VALUES[requestStep];

        if (requestStep === RequestStepsView.Request && values?.legalName && userinfo?.displayName) {
            values.legalName = generateLegalNameValue(userinfo.displayName);
        }

        dispatch({
            type: 'SET_VALUES',
            values: values,
            isUserChange: true,
        });
    };

    return (
        <Component>
            <ToolBarButton
                icon='AutoEnhanceOff'
                label='Fill out'
                showLabel
                isEnabled={isEnabled}
                onClick={handleClick}
                styleOverrides={{ borderRadius: '4px 4px 0 0' }}
            />
        </Component>
    );
}

const Component = styled.div`
    position: fixed;
    top: 0;
    right: 10px;
    overflow: hidden;
`;
