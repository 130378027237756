import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionBDGData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionBdg/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionBDGData => {
    const { dissolutionData, entity } = entityDto;

    return {
        entityOid: entity.entityOId,
        appSupport: dissolutionData?.dissolutionBDG?.appSupport,
        registeredAgent: dissolutionData?.dissolutionBDG?.registeredAgent,
        signatures: dissolutionData?.dissolutionBDG?.signatures,
        stateJurisdiction: dissolutionData?.dissolutionBDG?.stateJurisdiction,
        finalConfirmations: dissolutionData?.dissolutionBDG?.finalConfirmations,
        updateDetails: dissolutionData?.dissolutionBDG?.updateDetails,
        dissolutionDate: dissolutionData?.dissolutionBDG?.dissolutionDate,
        savedBy: dissolutionData?.dissolutionBDG?.savedBy,
        savedAt: dissolutionData?.dissolutionBDG?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionBDGData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;

    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionBDG: {
            ...original?.dissolutionData?.dissolutionBDG,
            ...section,
        },
    };

    return toReturn;
};
