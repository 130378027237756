import { MEDIUM_SCREEN_WIDTH, SPACING } from 'Utilities/Layout';

import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';

export const FileUploadContainer = styled.div<{ colors: ThemeColors }>`
    min-height: 60px;
    display: grid;
    grid-gap: ${SPACING.XXS}px;
    background-color: ${({ colors }) => colors.systemGray6};
    border-radius: 0 0 8px 8px;
`;

export const PendingDocuments = styled.div<{ colors: ThemeColors; useTopBorder: boolean }>`
    display: grid;
    grid-gap: ${SPACING.LG}px;
    padding: ${SPACING.LG}px ${SPACING.XL}px;
    border-top: ${({ colors, useTopBorder }) => `${useTopBorder ? 1 : 0}px solid ${colors.normalControlBorderColor}`};
    border-bottom: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};
`;

export const FileUploadBorder = styled.div<{ colors: ThemeColors }>`
    border: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};
    border-radius: 8px;
`;

export const FileListPanel = styled.div<{ colors: ThemeColors }>`
    display: grid;
    grid-gap: ${SPACING.LG}px;
    padding: ${SPACING.LG}px ${SPACING.XL}px;
    border-bottom: ${({ colors }) => `solid ${colors.normalControlBorderColor}`};
`;

export const FileListLayout = styled.div<{ isEditable?: boolean }>`
    display: grid;
    grid-gap: ${SPACING.SM}px;
    grid-auto-flow: column;
    grid-template-columns: ${({ isEditable = false }) => `auto 1fr ${isEditable ? '30px' : ''}`};
`;

export const FileListStampedHeader = styled.div<{
    isEditable?: boolean;
}>`
    display: grid;
    grid-gap: ${SPACING.SM}px;
    grid-auto-flow: column;
    padding-left: ${SPACING.XS + SPACING.XL}px;
    grid-template-columns: ${({ isEditable }) => `1fr 200px  ${isEditable ? '30px' : '37px'}`};
`;

export const FileListTreeViewHeader = styled.div<{
    isEditable?: boolean;
}>`
    display: grid;
    grid-gap: ${SPACING.SM}px;
    grid-auto-flow: column;
    grid-template-columns: ${({ isEditable }) => `30px 1fr 150px  ${isEditable ? '30px' : '37px'}`};
    padding-top: ${SPACING.XS}px;
`;

export const FileLayout = styled.div<{ hasEffectiveDate?: boolean; isEditable?: boolean }>`
    display: grid;
    grid-gap: ${SPACING.SM}px;
    grid-auto-flow: column;
    grid-template-columns: ${({ hasEffectiveDate, isEditable }) =>
        `auto 180px ${hasEffectiveDate ? '200px' : ''} ${!isEditable ? '38px' : ''}`};

    @media screen and (max-width: ${MEDIUM_SCREEN_WIDTH}px) {
        grid-template-columns: none;
        grid-row-gap: ${SPACING.XXS}px;
        & * {
            text-align: left;
            line-height: 12px;
        }
        & :first-child {
            grid-column: 1 / 3;
        }
        & :not(:first-child) {
            grid-row: 2;
            justify-self: start;
        }
    }
`;

export const DocumentLayout = styled.div<{
    hasEffectiveDate?: boolean;
    isEditable?: boolean;
}>`
    display: grid;
    grid-gap: ${SPACING.SM}px;
    grid-auto-flow: column;
    padding-left: ${SPACING.XS}px;
    grid-template-columns: ${({ hasEffectiveDate }) => `3fr ${hasEffectiveDate ? '3fr' : ''} 200px 30px`};
`;
