import { Debugger, Expander, Footer, Form, Select, Text, TextField } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import { interCompanyBalanceDashboardLink, trialBalanceDashboardLink } from './utils';

import ActionPanel from './components/ActionPanel/ActionPanel';
import BalanceCheck from '../components/BalanceCheck';
import { CompletionState } from 'models/LegalEntityRequest/DefaultValues';
import { DISSOLUTION_TREASURY_STATE } from 'models/LegalEntityRequest/Workflow';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import { LegalEntityDissolutionTreasuryProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionTreasuryValidation from 'modules/LegalEntityCreation/validation/use-dissolution-treasury-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionTreasury } from '../../context/Provider';

export default function LegalEntityDissolutionTreasury(props: LegalEntityDissolutionTreasuryProps) {
    const context = useLegalEntityDissolutionTreasury();
    const {
        state: {
            data: {
                values: { entityOid, savedAt, savedBy },
            },
        },
        getValue,
    } = context;

    const { workItem } = props;

    const {
        state: { legalEntityDissolutionTreasuryWorkItem },
    } = useCreationViewContext();
    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionTreasuryWorkItem,
        saved: {
            savedBy,
            savedAt,
        },
    });

    useDissolutionTreasuryValidation();

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Treasury} />
            <Form>
                <Expander
                    header='Bank Accounts'
                    requestStep={RequestStepsView.Dissolution_Treasury}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={'0 0 0 0'}>
                            <GridLayout columns='1fr 1fr'>
                                <StackPanel>
                                    <Select
                                        label='Check that all bank accounts are closed on the Treasury system'
                                        labelToolTip={{
                                            component:
                                                'Treasury team to close bank accounts. Accounts including banking, trading, and ISDA. Ensure that various arrangements are addressed as these tasks may be completed by various different teams (Ops/Middle Office/GFF). All entity and fund level bank accounts should be closed. Get confirmations.',
                                            options: { showDuration: 15000 },
                                        }}
                                        itemsSource={CompletionState}
                                        {...getValue('allAccountsClosed')}
                                        isRequired
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                                <StackPanel>
                                    <Select
                                        label='Close any accounts opened outside of BX Treasury'
                                        labelToolTip={{
                                            component: 'Coordinate with 3rd party banks to close accounts.',
                                        }}
                                        itemsSource={CompletionState}
                                        {...getValue('outsideAccountsClosed')}
                                        isRequired
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <Expander
                    header='Zero Balances'
                    requestStep={RequestStepsView.Dissolution_Treasury}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={'0 0 0 0'}>
                            <GridLayout columns='1fr 1fr'>
                                <StackPanel>
                                    <Select
                                        label={`Entity's trial balance is zero at the natural account, product, cost center, and intercompany code level.`}
                                        labelToolTip={{
                                            component: `All account balances in the entity's trial balance are zero. If they are not, you will be prevented from approving the page.`,
                                        }}
                                        height={120}
                                        itemsSource={CompletionState}
                                        {...getValue('trialBalanceZero')}
                                        isRequired
                                        isPendingChange={false}
                                        bottomDescription={trialBalanceDashboardLink}
                                    />
                                </StackPanel>
                                <StackPanel>
                                    <Select
                                        label={`Under all other entities' trial balances, there are no open intercompany balances with the entity that is being closed`}
                                        itemsSource={CompletionState}
                                        height={120}
                                        {...getValue('noOpenIntercompanyBalances')}
                                        isRequired
                                        isPendingChange={false}
                                        bottomDescription={interCompanyBalanceDashboardLink}
                                    />
                                </StackPanel>
                            </GridLayout>
                            <BalanceCheck isEnabled={props.mode === 'Edit'} />
                        </StackPanel>
                    }
                />
                <Expander
                    header='WSS'
                    requestStep={RequestStepsView.Dissolution_Treasury}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={'0 0 0 0'}>
                            <GridLayout columns='1fr 1fr'>
                                <StackPanel>
                                    <Select
                                        label='Close the entity in WSS'
                                        labelToolTip={{ component: 'Close the entity in WSS.' }}
                                        itemsSource={CompletionState}
                                        {...getValue('closeInWSS')}
                                        isRequired
                                        isPendingChange={false}
                                    />
                                </StackPanel>
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <Expander
                    header='Approver Notes and Status'
                    toolTip='This section is used to enter approver notes that help other approvers. It is also used to communicate in-progress status. Please enter in-progress status and hit Save during the in-progress steps.'
                    toolTipService={{ showDuration: 10000 }}
                    content={
                        <div className='text-area-hide-scrollbar'>
                            <TextField
                                minHeight={120}
                                maxHeight={240}
                                variant='default'
                                textWrapping='wrap'
                                padding={`${SPACING.XS}px 0`}
                                canUndo={false}
                                {...getValue('approverNotes')}
                                isPendingChange={false}
                            />
                        </div>
                    }
                />
                <DissolutionRejectReason
                    workflowType={WorkflowType.LegalEntityDissolutionTreasuryWorkflowType}
                    currentWorkItemState={workItem?.workItemState}
                    workItemStateToExclude={DISSOLUTION_TREASURY_STATE.DISSOLUTION_APPROVED}
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode === 'Edit' && ActionPanel && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
