import { Border, HORIZONTAL_FORM_PADDING, SPACING } from '../../../../../Utilities/Layout';
import { Button, StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { Expander, Text } from 'components/';
import React, { Fragment, useMemo } from 'react';

import InformationAddress from './InformationAddress';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { isEmpty } from '../../../../../Utilities/Validations';
import useAddressesValidation from '../../../validation/use-addresses-validation';
import useColors from 'api/hooks/use-theme';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';

export default function InformationAddresses() {
    const {
        state: {
            data: {
                values: { addresses = [] },
                noPendingValues,
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityInformation();

    function addAddress() {
        setValue('addresses', [...addresses.concat({ isDeleted: false })]);
    }

    const noPendingAddresses = noPendingValues?.addresses;

    const { validations } = useAddressesValidation();

    const { normalControlBorderColor } = useColors();

    const shouldShowHeadQuarterAddress = useMemo(() => {
        const { length: pendingAddresses } = addresses.filter(({ isDeleted }) => !isDeleted);
        const { length: headQuarters } = addresses?.filter(({ addressTypeId }) => addressTypeId === 1);
        return !pendingAddresses && !headQuarters;
    }, [addresses]);

    return (
        <Expander
            header='Addresses'
            requestStep={RequestStepsView.Legal_Entity_Information}
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL}>
                    {isEmpty(addresses) ? (
                        <TextBlock styleName='captionStyle' padding={HORIZONTAL_FORM_PADDING}>
                            No address added
                        </TextBlock>
                    ) : (
                        addresses.map((_, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Border color={normalControlBorderColor} />}
                                <InformationAddress
                                    index={index}
                                    validation={validations[index]}
                                    noPendingValues={noPendingAddresses?.[index]}
                                />
                                {shouldShowHeadQuarterAddress && (
                                    <Text
                                        horizontalAlignment='center'
                                        verticalAlignment='center'
                                        textAlignment='center'
                                        variant='errorBold'>
                                        A Headquarter Address is required
                                    </Text>
                                )}
                            </Fragment>
                        ))
                    )}
                </StackPanel>
            }
            {...(isEditable && {
                footer: <Button content='Add Address' isEnabled={isEditable} onClick={addAddress} />,
            })}
        />
    );
}
