import { Debugger, Expander, Footer, Form, Select, TextField } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import React, { useEffect } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { CompletionState } from 'models/LegalEntityRequest/DefaultValues';
import { DISSOLUTION_LITIGATION_STATE } from 'models/LegalEntityRequest/Workflow';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import { LegalEntityDissolutionLitigationProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { updateValidation } from 'modules/LegalEntityCreation/context/actions';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionLitigation } from '../../context/Provider';
import { validateCompleted } from 'modules/LegalEntityCreation/validation/utils';

export default function LegalEntityDissolutionLitigation(props: LegalEntityDissolutionLitigationProps) {
    const context = useLegalEntityDissolutionLitigation();

    const {
        state: { legalEntityDissolutionLitigationWorkItem },
    } = useCreationViewContext();

    const {
        state: {
            data: {
                values: { entityOid, noOutstandingLegalMatters, savedAt, savedBy },
            },
            version,
        },
        dispatch,
        getValue,
    } = context;

    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionLitigationWorkItem,
        saved: {
            savedBy,
            savedAt,
        },
    });

    const { workItem, mode } = props;

    useEffect(() => {
        updateValidation(dispatch, {
            noOutstandingLegalMatters: validateCompleted(noOutstandingLegalMatters),
        });
    }, [dispatch, noOutstandingLegalMatters, version]);

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Litigation} />
            <Form>
                <Expander
                    header='Legal Entity Dissolution - Litigation'
                    requestStep={RequestStepsView.Dissolution_Litigation}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={`${SPACING.XL}px 0 0 0`}>
                            <GridLayout columns='2fr 1fr'>
                                <StackPanel>
                                    <TextBlock
                                        text='Confirm that there are no pending legal matters related to this entity.'
                                        toolTip='Reach out to law firms and necessary parties to confirm that the entity is not engaged in an active legal matter.
                                        If there are any pending legal matters, please reject the dissolution request and provide the reason to the requestor.
                                        If there are no pending legal matters, please approve the request and include the date in which there are no pending legal matters as of.'
                                        toolTipService={{ showDuration: 10000 }}
                                        margin={`${SPACING.XS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    {...getValue('noOutstandingLegalMatters')}
                                    isPendingChange={false}
                                    isRequired
                                />
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <Expander
                    header='Approver Notes and Status'
                    toolTip='This section is used to enter approver notes that help other approvers. It is also used to communicate in-progress status. Please enter in-progress status and hit Save during the in-progress steps.'
                    toolTipService={{ showDuration: 10000 }}
                    content={
                        <div className='text-area-hide-scrollbar'>
                            <TextField
                                minHeight={120}
                                maxHeight={240}
                                variant='default'
                                textWrapping='wrap'
                                padding={`${SPACING.XS}px 0`}
                                canUndo={false}
                                {...getValue('approverNotes')}
                                isPendingChange={false}
                            />
                        </div>
                    }
                />
                <DissolutionRejectReason
                    workflowType={WorkflowType.LegalEntityDissolutionLitigationWorkflowType}
                    currentWorkItemState={workItem?.workItemState}
                    workItemStateToExclude={DISSOLUTION_LITIGATION_STATE.DISSOLUTION_APPROVED}
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {mode === 'Edit' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
