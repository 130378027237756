import { LogError, SearchEntity } from '../../models';

import { ElasticSearchResult } from '../../models/LegalEntitySearch/ElasticSearchResult';
import { EntityMasterSearchRequestV4 } from '../../models/LegalEntitySearch/SearchRequest';

export abstract class IEMSearchService {
    abstract logInfo: LogError;

    public abstract searchLegalEntity<T extends Partial<SearchEntity>>(
        request: EntityMasterSearchRequestV4<T>,
        abortController?: AbortController
    ): Promise<ElasticSearchResult<T>>;
    public abstract getLegalEntitiesByField<T extends Partial<SearchEntity>>(
        request: EntityMasterSearchRequestV4<T>,
        field: keyof T
    ): Promise<ElasticSearchResult<T>>;
}
