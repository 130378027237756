import { useEffect, useState } from 'react';

import { Brn } from '../models';
import { IPermissionService } from '../services/interfaces/IPermissionService';
import { ServiceLocator } from '@bxgrandcentral/shell';

type UserInformation = {
    brns: Brn[];
};

export default function useUserInformation() {
    const service = ServiceLocator.container.resolve(IPermissionService);
    const [userInformation, setUserInformation] = useState<UserInformation>({ brns: [] });

    useEffect(() => {
        service.GetPolicyServerCapabilities().then((policies) => {
            setUserInformation({ brns: policies.map(({ brn }) => brn) });
        });
    }, [service]);

    return { ...userInformation };
}
