import { Expander, TextField } from 'components';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { useCreationViewContext } from '../LegalEntityCreationView/context/creation-view-context';

type Props = {
    workflowType?: string;
    currentWorkItemState?: string;
    workItemStateToExclude?: string;
};

export default function DissolutionRejectReason({ workflowType, currentWorkItemState, workItemStateToExclude }: Props) {
    const {
        state: { latestRejectedDissolutionWorkflow },
    } = useCreationViewContext();

    const showRejectReason =
        latestRejectedDissolutionWorkflow?.workflowType === workflowType &&
        currentWorkItemState !== workItemStateToExclude;

    if (!showRejectReason) {
        return null;
    }

    return (
        <Expander
            header='Reason For Rejection'
            content={
                <div className='text-area-hide-scrollbar'>
                    <TextField
                        minHeight={120}
                        maxHeight={240}
                        variant='default'
                        textWrapping='wrap'
                        padding={`${SPACING.XS}px 0`}
                        canUndo={false}
                        isPendingChange={false}
                        isReadOnly
                        value={latestRejectedDissolutionWorkflow?.rejectReason}
                    />
                </div>
            }
        />
    );
}
