import LegalEntityDissolutionLegal from './LegalEntityDissolutionLegal';
import { LegalEntityDissolutionLegalProps } from './model';
import { LegalEntityDissolutionLegalProvider } from '../../context/Provider';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import useDissolutionLegalAutopopulateData from './hooks/use-dissolution-legal-autopopulate-data';

export default function LegalEntityDissolutionTaxSection(props: LegalEntityDissolutionLegalProps) {
    const { calculateDefaultValues } = useDissolutionLegalAutopopulateData();

    return (
        <LegalEntityDissolutionLegalProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_Legal}>
            <LegalEntityDissolutionLegal {...props} />
        </LegalEntityDissolutionLegalProvider>
    );
}
