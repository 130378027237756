import {
    AdvancedFieldOptionItem,
    EntityMasterSearchRequestV4,
    FieldSearchType,
    RequestedSearchItem,
    SearchAnalyzerType,
    SearchOperatorType,
    SearchType,
    SubfieldToSearch,
} from '../../models/LegalEntitySearch/SearchRequest';
import { LogError, SearchEntity } from '../../models';

import ApiService from '../ApiService';
import { ElasticSearchResult } from '../../models/LegalEntitySearch/ElasticSearchResult';
import { IEMSearchService } from '../interfaces/IEMSearchService';
import { constants } from '../../constants/env-config';

export class EMSearchService implements IEMSearchService {
    public logInfo: LogError = {
        service: 'Legal Entity Request Service',
        module: 'Global',
    };

    public baseURL: string = constants.ENTITY_MASTER_API;

    private completeRequest(searchItems: RequestedSearchItem[]): RequestedSearchItem[] {
        let advancedFieldOptionItem: AdvancedFieldOptionItem[] = [
            {
                subFieldToSearch: SubfieldToSearch.SearchAutoCompleteTerms,
            },
        ];
        let fieldSearchType = FieldSearchType.SingleField_MatchTerm;
        searchItems.forEach((searchItem) => {
            if (searchItem.fields.length > 0) {
                fieldSearchType =
                    searchItem.fields.length === 1
                        ? FieldSearchType.SingleField_MatchTerm
                        : FieldSearchType.MultiField_MostFields;
            }
            searchItem.fieldSearchType = fieldSearchType;
            searchItem.searchAnalyzerType = SearchAnalyzerType.Standard;
            searchItem.searchOperator = searchItem.searchOperator ?? SearchOperatorType.And;
            searchItem.searchInclusionType = searchItem.searchInclusionType ?? SearchType.Include;
            searchItem.minimumShouldMatch = '100%';
            searchItem.fields.forEach((field) => {
                field.advancedFieldOptions = field.advancedFieldOptions
                    ? field.advancedFieldOptions
                    : advancedFieldOptionItem;
            });
        });

        return searchItems;
    }

    public searchLegalEntity<T extends Partial<SearchEntity>>(
        request: EntityMasterSearchRequestV4<T>,
        abortController?: AbortController
    ): Promise<ElasticSearchResult<T>> {
        // Complete request with default elements
        if (request?.indexSearchRequest?.searchItems) {
            request.indexSearchRequest.searchItems = this.completeRequest(request?.indexSearchRequest?.searchItems);
        }

        return ApiService.Post(
            `${this.baseURL}search/search-request/${request.indexType}`,
            this.logInfo,
            request,
            true,
            false,
            false,
            abortController
        )
            .then((x: ElasticSearchResult<T>) => {
                return x;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    public getLegalEntitiesByField<T extends Partial<SearchEntity>>(
        request: EntityMasterSearchRequestV4<T>
    ): Promise<ElasticSearchResult<T>> {
        // Complete request with default elements
        if (request?.indexSearchRequest?.searchItems) {
            request.indexSearchRequest.searchItems = this.completeRequest(request?.indexSearchRequest?.searchItems);
        }
        return ApiService.Post(
            `${this.baseURL}search/search-request/${request.indexType}`,
            this.logInfo,
            request,
            true
        );
    }
}
