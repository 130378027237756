import { Grid, SelectorField, SelectorFieldProps, TextBlock, ToolTipService } from '@bxgrandcentral/controls';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { isEqual, isNull, isUndefined } from 'lodash';

import { FormControl } from '../controls.styled';
import { KeyValue } from 'models';
import { SPACING } from 'Utilities/Layout';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import useColors from 'api/hooks/use-theme';
import { usePrevious } from 'api/hooks/use-previous';

type Props = Omit<SelectorFieldProps, 'validationError'> & {
    height?: number;
    itemsSource?: KeyValue[];
    valuePath?: string;
    version?: number;
    labelToolTip?: {
        component: any;
        options?: ToolTipService;
    };
    isPendingChange?: boolean;
    showError?: boolean;
    validationError?: string;
    variant?: 'form' | 'dashboard' | 'default';
    bottomDescription?: string | ReactNode;
};

export default function Select({
    isEditable,
    isRequired,
    validationError,
    showError,
    value,
    label,
    labelToolTip,
    onValueChanged,
    height,
    selectedValuePath = 'Key',
    itemsSource = [],
    valuePath = 'Value',
    isPendingChange,
    version,
    variant = 'form',
    bottomDescription,
    showSearchBox,
    ...rest
}: Props) {
    const toolTip = useMemo(() => itemsSource.find(({ Key }) => Key === value), [itemsSource, value]);
    const [internalValue, setInternalValue] = useState(() => value);
    const previousInternalValue = usePrevious(internalValue);
    const { accentColor } = useColors();

    const searchBoxEnabled = showSearchBox !== false && (itemsSource.length > 1 || showSearchBox);

    useEffect(() => {
        if (isEditable && !isEqual(value, internalValue) && !isEqual(internalValue, previousInternalValue)) {
            onValueChanged && onValueChanged(internalValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue, isEditable]);

    useEffect(() => {
        if (!isEqual(value, internalValue)) {
            setInternalValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <FormControl height={height} className={variant}>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <>
                    <TextBlock
                        styleName='fieldLabelStyle'
                        text={label}
                        toolTip={labelToolTip?.component}
                        toolTipService={labelToolTip?.options}
                        margin={`0 0 ${SPACING.XXS}px 0`}
                    />
                    {!isEditable && isPendingChange && (
                        <TextBlock
                            styleName='fieldLabelStyle'
                            text='PENDING'
                            fontSize='16px'
                            foregroundColor={accentColor}
                            textAlignment='right'
                        />
                    )}
                </>
            </Grid>
            <div>
                <SelectorField
                    key={version}
                    selectionMode='single'
                    displayMemberPath={valuePath}
                    searchPath={valuePath}
                    selectedValuePath={selectedValuePath}
                    maxDropDownHeight='250px'
                    canClearSelection
                    canUndo={
                        isEditable &&
                        ((!isUndefined(value) && !isUndefined(internalValue)) ||
                            (!isNull(value) && !isNull(internalValue)))
                    }
                    value={internalValue ? [internalValue] : []}
                    itemsSource={itemsSource}
                    isEditable={isEditable}
                    isRequired={isRequired && isEditable}
                    showSearchBox={searchBoxEnabled}
                    onValueChanged={(newValue: any) => setInternalValue(newValue[0] ?? null)}
                    {...(!isEditable && { toolTip: toolTip?.Value })}
                    {...rest}
                />
                {(isEditable || showError) && <ValidationMessage message={validationError} />}
            </div>
            {bottomDescription && (
                <TextBlock styleName='fieldLabelStyle' padding={`${SPACING.XS}px 0 0 0`}>
                    {bottomDescription}
                </TextBlock>
            )}
        </FormControl>
    );
}
