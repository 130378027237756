import { LogError } from '../models/index';

export default class ApiService {
    public static Get(urlString: string, errorInfo: LogError, params?: any, responseType?: string): Promise<any> {
        let url = new URL(urlString);
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    url.searchParams.append(key, value as string);
                }
            });
        }
        return this.Fetch('GET', url.toString(), errorInfo, responseType);
    }

    static Post(
        url: string,
        errorInfo: LogError,
        data?: any,
        hasHeaders?: boolean,
        isFormData?: boolean,
        isAttachment?: boolean,
        abortController?: AbortController
    ): Promise<any> {
        return this.FetchWithBody('POST', url, data, errorInfo, hasHeaders, isFormData, isAttachment, abortController);
    }

    static Put(url: string, errorInfo: LogError, data: any, hasHeaders?: boolean, isFormData?: boolean): Promise<any> {
        return this.FetchWithBody('PUT', url, data, errorInfo, hasHeaders, isFormData);
    }

    static Delete(url: string, errorInfo: LogError): Promise<any> {
        return this.Fetch('DELETE', url, errorInfo, 'text');
    }

    static Patch(
        url: string,
        errorInfo: LogError,
        data: any,
        hasHeaders?: boolean,
        isFormData?: boolean
    ): Promise<any> {
        return this.FetchWithBody('PATCH', url, data, errorInfo, hasHeaders, isFormData);
    }

    private static async Fetch(
        httpVerb: string,
        url: string,
        errorInfo: LogError,
        responseType?: string
    ): Promise<any | undefined> {
        responseType = responseType || 'json';
        const response = await fetch(url, {
            method: httpVerb,
        });

        if (!response.ok) {
            let responseObject = await response.json();
            if (responseObject.ErrorMessage != null) {
                return responseObject;
            } else {
                let errorId: number = this.SaveLogError(errorInfo, url, response);
                return Promise.reject({ errorId: errorId, message: response.statusText });
            }
        } else {
            switch (responseType) {
                case 'text':
                    return response.text();
                case 'binary':
                    return response.arrayBuffer();
                default:
                    return response.status !== 204 ? response.json() : Promise.resolve(undefined);
            }
        }
    }

    private static getFormData(object: any) {
        const formData = new FormData();
        Object.keys(object).forEach((key) => object[key] && formData.append(key, object[key]));
        return formData;
    }

    private static async FetchWithBody(
        httpVerb: string,
        url: string,
        data: any,
        errorInfo: LogError,
        hasHeaders: boolean = false,
        isFormData: boolean = false,
        isAttachment: boolean = false,
        abortController?: AbortController
    ): Promise<any> {
        let body: FormData | string;
        const headers: any = {
            Accept: 'application/json',
            'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
        };

        if (isFormData) {
            body = new FormData();
            if (isAttachment) {
                body.append('File', data);
            } else {
                body = isFormData
                    ? this.getFormData(data)
                    : JSON.stringify(data, (k, v) => (v === undefined ? null : v));
            }
        } else {
            body = JSON.stringify(data, (k, v) => (v === undefined ? null : v));
        }

        let request: any = {
            method: httpVerb,
            body: body,
            headers: hasHeaders ? headers : null,
            signal: abortController?.signal,
        };

        /*const body = isFormData ? data : JSON.stringify(data, (k,v) => v === undefined ? null : v);
		let request: any = {
			method: httpVerb,
			body: body,
			headers: hasHeaders ? headers : null,
		};*/

        let response = await fetch(url, request);
        if (!response.ok) {
            let errorId: number = this.SaveLogError(errorInfo, url, response);
            return Promise.reject({ errorId: errorId, message: response.statusText });
        } else {
            return response.json();
        }
    }

    private static SaveLogError(errorInfo: LogError, url: string, response: any) {
        let errorId: number = -1;
        if (response) {
            let timestamp = new Date();
            errorInfo.error = response.statusText;
            errorInfo.status = response.status;
            errorInfo.timestamp = timestamp;
            errorInfo.url = url;
            errorId = 1;
        }
        return errorId;
    }
}
