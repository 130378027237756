import LegalEntityDissolutionTreasury from './LegalEntityDissolutionTreasury';
import { LegalEntityDissolutionTreasuryProps } from './model';
import { LegalEntityDissolutionTreasuryProvider } from '../../context/Provider';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import useDissolutionTreasuryAutopopulateData from './hooks/use-dissolution-treasury-autopopulate-data';

export default function LegalEntityDissolutionTreasurySection(props: LegalEntityDissolutionTreasuryProps) {
    const { calculateDefaultValues } = useDissolutionTreasuryAutopopulateData();

    return (
        <LegalEntityDissolutionTreasuryProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_Treasury}>
            <LegalEntityDissolutionTreasury {...props} />
        </LegalEntityDissolutionTreasuryProvider>
    );
}
