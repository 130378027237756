import { RequestStepsView } from '../LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { generatePath } from 'react-router-dom';
import { nameOf } from '../../Utilities/ReflectionUtil';

export type editLegalEntityParams = Record<'legalEntityId', string>;
const root = '/legalentity';

export const externalLink = () => {
    return `${root}/externalUrl/`;
};

export const PAGES = {
    ENTITY_EDITOR_SEARCH: '/search',
} as const;

export type Page = (typeof PAGES)[keyof typeof PAGES];

export const LegalEntityRequestPaths = {
    root,
    dashboard: root,
    newLegalEntityRequest: `${root}/new`,
    environment: `/environment`,
    search: `${root}/search`,
    editLegalEntityRequestPattern: `${root}/:${nameOf<editLegalEntityParams>('legalEntityId')}(\\d+)`,
    externalLink: externalLink,
    editLegalEntityRequest: (section: string, params: editLegalEntityParams) => generatePath(section, params),
} as const;

export const getSectionPathName = (workitemState?: string, workitemType?: string) => {
    switch (workitemState) {
        case 'Review':
        case 'Update Request':
            return RequestStepsView.Request;
        case 'LegalEntityUpdateWorkflow':
            return RequestStepsView.Legal_Entity_Information;
        case 'Document Preparation':
        case 'Update Document Preparation':
        case 'Document Review':
            return RequestStepsView.Document_Preparation;
        case 'Registration':
        case 'Update Domestic Registration':
            return RequestStepsView.Domestic_Registration;
        case 'Approval':
            return RequestStepsView.Approval;
        case 'ForeignRegistrationChildProcess':
            return RequestStepsView.Foreign_Registration;
        case 'TaxChildProcess':
            return RequestStepsView.Tax_Classification;
        case 'FinanceChildProcess':
            return RequestStepsView.Finance_Details;
        case 'OwnershipChildProcess':
            return RequestStepsView.Ownership_Information;
        default:
            return '';
    }
};

export const getSectionPathNameForSearchedItem = (
    hasLegalRole?: boolean,
    hasTaxRole?: boolean,
    hasFinanceRole?: boolean
) => {
    switch (true) {
        case hasLegalRole:
            return RequestStepsView.Legal_Entity_Information;
        case hasTaxRole:
            return RequestStepsView.Tax_Classification;
        case hasFinanceRole:
            return RequestStepsView.Finance_Details;
        default:
            return '';
    }
};
