export enum RequestStepsView {
    Legal_Entity_Information = 'Legal Entity Information',
    Request = 'Request',
    Document_Preparation = 'Document Preparation',
    Domestic_Registration = 'Domestic Registration',
    Corporate_Transparency = 'Corporate Transparency',
    Approval = 'Approval',
    Foreign_Registration = 'Foreign Registration',
    Tax_Classification = 'Tax Classification',
    Finance_Details = 'Finance Details',
    Ownership_Information = 'Ownership Information',
    Dissolution_Details = 'Dissolution Details',
    Dissolution_Approval = 'Dissolution Request Approval',
    Dissolution_Litigation = 'Dissolution Litigation',
    Dissolution_Tax = 'Dissolution Tax',
    Dissolution_Finance = 'Dissolution Finance',
    Dissolution_Legal = 'Dissolution Legal',
    Dissolution_Treasury = 'Dissolution Treasury',
    Dissolution_BDG = 'Dissolution Data Governance',
}

export interface RequestStepPanel {
    requestStepView: RequestStepsView;
    section: string;
    title: string;
    hasActions?: boolean;
    customIcon?: string;
}

export enum UserScopes {
    TaxClassification = 'TaxClassification',
    FinanceInformation = 'FinanceInformation',
    OwnershipInformation = 'OwnershipInformation',
    LegalInformation = 'LegalInformation',
    ForeignRegistration = 'ForeignRegistration',
    ForeignRegistrationUpdate = 'ForeignRegistrationUpdate',
    DocumentPreparation = 'DocumentPreparation',
    DomesticRegistration = 'DomesticRegistration',
    CorporateTransparency = 'CorporateTransparency',
    Approval = 'Approval',
    Details = 'Details',
    DissolutionRequest = 'Dissolution',
    DissolutionLitigation = 'DissolutionLitigation',
    DissolutionTax = 'DissolutionTax',
    DissolutionFinance = 'DissolutionFinance',
    DissolutionLegal = 'DissolutionLegal',
    DissolutionTreasury = 'DissolutionTreasury',
    DissolutionBDG = 'DissolutionBDG',
}

export enum ScopePermissions {
    Create = 'Create',
    Approve = 'Approve',
    View = 'View',
}

export const Capabilities = {
    createRequest: { permission: ScopePermissions.Create, scope: UserScopes.Details },
    approveRequest: { permission: ScopePermissions.Approve, scope: UserScopes.Details },
    approveApproval: { permission: ScopePermissions.Approve, scope: UserScopes.Approval },
    createDocumentPreparation: { permission: ScopePermissions.Create, scope: UserScopes.DocumentPreparation },
    approveDocumentPreparation: { permission: ScopePermissions.Approve, scope: UserScopes.DocumentPreparation },
    createDomesticRegistration: { permission: ScopePermissions.Create, scope: UserScopes.DomesticRegistration },
    createForeignRegistration: { permission: ScopePermissions.Create, scope: UserScopes.ForeignRegistration },
    createCorporateTransparency: { permission: ScopePermissions.Create, scope: UserScopes.CorporateTransparency },
    approveCorporateTransparency: { permission: ScopePermissions.Approve, scope: UserScopes.CorporateTransparency },
    approveForeignRegistration: { permission: ScopePermissions.Approve, scope: UserScopes.ForeignRegistration },
    updateForeignRegistration: { permission: ScopePermissions.Create, scope: UserScopes.ForeignRegistrationUpdate },
    createTaxClassification: { permission: ScopePermissions.Create, scope: UserScopes.TaxClassification },
    approveTaxClassification: { permission: ScopePermissions.Approve, scope: UserScopes.TaxClassification },
    createFinanceInformation: { permission: ScopePermissions.Create, scope: UserScopes.FinanceInformation },
    approveFinanceInformation: { permission: ScopePermissions.Approve, scope: UserScopes.FinanceInformation },
    createOwnershipInformation: { permission: ScopePermissions.Create, scope: UserScopes.OwnershipInformation },
    approveOwnershipInformation: { permission: ScopePermissions.Approve, scope: UserScopes.OwnershipInformation },
    createLegalInformation: { permission: ScopePermissions.Create, scope: UserScopes.LegalInformation },
    approveLegalInformation: { permission: ScopePermissions.Approve, scope: UserScopes.LegalInformation },
    viewDissolutionRequest: { permission: ScopePermissions.View, scope: UserScopes.DissolutionRequest },
    createDissolutionRequest: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionRequest },
    approveDissolutionRequest: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionRequest },
    createDissolutionLitigation: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionLitigation },
    approveDissolutionLitigation: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionLitigation },
    createDissolutionTax: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionTax },
    approveDissolutionTax: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionTax },
    createDissolutionFinance: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionFinance },
    approveDissolutionFinance: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionFinance },
    createDissolutionLegal: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionLegal },
    approveDissolutionLegal: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionLegal },
    createDissolutionTreasury: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionTreasury },
    approveDissolutionTreasury: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionTreasury },
    createDissolutionBDG: { permission: ScopePermissions.Create, scope: UserScopes.DissolutionBDG },
    approveDissolutionBDG: { permission: ScopePermissions.Approve, scope: UserScopes.DissolutionBDG },
} as const;

export type CapabilityKeys = keyof typeof Capabilities;
export type Capability = (typeof Capabilities)[CapabilityKeys];
export type Capabilities = Record<CapabilityKeys, Capability>;
