import { CREATE_ENTITY_STATE } from 'models/LegalEntityRequest/Workflow';
import LegalEntityDocumentPreparationSection from '../../LegalEntityDocumentPreparation/LegalEntityDocumentPreparationSection';
import React from 'react';
import { RequestStepsView } from '../RequestStepsView';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useUpdateForeignRegistrationProps from '../hooks/use-update-foreign-registration-props';

export default function DocumentPreparationStepRenderer() {
    const { saveSection, reloadUserScopes, completeTask } = useApiServices();
    const updateForeignRegistrationProps = useUpdateForeignRegistrationProps();

    const {
        state: { isWorkItemsRefreshing, sections, entityCreationWorkItem: workItem, selectedPanel },
    } = useCreationViewContext();

    const data = sections.DocumentPreparation;

    if (isWorkItemsRefreshing || !selectedPanel?.hasActions)
        return <LegalEntityDocumentPreparationSection mode='Readonly' data={data} />;

    switch (workItem?.['workItemState']) {
        case CREATE_ENTITY_STATE.UPDATE_DOCUMENT_PREPARATION:
        case CREATE_ENTITY_STATE.DOCUMENT_PREPARATION:
            return (
                <LegalEntityDocumentPreparationSection
                    mode='Edit'
                    data={data}
                    workItem={workItem}
                    onSave={async (changes) => {
                        return saveSection(sections, 'DocumentPreparation', changes).then((e) =>
                            updateForeignRegistrationProps(workItem, e).then(() => reloadUserScopes(e?.entityOId ?? 0))
                        );
                    }}
                    onCompleteTask={async (task, exitCode) => {
                        await completeTask(workItem, task, exitCode, RequestStepsView.Document_Preparation, undefined, {
                            skipRequestNotesUpdate: true,
                        });
                    }}
                />
            );
        case 'Document Review':
            return (
                <LegalEntityDocumentPreparationSection
                    mode='Approval'
                    data={data}
                    workItem={workItem}
                    onCompleteTask={(task, exitCode, rejectReason) => {
                        completeTask(
                            workItem,
                            task,
                            exitCode,
                            exitCode === 'Reject' ? RequestStepsView.Request : RequestStepsView.Domestic_Registration,
                            rejectReason,
                            exitCode === 'Reject'
                                ? {
                                      skipRequestNotesUpdate: true,
                                  }
                                : undefined
                        );
                    }}
                />
            );
        default:
            return <LegalEntityDocumentPreparationSection mode='Readonly' data={sections.DocumentPreparation} />;
    }
}
