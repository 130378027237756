import LegalEntityRequest from './LegalEntityRequest';
import { LegalEntityRequestProps } from './model';
import { LegalEntityRequestProvider } from '../context/Provider';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityRequestSection(props: LegalEntityRequestProps) {
    return (
        <LegalEntityRequestProvider storedValues={props.data} mode={props.mode} requestStep={RequestStepsView.Request}>
            <LegalEntityRequest {...props} />
        </LegalEntityRequestProvider>
    );
}
