import { constants } from 'constants/env-config';
import { entitySections } from 'modules/LegalEntityCreation/LegalEntityCreationView/EntityToSectionMapper';

export function getSectionUrlForPatch(section: keyof entitySections) {
    return section === 'LegalEntityInformation' ? 'LegalInformation' : section;
}

export const utilUpdateTaskServiceCall = () => {
    return `${constants.ENTITY_MASTER_API}workitem/updateWorkitem`;
};

export const utilCompleteTaskServiceCall = () => {
    return `${constants.ENTITY_MASTER_API}workitem/task`;
};
