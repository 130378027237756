import { Expander, Form } from 'components';
import React, { Fragment } from 'react';

import LegalEntityForeignRegistration from './LegalEntityForeignRegistration';
import { LegalEntityForeignRegistrationSectionProps } from './model';
import { TextBlock } from '@bxgrandcentral/controls';
import { isEmpty } from 'Utilities/Validations';

export default function LegalEntityForeignRegistrationSection({
    data,
    hasOpenWorkitem,
    ...rest
}: LegalEntityForeignRegistrationSectionProps) {
    return (
        <Form>
            {isEmpty(data) ? (
                <Expander
                    header={`Foreign Registration Details`}
                    content={<TextBlock styleName='captionStyle'>No foreign registration added</TextBlock>}
                />
            ) : (
                data.map((registration, index) => {
                    const isLast = index === data.length - 1;
                    return (
                        <Fragment key={`${registration.key}`}>
                            <LegalEntityForeignRegistration
                                {...rest}
                                data={data[index]}
                                showDocumentsInReadOnlyTreeView={isLast && !hasOpenWorkitem}
                            />
                        </Fragment>
                    );
                })
            )}
        </Form>
    );
}
