import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionLitigationData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionLitigation/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionLitigationData => {
    const { entity, dissolutionData } = entityDto;

    return {
        entityOid: entity.entityOId,
        noOutstandingLegalMatters: dissolutionData?.dissolutionLitigation?.noOutstandingLegalMatters,
        approverNotes: dissolutionData?.dissolutionLitigation?.approverNotes,
        savedBy: dissolutionData?.dissolutionLitigation?.savedBy,
        savedAt: dissolutionData?.dissolutionLitigation?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionLitigationData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;

    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionLitigation: {
            ...original?.dissolutionData?.dissolutionLitigation,
            ...section,
        },
    };
    return toReturn;
};
