import { VALIDATION_MESSAGES, validateDateTimeField, validateRequiredField } from './utils';
import { isCaymanIslands, isLuxembourg, isUnitedStates } from 'models';
import { useEffect, useState } from 'react';

import { LegalEntityRegistrationData } from '../LegalEntityRegistration/model';
import { ValidationErrors } from '../context/model';
import { getRegisteredAgents } from '../LegalEntityDocumentPreparation/utils';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityRegistrationUpdate } from '../context/Provider';

export default function useForeignRegistrationUpdateValidation() {
    const {
        state: {
            data: {
                values: { RegistrationData = [] },
            },
            isEditable,
            version,
        },
        dispatch,
    } = useLegalEntityRegistrationUpdate();

    const [validations, setValidations] = useState<ValidationErrors<Partial<LegalEntityRegistrationData>>[]>([]);

    useEffect(() => {
        if (isEmpty(RegistrationData)) {
            setValidations([]);
            return;
        }

        setValidations(
            RegistrationData.map((registration) =>
                registration.isDeleted
                    ? {}
                    : {
                          country: validateRequiredField(registration.country),
                          registeredAgent: validateRequiredField(registration.registeredAgent, {
                              isRequired: getRegisteredAgents(registration.country),
                          }),
                          state: validateRequiredField(registration.state, {
                              isRequired: isUnitedStates(registration.country) || isCaymanIslands(registration.country),
                          }),
                          registrationNumber: validateRequiredField(registration.registrationNumber, {
                              isRequired: !isLuxembourg(registration.country),
                          }),
                          formationDate: validateDateTimeField(registration.formationDate, {
                              isOptionalWhen: isLuxembourg(registration.country),
                              canBeFutureDate: false,
                          }),
                      }
            )
        );
    }, [RegistrationData, dispatch]);

    useEffect(() => {
        if (!isEditable || isEmpty(validations)) {
            updateValidation(dispatch, { addresses: undefined });
            return;
        }

        updateValidation(dispatch, {
            RegistrationData: validations.some((validation) => Object.values(validation).some((value) => value))
                ? VALIDATION_MESSAGES.COMBINED_ERROR
                : undefined,
        });
    }, [validations, dispatch, version, isEditable]);

    return { validations };
}
