import { KeyValue } from 'models';

export const DashboardViewOptions: KeyValue[] = [
    {
        Key: 1,
        Value: 'All Requests',
    },
    {
        Key: 2,
        Value: 'Pending Requests',
    },
    {
        Key: 3,
        Value: 'Closed Requests',
    },
];

export enum WorkItemState {
    Open = 'Open',
    Closed = 'Closed',
    OpenOrClosed = 'OpenOrClosed',
}

export const DASHBOARD_VIEW_STATE: Record<number, WorkItemState> = {
    1: WorkItemState.OpenOrClosed,
    2: WorkItemState.Open,
    3: WorkItemState.Closed,
} as const;
