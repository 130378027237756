import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { LegalEntityDissolutionTaxData } from '../model';
import { useCallback } from 'react';

export default function useDissolutionTaxAutopopulateData(data?: LegalEntityDissolutionTaxData) {
    const calculateDefaultValues = useCallback(
        () => ({
            allTaxRelated: CompletionStateKeys.Incomplete,
            taxInvoicesPreBilled: CompletionStateKeys.Incomplete,
            allTaxReporting: CompletionStateKeys.Incomplete,
            deregistrationAndNotification: CompletionStateKeys.Incomplete,
            taxAndLiquidationSteps: CompletionStateKeys.Incomplete,
        }),
        []
    );

    return { calculateDefaultValues };
}
