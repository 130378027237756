import ApiService from 'services/ApiService';
import { constants } from 'constants/env-config';
import { useQuery } from 'react-query';

export type ThirdPartyUser = {
    userOId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    isTerminated: false;
    isActiveEntity: boolean;
    upn: string;
    entityOId: number;
};

export default function useThirdPartyUsers() {
    const fetch = async (): Promise<ThirdPartyUser[]> => {
        const users: ThirdPartyUser[] = await ApiService.Get(`${constants.ENTITY_MASTER_API}users/third-parties/list`, {
            service: 'Legal Entity Request Service',
            module: 'Global',
        });
        return users.filter(({ isActiveEntity, isTerminated }) => isActiveEntity && !isTerminated);
    };

    return useQuery('Third party users', fetch, {
        refetchInterval: 1000 * 60 * 5,
    });
}
