import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo } from 'react';

import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityTaxClassification } from '../context/Provider';
import { useReferenceData } from 'api';
import { validateRequiredField } from './utils';

type Props = {
    isPortCoManaged?: boolean;
};

export default function useTaxClassificationValidation({ isPortCoManaged }: Props) {
    const {
        state: {
            data: {
                values: {
                    chapterThreeStatusId,
                    crsClassificationId,
                    crsSubClassificationId,
                    crsSubClassificationDetailId,
                    fatcaClassificationId,
                    isCtbElectionApproved,
                    usStateTaxClassificationTypeId,
                    usTaxClassificationTypeId,
                    tax8832EffectiveDate,
                    taxPreparerEntityOId,
                },
            },
            version,
        },
        dispatch,
    } = useLegalEntityTaxClassification();

    const {
        data: { CRSSubClassification, CRSSubClassificationDetails },
    } = useReferenceData();

    const crsSubClassifications = useMemo(
        () => CRSSubClassification?.filter(({ ParentId }) => ParentId === crsClassificationId?.toString()),
        [CRSSubClassification, crsClassificationId]
    );

    const crsSubClassificationDetails = useMemo(
        () => CRSSubClassificationDetails?.filter(({ ParentId }) => ParentId === crsSubClassificationId?.toString()),
        [CRSSubClassificationDetails, crsSubClassificationId]
    );

    useEffect(() => {
        updateValidation(dispatch, {
            fatcaClassificationId: validateRequiredField(fatcaClassificationId),
            crsClassificationId: validateRequiredField(crsClassificationId),
            crsSubClassificationId: validateRequiredField(crsSubClassificationId, {
                isRequired: !isNil(crsClassificationId) && !isEmpty(crsSubClassifications),
            }),
            crsSubClassificationDetailId: validateRequiredField(crsSubClassificationDetailId, {
                isRequired: !isNil(crsSubClassificationId) && !isEmpty(crsSubClassificationDetails),
            }),
            usTaxClassificationTypeId: validateRequiredField(usTaxClassificationTypeId),
            usStateTaxClassificationTypeId: validateRequiredField(usStateTaxClassificationTypeId),
            tax8832EffectiveDate: validateRequiredField(tax8832EffectiveDate, {
                isRequired: isCtbElectionApproved,
            }),
            chapterThreeStatusId: validateRequiredField(chapterThreeStatusId),
            taxPreparerEntityOId: validateRequiredField(taxPreparerEntityOId, {
                isRequired: !isPortCoManaged,
            }),
        });
    }, [
        chapterThreeStatusId,
        crsClassificationId,
        crsSubClassificationDetailId,
        crsSubClassificationDetails,
        crsSubClassificationId,
        crsSubClassifications,
        fatcaClassificationId,
        isCtbElectionApproved,
        tax8832EffectiveDate,
        usStateTaxClassificationTypeId,
        usTaxClassificationTypeId,
        taxPreparerEntityOId,
        isPortCoManaged,
        version,
        dispatch,
    ]);
}
