import { CompletionStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { LegalEntityDissolutionTreasuryData } from '../model';
import { useCallback } from 'react';

export default function useDissolutionTreasuryAutopopulateData(data?: LegalEntityDissolutionTreasuryData) {
    const calculateDefaultValues = useCallback(
        () => ({
            allAccountsClosed: CompletionStateKeys.Incomplete,
            outsideAccountsClosed: CompletionStateKeys.Incomplete,
            trialBalanceZero: CompletionStateKeys.Incomplete,
            noOpenIntercompanyBalances: CompletionStateKeys.Incomplete,
            closeInWSS: CompletionStateKeys.Incomplete,
        }),
        []
    );

    return { calculateDefaultValues };
}
