import { DOCUMENT_DATA_IDS, SECTION_NAME_SEPARATOR } from './models';

import { DocumentElement } from 'models/LegalEntityRequest/Documents/DocumentsResponse';
import { supportedExtensions } from 'models/shared/refData/NewDocumentType';

export const getSectionName = (documentElements: DocumentElement[] = []) =>
    documentElements.find(({ dataItemId }) => parseInt(dataItemId) === DOCUMENT_DATA_IDS.SECTION_NAME)
        ?.documentElementValue ?? '_';

export const cleanupSectionName = (sectionName: string) => {
    const index = sectionName.indexOf(SECTION_NAME_SEPARATOR);
    return index > -1 ? sectionName.slice(0, index) : sectionName;
};

type ConvertToDocumentProps = {
    file: File;
    entityOid: number;
    sectionName: string;
    taxRegistrationId?: number;
};

const isLessThan100Mb = ({ size }: File) => parseFloat((size / (1024 * 1024)).toFixed(2)) <= 100;

const isValidExtension = ({ name }: File) =>
    supportedExtensions.includes(name.toLocaleLowerCase().split('.').pop() ?? '');

export const convertToDocument = ({ file, entityOid, sectionName, taxRegistrationId }: ConvertToDocumentProps) => ({
    id: Math.random() * 100,
    entityOid,
    sectionName: cleanupSectionName(sectionName),
    fileName: file.name,
    documentData: file,
    documentTypeId: 0,
    taxRegistration: taxRegistrationId,
});

export const filterUploadedFiles = (files: File[]) =>
    files.reduce(
        (result, file) => {
            if (!isValidExtension(file)) {
                result.filesWithInvalidExtensions.push(file);
            } else if (!isLessThan100Mb(file)) {
                result.filesMoreThan100Mb.push(file);
            } else {
                result.validFiles.push(file);
            }
            return result;
        },
        { validFiles: [] as File[], filesMoreThan100Mb: [] as File[], filesWithInvalidExtensions: [] as File[] }
    );
