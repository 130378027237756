import LegalEntityForeignRegistrationUpdate from './LegalEntityForeignRegistrationUpdate';
import { LegalEntityForeignRegistrationUpdateSectionProps } from './model';
import { LegalEntityRegistrationUpdateProvider } from 'modules/LegalEntityCreation/context/Provider';
import React from 'react';

export default function (props: LegalEntityForeignRegistrationUpdateSectionProps) {
    return (
        <LegalEntityRegistrationUpdateProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}>
            <LegalEntityForeignRegistrationUpdate {...props} />
        </LegalEntityRegistrationUpdateProvider>
    );
}
