import { Button, Dialog, Grid } from '@bxgrandcentral/controls';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { NewRequestAction } from '../../models';
import SingleSelectorField from '../../components/SingleSelectorField/SingleSeletorField';
import { nameOf } from '../../Utilities/ReflectionUtil';
import { useRequestActionTypes } from '../../api';

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function CreateRequestDialog({ isOpen, setIsOpen }: Props) {
    const [selected, setSelected] = useState<NewRequestAction>();
    const { data } = useRequestActionTypes();

    const onClick = useCallback(() => {
        selected?.action();
    }, [selected]);

    const onDialogClosed = useCallback(() => {
        setIsOpen(false);
        setSelected(undefined);
    }, [setIsOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            title='Select request'
            isOpen={isOpen}
            onDialogClosed={onDialogClosed}
            width={320}
            dialogResultType='done'
            isDoneActionEnabled>
            <Grid padding='25px' rowDefinitions='* *' columnDefinitions='* auto'>
                <SingleSelectorField
                    gridColumnSpan={2}
                    margin='10px 0px'
                    label='Request Type'
                    showSearchBox
                    isRequired
                    itemsSource={data}
                    displayMemberPath={nameOf<NewRequestAction>('displayName')}
                    selected={selected}
                    onChange={setSelected}
                />
                <Button gridRow={1} gridColumn={1} margin='15px 0px 0px 0px' onClick={onClick} isEnabled={!!selected}>
                    Create Request
                </Button>
            </Grid>
        </Dialog>
    );
}
