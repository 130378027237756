import { MEDIUM_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from 'Utilities/Layout';
import { useEffect, useState } from 'react';

type Dimensions = {
    width: number;
    height: number;
};

export default function useViewport() {
    const [dimensions, setDimensions] = useState<Dimensions>(getWindowSize());

    function getWindowSize(): Dimensions {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }

    useEffect(() => {
        function handleWindowResize() {
            setDimensions(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return {
        isSmallScreen: dimensions.width <= SMALL_SCREEN_WIDTH,
        isMediumScreen: dimensions.width <= MEDIUM_SCREEN_WIDTH,
    };
}
