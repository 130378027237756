import { LegalEntityDTO } from '../../../../models';
import { LegalEntityDissolutionLegalData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionLegal/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionLegalData => {
    const { dissolutionData, entity } = entityDto;

    return {
        entityOid: entity.entityOId,
        noOutstandingFundRelatedDuties: dissolutionData?.dissolutionLegal?.noOutstandingFundRelatedDuties,
        noActiveFundRegistrations: dissolutionData?.dissolutionLegal?.noActiveFundRegistrations,
        approverNotes: dissolutionData?.dissolutionLegal?.approverNotes,
        savedBy: dissolutionData?.dissolutionLegal?.savedBy,
        savedAt: dissolutionData?.dissolutionLegal?.savedAt,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionLegalData>
) => {
    const toReturn = { ...original } as RecursivePartial<LegalEntityDTO>;

    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionLegal: {
            ...original?.dissolutionData?.dissolutionLegal,
            ...section,
        },
    };

    return toReturn;
};
