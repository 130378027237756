import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useAppContext } from 'context/app-context';

export default function QueryPanel() {
    const {
        state: {
            settings: { isQueryPanelEnabled },
        },
    } = useAppContext();

    return isQueryPanelEnabled ? (
        <ReactQueryDevtools toggleButtonProps={{ style: { left: 0, bottom: '150px', height: '40px' } }} />
    ) : null;
}
