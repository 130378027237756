import {
    Column,
    ColumnChooser,
    TreeList as DevExtremeTreeList,
    HeaderFilter,
    Scrolling,
    SearchPanel,
    Selection,
    Sorting,
} from 'devextreme-react/tree-list';
import { GlobalStyle, TreeList } from './TreeList.styled';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StackPanel, ThemeManager } from '@bxgrandcentral/controls';

import { Template } from 'devextreme-react/core/template';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import dxTreeList from 'devextreme/ui/tree_list';

export type devExpressGridOptionChanged = {
    fullName?: string;
    name?: string | 'columns';
    component?: any;
    value?: any;
    previousValue?: any;
};

export type toolbarItemConfig = {
    location: 'center' | 'before' | 'after';
    render: (treeComponent: dxTreeList) => JSX.Element;
};

export interface ITreeListDevExpressProps<T> {
    columnsConfig?: Column[];
    scrollingMode?: 'infinite' | 'standard' | 'virtual';
    onOptionChanged?: (optionChange: devExpressGridOptionChanged) => void;
    onContentReady?: (data: any) => void;
    dataSource?: Array<T>;
    dataSourceFilter?: any[];
    height?: number | string;
    showLoadPanel?: boolean;
    showColumnChooser?: boolean;
    showSearchPanel?: boolean;
    showColumnHeaderSearch?: boolean;
    sortingMode?: 'single' | 'multiple' | 'none';
    onSelectedRowKeysChanged?: (selectedRowKeys: T[]) => void;
    onCellClick?: (data?: any) => void;
    onItemDoubleClicked?: (item: T) => void;
    keyExpr: string;
    parentIdExpr: string;
    itemsExpr?: string;
    additionalToolbarItems?: toolbarItemConfig[];
}

const TreeListDevExpress = <T extends any>(props: PropsWithChildren<ITreeListDevExpressProps<T>>) => {
    const {
        dataSource,
        dataSourceFilter,
        itemsExpr = 'items',
        keyExpr,
        parentIdExpr,
        height = '100%',
        scrollingMode = 'virtual',
        showLoadPanel,
        showColumnChooser,
        showSearchPanel = false,
        showColumnHeaderSearch = false,
        sortingMode = 'multiple',
        additionalToolbarItems = [],
    } = props;
    const dataGridAttribute = { id: 'DevExpressDataGrid' };
    // eslint-disable-next-line
    const [forceUpdateDimensions, setForceUpdateDimensions] = useState<boolean>();

    const dataGridRef: { current: any } = useRef();
    const dataGridWidgetContentRef: { current: any } = useRef();
    const dataGridSummaryWidgetContentRef: { current: any } = useRef();

    /* // This is to clear selection if selectedRowKeys is []
	useEffect(() => {
		if (dataGridRef.current && props.selectedRowKeys === []) {
			dataGridRef.current.instance.clearSelection();
		}
	}, [props.selectedRowKeys]);
 */
    // this is for resize grid with side panels

    // this is for resize grid with side panels
    useEffect(() => {
        if (dataGridWidgetContentRef.current) {
            dataGridWidgetContentRef.current.component.updateDimensions();
        }
        if (dataGridSummaryWidgetContentRef.current) {
            dataGridSummaryWidgetContentRef.current.component.updateDimensions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceUpdateDimensions]);

    // this is to use LoadPanel
    useEffect(() => {
        if (dataGridWidgetContentRef.current) {
            if (showLoadPanel) {
                dataGridWidgetContentRef.current.component.beginCustomLoading();
            } else {
                dataGridWidgetContentRef.current.component.endCustomLoading();
            }
        }
    }, [showLoadPanel]);

    const onCellClick = useCallback(
        (event: any) => {
            if (props.onCellClick && event.rowType === 'data' && event.column && !event.column.cellTemplate) {
                props.onCellClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onCellClick]
    );

    const onSelectionChanged = useCallback(
        (e: { selectedRowsData?: T[] }) => {
            if (props.onSelectedRowKeysChanged) {
                props.onSelectedRowKeysChanged(e.selectedRowsData ?? []);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onSelectedRowKeysChanged]
    );
    const onRowDoubleClicked = useCallback(
        (e: { data?: T }) => e.data && props.onItemDoubleClicked?.(e.data),
        [props.onItemDoubleClicked]
    );

    const templates = useMemo(() => {
        return additionalToolbarItems.map((config, i) => {
            const key = `templateKey-${i}`;
            return (
                <Template
                    key={key}
                    name={key}
                    render={(a: { treeComponent: dxTreeList }) => {
                        return config.render(a.treeComponent);
                    }}
                />
            );
        });
    }, [additionalToolbarItems]);

    const onToolbarPreparing = useCallback(
        (e: { component?: dxTreeList; toolbarOptions?: dxToolbarOptions }) => {
            if (e.toolbarOptions?.items) {
                e.toolbarOptions.items.unshift(
                    ...additionalToolbarItems.map((config, i) => {
                        return {
                            location: config.location,
                            template: `templateKey-${i}`,
                            treeComponent: e.component,
                        };
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...templates]
    );

    return (
        <StackPanel height={height}>
            <GlobalStyle theme={ThemeManager.activeTheme} />
            <TreeList theme={ThemeManager.activeTheme}>
                <DevExtremeTreeList
                    ref={dataGridRef}
                    className='treeList'
                    elementAttr={dataGridAttribute}
                    dataSource={{ store: dataSource, filter: dataSourceFilter }}
                    allowColumnReordering
                    columnResizingMode='nextColumn'
                    showBorders
                    itemsExpr={itemsExpr}
                    height={height}
                    width={'100%'}
                    showRowLines
                    style={{ fontSize: '12px' }}
                    onCellClick={onCellClick}
                    onSelectionChanged={onSelectionChanged}
                    onRowDblClick={onRowDoubleClicked}
                    keyExpr={keyExpr}
                    parentIdExpr={parentIdExpr}
                    onOptionChanged={props.onOptionChanged}
                    onToolbarPreparing={onToolbarPreparing}
                    onContentReady={props.onContentReady}>
                    <Sorting mode={sortingMode} />
                    <Scrolling mode={scrollingMode} rowRenderingMode='virtual' />
                    <Selection mode='single' />
                    <SearchPanel visible={showSearchPanel} width={240} placeholder='Search...' />
                    <HeaderFilter visible={showColumnHeaderSearch} allowSearch={true} />
                    <ColumnChooser enabled={showColumnChooser} mode='select' title='Column Settings' />
                    {templates}
                    {(props.columnsConfig || []).map((column: Column, index: number) => {
                        return (
                            <Column
                                {...column.props}
                                key={index}
                                allowReordering={column.props.allowReordering ?? false}
                                alignment={column.props.alignment || 'right'}
                            />
                        );
                    })}
                    {props.children}
                </DevExtremeTreeList>
            </TreeList>
        </StackPanel>
    );
};

export default TreeListDevExpress;
