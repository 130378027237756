import { Task, WorkItem } from '../../Workflow';

import { WorkflowType } from './WorkflowTypes';

export type CreateEntityTaskType =
    | 'ReviewTransitionTaskType'
    | 'DocPrepTransitionTaskType'
    | 'ReviewDocPrepTaskType'
    | 'submitToApprovalTaskType'
    | 'CorporateTransparencyTaskType'
    | 'CTANotificationTaskType'
    | 'ActivateEntity'
    | 'TransitionToPostProcess1TaskType'
    | 'ResubmitToApproval'
    | 'UpdateDocPrepTaskKey'
    | 'UpdateRequestTaskType';

export const CREATE_ENTITY_STATE = {
    REVIEW: 'Review',
    UPDATE_REQUEST: 'Update Request',
    DOCUMENT_PREPARATION: 'Document Preparation',
    DOCUMENT_REVIEW: 'Document Review',
    UPDATE_DOCUMENT_PREPARATION: 'Update Document Preparation',
    REGISTRATION: 'Registration',
    CORPORATE_TRANSPARENCY_REQUEST: 'Corporate Transparency Request',
    APPROVAL: 'Approval',
    UPDATE_DOMESTIC_REGISTRATION: 'Update Domestic Registration',
    POSTPROCESS_1: 'PostProcess1',
    POSTPROCESS_2: 'PostProcess2',
    POSTPROCESS_3: 'PostProcess3',
    LEGAL_ENTITY_SETUP_COMPLETE: 'Legal Entity Setup Complete',
} as const;

export type CreateEntityStateKeyType = keyof typeof CREATE_ENTITY_STATE;
export type CreateEntityStateType = (typeof CREATE_ENTITY_STATE)[CreateEntityStateKeyType];

export type CreateLegalEntityWorkflowTask = Task & Record<keyof Pick<Task, 'taskType'>, CreateEntityTaskType>;
export type CreateLegalEntityWorkflow = Omit<WorkItem, 'tasks'> &
    Record<keyof Pick<WorkItem, 'tasks'>, CreateLegalEntityWorkflowTask[] | undefined> & {
        workflowType: WorkflowType.LegalEntityCreationWorkflowType;
        workItemState: CreateEntityStateType;
        customProperties: Partial<CreateLegalEntityWorkflowCustomProperties>;
    };

export type CreateLegalEntityWorkflowCustomProperties = {
    bankAccountRequired: boolean;
    consolidationRequired: boolean;
    taxInfoRequired: boolean;
    financeInfoRequired: boolean;
    ownershipInfoRequired: boolean;
    EINCreationRequired: boolean;
    registrationID1: number;
    registrationID2: number;
    registrationID3: number;
    taskComment?: string;
};
export interface CancelWorkItemRequest {
    workItemId: number;
    workItemVersionNumber: number;
    customProperties: { [key: string]: any };
    [key: string]: any;
}
export enum TaskStatus {
    New = 'New',
    Initialized = 'Initialized',
    Open = 'Open',
    InProcess = 'InProcess',
    Complete = 'Complete',
    Canceled = 'Canceled',
}
