import { CreateLegalEntityWorkflowTask } from '../../../../models/LegalEntityRequest/Workflow/CreateLegalEntityWorkflow';
import LegalEntityApprovalSection from 'modules/LegalEntityCreation/LegalEntityApproval/LegalEntityApprovalSection';
import React from 'react';
import { RequestStepsView } from '../RequestStepsView';
import { entitySectionMapper } from '../EntityToSectionMapper';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';

export default function ApprovalStepRenderer() {
    const { saveWorkItem, completeTask, reloadEntityAndWorkItems } = useApiServices();
    const {
        state: { isWorkItemsRefreshing, entityCreationWorkItem: workItem, sections, selectedPanel },
    } = useCreationViewContext();

    const data = entitySectionMapper.mapToApprovalSection(workItem, sections);

    if (isWorkItemsRefreshing || !selectedPanel?.hasActions || workItem?.workItemState !== 'Approval')
        return <LegalEntityApprovalSection mode='ReadOnly' data={data} />;

    return (
        <LegalEntityApprovalSection
            mode='Edit'
            data={data}
            workItem={workItem}
            onSave={saveWorkItem}
            onCompleteTask={async (task: CreateLegalEntityWorkflowTask, exitCode: string, rejectReason) => {
                const section = exitCode === 'Approve' ? '' : RequestStepsView.Domestic_Registration;
                await completeTask(workItem, task, exitCode, section, rejectReason);
                section.length ? await reloadEntityAndWorkItems(2000) : await reloadEntityAndWorkItems();
            }}
        />
    );
}
