import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type Props = {
    noRowsMessage: string;
    isError?: boolean;
};

export default function GridNoRowsOverlay({ noRowsMessage = 'No data.', isError }: Props) {
    const colors = useColors();
    const message = isError ? 'An error occurred while loading data.' : noRowsMessage;

    return (
        <div className='ag-overlay-no-row-center'>
            <Component colors={colors} className={isError ? 'has-error' : ''}>
                <span>{message}</span>
            </Component>
        </div>
    );
}

const Component = styled.div<{ colors: ThemeColors }>`
    padding: ${SPACING.SM}px;
    padding-top: ${SPACING.XXS}px;
    width: 400px;
    & > span {
        padding: 0;
        font-size: 16px;
    }
    &.has-error > span {
        color: ${({ colors }) => colors.systemRedColor};
    }
`;
