import { Border, SPACING } from '../../../../../Utilities/Layout';
import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { Fragment, useMemo } from 'react';

import DomesticRegistration from './DomesticRegistration';
import { Expander } from 'components/';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import useColors from 'api/hooks/use-theme';
import useInformationDomesticRegistrationValidation from 'modules/LegalEntityCreation/validation/use-information-domestic-registration-validation';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';

export default function DomesticRegistrations() {
    const {
        state: {
            data: {
                values: { RegistrationData = [] },
                noPendingValues,
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityInformation();

    function addDomesticRegistration() {
        setValue('RegistrationData', [...RegistrationData.concat({ isDomestic: true })]);
    }

    const noPendingRegistrationData = noPendingValues?.RegistrationData;

    const { validations } = useInformationDomesticRegistrationValidation();

    const { normalControlBorderColor } = useColors();

    const isAddButtonEnabled = useMemo(() => {
        const hasExistingWithDissolutionDate =
            RegistrationData.filter(({ isDeleted }) => !isDeleted).length < 2 && !!RegistrationData[0]?.dissolutionDate;

        return isEditable && (RegistrationData.length === 0 || hasExistingWithDissolutionDate);
    }, [isEditable, RegistrationData]);

    return (
        <Expander
            header='Domestic Registration Details'
            requestStep={RequestStepsView.Legal_Entity_Information}
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL}>
                    {RegistrationData.map((_, index) => (
                        <Fragment key={index}>
                            {index > 0 && <Border color={normalControlBorderColor} />}
                            <DomesticRegistration
                                index={index}
                                validation={validations[index]}
                                noPendingValues={noPendingRegistrationData?.[index]}
                            />
                        </Fragment>
                    ))}
                </StackPanel>
            }
            {...(isEditable && {
                footer: (
                    <Button
                        content='Add Domestic Registration'
                        isEnabled={isAddButtonEnabled}
                        onClick={addDomesticRegistration}
                    />
                ),
            })}
        />
    );
}
