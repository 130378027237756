export type Capability = {
    capability: string;
    brn: Brn;
};

export const BRNS = {
    LEGAL_INFORMATION_REQUESTOR: 'legal-entity:legal-information-request',
    FOREIGN_REGISTRATION_REQUESTOR: 'legal-entity:foreign-registration-request',
    OWNERSHIP_INFORMATION_REQUESTOR: 'legal-entity:ownership-information-request',
    TAX_INFORMATION_REQUESTOR: 'legal-entity:tax-information-request',
    FINANCE_INFORMATION_REQUESTOR: 'legal-entity:finance-information-request',

    LEGAL_INFORMATION_APPROVER: 'legal-entity:legal-information-approval',
    FOREIGN_REGISTRATION_APPROVER: 'legal-entity:foreign-registration-approval',
    OWNERSHIP_INFORMATION_APPROVER: 'legal-entity:ownership-information-approval',
    TAX_INFORMATION_APPROVER: 'legal-entity:tax-information-approval',
    FINANCE_INFORMATION_APPROVER: 'legal-entity:finance-information-approval',
    CTA_APPROVER: 'legal-entity:cta-approval',

    LEGAL_ENTITY_GOD: 'legal-entity:god',
} as const;

export type BrnTypes = keyof typeof BRNS;

export type Brn = (typeof BRNS)[BrnTypes];

export const entityEditorSearchBrns: Brn[] = [
    BRNS.LEGAL_INFORMATION_REQUESTOR,
    BRNS.FOREIGN_REGISTRATION_REQUESTOR,
    BRNS.OWNERSHIP_INFORMATION_REQUESTOR,
    BRNS.TAX_INFORMATION_REQUESTOR,
    BRNS.FINANCE_INFORMATION_REQUESTOR,
    BRNS.LEGAL_INFORMATION_APPROVER,
    BRNS.FOREIGN_REGISTRATION_APPROVER,
    BRNS.OWNERSHIP_INFORMATION_APPROVER,
    BRNS.TAX_INFORMATION_APPROVER,
    BRNS.FINANCE_INFORMATION_APPROVER,
    BRNS.LEGAL_ENTITY_GOD,
];
