import { VALIDATION_MESSAGES, validateDateTimeField, validateRequiredField } from './utils';
import { useCallback, useEffect, useState } from 'react';
import { useLegalEntityAuthorizedPerson, useLegalEntityOwnerShipInformation } from '../context/Provider';

import { AuthorizedPerson } from '../LegalEntityOwnershipInformation/model';
import { ValidationErrors } from '../context/model';
import { isNil } from 'lodash';
import { updateValidation } from '../context/actions/form-actions';

type Props = {
    index?: number;
};

export default function useAuthorizedPersonEditValidation({ index }: Props) {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
            },
            version,
        },
    } = useLegalEntityOwnerShipInformation();

    const {
        state: {
            data: { values: editedAuthorizedPerson },
        },
        dispatch,
    } = useLegalEntityAuthorizedPerson();

    const [validations, setValidations] = useState<ValidationErrors<AuthorizedPerson>>({});

    const validateTitle = useCallback(() => {
        const { title, name } = editedAuthorizedPerson;
        if (isNil(title)) {
            return VALIDATION_MESSAGES.REQUIRED_FIELD;
        }
        return authorizedPerson.some(
            (person, itemIndex) =>
                itemIndex !== index && !person.isDeleted && person.title === title && person.name === name
        )
            ? VALIDATION_MESSAGES.SAME_THIRD_PARTY_TITLE
            : undefined;
    }, [authorizedPerson, editedAuthorizedPerson, index]);

    useEffect(() => {
        const { appointmentDate, name, resignationDate, isDeleted } = editedAuthorizedPerson;
        const validationResult = isDeleted
            ? {}
            : {
                  appointmentDate: validateRequiredField(appointmentDate),
                  resignationDate: validateDateTimeField(resignationDate, {
                      isRequired:
                          appointmentDate && resignationDate && new Date(appointmentDate) > new Date(resignationDate),
                      errorMessage: VALIDATION_MESSAGES.APPOINTMENT_AND_RESIGNATION_DATE,
                  }),
                  name: validateRequiredField(name),
                  title: validateTitle(),
              };
        setValidations(validationResult);
    }, [editedAuthorizedPerson, version, dispatch, validateTitle]);

    useEffect(() => {
        updateValidation(dispatch, validations);
    }, [validations, version, dispatch]);

    return { validations };
}
