import { ColDef, DateFilter, NumberFilter, TextFilter, ValueFormatterParams } from 'ag-grid-community';

import EntityOIdCellRenderer from './components/EntityOIdCellRenderer';
import { LegalEntityRequest } from 'models';

export const defaultColumnDefinition: ColDef[] = [
    {
        field: 'entityOId',
        headerName: 'Entity ID',
        headerTooltip: 'Entity ID',
        suppressMovable: true,
        hide: false,
        filter: false,
        resizable: false,
        minWidth: 140,
        maxWidth: 140,
        sort: 'desc',
        comparator(valueA, valueB, nodeA, nodeB, isDescending) {
            if (!isDescending) {
                if (valueA === valueB && nodeA.data.isParent) return -1;
                if (valueA < valueB && nodeA.data.isParent) return 0;
                if (valueA < valueB && !nodeA.data.isParent) return -1;
                if (valueA > valueB && nodeB.data.isParent) return 0;
                if (valueA > valueB && !nodeB.data.isParent) return 0;
                return 0;
            } else {
                if (valueA === valueB && !nodeB.data.isParent) return 1;
                if (valueA < valueB && !nodeB.data.isParent) return 1;
                if (valueA < valueB && nodeB.data.isParent) return 1;
                if (valueA > valueB && !nodeA.data.isParent) return 1;
                if (valueA > valueB && nodeA.data.isParent) return 1;
                return 0;
            }
        },
        cellRenderer: EntityOIdCellRenderer,
    },
    {
        field: 'mdmEntityOId',
        headerName: 'Legacy ID',
        headerTooltip: 'Legacy MDM Entity OId',
        suppressMovable: true,
        hide: false,
        filter: false,
        resizable: false,
        minWidth: 100,
        maxWidth: 100,
        cellClass: 'cell-mdmEntityOId',
    },
    {
        field: 'primaryName',
        headerName: 'Entity Name',
        headerTooltip: 'Entity Name',
        hide: false,
        filter: false,
        cellClass: 'cell-primaryName',
    },
    {
        field: 'parentProcess',
        headerName: 'Parent Process',
        headerTooltip: 'Parent Process',
        hide: false,
        cellClass: 'cell-parentProcess',
    },
    {
        field: 'workItemType',
        headerName: 'Work Item Type',
        headerTooltip: 'Work Item Type',
        hide: false,
        cellClass: 'cell-workItemType',
    },
    {
        field: 'workItemState',
        headerName: 'Current State',
        headerTooltip: 'Current State',
        hide: false,
        cellClass: 'cell-workItemState',
    },
    {
        field: 'businessDriver',
        headerName: 'Business Driver',
        headerTooltip: 'Business Driver',
        hide: false,
        cellClass: 'cell-businessDriver',
    },
    {
        field: 'businessUnit',
        headerName: 'Business Unit',
        headerTooltip: 'Business Unit',
        hide: false,
        cellClass: 'cell-businessUnit',
    },
    {
        field: 'subBusinessUnit',
        headerName: 'Sub-Business Unit',
        headerTooltip: 'Sub-Business Unit',
        hide: false,
        cellClass: 'cell-subBusinessUnit',
    },
    {
        field: 'administeredBy',
        headerName: 'Administered / Managed By',
        headerTooltip: 'Administered / Managed By',
        hide: true,
        cellClass: 'cell-administeredBy',
    },
    {
        field: 'externalAdmin',
        headerName: 'External Admin',
        headerTooltip: 'External Admin',
        hide: true,
        cellClass: 'cell-externalAdmin',
    },
    { field: 'lawFirm', headerName: 'Law Firm', headerTooltip: 'Law Firm', hide: true, cellClass: 'cell-lawFirm' },
    { field: 'project', headerName: 'Project', headerTooltip: 'Project', hide: true, cellClass: 'cell-project' },
    {
        field: 'requestedUser',
        headerName: 'Request By',
        headerTooltip: 'Request By',
        hide: false,
        cellClass: 'cell-requestedUser',
    },
    {
        field: 'requestedDateUtc',
        headerName: 'Requested Date',
        headerTooltip: 'Requested Date',
        filter: DateFilter,
        valueFormatter: dateFormatter,
        hide: false,
        cellClass: 'cell-requestedDateUtc',
    },
    {
        field: 'lastModifiedUser',
        headerName: 'Last Updated By',
        headerTooltip: 'Last Updated By',
        hide: true,
        cellClass: 'cell-lastModifiedUser',
    },
    {
        field: 'lastModifiedDateUtc',
        headerName: 'Last Updated Date',
        headerTooltip: 'Last Updated Date',
        filter: DateFilter,
        valueFormatter: dateTimeFormatter,
        hide: false,
        cellClass: 'cell-lastModifiedDateUtc',
    },
    {
        field: 'structureType',
        headerName: 'Structure Type',
        headerTooltip: 'Structure Type',
        hide: true,
        cellClass: 'cell-structureType',
    },
    {
        field: 'country',
        headerName: 'Domestic Country',
        headerTooltip: 'Domestic Country',
        filter: TextFilter,
        hide: true,
        cellClass: 'cell-country',
    },
    {
        field: 'state',
        headerName: 'Domestic State',
        headerTooltip: 'Domestic State',
        filter: TextFilter,
        hide: true,
        cellClass: 'cell-state',
    },
    {
        field: 'registeredAgent',
        headerName: 'Registered Agent',
        headerTooltip: 'Registered Agent',
        hide: true,
        cellClass: 'cell-registeredAgent',
    },
    {
        field: 'formationDate',
        headerName: 'Domestic Formation Date',
        headerTooltip: 'Domestic Formation Date',
        filter: DateFilter,
        valueFormatter: dateFormatter,
        hide: true,
        cellClass: 'cell-formationDate',
    },
    {
        field: 'daysSinceFormationDate',
        headerName: 'Days since Formation',
        headerTooltip: 'Days since Formation',
        filter: NumberFilter,
        hide: true,
        cellClass: 'cell-daysSinceFormationDate',
        cellStyle: { color: 'red' },
    },
    {
        field: 'corporateTransparencyStatus',
        headerName: 'CTA Status',
        headerTooltip: 'CTA Status',
        hide: true,
        cellClass: 'cell-corporateTransparencyStatus',
    },
    {
        field: 'reviewDate',
        headerName: 'CTA Review Date',
        headerTooltip: 'CTA Review Date',
        filter: DateFilter,
        valueFormatter: localDateFormatter,
        hide: true,
        cellClass: 'cell-reviewDate',
    },
];

const { format } = new Intl.DateTimeFormat('en-US');

export function dateFormatter({ value }: ValueFormatterParams | { value: string }) {
    return value ? format(new Date(value)) : '';
}

export function localDateFormatter({ value }: ValueFormatterParams | { value: string }) {
    return value ? new Date(value).toUTCYearMonthDay() : '';
}

function pad(value: number) {
    return value < 10 ? '0' + value : value;
}

export function dateTimeFormatter({ value }: ValueFormatterParams | { value: string }) {
    if (!value) {
        return '';
    }
    const date = new Date(value);

    const hours = date.getHours();
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;

    return `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()}, ${pad(hours12)}:${pad(
        date.getMinutes()
    )} ${meridiem}`;
}

const mapForSpecialValues = {
    PostProcess: 'Awaiting Dependencies',
    PostProcess1: 'Awaiting Dependencies',
    PostProcess2: 'Awaiting Dependencies',
    PostProcess3: 'Awaiting Dependencies',
    EIN: 'EIN',
    EINChildProcess: 'EIN Child Process',
    'EIN In Progress': 'EIN In Progress',
    'EIN Notification': 'EIN Notification',
    'EIN Setup Complete': 'EIN Setup Complete',
    'BDG Pending To Submit': 'BDG Pending To Submit',
    'BDG Dissolution Setup Complete': 'BDG Dissolution Setup Complete',
};

export const getFriendlyValues = ({ value }: ValueFormatterParams | { value: string }) => {
    if (!value) {
        return '';
    }

    if (Object.keys(mapForSpecialValues).includes(value)) {
        return mapForSpecialValues[value as keyof typeof mapForSpecialValues];
    }

    const words = value?.match(/[A-Za-z][a-z]*/g) || [];

    return words.join(' ');
};

export const getParentProcessValue = ({
    value,
    data,
}: ValueFormatterParams | { value: boolean; data?: LegalEntityRequest }) => {
    if (data?.workItemType?.includes('Dissolution')) {
        return 'Dissolution';
    }
    return value ? 'Legal Entity Standalone Update Request' : 'Legal Entity Onboarding';
};
