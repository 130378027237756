import { isNull, isUndefined } from 'lodash';

import ApiService from '../ApiService';
import { DocumentRequest } from '../../models/LegalEntityRequest/Documents/Documents';
import { DocumentsResponse } from '../../models/LegalEntityRequest/Documents/DocumentsResponse';
import { IDocumentService } from '../interfaces/IDocumentService';
import { LogError } from '../../models';
import { StoredDocumentModel } from 'components/controls/FileUpload/models';
import { constants } from '../../constants/env-config';
import { getSectionName } from 'components/controls/FileUpload/utils';

export class DocumentService implements IDocumentService {
    public logInfo: LogError = {
        service: 'Legal Entity Request Service',
        module: 'Global',
    };

    public baseURL: string = constants.ENTITY_MASTER_API;

    public async UploadFile(
        entityOid: number,
        file: any,
        sectionName: string,
        documentType: number,
        taxRegistration?: number,
        effectiveDate?: Date
    ): Promise<any> {
        let data: DocumentRequest = {
            EntityOId: entityOid,
            File: file,
            EffectiveDate: effectiveDate?.getYearMonthDay(),
            SectionName: sectionName,
            DocumentTypeId: documentType,
            TaxRegistrationId: taxRegistration,
        };
        this.logInfo.method = this.UploadFile.name;
        let response = new Promise<any>((resolve, reject) => {
            ApiService.Post(`${this.baseURL}documents/upload`, this.logInfo, data, false, true)
                .then((x: any) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
        return response;
    }

    public DownloadFile(entityOid: number, documentId: number): Promise<any> {
        this.logInfo.method = this.DownloadFile.name;
        let response = new Promise<any>((resolve, reject) => {
            ApiService.Get(
                `${this.baseURL}documents/download/${entityOid}/document/${documentId}`,
                this.logInfo,
                false,
                'binary'
            )
                .then((x: any[]) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
        return response;
    }

    public GetFiles(entityOid: number): Promise<DocumentsResponse[]> {
        this.logInfo.method = this.GetFiles.name;
        return ApiService.Get(`${this.baseURL}documents/${entityOid}`, this.logInfo).then(
            (response: DocumentsResponse[]) => {
                return response;
            }
        );
    }

    public UpdateDocumentElements(document: StoredDocumentModel): Promise<any> {
        this.logInfo.method = this.UpdateDocumentElements.name;

        const {
            document: {
                documentOId: { value },
                documentElements,
                effectiveDateString,
                taxRegistrationIds,
            },
            updates: { effectiveDate: updatedEffectiveDate, sectionName },
        } = document;

        const effectiveDate = isNull(updatedEffectiveDate)
            ? updatedEffectiveDate
            : isUndefined(updatedEffectiveDate)
            ? effectiveDateString
            : updatedEffectiveDate.toLocaleDateString();

        return ApiService.Post(
            `${this.baseURL}documents/update/document-elements`,
            this.logInfo,
            {
                documentOId: value,
                taxRegistrationId: taxRegistrationIds[0],
                effectiveDate,
                leWorkflowSectionName: sectionName ?? getSectionName(documentElements),
            },
            true
        ).then((response: any) => {
            return response;
        });
    }

    public DeleteFile(entityOId: number, documentId: number): Promise<any> {
        this.logInfo.method = this.DeleteFile.name;
        let response = new Promise<any>((resolve, reject) => {
            ApiService.Delete(`${this.baseURL}documents/entities/${entityOId}/documents/${documentId}`, this.logInfo)
                .then((x: any[]) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
        return response;
    }
}
