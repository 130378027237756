import { DASHBOARD_VIEW_STATE, WorkItemState } from 'modules/RequestsDashboard/models';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';

import { ILegalEntityService } from '../../services/interfaces';
import { LegalEntityRequest } from '../../models';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { calcDaysSinceToday } from 'Utilities/date';
import { useAppContext } from '../../context/app-context';

export type LegalEntityRefetch = <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<QueryObserverResult<LegalEntityRequest[], unknown>>;

export async function fetchLegalEntities({
    userTraksCache,
    fromDateJSON,
    toDateJSON,
    workItemState,
}: {
    userTraksCache: boolean;
    fromDateJSON?: string | undefined;
    toDateJSON?: string | undefined;
    workItemState: WorkItemState;
}) {
    const service = ServiceLocator.container.resolve(ILegalEntityService);
    const response = await service.GetLegalEntities(userTraksCache, workItemState, fromDateJSON, toDateJSON);
    return response.map((entity, index) => {
        const { isParent, entityOId, formationDate } = entity;
        const uniqueId = `${entityOId}`.concat(!isParent ? `-${index}` : ``);
        return {
            ...entity,
            uniqueId,
            daysSinceFormationDate: formationDate ? calcDaysSinceToday(formationDate) : undefined,
        };
    });
}

export default function useLegalEntities() {
    const {
        state: {
            dashboard: { fromDate, toDate, dashboardView },
            settings: { userTraksCache },
        },
    } = useAppContext();

    const fetch = async (): Promise<LegalEntityRequest[]> => {
        const fromDateJSON = fromDate?.convertServerDateToLocalDate().toJSON();
        const toDateJSON = toDate?.convertServerDateToLocalDate().toJSON();

        return await fetchLegalEntities({
            userTraksCache,
            fromDateJSON,
            toDateJSON,
            workItemState: DASHBOARD_VIEW_STATE[dashboardView],
        });
    };

    return useQuery('Legal Entities', fetch, {
        refetchInterval: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
    });
}
