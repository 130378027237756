import { GlobalDialogProps, GlobalState } from 'GlobalState';
import React, { useState } from 'react';

import Dialog from './Dialog';

class Notifier<T> {
    private _listener: (t: T) => void = () => {};
    constructor() {
        this.registerNotification = this.registerNotification.bind(this);
        this.notify = this.notify.bind(this);
    }
    public registerNotification(callback: (t: T) => void) {
        this._listener = callback;
    }
    public notify(t: T) {
        this._listener(t);
    }
}

export default function DialogPrompt() {
    const [isOpen, setIsOpen] = useState(false);
    const [notifier, setNotifier] = useState<Notifier<boolean | undefined>>();
    const [dialogProps, setDialogProps] = useState<GlobalDialogProps>();

    GlobalState.openDialog = ({
        variant = 'warning',
        title = '',
        content = '',
        isConfirmationDialog = true,
        okButtonLabel = 'Confirm',
        cancelButtonLabel = 'Cancel',
    }) => {
        setDialogProps({ variant, title, content, isConfirmationDialog, okButtonLabel, cancelButtonLabel });
        setIsOpen(true);
        const callback = new Notifier<boolean | undefined>();
        setNotifier(callback);
        const promise = new Promise<boolean>((resolve, reject) => {
            callback.registerNotification((v) => {
                if (v === undefined) return reject();
                resolve(v);
            });
        });
        return promise;
    };

    const resolveDialog = (result: boolean | undefined) => {
        notifier?.notify(result);
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return <Dialog {...dialogProps} handleOk={() => resolveDialog(true)} handleCancel={() => resolveDialog(false)} />;
}
