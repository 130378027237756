import * as React from 'react';

import { BooleanField, Text } from 'components';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { setIsRequestConfirmationRequired, setUseTraksCache, useAppContext } from 'context/app-context';

import { SPACING } from 'Utilities/Layout';
import { constants } from '../constants/env-config';
import packageJson from '../../package.json';

export default function ApplicationSettings() {
    const {
        state: {
            settings: { isRequestConfirmationRequired, userTraksCache },
        },
        dispatch,
    } = useAppContext();

    return (
        <StackPanel padding={`${SPACING.XL}px ${SPACING.LG}px`} styleOverrides={{ justifyContent: 'space-between' }}>
            <StackPanel itemGap={SPACING.XL}>
                <TextBlock text='Settings' styleName='lightHeading1Style' />
                <BooleanField
                    label='Confirmation required before request actions'
                    value={isRequestConfirmationRequired}
                    canResetValue={false}
                    canUndo={false}
                    onValueChanged={(newValue = false) => {
                        setIsRequestConfirmationRequired(dispatch, newValue);
                        localStorage.setItem('settingsIsRequestConfirmationRequired', newValue.toString());
                    }}
                />
                <BooleanField
                    label='Use Traks cache'
                    value={userTraksCache}
                    canResetValue={false}
                    canUndo={false}
                    onValueChanged={(newValue = true) => {
                        setUseTraksCache(dispatch, newValue);
                        localStorage.setItem('settingsUseTraksCache', newValue.toString());
                    }}
                />
            </StackPanel>
            <StackPanel itemGap={SPACING.MD}>
                <Text variant='body-small' textAlignment='right'>
                    Version: {packageJson.version.substring(0, packageJson.version.length - 2)}.{constants.VERSION}
                </Text>
            </StackPanel>
        </StackPanel>
    );
}
