import { Address, Country } from '..';

import { KeyValue } from 'api/models/reference-data';

export enum CompletionStateKeys {
    Incomplete = 1,
    Complete = 2,
}

export const CompletionState: KeyValue[] = [
    { Key: CompletionStateKeys.Incomplete, Value: 'Incomplete' },
    { Key: CompletionStateKeys.Complete, Value: 'Complete' },
];

export enum YesNoStateKeys {
    No = 1,
    Yes = 2,
}

export const YesNoState: KeyValue[] = [
    { Key: YesNoStateKeys.No, Value: 'No' },
    { Key: YesNoStateKeys.Yes, Value: 'Yes' },
];

export enum RegisteredAgent {
    Intertrust = 3,
    Maples = 6,
}

export enum LegalTypes {
    Corporation = 1,
    LimitedPartnership = 3,
    LimitedLiabilityCompany = 2,
    SARLSAGmbH = 4,
    SCSp = 5,
    Trust = 6,
    Icav = 7,
}

export enum InterestTypes {
    GeneralPartner = 2,
    LimitedPartner = 3,
    ManagingMember = 5,
    Member = 6,
    SoleMember = 7,
    Shareholder = 8,
}

export enum InterestUnit {
    Share = 3,
    Interest = 1,
    Unit = 2,
}

export enum TaxCodeType {
    GIIN = 13,
}

export const defaultAddress: Partial<Address> = {
    line1: undefined,
    line2: undefined,
    line3: undefined,
    line4: undefined,
    countryId: undefined,
    city: undefined,
    state: undefined,
    stateId: undefined,
    postalCode: undefined,
} as const;

export function getDefaultAddress(country?: number, registeredAgent?: number) {
    switch (country) {
        case Country.UnitedStates: {
            return {
                ...defaultAddress,
                line1: '345 Park Avenue',
                countryId: Country.UnitedStates,
                city: 'New York',
                state: 'New York',
                stateId: 4868,
                postalCode: '10154',
            };
        }
        case Country.UnitedKingdom: {
            return {
                ...defaultAddress,
                line1: '40 Berkeley Square',
                countryId: Country.UnitedKingdom,
                city: 'City of London',
                state: 'London, City of',
                stateId: 4724,
                postalCode: 'W1J 5AL',
            };
        }
        case Country.Singapore: {
            return {
                ...defaultAddress,
                line1: '77 Robinson Road, #13-00, Robinson 77',
                countryId: Country.Singapore,
                postalCode: '068896',
            };
        }
        case Country.Luxembourg: {
            return {
                ...defaultAddress,
                line1: '2-4 rue Eugene Ruppert',
                countryId: Country.Luxembourg,
                state: 'Luxembourg (fr)',
                stateId: 2420,
                postalCode: 'L-2453',
            };
        }
        case Country.Ireland: {
            return {
                ...defaultAddress,
                line1: '2nd Floor',
                line2: '30 Herbert Street',
                countryId: Country.Ireland,
                city: 'Dublin',
                state: 'Dublin',
                stateId: 1845,
                postalCode: '2',
            };
        }
        case Country.Bermuda: {
            return {
                ...defaultAddress,
                line1: 'Clarendon House',
                line2: 'Church Street',
                countryId: Country.Bermuda,
                city: 'Hamilton',
                state: 'Hamilton',
                stateId: 475,
                postalCode: 'HM11',
            };
        }
        case Country.CaymanIslands: {
            if (registeredAgent === RegisteredAgent.Intertrust) {
                return {
                    ...defaultAddress,
                    line1: 'One Nexus Way, Camana Bay',
                    countryId: Country.CaymanIslands,
                    city: 'Grand Cayman',
                    state: 'George Town',
                    stateId: 750,
                    postalCode: 'KY1-9005',
                };
            } else if (registeredAgent === RegisteredAgent.Maples) {
                return {
                    ...defaultAddress,
                    line1: 'PO Box 309',
                    line2: 'Ugland House',
                    countryId: Country.CaymanIslands,
                    city: 'Grand Cayman',
                    state: 'George Town',
                    stateId: 750,
                    postalCode: 'KY1-1104',
                };
            } else {
                return defaultAddress;
            }
        }
        default: {
            return defaultAddress;
        }
    }
}
