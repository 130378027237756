import { LegalEntityDTO, TaxRegistration } from '../../../../models';
import { getTaxRegistrationsFromForeign, mapForeignRegistrationUpdateSection } from './helpers';

import { LegalEntityRegistrationUpdateData } from 'modules/LegalEntityCreation/LegalEntityRegistration/LegalEntityForeignRegistrationUpdate/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';
import { isNil } from 'lodash';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityRegistrationUpdateData => {
    const { entity } = entityDto;
    const {
        legalEntity: { legalTypeID },
        taxRegistrations,
    } = entity;

    const foreignRegistrations = taxRegistrations?.filter(
        ({ dissolutionDate, isDomestic }) => isNil(dissolutionDate) && !isDomestic
    );

    return {
        RegistrationData:
            foreignRegistrations?.map((registration: TaxRegistration) => {
                return {
                    ...mapForeignRegistrationUpdateSection(registration, entity?.entityOId ?? 0, true),
                    legalType: legalTypeID,
                };
            }) ?? [],
        legalType: legalTypeID,
    };
};

export const mapFromSectionData = (section?: LegalEntityRegistrationUpdateData): RecursivePartial<LegalEntityDTO> => {
    const toReturn = {} as RecursivePartial<LegalEntityDTO>;
    toReturn.entity = toReturn.entity ?? {};
    const { taxRegistrations, removedTaxRegistrations } = getTaxRegistrationsFromForeign(section?.RegistrationData);

    if (taxRegistrations.length > 0 && toReturn.entity) toReturn.entity.taxRegistrations = taxRegistrations;
    if (removedTaxRegistrations.length > 0) toReturn.removedTaxRegistrations = removedTaxRegistrations;

    return toReturn;
};
