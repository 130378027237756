export enum WorkflowType {
    LegalEntityCreationWorkflowType = 'LegalEntityCreationWorkflow',
    LegalEntityUpdateWorkflowType = 'LegalEntityUpdateWorkflow',
    ForeignRegistrationUpdateWorkflowType = 'ForeignRegistrationUpdateWorkflow',
    ForeignRegistrationWorkflowType = 'ForeignRegistrationChildProcess',
    FinanceInfoWorkflowType = 'FinanceChildProcess',
    TaxClassificationWorkflowType = 'TaxChildProcess',
    OwnershipWorkflowType = 'OwnershipChildProcess',
    LegalEntityDissolutionParentWorkflowType = 'DissolutionParentWorkflow',
    LegalEntityDissolutionLitigationWorkflowType = 'DissolutionLitigationWorkflow',
    LegalEntityDissolutionTaxWorkflowType = 'DissolutionTaxWorkflow',
    LegalEntityDissolutionFinanceWorkflowType = 'DissolutionFinanceWorkflow',
    LegalEntityDissolutionLegalWorkflowType = 'DissolutionLegalWorkflow',
    LegalEntityDissolutionTreasuryWorkflowType = 'DissolutionTreasuryWorkflow',
}

export enum ExternalWorkflowTypes {
    EinWorkItem = 'EINChildProcess',
    BanckAccnt = '',
}

export type ParentWorkflows =
    | WorkflowType.LegalEntityUpdateWorkflowType
    | WorkflowType.LegalEntityDissolutionParentWorkflowType;
