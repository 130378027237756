import { InterestTypes, LegalTypes } from 'models/LegalEntityRequest/DefaultValues';
import { useCallback, useEffect } from 'react';

import { VALIDATION_MESSAGES } from './utils';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityOwnerShipInformation } from '../context/Provider';

const { SCSp, LimitedPartnership, LimitedLiabilityCompany } = LegalTypes;
const { SoleMember, LimitedPartner, GeneralPartner } = InterestTypes;

const { EMPTY_OWNERSHIP_DETAILS, GENERAL_TYPE_REQUIRED, INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER } =
    VALIDATION_MESSAGES;

type Props = {
    legalType?: number;
};

export default function useOwnershipDetailsValidation({ legalType }: Props) {
    const {
        state: {
            data: {
                values: { ownershipDetails = [] },
            },
            version,
        },
        dispatch,
    } = useLegalEntityOwnerShipInformation();

    const validateInterestType = useCallback(() => {
        const interestTypes =
            ownershipDetails?.reduce((result: number[], current) => {
                const { isDeleted, interestType } = current;
                return !isDeleted && interestType ? [...result, interestType] : result;
            }, []) ?? [];

        switch (legalType) {
            case SCSp:
            case LimitedPartnership: {
                return interestTypes.includes(LimitedPartner) && !interestTypes.includes(GeneralPartner)
                    ? GENERAL_TYPE_REQUIRED
                    : undefined;
            }
            case LimitedLiabilityCompany: {
                const onlySoleMember = interestTypes.length > 1 && interestTypes.includes(SoleMember);
                return onlySoleMember ? INTEREST_TYPE_SHOULD_BE_ONLY_SOLE_MEMBER : undefined;
            }
        }
    }, [legalType, ownershipDetails]);

    useEffect(() => {
        if (isEmpty(ownershipDetails?.filter(({ isDeleted }) => !isDeleted))) {
            updateValidation(dispatch, { ownershipDetails: EMPTY_OWNERSHIP_DETAILS });
            return;
        }

        const interestTypeValidation = validateInterestType();

        if (interestTypeValidation) {
            updateValidation(dispatch, { ownershipDetails: interestTypeValidation });
            return;
        }

        updateValidation(dispatch, { ownershipDetails: undefined });
    }, [version, dispatch, ownershipDetails, validateInterestType]);
}
