export enum Country {
    UnitedStates = 236,
    Japan = 112,
    HongKong = 100,
    Australia = 14,
    Canada = 40,
    CaymanIslands = 42,
    China = 46,
    India = 103,
    Luxembourg = 130,
    Mauritius = 142,
    Singapore = 200,
    UnitedKingdom = 235,
    VirginIslandsBritish = 243,
    Ireland = 107,
    Bermuda = 25,
}

export const Countries = {
    UnitedStates: 236,
    Japan: 112,
    HongKong: 100,
    Australia: 14,
    Canada: 40,
    CaymanIslands: 42,
    China: 46,
    India: 103,
    Luxembourg: 130,
    Mauritius: 142,
    Singapore: 200,
    UnitedKingdom: 235,
    VirginIslandsBritish: 243,
    Ireland: 107,
    Bermuda: 25,
    Germany: 83,
    Jersey: 113,
    Netherlands: 157,
    Italy: 110,
    Guernsey: 92,
    Spain: 209,
    Denmark: 61,
    Default: 10000,
} as const;

export const COUNTRIES_WITHOUT_REGISTERED_AGENTS = [
    Countries.Luxembourg,
    Countries.Germany,
    Countries.Ireland,
    Countries.Jersey,
    Countries.Netherlands,
    Countries.UnitedKingdom,
    Countries.Italy,
    Countries.Guernsey,
    Countries.Spain,
    Countries.Denmark,
    Countries.Canada,
] as const;

export const NonLegalCountries = [Countries.CaymanIslands, Countries.UnitedStates, Countries.Luxembourg] as const;

export enum OutOfLegalCountries {
    CaymanIslands = 42,
    UnitedStates = 236,
    Luxembourg = 130,
}

export function isUnitedStates(countryId?: number) {
    return countryId === Countries.UnitedStates;
}

export function isLuxembourg(countryId?: number) {
    return countryId === Countries.Luxembourg;
}

export function isCaymanIslands(countryId?: number) {
    return countryId === Countries.CaymanIslands;
}
