import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { useMemo } from 'react';
import { setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { LegalEntityTaxClassificationProps } from '../../model';
import { SubmitButton } from 'components';
import { TaskActions } from 'modules/LegalEntityCreation/Shared/TaskActions';
import { isOpen } from 'models/Workflow';
import useApiServices from '../../../LegalEntityCreationView/hooks/use-api-services';
import useDocumentUpload from 'hooks/use-document-upload';
import { useLegalEntityTaxClassification } from 'modules/LegalEntityCreation/context/Provider';
import { useRequestContext } from 'context/request-context';

export default function ActionPanel(props: LegalEntityTaxClassificationProps) {
    const {
        state: {
            data: {
                values: { entityOId },
            },
            documents: { sections },
            canSave,
            canSubmit,
        },
        getChanges,
        dispatch,
    } = useLegalEntityTaxClassification();

    const { updateDocuments } = useDocumentUpload();
    const { updateWorkItemNotes } = useApiServices();

    const { isRequestNotesChanged } = useRequestContext();

    const submitTask = useMemo(
        () =>
            props.mode === 'Edit'
                ? props.workItem?.tasks?.find(
                      ({ taskType, status }) => ['RequestTaskType'].includes(taskType) && isOpen(status)
                  )
                : undefined,
        [props]
    );

    const handleSaveAtCreate = async () => {
        if (props.mode !== 'Create') {
            return;
        }

        setIsLoading(dispatch, true);
        try {
            await updateDocuments(sections, entityOId);
            await props.onSave(getChanges());
            await props.onCreateWorkItem(entityOId ? entityOId : props.data?.entityOId ?? 0, 'TaxChildProcess');
            setShouldReload(dispatch, true);
        } catch (reason) {
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    const handleSaveAtEdit = async (isSubmit = false) => {
        if (props.mode !== 'Edit') {
            return;
        }

        setIsLoading(dispatch, true);
        try {
            await updateDocuments(sections, entityOId);
            if (!isSubmit && isRequestNotesChanged(props.workItem)) {
                await updateWorkItemNotes(props.workItem);
            }
            await props.onSave(getChanges());
            setShouldReload(dispatch, true);
        } catch (reason) {
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (props.mode === 'Create') {
        return (
            <SubmitButton
                label='Save'
                isEnabled={canSave}
                onClick={async () => {
                    await handleSaveAtCreate();
                }}
            />
        );
    }

    if (props.mode === 'Edit') {
        return (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    isEnabled={canSave || isRequestNotesChanged(props.workItem)}
                    styleName='textOnlyButtonStyle'
                    onClick={async () => {
                        await handleSaveAtEdit();
                    }}
                />
                {submitTask && (
                    <TaskActions
                        task={submitTask}
                        isSubmitEnabled={canSubmit}
                        onCompleteTask={(task, exitCode) => {
                            props.onCompleteTask(task, exitCode as string);
                        }}
                        preSubmit={async () => {
                            await handleSaveAtEdit(true);
                        }}
                    />
                )}
            </StackPanel>
        );
    }

    if (props.mode === 'Approval') {
        const approveTask = props.workItem.tasks?.find(
            (task) => task.taskType === 'UpdateRequestTaskType' && isOpen(task.status)
        );

        return approveTask ? (
            <TaskActions
                onCompleteTask={(task, exitCode, rejectReason) => {
                    props.onCompleteTask(task, exitCode as string, rejectReason);
                    setShouldReload(dispatch, true);
                }}
                task={approveTask}
            />
        ) : null;
    }
    return null;
}
