import { FinanceInfoTask, FinanceInfoWorkflow } from 'models/LegalEntityRequest/Workflow';

import { KeyValue } from 'models';

type DependentData = {
    legalName?: string;
    businessUnitOId?: number;
    subBusinessUnitOId?: number;
    administeredBy?: number;
};

type FormData = {
    oracleNumber?: number;
    productNumber?: string;
    costCenterNumber?: string;
    oracleLineOfBusinessId?: number;
    oracleGSO?: number;
    investranId?: number;
    primaryLedgerId?: number;
    billingOracleNumber?: string;
    billingProductNumber?: string;
    billingCostCenterNumber?: string;
    treasuryLineOfBusinessId?: number;
    investranFundFamily?: number;
    investranParentFund?: number;
    consolidatingStatusInOracleId?: number;
    repaymentGroupId?: number;
    wssPaymentTypeId?: number;
    oracleLocationCodeId?: number;
    paymentsToWss?: boolean;
    isAgisRequired?: boolean;
    pushToOracle?: boolean;
    billingProjectCode?: string;
    pushLegalEntityToInvestran?: boolean;
    investranGPFundFamily?: number;
    isGPFund?: boolean;
    gpInterestType?: number;
    gpReportingType?: number;
    gpEntityTier?: number;
    taxPercentage?: string;
    sbsLegalEntity?: boolean;
    doubleEntryTransactionProcessing?: boolean;
    fiscalPeriodEnd?: Date;
    investranLegalEntityType?: number;
    investranCurrency?: number;
    investranDomain?: number;
    entityController?: number;
};

export type LegalEntityFinanceDetailsData = FormData & DependentData;

export const CostCenterNumbers = {
    PE: 56001,
    RE_BREP: 57001,
    RE_BREDS: 57093,
    RE_BPP_US: 57202,
    RE_BPP_Asia: 57087,
    RE_BPP_EU: 57205,
    BTO: 56008,
    BXG: 56184,
    LS: 56134,
    INFRA: 56133,
};

export const ProductNumbers = {
    PE: 4351,
    RE_BREP: 6221,
    RE_BREDS: 6281,
    RE_BPP_US: 6230,
    RE_BPP_Asia: 6236,
    RE_BPP_EU: 6235,
    SP: 4611,
    BTO: 4811,
    BXG: 4815,
    LS: 4752,
    INFRA: 4715,
};

export const OracleGSOLegalEntityFamilies: KeyValue[] = [
    { Key: 0, Value: 'GSO Management' },
    { Key: 1, Value: 'GSO Funds' },
    { Key: 2, Value: 'GSO GP' },
    { Key: 3, Value: 'GSO CLO' },
];

type BaseProps = {
    data?: LegalEntityFinanceDetailsData;
    noPendingData?: LegalEntityFinanceDetailsData;
};

type ReadOnlyProps = {
    mode: 'Readonly';
    workItem?: FinanceInfoWorkflow;
};

type CreateProps = {
    mode: 'Create';
    workItem?: FinanceInfoWorkflow;
    onSave: (data: Partial<LegalEntityFinanceDetailsData>) => Promise<number>;
    onCreateWorkItem: (entityId: number, type: string) => void;
};

type EditProps = {
    mode: 'Edit';
    workItem: FinanceInfoWorkflow;
    onSave: (data: Partial<LegalEntityFinanceDetailsData>) => Promise<number>;
    onCompleteTask: (task: FinanceInfoTask, exitCode: string, rejectReason?: string) => void;
};

type ApprovalProps = {
    mode: 'Approval';
    workItem: FinanceInfoWorkflow;
    onCompleteTask: (task: FinanceInfoTask, exitCode: string, rejectReason?: string) => void;
};

export type LegalEntityFinanceDetailsProps = BaseProps & (ReadOnlyProps | CreateProps | EditProps | ApprovalProps);
