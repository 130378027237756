import { LegalEntityInformationData } from '../model';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export default function useRemovingNegativeIds() {
    const filterNegativeIds = useCallback((data?: LegalEntityInformationData) => {
        if (isNil(data)) {
            return {};
        }

        const { taxCodes = [], RegistrationData = [], addresses = [] } = data;

        return {
            ...data,
            taxCodes: taxCodes.filter((element) => element.taxCodeId !== undefined && element.taxCodeId >= 0),
            addresses: addresses.filter((element) => element.addressId !== undefined && element.addressId >= 0),
            RegistrationData: RegistrationData.filter(
                (element) => element.taxRegistrationId !== undefined && element.taxRegistrationId >= 0
            ),
        };
    }, []);
    return { filterNegativeIds };
}
