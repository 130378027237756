import { FontIcon, StackPanel } from '@bxgrandcentral/controls';
import React, { useState } from 'react';

import { SPACING } from 'Utilities/Layout';
import TextField from 'components/controls/TextField/TextField';
import styled from 'styled-components';

type Props = {
    value: string;
    onValueChanged: (newValue: string) => void;
};

export default function FileNameField({ value, onValueChanged }: Props) {
    const [defaultValue] = useState(value);
    const [fileName, setFileName] = useState(value);
    const [extension] = useState(value.split('.').pop() ?? '');

    const getFileNameWithExtension = (newValue: string) => (newValue ? `${newValue}.${extension}` : newValue);

    const [key, setKey] = useState(0);

    return (
        <StackPanel styleOverrides={{ position: 'relative' }}>
            <TextField
                key={key}
                value={fileName}
                canUndo={false}
                isEditable
                isRequired
                canResetValue={false}
                variant='default'
                onGotFocus={() => {
                    if (extension && fileName.endsWith(extension)) {
                        setFileName(fileName.slice(0, fileName.lastIndexOf('.')));
                    }
                }}
                onLostFocus={() => {
                    const newFileName = getFileNameWithExtension(fileName);
                    setFileName(newFileName);
                    if (value !== newFileName) {
                        onValueChanged(newFileName);
                    }
                    // workaround because GC tooltip thrown an error by clicking undo
                    setKey(key + 1);
                }}
                onValueChanged={(newValue = '') => {
                    if (newValue !== value) {
                        setFileName(newValue);
                        onValueChanged(
                            extension && newValue.endsWith(extension) ? newValue : getFileNameWithExtension(newValue)
                        );
                    }
                }}
            />
            {/* workaround because GC tooltip thrown an error by clicking undo */}
            {value !== defaultValue && (
                <ResetButton
                    onClick={() => {
                        setFileName(defaultValue);
                        onValueChanged(defaultValue);
                    }}>
                    <FontIcon iconName='Undo' />
                </ResetButton>
            )}
        </StackPanel>
    );
}

const ResetButton = styled.div`
    background: none;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    padding: 0 ${SPACING.XXS}px;
    right: 6px;
    &:hover {
        cursor: pointer;
    }
`;
