import React, { ReactElement, useState } from 'react';
import { StackPanel, TextBlock, ToolBarButton, ToolTipService } from '@bxgrandcentral/controls';

import { SPACING } from '../../Utilities/Layout';
import Text from 'components/Text/Text';
import styled from 'styled-components';
import useColors from '../../api/hooks/use-theme';

type Props = {
    header: string;
    isExpanded?: boolean;
    padding?: number;
    requestStep?: string;
    toolTip?: string;
    toolTipService?: ToolTipService;
    content: ReactElement;
    footer?: ReactElement;
};

export default function Expander({
    header,
    padding,
    isExpanded: isExpandedProp = true,
    content,
    footer,
    requestStep,
    toolTip,
    toolTipService,
}: Props) {
    const [isExpanded, setIsExpanded] = useState(isExpandedProp);

    const { accentColor, normalControlBorderColor } = useColors();

    const isPanelHidden = localStorage.getItem(`${requestStep}_${header.toLowerCase().replace(' ', '_')}`) === 'false';

    return (
        <Component color={normalControlBorderColor}>
            <Header>
                <ToolBarButton
                    foregroundColor={accentColor}
                    fontSize={18}
                    icon={isExpanded ? 'CalculatorSubtract' : 'Add'}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                />
                <TextBlock fontSize={24} text={header} toolTip={toolTip} toolTipService={toolTipService} />
            </Header>
            <StackPanel styleOverrides={{ display: isExpanded ? 'flex' : 'none' }}>
                <Content padding={padding}>
                    {isPanelHidden ? (
                        <Text variant='body-small' {...(padding === 0 && { styleOverrides: { padding: `0 70px` } })}>
                            Content has been disabled
                        </Text>
                    ) : (
                        content
                    )}
                </Content>
                {footer && <Footer color={normalControlBorderColor}>{footer}</Footer>}
            </StackPanel>
        </Component>
    );
}

type StyleProps = Partial<{
    padding: number;
    color: string;
}>;

const Component = styled.div<StyleProps>`
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px, rgb(0 0 0 / 20%) 0px 2px 4px -1px;
    border: ${({ color }) => `1px solid ${color}`};
    margin: 4px;
`;

const Content = styled.div<StyleProps>`
    padding: ${({ padding = 70 }) => `${SPACING.LG}px ${padding}px`};
    padding-top: 0;
`;

const Header = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: ${SPACING.SM}px;
    padding: ${SPACING.LG}px;
`;

const Footer = styled.div<StyleProps>`
    display: flex;
    justify-content: flex-end;
    padding: ${SPACING.LG}px;
    border-top: ${({ color }) => `1px solid ${color}`};
`;
