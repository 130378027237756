import { Button, StackPanel } from '@bxgrandcentral/controls';
import { setIsEditable, setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import { Capabilities } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { DISSOLUTION_REJECT_DIALOG_MESSAGE } from 'modules/LegalEntityCreation/validation/utils';
import React from 'react';
import { TaskActions } from '../../../../Shared/TaskActions';
import { isEmpty } from 'lodash';
import { isOpen } from '../../../../../../models/Workflow';
import useColors from 'api/hooks/use-theme';
import useHasCapability from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-has-capability';
import { useLegalEntityDissolutionBDG } from 'modules/LegalEntityCreation/context/Provider';

export default function ActionPanel(props: ActionPanelProps) {
    const { entityOId } = props;
    const colors = useColors();

    const {
        state: {
            data: { changes },
            canSave,
            canApprove,
        },
        dispatch,
    } = useLegalEntityDissolutionBDG();

    const hasCapability = useHasCapability();

    const handleSave = async (isSubmit = false) => {
        if (props.mode === 'Readonly' || props.mode === 'Approval' || !entityOId) {
            return;
        }

        setIsLoading(dispatch, true);
        setIsEditable(dispatch, false);

        try {
            if (!isEmpty(changes)) {
                await props.onSave(changes);
            }

            setShouldReload(dispatch, true);

            if (!isSubmit) {
                setIsEditable(dispatch, true);
            }
        } catch (reason) {
            setIsEditable(dispatch, true);
            setIsLoading(dispatch, false);
            return Promise.reject(reason);
        }
    };

    if (!entityOId) {
        return null;
    }

    switch (props.mode) {
        case 'Edit': {
            const submitTask = props.workItem?.tasks?.find(
                (task) => task.taskType === 'BdgSubmitTaskType' && isOpen(task.status)
            );

            return (
                <StackPanel
                    orientation='horizontal'
                    horizontalAlignment='right'
                    styleOverrides={{ justifyContent: 'space-between' }}>
                    <Button
                        isEnabled={canSave}
                        content='Save'
                        styleName='textOnlyButtonStyle'
                        onClick={async () => {
                            await handleSave();
                        }}
                    />
                    {submitTask ? (
                        <TaskActions
                            isSubmitEnabled={canApprove && hasCapability(Capabilities.approveDissolutionBDG)}
                            task={{
                                ...submitTask,
                                exitCodes: submitTask?.exitCodes?.filter((code) => code !== 'Withdraw'),
                            }}
                            onCompleteTask={async (task, exitCode, rejectReason) => {
                                setIsLoading(dispatch, true);
                                await props.onCompleteTask(task, exitCode as string, rejectReason);
                                setIsLoading(dispatch, false);
                            }}
                            preSubmit={async () => {
                                await handleSave(true);
                            }}
                            rejectDialogMessage={DISSOLUTION_REJECT_DIALOG_MESSAGE}
                            rejectDialogMessageColor={colors.systemRedColor}
                        />
                    ) : null}
                </StackPanel>
            );
        }
        default:
            return null;
    }
}
