import { Debugger, Expander, Footer, Form, Select, TextField } from 'components';
import { GridLayout, SPACING } from 'Utilities/Layout';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { CompletionState } from 'models/LegalEntityRequest/DefaultValues';
import { DISSOLUTION_TAX_STATE } from 'models/LegalEntityRequest/Workflow';
import DissolutionRejectReason from 'modules/LegalEntityCreation/Shared/DissolutionRejectReason';
import { LegalEntityDissolutionTaxProps } from './model';
import PageUpdateInfo from '../components/PageUpdateInfo';
import React from 'react';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useDissolutionTaxValidation from 'modules/LegalEntityCreation/validation/use-dissolution-tax-validation';
import useLastCompletedTask from '../hooks/use-last-completed-task';
import { useLegalEntityDissolutionTax } from '../../context/Provider';

export default function LegalEntityDissolutionTax(props: LegalEntityDissolutionTaxProps) {
    const context = useLegalEntityDissolutionTax();
    const {
        state: { legalEntityDissolutionTaxWorkItem },
    } = useCreationViewContext();
    const {
        state: {
            data: {
                values: { entityOid, savedAt, savedBy },
            },
        },
        getValue,
    } = context;

    const { workItem } = props;
    const lastCompletedTask = useLastCompletedTask({
        workItem: legalEntityDissolutionTaxWorkItem,
        saved: {
            savedBy,
            savedAt,
        },
    });

    useDissolutionTaxValidation();

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Dissolution_Tax} />
            <Form>
                <Expander
                    header='Legal Entity Dissolution - Tax'
                    requestStep={RequestStepsView.Dissolution_Tax}
                    content={
                        <StackPanel itemGap={SPACING.XL} margin={`${SPACING.XL}px 0 0 0`}>
                            <GridLayout columns='2fr 1fr'>
                                <StackPanel>
                                    <TextBlock
                                        text='All tax-related work has been settled, completed, and filed.'
                                        toolTip='Confirm if there are any tax-related liabilities, any tax withholding refunds to be distributed, and any pending invoices with tax service provider.
                                        Get tax invoices pre-billed for the current year.'
                                        toolTipService={{ showDuration: 10000 }}
                                        margin={`${SPACING.XS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('allTaxRelated')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Tax invoices pre-billed for the current year.'
                                        margin={`${SPACING.XXS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('taxInvoicesPreBilled')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='All tax reporting obligations have been attended to.'
                                        toolTip='This includes FATCA, tax returns and tax audits.'
                                        margin={`${SPACING.XXS}px 0 0 0`}
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('allTaxReporting')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Confirm any deregistration / notification of dissolution to tax authorities is complete across all taxes. '
                                        toolTip='This includes Corporate tax, VAT, and payroll taxes.'
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('deregistrationAndNotification')}
                                    isPendingChange={false}
                                />
                                <StackPanel>
                                    <TextBlock
                                        text='Tax to consider tidy up steps and liquidation steps to consider any tax impacts.'
                                        toolTip='Tax to review any steps undertaken to “tidy-up” the financial accounts of the entity if material assets still remaining.'
                                    />
                                </StackPanel>
                                <Select
                                    itemsSource={CompletionState}
                                    isRequired
                                    {...getValue('taxAndLiquidationSteps')}
                                    isPendingChange={false}
                                />
                            </GridLayout>
                        </StackPanel>
                    }
                />
                <Expander
                    header='Approver Notes and Status'
                    toolTip='This section is used to enter approver notes that help other approvers. It is also used to communicate in-progress status. Please enter in-progress status and hit Save during the in-progress steps.'
                    toolTipService={{ showDuration: 10000 }}
                    content={
                        <div className='text-area-hide-scrollbar'>
                            <TextField
                                minHeight={120}
                                maxHeight={240}
                                variant='default'
                                textWrapping='wrap'
                                padding={`${SPACING.XS}px 0`}
                                canUndo={false}
                                {...getValue('approverNotes')}
                                isPendingChange={false}
                            />
                        </div>
                    }
                />
                <DissolutionRejectReason
                    workflowType={WorkflowType.LegalEntityDissolutionTaxWorkflowType}
                    currentWorkItemState={workItem?.workItemState}
                    workItemStateToExclude={DISSOLUTION_TAX_STATE.DISSOLUTION_APPROVED}
                />
                <PageUpdateInfo lastCompletedTask={lastCompletedTask} />
            </Form>
            {props.mode === 'Edit' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
