import { Capability, UserScopes } from '../RequestStepsView';
import { filterFRScopes, filterScopes } from '../LegalEntityCreationViewHelpers';

import { useCallback } from 'react';
import { useCreationViewContext } from '../context/creation-view-context';

export default function useHasCapability() {
    const {
        state: { userScopes },
    } = useCreationViewContext();

    const hasCapability = useCallback(
        ({ permission, scope }: Capability) =>
            scope !== UserScopes.ForeignRegistration
                ? filterScopes(permission, userScopes).some((cap: string) => cap === scope)
                : filterFRScopes(permission, userScopes).some((cap: string) => cap === scope),
        [userScopes]
    );

    return hasCapability;
}
