import { Capabilities, RequestStepPanel, RequestStepsView } from '../RequestStepsView';
import { useCallback, useMemo } from 'react';

import { filterScopes } from '../LegalEntityCreationViewHelpers';
import { useCreationViewContext } from '../context/creation-view-context';
import useHasCapability from './use-has-capability';

export default function useCheckPanelIsViewable() {
    const {
        state: { userScopes },
    } = useCreationViewContext();

    const hasCapability = useHasCapability();

    const stepsWithUniqueViewRoles = [
        {
            stepView: RequestStepsView.Dissolution_Details,
            requiredCapability: Capabilities.viewDissolutionRequest,
        },
        {
            stepView: RequestStepsView.Dissolution_Approval,
            requiredCapability: Capabilities.approveDissolutionRequest,
        },
    ];

    const filteredByView = useMemo(() => {
        return filterScopes('View', userScopes);
    }, [userScopes]);

    const hasViewAllRole = useMemo(() => {
        return userScopes?.some((scope: string) => scope === 'ViewAll');
    }, [userScopes]);

    const checkPanelIsViewable = useCallback(
        (panel: RequestStepPanel) => {
            const stepUniqueRule = stepsWithUniqueViewRoles.find(({ stepView }) => stepView === panel.requestStepView);

            if (stepUniqueRule) {
                return hasCapability(stepUniqueRule.requiredCapability);
            }

            return filteredByView.includes(panel.section);
        },
        [filteredByView, hasCapability, stepsWithUniqueViewRoles]
    );

    return { checkPanelIsViewable, hasViewAllRole };
}
