import { ForeignRegistrationTask, ForeignRegistrationWorkflow } from 'models/LegalEntityRequest/Workflow';
import React, { useMemo } from 'react';

import ActionPanel from './components/ActionPanel';
import { LegalEntityForeignRegistrationSectionProps } from './model';
import LegalEntityRegistration from '../LegalEntityRegistration';
import { LegalEntityRegistrationData } from '../model';
import { LegalEntityRegistrationProvider } from 'modules/LegalEntityCreation/context/Provider';
import { isOpen } from 'models/Workflow';
import { noop } from 'lodash';

type Props = Omit<LegalEntityForeignRegistrationSectionProps, 'data'> & {
    data: LegalEntityRegistrationData;
};

export default function LegalEntityForeignRegistration({
    data,
    workItems: workItemsProps = [],
    onCompleteTask: onCompleteTaskProp = noop,
    onSave,
    showDocumentsInReadOnlyTreeView,
}: Props) {
    const workItems = useMemo(
        () =>
            workItemsProps.reduce<{ [regId: number]: ForeignRegistrationWorkflow }>((agg, wi) => {
                agg[wi.customProperties.registrationID] = wi;
                return agg;
            }, {}),
        [workItemsProps]
    );

    const workItem = workItems[data.key];
    const onCompleteTaskByWorkItem = onCompleteTaskProp.bind(null, workItem);
    const workitemState = workItem?.workItemState;

    const commonProps = useMemo(() => {
        if (!workItem) {
            return { mode: 'Readonly' };
        }
        switch (workitemState) {
            case 'RegistrationRequest': {
                return {
                    task: workItem.tasks?.find((t) => t.taskType === 'RequestTaskType' && isOpen(t.status)),
                    mode: 'Edit',
                    onCompleteTask: (task: ForeignRegistrationTask, exitCode: string) =>
                        onCompleteTaskByWorkItem(task, exitCode),
                };
            }
            case 'RegistrationApproval': {
                return {
                    mode: 'Approval',
                    task: workItem.tasks?.find((t) => t.taskType === 'UpdateRequestTaskType' && isOpen(t.status)),
                    onCompleteTask: (
                        task: ForeignRegistrationTask,
                        exitCode: string | undefined,
                        rejectReason: string | undefined
                    ) => {
                        onCompleteTaskByWorkItem(task, exitCode, rejectReason);
                    },
                };
            }
            default: {
                return { mode: 'Readonly' };
            }
        }
    }, [onCompleteTaskByWorkItem, workItem, workitemState]);

    return (
        <LegalEntityRegistrationProvider storedValues={data} mode={commonProps.mode}>
            <LegalEntityRegistration
                mode={commonProps.mode}
                type='foreign'
                workitem={workItems?.[data.key]}
                {...(commonProps.mode !== 'Readonly' && {
                    actionPanel: <ActionPanel {...commonProps} onSave={onSave} />,
                })}
                showDocumentsInReadOnlyTreeView={showDocumentsInReadOnlyTreeView}
            />
        </LegalEntityRegistrationProvider>
    );
}
