import { Link } from 'react-router-dom';
import React from 'react';
import { constants } from 'constants/env-config';

export const trialAndIntercompanyBalanceDashboardLinks = (
    <>
        Click to view{' '}
        <Link to={{ pathname: constants.TRIAL_BALANCES_DASHBOARD_URL }} target='_blank'>
            Trial Balances Dashboard
        </Link>
        {' and '}
        <Link to={{ pathname: constants.INTERCOMPANY_BALANCES_DASHBOARD_URL }} target='_blank'>
            Intercompany Balances Dashboard
        </Link>{' '}
        to see balances associated with this entity
    </>
);

export const linkToWorkflowsTool = (
    <>
        <Link to={{ pathname: constants.WORKFLOWS_TOOL_URL }} target='_blank'>
            Link
        </Link>{' '}
        to the Workflows tool to ask Treasury to close bank accounts
    </>
);
