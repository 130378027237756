import { Button, StackPanel, TextBlock } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';

type Props = {
    addChanges: () => void;
    isEnabled: boolean;
    text: string;
};
export default function UpdateButton({ addChanges, isEnabled, text }: Props) {
    return (
        <Button
            content={
                <StackPanel orientation='horizontal' verticalAlignment='center' itemGap={SPACING.XXS}>
                    <TextBlock text={text} fontSize={12} />
                </StackPanel>
            }
            styleOverrides={{ alignSelf: 'center' }}
            padding={`${SPACING.XS}px ${SPACING.MD}px`}
            isEnabled={isEnabled}
            onClick={() => addChanges()}
        />
    );
}
