import { DoubleColumnLayout, SPACING } from '../../../../../Utilities/Layout';
import React, { useMemo } from 'react';
import { Select, TextField } from 'components';

import { Address } from 'models';
import { Expander } from 'components/';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { isEmpty } from 'lodash';
import useDocumentPreparationRegisteredOfficeAddressValidation from 'modules/LegalEntityCreation/validation/use-document-preparation-registered-office-address-validation';
import { useLegalEntityDocumentPreparation } from '../../../context/Provider';
import { useReferenceData } from '../../../../../api';

export default function LegalEntityRegisteredOfficeAddress() {
    const {
        state: {
            isEditable,
            data: { values },
            version,
        },
        setValue,
    } = useLegalEntityDocumentPreparation();

    const { validations } = useDocumentPreparationRegisteredOfficeAddressValidation();

    const { registeredOfficeAddress } = values;

    const address = registeredOfficeAddress || ({} as Address);

    const {
        data: { Country, State },
    } = useReferenceData();

    const states = useMemo(
        () => State?.filter(({ ParentId }) => ParentId === `${address.countryId}`),
        [State, address.countryId]
    );

    const isCountryIdRequired = !!(
        registeredOfficeAddress?.line1 ||
        registeredOfficeAddress?.line2 ||
        registeredOfficeAddress?.line3 ||
        registeredOfficeAddress?.line4 ||
        registeredOfficeAddress?.city ||
        registeredOfficeAddress?.postalCode
    );

    return (
        <Expander
            header='Registered Office Address'
            requestStep={RequestStepsView.Document_Preparation}
            content={
                <DoubleColumnLayout>
                    <StackPanel itemGap={SPACING.SM}>
                        <TextField
                            version={version}
                            label='Line 1'
                            isEditable={isEditable}
                            value={address?.line1}
                            onValueChanged={(newValue) => {
                                if (newValue || address?.line1) {
                                    const updated = { ...address };
                                    updated.line1 = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                        <TextField
                            version={version}
                            label='Line 2'
                            isEditable={isEditable}
                            value={address?.line2}
                            onValueChanged={(newValue) => {
                                if (newValue || address?.line2) {
                                    const updated = { ...address };
                                    updated.line2 = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                        <TextField
                            version={version}
                            label='Line 3'
                            isEditable={isEditable}
                            value={address?.line3}
                            onValueChanged={(newValue) => {
                                if (newValue || address?.line3) {
                                    const updated = { ...address };
                                    updated.line3 = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                        <TextField
                            version={version}
                            label='Line 4'
                            isEditable={isEditable}
                            value={address?.line4}
                            onValueChanged={(newValue) => {
                                if (newValue || address?.line4) {
                                    const updated = { ...address };
                                    updated.line4 = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                    </StackPanel>
                    <StackPanel itemGap={SPACING.SM}>
                        <Select
                            version={version}
                            label='Country'
                            itemsSource={Country}
                            value={address?.countryId}
                            isEditable={isEditable}
                            onValueChanged={(newValue) => {
                                if (newValue || address.countryId) {
                                    const updated = { ...address };
                                    updated.countryId = newValue;
                                    updated.city = undefined;
                                    updated.state = undefined;
                                    updated.stateId = undefined;
                                    updated.postalCode = undefined;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                            isRequired={isCountryIdRequired}
                            validationError={validations.countryId}
                        />
                        <TextField
                            version={version}
                            label='City'
                            isEditable={isEditable}
                            value={address?.city}
                            onValueChanged={(newValue) => {
                                if (newValue || address?.city) {
                                    const updated = { ...address };
                                    updated.city = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                        <Select
                            version={version}
                            label='State'
                            itemsSource={states}
                            value={address?.stateId}
                            isEditable={isEditable && !isEmpty(states)}
                            onValueChanged={(newValue) => {
                                if (newValue || address.stateId) {
                                    const updated = { ...address };
                                    updated.stateId = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                        <TextField
                            version={version}
                            label='Postal Code'
                            isEditable={isEditable}
                            value={address?.postalCode}
                            onValueChanged={(newValue) => {
                                if (newValue || address?.postalCode) {
                                    const updated = { ...address };
                                    updated.postalCode = newValue;
                                    setValue('registeredOfficeAddress', updated);
                                }
                            }}
                        />
                    </StackPanel>
                </DoubleColumnLayout>
            }
        />
    );
}
