import { ValidationMessages } from '../../src/models/shared/forms/ValidationMessages';

export function isEmpty(value?: string | null): value is '' | undefined | null;
export function isEmpty(value?: Array<any> | null): value is [] | undefined | null;
export function isEmpty(value?: number): value is number | undefined;
export function isEmpty(value?: boolean): value is undefined;
export function isEmpty(value?: Date): value is Date | undefined;
export function isEmpty(value?: { [key: string]: any }): value is {} | undefined;
export function isEmpty(value: any): any {
    if (typeof value === 'undefined' || value === null) return true;
    if (Array.isArray(value)) return value.length === 0;
    if (typeof value === 'number') return Number.isNaN(value);
    if (typeof value === 'string') return value.trim() === '';
    if (value instanceof Date) return Number.isNaN(value.getTime());
    if (typeof value === 'object') return Object.values(value as { [key: string]: any }).every(isEmpty);
}

export const isValidNumber = (value: string | number | undefined) => {
    return (typeof value === 'number' && !isEmpty(value)) || (typeof value === 'string' && /^\d+$/.test(value));
};

export function numberValidation(value: string | undefined, minLength?: number, isRequered?: boolean) {
    return isEmpty(value) && isRequered === undefined
        ? new Error(ValidationMessages.RequiredField)
        : !isValidNumber(value) && !isEmpty(value)
        ? new Error(ValidationMessages.InvalidNumber)
        : !isEmpty(value) && minLength !== undefined && value.length < minLength - 1
        ? new Error(ValidationMessages.NumLength(minLength))
        : undefined;
}
