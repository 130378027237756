import { CreationViewContext as CreationViewContextModel, CreationViewState, ProviderProps } from './model';
import React, { createContext, useContext, useReducer } from 'react';
import { emptyEntitySections, entitySectionMapper } from '../EntityToSectionMapper';
import { isNil, noop } from 'lodash';

const initialState: CreationViewState = {
    legalEntityOId: undefined,
    userScopes: undefined,
    sections: emptyEntitySections,
    noPendingSections: emptyEntitySections,
    selectedPanel: undefined,
    entityCreationWorkItem: undefined,
    foreignRegistrationWorkItems: [],
    foreignRegistrationUpdateWorkItem: undefined,
    financeInformationWorkItem: undefined,
    taxClassificationWorkItem: undefined,
    legalEntityUpdateWorkItem: undefined,
    legalEntityDissolutionParentWorkItem: undefined,
    legalEntityDissolutionLitigationWorkItem: undefined,
    legalEntityDissolutionTaxWorkItem: undefined,
    legalEntityDissolutionFinanceWorkItem: undefined,
    legalEntityDissolutionLegalWorkItem: undefined,
    legalEntityDissolutionTreasuryWorkItem: undefined,
    ownershipWorkItem: undefined,
    editWorkFlow: false,
    isWorkItemsRefreshing: false,
    sideBarLength: 0,
    loadingStates: {},
    latestRejectedDissolutionWorkflow: undefined,
    isCTAReview: false,
    isEntityDissolved: false,
    isEntityDissolvable: false,
};

const CreationViewContext = createContext<CreationViewContextModel>({
    state: {} as CreationViewState,
    dispatch: () => noop,
});
CreationViewContext.displayName = 'AppContext';

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'SET_LEGAL_ENTITY_OID': {
            return {
                ...state,
                legalEntityOId: action.value,
            };
        }
        case 'SET_USER_SCOPES': {
            return {
                ...state,
                userScopes: action.value,
            };
        }
        case 'SET_SECTIONS': {
            return {
                ...state,
                sections: action.value?.entity ? entitySectionMapper.mapToSections(action.value) : emptyEntitySections,
            };
        }
        case 'SET_NO_PENDING_SECTIONS': {
            return {
                ...state,
                noPendingSections: action.value?.entity
                    ? entitySectionMapper.mapToSections(action.value)
                    : emptyEntitySections,
            };
        }
        case 'SET_LEGAL_ENTITY_CREATION_SELECTED_PANEL': {
            return {
                ...state,
                selectedPanel: action.value,
            };
        }
        case 'SET_ENTITY_CREATION_WORK_ITEM': {
            return {
                ...state,
                entityCreationWorkItem: action.value,
            };
        }
        case 'SET_FOREIGN_REGISTRATION_WORK_ITEMS': {
            return {
                ...state,
                foreignRegistrationWorkItems: action.value,
            };
        }
        case 'SET_FOREIGN_REGISTRATION_UPDATE_WORK_ITEM': {
            return {
                ...state,
                foreignRegistrationUpdateWorkItem: action.value,
            };
        }
        case 'SET_FINANCE_INFORMATION_WORK_ITEM': {
            return {
                ...state,
                financeInformationWorkItem: action.value,
            };
        }
        case 'SET_TAX_CLASSIFICATION_WORK_ITEM': {
            return {
                ...state,
                taxClassificationWorkItem: action.value,
            };
        }
        case 'SET_LEGAL_ENTITY_UPDATE_WORK_ITEM': {
            return {
                ...state,
                legalEntityUpdateWorkItem: action.value,
            };
        }
        case 'SET_OWNERSHIP_WORK_ITEM': {
            return {
                ...state,
                ownershipWorkItem: action.value,
            };
        }
        case 'SET_DISSOLUTION_PARENT_WORK_ITEM': {
            return {
                ...state,
                legalEntityDissolutionParentWorkItem: action.value,
            };
        }
        case 'SET_DISSOLUTION_LITIGATION_WORK_ITEM': {
            return {
                ...state,
                legalEntityDissolutionLitigationWorkItem: action.value,
            };
        }
        case 'SET_DISSOLUTION_TAX_WORK_ITEM': {
            return {
                ...state,
                legalEntityDissolutionTaxWorkItem: action.value,
            };
        }
        case 'SET_DISSOLUTION_FINANCE_WORK_ITEM': {
            return {
                ...state,
                legalEntityDissolutionFinanceWorkItem: action.value,
            };
        }
        case 'SET_DISSOLUTION_LEGAL_WORK_ITEM': {
            return {
                ...state,
                legalEntityDissolutionLegalWorkItem: action.value,
            };
        }
        case 'SET_DISSOLUTION_TREASURY_WORK_ITEM': {
            return {
                ...state,
                legalEntityDissolutionTreasuryWorkItem: action.value,
            };
        }
        case 'SET_EDIT_WORKFLOW': {
            return {
                ...state,
                editWorkFlow: action.value,
            };
        }
        case 'SET_IS_WORKITEM_REFRESHING': {
            return {
                ...state,
                isWorkItemsRefreshing: action.value,
            };
        }
        case 'SET_SIDEBAR_LENGTH': {
            return {
                ...state,
                sideBarLength: action.value,
            };
        }
        case 'SET_IS_CTA_REVIEW': {
            return {
                ...state,
                isCTAReview: action.value,
            };
        }
        case 'SET_LATEST_REJECTED_DISSOLUTION_WORKFLOW': {
            return {
                ...state,
                latestRejectedDissolutionWorkflow: action.value,
            };
        }
        case 'SET_IS_ENTITY_DISSOLVED': {
            return {
                ...state,
                isEntityDissolved: action.value,
            };
        }
        case 'SET_IS_ENTITY_DISSOLVABLE': {
            return {
                ...state,
                isEntityDissolvable: action.value,
            };
        }
        case 'SET_LOADING_STATE': {
            return {
                ...state,
                loadingStates: {
                    ...state.loadingStates,
                    [action.value]: true,
                },
            };
        }
        case 'UNSET_LOADING_STATE': {
            const loadingStateCopy = { ...state.loadingStates };
            delete loadingStateCopy[action.value];

            return {
                ...state,
                loadingStates: loadingStateCopy,
            };
        }
        case 'CLEAN_CREATION_VIEW_CONTEXT': {
            return {
                ...state,
                userScopes: undefined,
                sections: emptyEntitySections,
                noPendingSections: emptyEntitySections,
                selectedPanel: undefined,
                entityCreationWorkItem: undefined,
                foreignRegistrationWorkItems: [],
                foreignRegistrationUpdateWorkItem: undefined,
                financeInformationWorkItem: undefined,
                taxClassificationWorkItem: undefined,
                legalEntityUpdateWorkItem: undefined,
                legalEntityDissolutionParentWorkItem: undefined,
                legalEntityDissolutionLitigationWorkItem: undefined,
                legalEntityDissolutionTaxWorkItem: undefined,
                legalEntityDissolutionFinanceWorkItem: undefined,
                legalEntityDissolutionLegalWorkItem: undefined,
                legalEntityDissolutionTreasuryWorkItem: undefined,
                ownershipWorkItem: undefined,
                editWorkFlow: false,
                isWorkItemsRefreshing: false,
                sideBarLength: 0,
                latestRejectedDissolutionWorkflow: undefined,
            };
        }
        default: {
            return state;
        }
    }
}

function CreationViewContextProvider({ children }: ProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <CreationViewContext.Provider value={value}>{children}</CreationViewContext.Provider>;
}

function useCreationViewContext() {
    const context = useContext(CreationViewContext);
    if (isNil(context)) {
        throw new Error('useCreationViewContext must be used within an CreationViewContextProvider');
    }

    return context;
}

export const setLegalEntityOId = (dispatch: any, value: any) => dispatch({ type: 'SET_LEGAL_ENTITY_OID', value });

export const setUserScopes = (dispatch: any, value: any) => dispatch({ type: 'SET_USER_SCOPES', value });

export const setSections = (dispatch: any, value: any) => dispatch({ type: 'SET_SECTIONS', value });

export const setNoPendingSections = (dispatch: any, value: any) => dispatch({ type: 'SET_NO_PENDING_SECTIONS', value });

export const setLegalEntityCreationSelectedPanel = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_LEGAL_ENTITY_CREATION_SELECTED_PANEL', value });

export const setEntityCreationWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_ENTITY_CREATION_WORK_ITEM', value });

export const setForeignRegistrationWorkItems = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_FOREIGN_REGISTRATION_WORK_ITEMS', value });

export const setForeignRegistrationUpdateWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_FOREIGN_REGISTRATION_UPDATE_WORK_ITEM', value });

export const setFinanceInformationWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_FINANCE_INFORMATION_WORK_ITEM', value });

export const setTaxClassificationWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_TAX_CLASSIFICATION_WORK_ITEM', value });

export const setLegalEntityUpdateWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_LEGAL_ENTITY_UPDATE_WORK_ITEM', value });

export const setOwnershipWorkItem = (dispatch: any, value: any) => dispatch({ type: 'SET_OWNERSHIP_WORK_ITEM', value });

export const setDissolutionParentWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_DISSOLUTION_PARENT_WORK_ITEM', value });

export const setDissolutionLitigationWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_DISSOLUTION_LITIGATION_WORK_ITEM', value });

export const setDissolutionTaxWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_DISSOLUTION_TAX_WORK_ITEM', value });

export const setDissolutionFinanceWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_DISSOLUTION_FINANCE_WORK_ITEM', value });

export const setDissolutionLegalWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_DISSOLUTION_LEGAL_WORK_ITEM', value });

export const setDissolutionTreasuryWorkItem = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_DISSOLUTION_TREASURY_WORK_ITEM', value });

export const setEditWorkFlow = (dispatch: any, value: any) => dispatch({ type: 'SET_EDIT_WORKFLOW', value });

export const setIsWorkItemsRefreshing = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_IS_WORKITEM_REFRESHING', value });

export const setSideBarLength = (dispatch: any, value: any) => dispatch({ type: 'SET_SIDEBAR_LENGTH', value });

export const setIsCTAReview = (dispatch: any, value: any) => dispatch({ type: 'SET_IS_CTA_REVIEW', value });

export const setLoadingState = (dispatch: any, value: any) => dispatch({ type: 'SET_LOADING_STATE', value });

export const unsetLoadingState = (dispatch: any, value: any) => dispatch({ type: 'UNSET_LOADING_STATE', value });

export const cleanCreationViewContext = (dispatch: any) => dispatch({ type: 'CLEAN_CREATION_VIEW_CONTEXT' });

export const setLatestRejectedDissolutionWorkflow = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_LATEST_REJECTED_DISSOLUTION_WORKFLOW', value });

export const setIsEntityDissolved = (dispatch: any, value: any) => dispatch({ type: 'SET_IS_ENTITY_DISSOLVED', value });

export const setIsEntityDissolvable = (dispatch: any, value: any) =>
    dispatch({ type: 'SET_IS_ENTITY_DISSOLVABLE', value });

export { CreationViewContextProvider, useCreationViewContext };
