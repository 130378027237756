import { Expander, FileUpload } from 'components';
import React, { useMemo } from 'react';

import { documentTypes as DocumentTypes } from 'models/shared/refData/NewDocumentType';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { getDocumentTypesForTreeView } from 'modules/LegalEntityCreation/LegalEntityDocumentPreparation/utils';
import { useLegalEntityCorporateTransparency } from 'modules/LegalEntityCreation/context/Provider';

export default function Documents() {
    const context = useLegalEntityCorporateTransparency();
    const {
        state: {
            data: {
                values: { entityOId },
            },
        },
    } = context;

    const documentTypes = useMemo(() => Object.values(DocumentTypes), []);
    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.CORPORATE_TRANSPARENCY);

    return (
        <Expander
            header='Existing Documents'
            content={
                <FileUpload
                    entityId={entityOId}
                    sectionName={SECTION_NAMES.CORPORATE_TRANSPARENCY}
                    documentTypes={documentTypes}
                    context={context}
                    isDisabled
                    showDocumentsInReadOnlyTreeView
                    showStampedDocuments={false}
                    treeViewDocumentTypes={treeViewDocumentTypes}
                />
            }
        />
    );
}
