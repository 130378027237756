export const documentTypes = {
    ActionOfSoleIncorporator: { Key: 5097, Value: 'Action of Sole Incorporator' },
    ApplicationAuthority: { Key: 347, Value: 'Application of Authority' },
    ApprovalForm8832: { Key: 486, Value: 'Approval of Form 8832' },
    Bylaws: { Key: 397, Value: 'Bylaws' },
    CertificateIncorporation: { Key: 244, Value: 'Certificate of Incorporation' },
    CertificateLimitedPartnership: { Key: 366, Value: 'Certificate of Limited Partnership' },
    CertificateOfNameChange: { Key: 227, Value: 'Certificate of Name Change' },
    CertificateRegistration: { Key: 165, Value: 'Certificate of Registration' },
    ConsentToUseName: { Key: 437, Value: 'Consent To Use Of Name' },
    CRSSelfcertification: { Key: 264, Value: 'CRS Self-certification' },
    EINApplication: { Key: 350, Value: 'EIN Application' },
    EINCTBConfirmation: { Key: 195, Value: 'EIN/CTB Confirmation' },
    ExtractsFromPublicRegister: { Key: 168, Value: 'Extracts from Public Register' },
    FormationDocument: { Key: 189, Value: 'Formation Document' },
    FormSS4: { Key: 193, Value: 'Form SS-4' },
    Form8821: { Key: 381, Value: 'Form 8821' },
    Form8832: { Key: 194, Value: 'Form 8832' },
    LpllcAgreement: { Key: 190, Value: 'LP/LLC Agreement' },
    MemorandumArticleAssociation: { Key: 162, Value: 'Memorandum Article of Association' },
    RegistrationDeclaration: { Key: 5098, Value: 'Registration Declaration' },
    RegisterOfMember: { Key: 5099, Value: 'Register of Member' },
    Resolution: { Key: 356, Value: 'Resolution' },
    StructureChart: { Key: 191, Value: 'Structure Chart' },
    TrustAgreement: { Key: 174, Value: 'Trust Agreement' },
    TrustDeed: { Key: 175, Value: 'Trust Deed' },
    W8BENE: { Key: 5038, Value: 'W-8BEN-E' },
    W8IMY: { Key: 5034, Value: 'W-8IMY' },
    W9: { Key: 5039, Value: 'W-9' },
    RegisterOfDirectorsAndOfficers: { Key: 507, Value: 'Register of Directors and Officers' },
    OfficerCertificate: { Key: 442, Value: 'Officer’s Certificate' },
    CertificateOfIndependentDirector: { Key: 455, Value: 'Certificate of Independent Director' },
    Prospectus: { Key: 504, Value: 'Prospectus' },
    BoardMinutesAndResolutions: { Key: 5211, Value: 'Board Minutes and Resolutions' },
    ShareTransfer: { Key: 345, Value: 'Share Transfer' },
    ShareCertificate: { Key: 377, Value: 'Share Certificate' },
    ShareholderMinutes: { Key: 5213, Value: 'Shareholder Minutes' },
    PowerOfAttorney: { Key: 236, Value: 'Power of Attorney' },
    RegulatoryReturns: { Key: 5212, Value: 'Regulatory Returns' },
    Notice: { Key: 337, Value: 'Notice' },
    PartnershipAgreement: { Key: 172, Value: 'Partnership Agreement' },
    CertificateofGoodStanding: { Key: 246, Value: 'Certificate of Good Standing' },
    Affidavit: { Key: 359, Value: 'Affidavit' },
    IncumbencyCertificate: { Key: 247, Value: 'Incumbency Certificate' },
    CertificateofContinuance: { Key: 166, Value: 'Certificate of Continuance' },
    CertificateOfDeRegistration: { Key: 440, Value: 'Certificate of De-Registration' },
    DissolutionDocument: { Key: 229, Value: 'Dissolution Document' },
    DeclarationofTrust: { Key: 368, Value: 'Declaration of Trust' },
    LetterofResignation: { Key: 340, Value: 'Letter of Resignation' },
    ShareholdersRegister: { Key: 474, Value: 'Shareholder’s Register' },
    LoanAgreement: { Key: 374, Value: 'Loan Agreement' },
    Other: { Key: 253, Value: 'Other' },
    AnnualReturn: { Key: 5210, Value: 'Annual Return' },
    ArticlesofIncorporation: { Key: 163, Value: 'Articles of Incorporation' },
    AssignmentofManagementAgreement: { Key: 418, Value: 'Assignment of Management Agreement' },
    CertificateofChangeofRegisteredAgent: { Key: 386, Value: 'Certificate of Change of Registered Agent' },
    CertificateofRegisteredSeal: { Key: 243, Value: 'Certificate of Registered Seal' },
    CertificateofTrust: { Key: 341, Value: 'Certificate of Trust' },
    DeedofVariation: { Key: 461, Value: 'Deed of Variation' },
    Email: { Key: 196, Value: 'Email' },
    MergerDocuments: { Key: 399, Value: 'Merger Documents' },
    OperatingAgreement: { Key: 241, Value: 'Operating Agreement' },
    Section10LPLaw: { Key: 338, Value: 'Section 10 LP Law' },
    Section9LPLaw: { Key: 339, Value: 'Section 9 LP Law' },
    ShareCharge: { Key: 363, Value: 'Share Charge' },
    ShareholdersAgreement: { Key: 373, Value: 'Shareholders Agreement' },
    SolvencyCertificate: { Key: 457, Value: 'Solvency Certificate' },
    TrustDoc: { Key: 173, Value: 'Trust' },
} as const;

export type DocumentTypeKeys = keyof typeof documentTypes;
export type DocumentType = (typeof documentTypes)[DocumentTypeKeys];

export const supportedExtensions = [
    'zip',
    'pdf',
    'docx',
    'xlsx',
    'unknown',
    'xls',
    'xml',
    'doc',
    'ppt',
    'pptx',
    'odt',
    'odp',
    'ods',
    'txt',
    'rtf',
    'pgp',
    'csv',
    'jpg',
    'png',
    'as',
    'asl',
    'asp',
    'docm',
    'dot',
    'dotm',
    'dotx',
    'htm',
    'html',
    'pdx',
    'wpd',
    'wps',
    'wpt',
    'emz',
    'svg',
    'svgz',
    'vdx',
    'vss',
    'vst',
    'bmp',
    'cdr',
    'dcx',
    'gif',
    'ico',
    'jpeg',
    'pct',
    'pic',
    'rgb',
    'sam',
    'tga',
    'tif',
    'tiff',
    'wpg',
    'dps',
    'dpt',
    'pot',
    'potx',
    'pps',
    'pptm',
    'et',
    'ett',
    'xlsm',
    'xlt',
    'msg',
].sort();
