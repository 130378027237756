import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';
import {
    dateFormatter,
    dateTimeFormatter,
    defaultColumnDefinition,
    getFriendlyValues,
    localDateFormatter,
} from 'modules/RequestsDashboard/components/Grid/model';
import { useCallback, useEffect, useMemo, useState } from 'react';

import EntityOIdCellRenderer from 'modules/RequestsDashboard/components/Grid/components/EntityOIdCellRenderer';
import { LegalEntityRequest } from 'models';
import SetFilter from 'modules/RequestsDashboard/components/SetFilter/SetFilter';
import useApplicationStorage from './use-application-storage';

const STORAGE_KEY_VISIBLE_COLUMNS = 'dashboard-visible-columns';

const setFilters = [
    'parentProcess',
    'workItemType',
    'workItemState',
    'businessDriver',
    'businessUnit',
    'subBusinessUnit',
    'administeredBy',
    'corporateTransparencyStatus',
];
export const fieldsWithRawValues = ['workItemType', 'workItemState'];

export const dateFormatters = ['requestedDateUtc', 'formationDate', 'reviewDate'];

export const localDateFormatters = ['reviewDate'];

export const dateTimeFormatters = ['lastModifiedDateUtc'];

export interface GridApiRef {
    column: ColumnApi | undefined;
    api: GridApi | undefined;
}

export default function useColumnConfiguration(data?: LegalEntityRequest[], gridApi?: GridApiRef) {
    const [columnDefinitions, setColumnDefinitions] = useState([] as ColDef[]);

    const { getValue, setValue } = useApplicationStorage();

    useEffect(() => {
        getValue(STORAGE_KEY_VISIBLE_COLUMNS).then((columns) => {
            if (columns && Array.isArray(columns)) {
                const columnDefinition = defaultColumnDefinition.map((definition) => ({
                    ...definition,
                    hide: !columns.find((column) => definition.field === column),
                }));
                setColumnDefinitions(columnDefinition);
            } else {
                setColumnDefinitions(defaultColumnDefinition);
            }
        });
    }, [getValue]);

    const generateSetFilter = useCallback((field?: keyof LegalEntityRequest, data?: LegalEntityRequest[]) => {
        if (!field || !data) {
            return { filter: false };
        }

        const values = Array.from(new Set(data.map((e) => e[field]))).sort();
        return values.length > 0
            ? {
                  filter: SetFilter,
                  filterParams: {
                      values,
                  },
              }
            : {};
    }, []);

    const removeSetFilters = useCallback(
        (definitions: ColDef<LegalEntityRequest>[]) =>
            definitions.map((column) => ({
                ...column,
                ...(setFilters.includes(column?.field ?? '') && { filter: false }),
            })),
        []
    );

    const columnDefs: ColDef<LegalEntityRequest>[] = useMemo(() => {
        const newColDefs = columnDefinitions.map((column: ColDef<LegalEntityRequest>) => {
            const { field = '' } = column;
            return {
                ...column,
                ...(field === 'entityOId' && { cellRenderer: EntityOIdCellRenderer }),
                ...(fieldsWithRawValues.includes(field) && { valueFormatter: getFriendlyValues }),
                ...(dateFormatters.includes(column?.field ?? '') && { valueFormatter: dateFormatter }),
                ...(localDateFormatters.includes(column?.field ?? '') && { valueFormatter: localDateFormatter }),
                ...(dateTimeFormatters.includes(column?.field ?? '') && {
                    valueFormatter: dateTimeFormatter,
                }),
                ...(setFilters.includes(field) && {
                    ...generateSetFilter(field as keyof LegalEntityRequest, data),
                }),
            };
        });
        if (newColDefs.length) {
            newColDefs[0].sort = (gridApi?.column as any)?.getColumnState()?.[0]?.sort ?? 'desc';
        }
        return newColDefs;
    }, [columnDefinitions, data, generateSetFilter, gridApi]);

    const setColumnDefs = useCallback(
        (definitions: ColDef[]) => {
            const visibleColumns = definitions.reduce<string[]>(
                (result, { hide, field }) => (hide ? result : result.concat(field!)),
                []
            );
            setValue(STORAGE_KEY_VISIBLE_COLUMNS, JSON.stringify(visibleColumns));
            setColumnDefinitions(removeSetFilters(definitions));
        },
        [removeSetFilters, setValue]
    );

    return { columnDefs, setColumnDefs };
}
