import { BooleanField } from 'components/controls';
import { Content } from './Debugger.styled';
import React from 'react';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SPACING } from 'Utilities/Layout';
import Text from 'components/Text/Text';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type Props = {
    requestStep: RequestStepsView;
};

export default function PanelOptions({ requestStep }: Props) {
    const convertToPanelKey = (panelName: string) => `${requestStep}_${panelName.toLowerCase().replace(' ', '_')}`;
    const colors = useColors();

    const shouldShouldRequestPanel = [
        RequestStepsView.Request,
        RequestStepsView.Document_Preparation,
        RequestStepsView.Legal_Entity_Information,
    ].includes(requestStep);

    return shouldShouldRequestPanel ? (
        <Content colors={colors}>
            <Text variant='body' styleOverrides={{ marginBottom: `${SPACING.MD}px` }}>
                Show/Hide Panels
            </Text>
            {requestPageExpanders[requestStep].map((panelName) => (
                <Panel>
                    <Text variant='body-small-bold' styleOverrides={{ paddingTop: '6px' }}>
                        {panelName}
                    </Text>
                    <BooleanField
                        trueValueLabel='Visible'
                        falseValueLabel='Hidden'
                        variant='dashboard'
                        canResetValue={false}
                        canUndo={false}
                        value={!(localStorage.getItem(convertToPanelKey(panelName)) === 'false')}
                        onValueChanged={(value = false) => {
                            localStorage.setItem(convertToPanelKey(panelName), value.toString());
                        }}
                        backgroundColor='none'
                    />
                </Panel>
            ))}
        </Content>
    ) : null;
}

const Panel = styled.div`
    display: flex;
    justify-content: space-between;
`;

const requestPageExpanders: { [key in RequestStepsView]: string[] } = {
    [RequestStepsView.Request]: ['Background', 'Legal Entity Details', 'Documents'],
    [RequestStepsView.Tax_Classification]: [],
    [RequestStepsView.Approval]: [],
    [RequestStepsView.Document_Preparation]: [
        'Domestic Registration Details',
        'Headquarters Address',
        'Foreign Registration Details',
    ],
    [RequestStepsView.Domestic_Registration]: [],
    [RequestStepsView.Corporate_Transparency]: [],
    [RequestStepsView.Finance_Details]: [],
    [RequestStepsView.Foreign_Registration]: [],
    [RequestStepsView.Legal_Entity_Information]: [
        'Legal Entity Details',
        'Tax Codes',
        'Domestic Registration Details',
        'Addresses',
        'Documents',
    ],
    [RequestStepsView.Ownership_Information]: [],
    [RequestStepsView.Dissolution_Details]: [],
    [RequestStepsView.Dissolution_Approval]: [],
    [RequestStepsView.Dissolution_Litigation]: [],
    [RequestStepsView.Dissolution_Tax]: [],
    [RequestStepsView.Dissolution_Finance]: [],
    [RequestStepsView.Dissolution_Legal]: [],
    [RequestStepsView.Dissolution_Treasury]: [],
    [RequestStepsView.Dissolution_BDG]: [],
};
