import { Button, StackPanel } from '@bxgrandcentral/controls';
import React, { memo, useEffect, useMemo } from 'react';
import { isEmpty, isNil } from 'lodash';
import {
    resetState,
    setIsEditable,
    setIsLoading,
    setIsSubmitted,
    setShouldReload,
    setShouldUpdateNewDocuments,
} from 'modules/LegalEntityCreation/context/actions';

import { ActionPanelProps } from '../../model';
import { SECTION_NAMES } from 'modules/LegalEntityCreation/models';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { TaskActions } from '../../../Shared/TaskActions';
import { isEditable } from 'modules/LegalEntityCreation/utils';
import { isOpen } from '../../../../../models/Workflow';
import { removeDocumentSection } from 'modules/LegalEntityCreation/context/actions';
import useDocumentUpload from '../../../../../hooks/use-document-upload';
import { useLegalEntityDocumentPreparation } from '../../../context/Provider';
import { useRequestContext } from 'context/request-context';

export function ActionPanel(props: ActionPanelProps & { onSubmitClicked: () => void }) {
    const { entityOId } = props;

    const {
        state: {
            data: { values, changes },
            documents: { sections },
            canSave,
            canSubmit,
            isSubmitted,
        },
        dispatch,
    } = useLegalEntityDocumentPreparation();

    const { isRequestNotesChanged } = useRequestContext();
    const { updateDocuments, updateStoredDocuments } = useDocumentUpload();

    const submitTask = useMemo(
        () =>
            props.mode === 'Edit'
                ? props.workItem?.tasks?.find(
                      ({ taskType, status }) =>
                          ['DocPrepTransitionTaskType', 'UpdateDocPrepTaskKey'].includes(taskType) && isOpen(status)
                  )
                : undefined,
        [props]
    );

    useEffect(() => {
        if (props.mode === 'Edit' && submitTask && isSubmitted && props.data) {
            props.onCompleteTask(submitTask);
            setTimeout(() => {
                resetState(dispatch, props.data);
                setIsEditable(dispatch, isEditable(props.mode));
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted]);

    const hasInvalidRegistration = useMemo(() => {
        const { domesticRegisteredAgent, domesticCountry, domesticState, foreignRegistrations } = values;
        const isEmptyDomestic =
            sections[SECTION_NAMES.DOMESTIC_REGISTRATION]?.pendingDocuments?.length > 0 &&
            isNil(domesticRegisteredAgent) &&
            isNil(domesticCountry) &&
            isNil(domesticState);

        const hasEmptyForeign = foreignRegistrations?.some((registration) => {
            const { registeredAgent, country, state, isDeleted } = registration;
            return !isDeleted && isNil(registeredAgent) && isNil(country) && isNil(state);
        });

        return isEmptyDomestic || hasEmptyForeign;
    }, [sections, values]);

    const generateChangesWithAddressId = () => {
        return {
            ...changes,
            headquarterAddress: {
                ...changes.headquarterAddress,
                ...(changes.headquarterAddress && {
                    addressId: props.data?.headquarterAddress?.addressId ?? changes.headquarterAddress?.addressId,
                }),
            },
        };
    };

    if (props.mode === 'Edit') {
        return (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    isEnabled={(canSave && !hasInvalidRegistration) || isRequestNotesChanged(props.workItem)}
                    styleName='textOnlyButtonStyle'
                    onClick={async () => {
                        try {
                            setIsLoading(dispatch, true);
                            if (isEmpty(changes)) {
                                await updateDocuments(sections, entityOId);
                            } else {
                                await updateStoredDocuments(sections, entityOId);
                                setShouldUpdateNewDocuments(dispatch, true);
                                changes.foreignRegistrations &&
                                    changes.foreignRegistrations.forEach((registration, index) => {
                                        if (registration.isDeleted) {
                                            removeDocumentSection(
                                                dispatch,
                                                `docPrep_foreign${SECTION_NAME_SEPARATOR}${index}`
                                            );
                                        }
                                    });
                                await props.onSave(generateChangesWithAddressId());
                            }

                            setShouldReload(dispatch, true);
                        } catch (e) {
                            setIsLoading(dispatch, false);
                            return Promise.reject();
                        }
                    }}
                />
                {submitTask && (
                    <TaskActions
                        task={submitTask}
                        isSubmitEnabled={canSubmit}
                        onCompleteTask={async (task, exitcode) => {
                            setIsEditable(dispatch, false);
                            setIsLoading(dispatch, true);
                            try {
                                if (isEmpty(changes)) {
                                    await updateDocuments(sections, entityOId);
                                    props.onCompleteTask(task, exitcode);
                                    setIsSubmitted(dispatch, true);
                                } else {
                                    await updateStoredDocuments(sections, entityOId);
                                    setShouldUpdateNewDocuments(dispatch, true);
                                    props.onSubmitClicked();
                                    changes.foreignRegistrations &&
                                        changes.foreignRegistrations.forEach((registration, index) => {
                                            if (registration.isDeleted) {
                                                removeDocumentSection(
                                                    dispatch,
                                                    `docPrep_foreign${SECTION_NAME_SEPARATOR}${index}`
                                                );
                                            }
                                        });
                                    await props.onSave(generateChangesWithAddressId());
                                }
                            } catch (e) {
                                setIsEditable(dispatch, true);
                                setIsLoading(dispatch, false);
                                return Promise.reject();
                            }
                        }}
                    />
                )}
            </StackPanel>
        );
    }

    if (props.mode === 'Approval') {
        const reviewTask = props.workItem.tasks?.find(
            (task) => task.taskType === 'ReviewDocPrepTaskType' && isOpen(task.status)
        );
        return reviewTask ? (
            <TaskActions
                task={reviewTask}
                onCompleteTask={(task, exitCode, rejectReason) =>
                    props.onCompleteTask(task, exitCode as string, rejectReason)
                }
            />
        ) : null;
    }

    return null;
}

export default memo(ActionPanel);
