export function showHideLinksByUrl(url: string, show?: boolean) {
    setTimeout(() => {
        const links = document.querySelectorAll(`[href="${url}"]`);

        if (links.length) {
            links.forEach((element) => {
                show ? element.classList.remove('hide-navbar-item') : element.classList.add('hide-navbar-item');
            });
        }
    }, 50);
}
