import { LegalEntityDissolutionApprovalData, LegalEntityDissolutionApprovalProps } from './model';
import React, { useCallback } from 'react';

import LegalEntityDissolutionApproval from './LegalEntityDissolutionApproval';
import { LegalEntityDissolutionApprovalProvider } from '../../context/Provider';
import { RequestStepsView } from '../../LegalEntityCreationView/RequestStepsView';

export default function LegalEntityDissolutionApprovalSection(props: LegalEntityDissolutionApprovalProps) {
    const calculateDefaultValues = useCallback(
        (data?: LegalEntityDissolutionApprovalData) => ({
            approveDissolution: data?.approveDissolution ?? false,
            approveSoftClosure: data?.approveSoftClosure ?? false,
        }),
        []
    );

    return (
        <LegalEntityDissolutionApprovalProvider
            storedValues={props.data}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Dissolution_Approval}>
            <LegalEntityDissolutionApproval {...props} />
        </LegalEntityDissolutionApprovalProvider>
    );
}
