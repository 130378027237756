import { Debugger, Expander, FileUpload, Footer, Form, RequestNotes } from 'components';
import React, { useState } from 'react';

import ActionPanel from './components/ActionPanel/ActionPanel';
import AuthorizedPersonsGrid from './components/AuthorizedPersons/AuthorizedPersonsGrid';
import { LegalEntityOwnershipInformationProps } from './model';
import OwnershipDetailsGrid from './components/OwnershipDetails/OwnershipDetailsGrid';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from '../models';
import { getDocumentTypesForTreeView } from '../LegalEntityDocumentPreparation/utils';
import { useLegalEntityOwnerShipInformation } from '../context/Provider';
import useOwnershipInformationDocuments from './hooks/use-ownership-information-documents';
import useLastCompletedTask from '../LegalEntityCreationView/hooks/use-last-completed-task';
import { ContentBox } from 'modules/RequestsDashboard/components/components.styled';
import PageUpdateInfo from '../LegalEntityDissolution/components/PageUpdateInfo';

export type FormsChangesState = {
    authorizedPerson: boolean;
    ownershipDetails: boolean;
};

export default function LegalEntityOwnershipInformation(props: LegalEntityOwnershipInformationProps) {
    const { state, dispatch } = useLegalEntityOwnerShipInformation();

    const {
        data: {
            values: { id, domesticKey },
        },
    } = state;

    const lastSubmitTask = useLastCompletedTask({
        workItem: props.workItem,
        taskType: 'RequestTaskType'
    });

    const lastApproveTask = useLastCompletedTask({
        workItem: props.workItem,
        taskType: 'UpdateRequestTaskType'
    });

    const [formsChangesState, setFormsChangesState] = useState<FormsChangesState>({
        authorizedPerson: false,
        ownershipDetails: false,
    });

    const { documentApproveDate, documentTypes, stampedDocumentTypes } = useOwnershipInformationDocuments({
        legalType: props.data?.legalType,
    });

    const isStandaloneMode =
        !props.workItem ||
        props.workItem?.isStandalone ||
        props.workItem?.workItemState === 'Ownership Setup Complete' ||
        props.workItem?.workItemState === 'Withdrawn';

    const documentSectionName = isStandaloneMode
        ? SECTION_NAMES.OWNERSHIP_INFORMATION_STANDALONE
        : SECTION_NAMES.OWNERSHIP_INFORMATION;

    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.OWNERSHIP_INFORMATION);

    const showDocumentsInReadOnlyTreeView = props.mode === 'Readonly' && props.workItem?.workItemStatus !== 'InProcess';

    const setAuthPersonFormHasChange = (hasChange: boolean) => {
        const states = { ...formsChangesState };
        states.authorizedPerson = hasChange;
        setFormsChangesState(states);
    };

    const setOwnershipDetailsFormHasChange = (hasChange: boolean) => {
        const states = { ...formsChangesState };
        states.ownershipDetails = hasChange;
        setFormsChangesState(states);
    };

    return (
        <>
            <Debugger inputState={state} requestStep={RequestStepsView.Ownership_Information} />
            <Form>
                <AuthorizedPersonsGrid
                    mode={props.mode}
                    isStandaloneWorkflow={isStandaloneMode}
                    setFormHasChanges={setAuthPersonFormHasChange}
                />
                <OwnershipDetailsGrid
                    mode={props.mode}
                    isStandaloneWorkflow={isStandaloneMode}
                    setFormHasChanges={setOwnershipDetailsFormHasChange}
                />
                <Expander
                    header={showDocumentsInReadOnlyTreeView ? 'Existing Documents' : 'Documents'}
                    requestStep={RequestStepsView.Ownership_Information}
                    content={
                        <FileUpload
                            entityId={id}
                            documentTypes={documentTypes}
                            stampedDocumentTypes={stampedDocumentTypes}
                            showStampedDocuments={!isStandaloneMode && props.mode !== 'Readonly'}
                            sectionName={documentSectionName}
                            context={{ state, dispatch }}
                            taxRegistrationId={domesticKey}
                            documentApproveDate={documentApproveDate}
                            keepDocumentTypes
                            showDocumentsInReadOnlyTreeView={showDocumentsInReadOnlyTreeView}
                            treeViewDocumentTypes={treeViewDocumentTypes}
                            standaloneCreateMode={props.mode === 'Create'}
                            effectiveDate='required'
                        />
                    }
                />
                {props.mode !== 'Create' && <RequestNotes workItem={props.workItem} mode={props.mode} />}
                <RejectReason mode={props.mode} taskType='UpdateRequestTaskType' tasks={props?.workItem?.tasks} />
                <ContentBox>
                    <PageUpdateInfo label={"Last Submitted by"} lastCompletedTask={lastSubmitTask} />
                    <PageUpdateInfo label={"Last Approved by"} lastCompletedTask={lastApproveTask} />
                </ContentBox>
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel {...props} formsChangesState={formsChangesState} />
                </Footer>
            )}
        </>
    );
}
