import { FontIcon } from '@bxgrandcentral/controls';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

export default function GridPendingColumnRenderer(props: ICellRendererParams) {
    const { value } = props;
    const { accentColor } = useColors();

    return value ? (
        <Wrapper>
            <FontIcon iconName={'Completed'} foregroundColor={accentColor} />
        </Wrapper>
    ) : (
        ''
    );
}

const Wrapper = styled.div`
    text-align: center;
    width: 80px;
`;
