import {
    DISSOLUTION_FINANCE_STATE,
    DISSOLUTION_LEGAL_STATE,
    DISSOLUTION_LITIGATION_STATE,
    DISSOLUTION_STATE,
    DISSOLUTION_TAX_STATE,
    DISSOLUTION_TREASURY_STATE,
    LegalEntityDissolutionFinanceState,
    LegalEntityDissolutionLegalState,
    LegalEntityDissolutionLitigationState,
    LegalEntityDissolutionState,
    LegalEntityDissolutionTaxState,
    LegalEntityDissolutionTreasuryState,
} from 'models/LegalEntityRequest/Workflow';

import { RequestStepsView } from '../RequestStepsView';
import { WorkflowType } from 'models/LegalEntityRequest/Workflow/WorkflowTypes';
import { useCallback } from 'react';
import { useCreationViewContext } from '../context/creation-view-context';

export default function useSidebarCustomIcon() {
    const {
        state: {
            legalEntityDissolutionParentWorkItem,
            legalEntityDissolutionLitigationWorkItem,
            legalEntityDissolutionTaxWorkItem,
            legalEntityDissolutionFinanceWorkItem,
            legalEntityDissolutionTreasuryWorkItem,
            legalEntityDissolutionLegalWorkItem,
            latestRejectedDissolutionWorkflow,
        },
    } = useCreationViewContext();

    const icons = {
        completed: 'Completed',
        rejected: 'EntryDecline',
    };

    const getSidebarCustomIcon = useCallback(
        (step: RequestStepsView) => {
            if (!legalEntityDissolutionParentWorkItem) {
                return;
            }

            const isDissolutionMainWorkItemAtRequestState =
                legalEntityDissolutionParentWorkItem?.workItemState === DISSOLUTION_STATE.DISSOLUTION_REQUEST;

            const isDissolutionMainWorkItemApproved = (
                [
                    DISSOLUTION_STATE.AWAITING_FOR_CHILD_APPROVAL,
                    DISSOLUTION_STATE.BDG_PENDING_SUBMIT,
                    DISSOLUTION_STATE.BDG_SETUP_COMPLETE,
                    DISSOLUTION_STATE.DISSOLUTION_COMPLETE,
                ] as (LegalEntityDissolutionState | undefined)[]
            ).includes(legalEntityDissolutionParentWorkItem?.workItemState);

            const isDissolutionBDGPending =
                legalEntityDissolutionParentWorkItem?.workItemState === DISSOLUTION_STATE.BDG_PENDING_SUBMIT;

            switch (step) {
                case RequestStepsView.Dissolution_Approval:
                    if (isDissolutionMainWorkItemApproved || isDissolutionBDGPending) {
                        return icons.completed;
                    } else if (
                        isDissolutionMainWorkItemAtRequestState &&
                        latestRejectedDissolutionWorkflow?.workflowType ===
                            WorkflowType.LegalEntityDissolutionParentWorkflowType
                    ) {
                        return icons.rejected;
                    }
                    break;

                case RequestStepsView.Dissolution_Litigation:
                    if (
                        isDissolutionMainWorkItemApproved &&
                        (
                            [
                                DISSOLUTION_LITIGATION_STATE.DISSOLUTION_APPROVED,
                                DISSOLUTION_LITIGATION_STATE.DISSOLUTION_COMPLETE,
                            ] as (LegalEntityDissolutionLitigationState | undefined)[]
                        ).includes(legalEntityDissolutionLitigationWorkItem?.workItemState)
                    ) {
                        return icons.completed;
                    } else if (
                        isDissolutionMainWorkItemAtRequestState &&
                        latestRejectedDissolutionWorkflow?.workflowType ===
                            WorkflowType.LegalEntityDissolutionLitigationWorkflowType
                    ) {
                        return icons.rejected;
                    }
                    break;

                case RequestStepsView.Dissolution_Tax:
                    if (
                        isDissolutionMainWorkItemApproved &&
                        (
                            [
                                DISSOLUTION_TAX_STATE.DISSOLUTION_APPROVED,
                                DISSOLUTION_TAX_STATE.DISSOLUTION_COMPLETE,
                            ] as (LegalEntityDissolutionTaxState | undefined)[]
                        ).includes(legalEntityDissolutionTaxWorkItem?.workItemState)
                    ) {
                        return icons.completed;
                    } else if (
                        isDissolutionMainWorkItemAtRequestState &&
                        latestRejectedDissolutionWorkflow?.workflowType ===
                            WorkflowType.LegalEntityDissolutionTaxWorkflowType
                    ) {
                        return icons.rejected;
                    }
                    break;

                case RequestStepsView.Dissolution_Finance:
                    if (
                        isDissolutionMainWorkItemApproved &&
                        (
                            [
                                DISSOLUTION_FINANCE_STATE.DISSOLUTION_APPROVED,
                                DISSOLUTION_FINANCE_STATE.DISSOLUTION_COMPLETE,
                            ] as (LegalEntityDissolutionFinanceState | undefined)[]
                        ).includes(legalEntityDissolutionFinanceWorkItem?.workItemState)
                    ) {
                        return icons.completed;
                    } else if (
                        isDissolutionMainWorkItemAtRequestState &&
                        latestRejectedDissolutionWorkflow?.workflowType ===
                            WorkflowType.LegalEntityDissolutionFinanceWorkflowType
                    ) {
                        return icons.rejected;
                    }
                    break;

                case RequestStepsView.Dissolution_Treasury:
                    if (
                        isDissolutionMainWorkItemApproved &&
                        (
                            [
                                DISSOLUTION_TREASURY_STATE.DISSOLUTION_APPROVED,
                                DISSOLUTION_TREASURY_STATE.DISSOLUTION_COMPLETE,
                            ] as (LegalEntityDissolutionTreasuryState | undefined)[]
                        ).includes(legalEntityDissolutionTreasuryWorkItem?.workItemState)
                    ) {
                        return icons.completed;
                    } else if (
                        isDissolutionMainWorkItemAtRequestState &&
                        latestRejectedDissolutionWorkflow?.workflowType ===
                            WorkflowType.LegalEntityDissolutionTreasuryWorkflowType
                    ) {
                        return icons.rejected;
                    }
                    break;
                case RequestStepsView.Dissolution_Legal:
                    if (
                        isDissolutionMainWorkItemApproved &&
                        (
                            [
                                DISSOLUTION_LEGAL_STATE.DISSOLUTION_APPROVED,
                                DISSOLUTION_LEGAL_STATE.DISSOLUTION_COMPLETE,
                            ] as (LegalEntityDissolutionLegalState | undefined)[]
                        ).includes(legalEntityDissolutionLegalWorkItem?.workItemState)
                    ) {
                        return icons.completed;
                    } else if (
                        isDissolutionMainWorkItemAtRequestState &&
                        latestRejectedDissolutionWorkflow?.workflowType ===
                            WorkflowType.LegalEntityDissolutionLegalWorkflowType
                    ) {
                        return icons.rejected;
                    }
                    break;
                case RequestStepsView.Dissolution_BDG:
                    if (
                        (
                            [DISSOLUTION_STATE.DISSOLUTION_COMPLETE, DISSOLUTION_STATE.BDG_SETUP_COMPLETE] as (
                                | LegalEntityDissolutionState
                                | undefined
                            )[]
                        ).includes(legalEntityDissolutionParentWorkItem?.workItemState)
                    ) {
                        return icons.completed;
                    }
                    break;
            }
        },
        [
            icons,
            latestRejectedDissolutionWorkflow,
            legalEntityDissolutionParentWorkItem,
            legalEntityDissolutionLitigationWorkItem,
            legalEntityDissolutionTaxWorkItem,
            legalEntityDissolutionFinanceWorkItem,
            legalEntityDissolutionTreasuryWorkItem,
            legalEntityDissolutionLegalWorkItem,
        ]
    );

    return getSidebarCustomIcon;
}
