import { CreateLegalEntityWorkflow, CreateLegalEntityWorkflowTask } from 'models/LegalEntityRequest/Workflow';
import { NewStructureType, OldStructureType } from 'models';

export type LegalEntityApprovalData = {
    consolidationRequired?: boolean;
    financeInformationRequired?: boolean;
    taxClassificationRequired?: boolean;
    EINAndGIINCreationRequired?: boolean;
    bankAccountRequired?: boolean;
    ownershipInfoRequired?: boolean;
    administeredBy?: number;
    structureType?: number;
    isOldStructureType?: boolean;
    domesticState?: number;
    domesticCountry?: number;
    isDisregardedEntity?: boolean;
};

export const oldStructureTypeBankAccounts = [
    OldStructureType.BlockerLeverage,
    OldStructureType.BlockerUnLeverage,
    OldStructureType.SPV,
    OldStructureType.SPVAggregator,
] as const;

export const newStructureTypeBankAccounts = [
    NewStructureType.BlockerLeveraged,
    NewStructureType.BlockerUnleveraged,
    NewStructureType.SPV,
    NewStructureType.SPVAggregator,
] as const;

type BaseProps = {
    data?: LegalEntityApprovalData | undefined;
};

type ReadOnlyProps = {
    mode: 'ReadOnly';
    workItem?: CreateLegalEntityWorkflow;
};

type EditProps = {
    mode: 'Edit';
    workItem: CreateLegalEntityWorkflow;
    onSave: (data: Partial<LegalEntityApprovalData>) => Promise<void>;
    onCompleteTask: (task: CreateLegalEntityWorkflowTask, exitCode: string, rejectReason?: string) => void;
};

export type LegalEntityApprovalProps = BaseProps & (ReadOnlyProps | EditProps);
