import { Expander, Text } from 'components';
import { Grid, ScrollViewer } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { ToolBarBackButton } from 'modules/LegalEntityCreation/LegalEntityCreationView/components';
import { constants } from '../../constants/env-config';
import { getKeysOf } from 'Utilities/object';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

export default function EnvironmentView() {
    const { normalControlBorderColor } = useColors();
    return (
        <>
            <Header color={normalControlBorderColor}>
                <ToolBarBackButton />
            </Header>
            <ScrollViewer padding={SPACING.XXL}>
                <Expander
                    header='Environment Variables'
                    content={
                        <Grid itemGap={SPACING.XS}>
                            <Grid columnDefinitions='1fr 2fr' margin={`0 0 ${SPACING.SM}px 0`}>
                                <Text>Key</Text>
                                <Text>Value</Text>
                            </Grid>
                            {getKeysOf(constants)
                                .sort()
                                .map((key) => (
                                    <Grid columnDefinitions='1fr 2fr'>
                                        <Text fontSize={14}>{key}</Text>
                                        <Text fontSize={14}>{constants[key]}</Text>
                                    </Grid>
                                ))}
                        </Grid>
                    }
                />
            </ScrollViewer>
        </>
    );
}

const Header = styled.div<{ color: string }>`
    display: flex;
    border: ${({ color }) => `1px solid ${color}`};
    border-top: none;
    padding: 0 ${SPACING.XS}px;
`;
