export enum NewStructureType {
    Admin = 1,
    Advisor = 2,
    AIV = 3,
    BlockerLeveraged = 4,
    BlockerUnleveraged = 5,
    CoInvest = 6,
    CoInvestDiscretionary = 7,
    Feeder = 8,
    FundMain = 9,
    GP = 10,
    REIT = 11,
    SBS = 12,
    Sleeve = 13,
    SPV = 14,
    SPVAggregator = 15,
}
