import {
    Address,
    AttributeBoolean,
    AttributeDate,
    AttributeNumeric,
    AttributePicklist,
    AttributeString,
    AttributeType,
    LegalEntityDTO,
    TaxCode,
    TaxRegistration,
} from '../../../../models';
import { RecursivePartial, isIn } from '../../../../Utilities/ReflectionUtil';

import { ForeignRegistrationData } from 'modules/LegalEntityCreation/LegalEntityRegistration/LegalEntityForeignRegistrationUpdate/model';
import { RegistrationData } from 'modules/LegalEntityCreation/LegalEntityInformation/model';
import { YesNoStateKeys } from 'models/LegalEntityRequest/DefaultValues';
import { isEmpty } from '../../../../Utilities/Validations';

export const getBooleanAttributes = (objectAttributeBooleans?: AttributeBoolean[]) =>
    objectAttributeBooleans?.reduce((prev, curr) => {
        prev[curr.objectAttributeId] = curr.value;
        return prev;
    }, {} as { [attr in AttributeType]?: boolean }) ?? {};

export const getStringAttributes = (objectAttributeStrings?: AttributeString[]) =>
    objectAttributeStrings?.reduce((prev, curr) => {
        prev[curr.objectAttributeId] = curr.value;
        return prev;
    }, {} as { [attr in AttributeType]?: string }) ?? {};

export const getDateAttributes = (objectAttributeDates?: AttributeDate[]) =>
    objectAttributeDates?.reduce((prev, curr) => {
        prev[curr.objectAttributeId] = curr.value;
        return prev;
    }, {} as { [attr in AttributeType]?: Date }) ?? {};

export const setIfDefined = <S, K extends keyof S>(
    section: Partial<S>,
    key: K,
    action: (incomingValue: S[K] | undefined) => void
) => {
    if (isIn(section, key)) action(section[key]);
};

type BooleanUpdaterParams = {
    original: LegalEntityDTO | undefined;
    booleanAttributes: Partial<AttributeBoolean>[];
    attributeType: AttributeType;
    value: boolean | undefined;
    checkSource: boolean;
};

export const createOrUpdateBooleanValue = ({
    original,
    booleanAttributes,
    attributeType,
    value,
    checkSource = true,
}: BooleanUpdaterParams) => {
    if (!checkSource) {
        booleanAttributes.push({
            objectAttributeId: attributeType,
            value: value,
        });

        return;
    }

    const inSource = original?.entity.objectAttributeBooleans?.find((a) => a.objectAttributeId === attributeType);
    if (inSource) {
        booleanAttributes.push({
            objectAttributeId: inSource.objectAttributeId,
            objectAttributeInstanceId: inSource.objectAttributeInstanceId,
            value: value,
        });
    } else {
        !isEmpty(value) && booleanAttributes.push({ objectAttributeId: attributeType, value: value });
    }
};

type stringUpdaterParams = {
    original: LegalEntityDTO | undefined;
    stringAttributes: Partial<AttributeString>[];
    attributeType: AttributeType;
    value: string | undefined;
};
export const createOrUpdateStringValue = ({
    original,
    stringAttributes,
    attributeType,
    value,
}: stringUpdaterParams) => {
    const inSource = original?.entity.objectAttributeStrings?.find((a) => a.objectAttributeId === attributeType);
    if (inSource) {
        stringAttributes.push({
            objectAttributeInstanceId: inSource.objectAttributeInstanceId,
            value: value?.toString(),
            objectAttributeId: inSource.objectAttributeId,
        });
    } else {
        !isEmpty(value) && stringAttributes.push({ objectAttributeId: attributeType, value: value });
    }
};

type dateUpdaterParams = {
    original: LegalEntityDTO | undefined;
    dateAttributes: Partial<AttributeDate>[];
    attributeType: AttributeType;
    value: Date | undefined;
};
export const createOrUpdateDateValue = ({ original, dateAttributes, attributeType, value }: dateUpdaterParams) => {
    const inSource = original?.entity.objectAttributeDates?.find((a) => a.objectAttributeId === attributeType);
    if (inSource) {
        dateAttributes.push({
            objectAttributeInstanceId: inSource.objectAttributeInstanceId,
            value,
            objectAttributeId: inSource.objectAttributeId,
        });
    } else {
        !isEmpty(value) && dateAttributes.push({ objectAttributeId: attributeType, value });
    }
};

type numberUpdaterParams = {
    original: LegalEntityDTO | undefined;
    numericAttributes: Partial<AttributeNumeric>[];
    attributeType: AttributeType;
    value: number | undefined;
};
export const createOrUpdateNumericValue = ({
    original,
    numericAttributes,
    attributeType,
    value,
}: numberUpdaterParams) => {
    const inSource = original?.entity.objectAttributeNumerics?.find((a) => a.objectAttributeId === attributeType);
    if (inSource) {
        numericAttributes.push({
            objectAttributeInstanceId: inSource.objectAttributeInstanceId,
            value: value,
            objectAttributeId: inSource.objectAttributeId,
        });
    } else {
        numericAttributes.push({ objectAttributeId: attributeType, value: value });
    }
};

type pickListUpdaterParams = {
    original: LegalEntityDTO | undefined;
    pickListAttributes: Partial<AttributePicklist>[];
    attributeType: AttributeType;
    value: number | undefined;
};
export const createOrUpdatePickListValue = ({
    original,
    pickListAttributes,
    attributeType,
    value,
}: pickListUpdaterParams) => {
    const inSource = original?.entity.objectAttributePicklists?.find((a) => a.objectAttributeId === attributeType);
    if (inSource) {
        pickListAttributes.push({
            objectAttributeInstanceId: inSource.objectAttributeInstanceId,
            picklistItemId: value,
            objectAttributeId: inSource.objectAttributeId,
        });
    } else {
        pickListAttributes.push({ objectAttributeId: attributeType, picklistItemId: value });
    }
};

type processedAddresses = {
    addresses: Address[];
    removedAddresses: number[];
};

export const getAddresses = (addresses: Address[] = []) =>
    addresses.reduce<processedAddresses>(
        (acc, address) => {
            if (address.isDeleted && address.addressId) {
                acc.removedAddresses.push(address.addressId);
            } else {
                const processedAddress: Partial<Address> = {};

                if (address.addressId) {
                    processedAddress.addressId = address.addressId;
                }
                processedAddress.addressTypeId = address.addressTypeId;
                processedAddress.line1 = address.line1;
                processedAddress.line2 = address.line2;
                processedAddress.line3 = address.line3;
                processedAddress.line4 = address.line4;
                processedAddress.countryId = address.countryId;
                processedAddress.city = address.city;
                processedAddress.state = address.state;
                processedAddress.stateId = address.stateId;
                processedAddress.postalCode = address.postalCode;

                acc.addresses.push(processedAddress);
            }

            return acc;
        },
        { addresses: [], removedAddresses: [] }
    );

type processedTaxCodes = {
    taxCodes: TaxCode[];
    removedTaxCodes: number[];
};

export const getTaxCodes = (taxCodes: TaxCode[] = []) =>
    taxCodes.reduce<processedTaxCodes>(
        (acc, taxCode) => {
            if (taxCode.isDeleted && taxCode.taxCodeId) {
                acc.removedTaxCodes.push(taxCode.taxCodeId);
            } else {
                acc.taxCodes.push(taxCode);
            }

            return acc;
        },
        { taxCodes: [], removedTaxCodes: [] }
    );

type processedTaxRegistrations = {
    taxRegistrations: RecursivePartial<TaxRegistration>[];
    removedTaxRegistrations: number[];
};

export const getTaxRegistrations = (registrationsData: RegistrationData[] = []) =>
    registrationsData.reduce<processedTaxRegistrations>(
        (acc, registration) => {
            if (registration.isDeleted && registration?.taxRegistrationId) {
                acc.removedTaxRegistrations.push(registration.taxRegistrationId);
            } else {
                const processedRegistration: Partial<TaxRegistration> = {};

                if (registration.taxRegistrationId) {
                    processedRegistration.taxRegistrationId = registration.taxRegistrationId;
                }
                processedRegistration.registeredAgentId = registration.domesticRegisteredAgent;
                processedRegistration.formationCountryId = registration.domesticCountry;
                processedRegistration.formationStateId = registration.domesticState;
                processedRegistration.formationNumber = registration.registrationNumber;
                processedRegistration.formationDate = registration.formationDate;
                processedRegistration.dissolutionDate = registration.dissolutionDate;
                processedRegistration.isDomestic = registration.isDomestic;

                acc.taxRegistrations.push(processedRegistration);
            }

            return acc;
        },
        { taxRegistrations: [], removedTaxRegistrations: [] }
    );

export const getTaxRegistrationsFromForeign = (registrationsData: ForeignRegistrationData[] = []) =>
    registrationsData.reduce<processedTaxRegistrations>(
        (acc, registration) => {
            if (registration.isDeleted && registration?.taxRegistrationId) {
                acc.removedTaxRegistrations.push(registration.taxRegistrationId);
            } else {
                const processedRegistration: Partial<TaxRegistration> = {};

                if (registration.taxRegistrationId) {
                    processedRegistration.taxRegistrationId = registration.taxRegistrationId;
                }
                processedRegistration.registeredAgentId = registration.registeredAgent;
                processedRegistration.formationCountryId = registration.country;
                processedRegistration.formationStateId = registration.state;
                processedRegistration.formationNumber = registration.registrationNumber;
                processedRegistration.formationDate = registration.formationDate;
                processedRegistration.isDomestic = false;

                acc.taxRegistrations.push(processedRegistration);
            }

            return acc;
        },
        { taxRegistrations: [], removedTaxRegistrations: [] }
    );

export const getDomesticRegistrationDataForSection = (
    taxRegistrations: TaxRegistration[] | any,
    latestDomesticTaxRegistrationId?: number
) => {
    const domesticRegistrations = taxRegistrations
        .filter(({ isDomestic, taxRegistrationId }: TaxRegistration) => {
            if (latestDomesticTaxRegistrationId) {
                // a negative taxRegistrationId means a pending registration
                return isDomestic && (taxRegistrationId === latestDomesticTaxRegistrationId || taxRegistrationId < 0);
            }

            return isDomestic;
        })
        .map((registration: TaxRegistration) => {
            const registrationData: RegistrationData = {};
            registrationData.taxRegistrationId = registration.taxRegistrationId;
            registrationData.dissolutionDate = registration.dissolutionDate;
            registrationData.domesticCountry = registration.formationCountryId;
            registrationData.domesticRegisteredAgent = registration.registeredAgentId;
            registrationData.domesticState = registration.formationStateId;
            registrationData.formationDate = registration.formationDate;
            registrationData.registrationNumber = registration.formationNumber;
            registrationData.isDomestic = registration.isDomestic;

            return registrationData;
        });

    return domesticRegistrations;
};

export const getForeignRegistrationsDataForSection = (taxRegistrations: TaxRegistration[] | any) => {
    const domesticRegistrations = taxRegistrations
        .filter(({ isDomestic }: TaxRegistration) => {
            return !isDomestic;
        })
        .map((registration: TaxRegistration) => {
            const registrationData: RegistrationData = {};
            registrationData.taxRegistrationId = registration.taxRegistrationId;
            registrationData.dissolutionDate = registration.dissolutionDate;
            registrationData.domesticCountry = registration.formationCountryId;
            registrationData.domesticRegisteredAgent = registration.registeredAgentId;
            registrationData.domesticState = registration.formationStateId;
            registrationData.formationDate = registration.formationDate;
            registrationData.registrationNumber = registration.formationNumber;
            registrationData.isDomestic = registration.isDomestic;

            return registrationData;
        });

    return domesticRegistrations;
};

export const mapForeignRegistrationUpdateSection = (
    registration: TaxRegistration | undefined,
    entityOid: number,
    includeTaxRegistrationId = false
) => {
    const taxReg: Partial<TaxRegistration> = registration ?? {};
    return {
        country: taxReg?.formationCountryId,
        key: taxReg?.taxRegistrationId ?? 0,
        registeredAgent: taxReg?.registeredAgentId,
        state: taxReg?.formationStateId,
        formationDate: taxReg?.formationDate,
        registrationNumber: taxReg?.formationNumber,
        entityOid: entityOid,
        isDeleted: false,
        ...(includeTaxRegistrationId && { taxRegistrationId: taxReg.taxRegistrationId }),
    };
};

export const mapRegistrationSection = (
    registration: TaxRegistration | undefined,
    entityOid: number,
    includeTaxRegistrationId = false
) => {
    const taxReg: Partial<TaxRegistration> = registration ?? {};
    return {
        country: taxReg.formationCountryId ?? -1,
        key: taxReg?.taxRegistrationId ?? 0,
        registeredAgent: taxReg?.registeredAgentId ?? 0,
        state: taxReg?.formationStateId ?? -1,
        formationDate: taxReg?.formationDate,
        registrationNumber: taxReg?.formationNumber,
        entityOid: entityOid,
        ...(includeTaxRegistrationId && { taxRegistrationId: taxReg.taxRegistrationId }),
    };
};

export const getDocPrepAddressFromSectionData = (address?: Address) => {
    const processedAddress: Partial<Address> = {};

    if (address?.addressId) {
        processedAddress.addressId = address?.addressId;
    }
    processedAddress.line1 = address?.line1;
    processedAddress.line2 = address?.line2;
    processedAddress.line3 = address?.line3;
    processedAddress.line4 = address?.line4;
    processedAddress.countryId = address?.countryId;
    processedAddress.city = address?.city;
    processedAddress.state = address?.state;
    processedAddress.stateId = address?.stateId;
    processedAddress.postalCode = address?.postalCode;

    const hasInputData = Object.entries(processedAddress).some(([key, value]) => key !== 'addressId' && value);

    return { processedAddress, hasInputData };
};

export const booleanToYesNoState = (booleanValue: boolean | undefined) => {
    switch (booleanValue) {
        case true: {
            return YesNoStateKeys.Yes;
        }
        case false: {
            return YesNoStateKeys.No;
        }
        default: {
            return undefined;
        }
    }
};

export const yesNoStateToBoolean = (yesNoStateKey: YesNoStateKeys | undefined) => {
    switch (yesNoStateKey) {
        case YesNoStateKeys.Yes: {
            return true;
        }
        case YesNoStateKeys.No: {
            return false;
        }
        default: {
            return undefined;
        }
    }
};
