import { DocumentsResponse } from '../../models/LegalEntityRequest/Documents/DocumentsResponse';
import { LogError } from '../../models';
import { StoredDocumentModel } from 'components/controls/FileUpload/models';

export abstract class IDocumentService {
    abstract logInfo: LogError;

    public abstract UploadFile(
        entityOid: number,
        message: any,
        sectionName: string,
        documentType: number,
        taxRegistrationId?: number,
        effectiveDate?: Date
    ): Promise<any>;
    public abstract DownloadFile(entityOid: number, documentId: number): Promise<any>;
    public abstract GetFiles(entityOid: number): Promise<DocumentsResponse[]>;
    public abstract UpdateDocumentElements(document: StoredDocumentModel): Promise<any>;
    public abstract DeleteFile(entityOId: number, documentId: number): Promise<any>;
}
