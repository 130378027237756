import { useCallback, useEffect, useMemo } from 'react';

import { EntityStatusId } from '../../../models/LegalEntity/EntityStatus';
import { IWorkflowService } from '../../../services/interfaces';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { VALIDATION_MESSAGES } from './utils';
import { ValidationMessages } from '../../../models';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import useLegalEntityNames from '../../../api/hooks/use-legal-entity-names';

type Props = {
    entityOid?: number;
    legalName?: string;
    dispatch: any;
    isEditable: boolean;
};

export default function useLegalNameValidation({ entityOid, legalName, dispatch, isEditable }: Props) {
    const { getEntitiesWithSameName } = useLegalEntityNames();

    const workflowService = useMemo(() => ServiceLocator.container.resolve(IWorkflowService), []);

    const getWorkitem = useCallback(
        async (legalEntityId: number) => {
            return (await workflowService.GetWorkItemForEntity(legalEntityId)).workItem;
        },
        [workflowService]
    );

    useEffect(() => {
        if (!isEditable) {
            return;
        }
        if (isEmpty(legalName)) {
            updateValidation(dispatch, {
                legalName: VALIDATION_MESSAGES.REQUIRED_FIELD,
            });
            return;
        }
        getEntitiesWithSameName(entityOid, legalName).then(async (entities) => {
            const conflictingEntity = entities.sort(({ entityStatusId }) =>
                entityStatusId === EntityStatusId.Active ? -1 : 1
            )[0];
            if (conflictingEntity) {
                const errorMessage = await ValidationMessages.IdenticalEntityName(
                    conflictingEntity,
                    getWorkitem(conflictingEntity.entityOId)
                );
                updateValidation(dispatch, {
                    legalName: errorMessage,
                });
            } else {
                updateValidation(dispatch, {
                    legalName: undefined,
                });
            }
        });
    }, [entityOid, legalName, getEntitiesWithSameName, getWorkitem, dispatch, isEditable]);
}
