import { validateDateTimeField, validateRequiredField } from './utils';

import { isLuxembourg } from '../../../models';
import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityRegistration } from '../context/Provider';

export default function useDomesticRegistrationDetailsValidation() {
    const {
        state: {
            data: {
                values: { registrationNumber, formationDate, country },
            },
            version,
        },
        dispatch,
    } = useLegalEntityRegistration();

    useEffect(() => {
        updateValidation(dispatch, {
            registrationNumber: validateRequiredField(registrationNumber, { isRequired: !isLuxembourg(country) }),
            formationDate: validateDateTimeField(formationDate, {
                isOptionalWhen: isLuxembourg(country),
                canBeFutureDate: false,
            }),
        });
    }, [country, dispatch, formationDate, registrationNumber, version]);
}
