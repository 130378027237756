import { isExternalAdmin, isHedgeFund, shouldHaveSubBusinessUnit } from '../utils';
import { validateNumberField, validateRequiredField } from './utils';

import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityRequest } from '../context/Provider';

export default function useRequestValidation() {
    const {
        state: {
            data: {
                values: {
                    administeredBy,
                    businessDriverId,
                    businessUnitOId,
                    externalAdminOId,
                    hedgehogID,
                    lawFirmEntityOId,
                    legalTypeId,
                    portfolioId,
                    shortName,
                    structureType,
                    subBusinessUnitOId,
                },
            },
        },
        dispatch,
    } = useLegalEntityRequest();

    useEffect(() => {
        updateValidation(dispatch, {
            administeredBy: validateRequiredField(administeredBy),
            businessDriverId: validateRequiredField(businessDriverId),
            businessUnitOId: validateRequiredField(businessUnitOId),
            externalAdminOId: validateRequiredField(externalAdminOId, {
                isRequired: isExternalAdmin(administeredBy),
            }),
            lawFirmEntityOId: validateRequiredField(lawFirmEntityOId, {
                isRequired: !isExternalAdmin(administeredBy),
            }),
            legalTypeId: validateRequiredField(legalTypeId),
            shortName: validateRequiredField(shortName, { isRequired: isHedgeFund(businessUnitOId) }),
            structureType: validateRequiredField(structureType),
            subBusinessUnitOId: validateRequiredField(subBusinessUnitOId, {
                isRequired: shouldHaveSubBusinessUnit(businessUnitOId),
            }),
            hedgehogID: validateNumberField({ value: hedgehogID, isRequired: false }),
        });
    }, [
        administeredBy,
        dispatch,
        businessDriverId,
        businessUnitOId,
        externalAdminOId,
        hedgehogID,
        lawFirmEntityOId,
        legalTypeId,
        portfolioId,
        shortName,
        structureType,
        subBusinessUnitOId,
    ]);
}
