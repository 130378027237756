import { AttributeType, LegalEntityDTO } from '../../../../models';

import { LegalEntityDissolutionFinanceData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionFinance/model';
import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityDissolutionFinanceData => {
    const { dissolutionData, entity } = entityDto;
    const { entityOId, objectAttributeStrings } = entity;

    const stringAttributes =
        objectAttributeStrings?.reduce((prev, curr) => {
            prev[curr.objectAttributeId] = curr.value;
            return prev;
        }, {} as { [attr in AttributeType]?: string }) ?? {};

    return {
        entityOid: entityOId,
        isAuditRequired: dissolutionData?.dissolutionFinance?.isAuditRequired,
        auditFinalBilling: dissolutionData?.dissolutionFinance?.auditFinalBilling,
        auditFinalPaymentTimeline: dissolutionData?.dissolutionFinance?.auditFinalPaymentTimeline,
        cashPositionSettleReceivables: dissolutionData?.dissolutionFinance?.cashPositionSettleReceivables,
        cashPositionDeterminePaydown: dissolutionData?.dissolutionFinance?.cashPositionDeterminePaydown,
        cashPositionProjectCash: dissolutionData?.dissolutionFinance?.cashPositionProjectCash,
        liabilities: dissolutionData?.dissolutionFinance?.liabilities,
        finalPaymentApproved: dissolutionData?.dissolutionFinance?.finalPaymentApproved,
        finalPaymentDistributed: dissolutionData?.dissolutionFinance?.finalPaymentDistributed,
        approverNotes: dissolutionData?.dissolutionFinance?.approverNotes,
        savedBy: dissolutionData?.dissolutionFinance?.savedBy,
        savedAt: dissolutionData?.dissolutionFinance?.savedAt,
        intercompanyBalancesAreZero: dissolutionData?.dissolutionFinance?.intercompanyBalancesAreZero,
        treasuryAskedToCloseBankAccounts: dissolutionData?.dissolutionFinance?.treasuryAskedToCloseBankAccounts,
        bankAccountBalancesAreZero: dissolutionData?.dissolutionFinance?.bankAccountBalancesAreZero,
        billingOracleNumber:
            dissolutionData?.dissolutionFinance?.billingOracleNumber ||
            stringAttributes[AttributeType.billingOracleNumber] !== stringAttributes[AttributeType.oracleNumber]
                ? stringAttributes[AttributeType.billingOracleNumber]
                : undefined,
        billingProductNumber:
            dissolutionData?.dissolutionFinance?.billingProductNumber ||
            stringAttributes[AttributeType.billingProductNumber] !== stringAttributes[AttributeType.productNumber]
                ? stringAttributes[AttributeType.billingProductNumber]
                : undefined,
        billingCostCenterNumber:
            dissolutionData?.dissolutionFinance?.billingCostCenterNumber ||
            stringAttributes[AttributeType.billingCostCenterNumber] !== stringAttributes[AttributeType.costCenterNumber]
                ? stringAttributes[AttributeType.billingCostCenterNumber]
                : undefined,
        billingProjectCode:
            dissolutionData?.dissolutionFinance?.billingProjectCode ||
            stringAttributes[AttributeType.billingProjectCode],
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityDissolutionFinanceData>
) => {
    const toReturn = {} as RecursivePartial<LegalEntityDTO>;

    toReturn.dissolutionData = {
        ...original?.dissolutionData,
        dissolutionFinance: {
            ...original?.dissolutionData?.dissolutionFinance,
            ...section,
        },
    };

    return toReturn;
};
