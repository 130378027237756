import { BooleanField, Debugger, Expander, Footer, Form } from 'components';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';

import ActionPanel from './ActionPanel';
import { LegalEntityApprovalProps } from './model';
import React from 'react';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { StackPanel } from '@bxgrandcentral/controls';
import { useLegalEntityApproval } from '../context/Provider';

export default function LegalEntityApproval(props: LegalEntityApprovalProps) {
    const { mode, workItem } = props;
    const context = useLegalEntityApproval();

    const {
        state: {
            isEditable,
            data: {
                values: {
                    financeInformationRequired,
                    isDisregardedEntity,
                    EINAndGIINCreationRequired,
                    taxClassificationRequired,
                },
            },
        },
        getValue,
        setValues,
    } = context;

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Approval} />
            <Form>
                <Expander
                    header='Approval'
                    content={
                        <DoubleColumnLayout>
                            <StackPanel itemGap={SPACING.SM}>
                                <BooleanField label='Consolidation Required' {...getValue('consolidationRequired')} />
                                <BooleanField
                                    label='Finance Information Required'
                                    {...getValue('financeInformationRequired')}
                                    onValueChanged={(newValue) =>
                                        setValues({
                                            financeInformationRequired: newValue,
                                            ...(!newValue && { bankAccountRequired: false }),
                                        })
                                    }
                                />
                                <BooleanField
                                    label='Tax Classification Required'
                                    {...getValue('taxClassificationRequired')}
                                    onValueChanged={(newValue) =>
                                        setValues({
                                            taxClassificationRequired: newValue,
                                            ...(!newValue && {
                                                bankAccountRequired: false,
                                                EINAndGIINCreationRequired: false,
                                            }),
                                        })
                                    }
                                />
                            </StackPanel>
                            <StackPanel itemGap={SPACING.SM}>
                                <BooleanField
                                    label='EIN/GIIN Creation Required'
                                    {...getValue('EINAndGIINCreationRequired')}
                                    isEditable={isEditable && taxClassificationRequired}
                                    onValueChanged={(newValue) =>
                                        setValues({
                                            EINAndGIINCreationRequired: newValue,
                                            ...(!newValue && !isDisregardedEntity && { bankAccountRequired: false }),
                                        })
                                    }
                                />
                                <BooleanField
                                    label='Bank Account Required'
                                    {...getValue('bankAccountRequired')}
                                    isEditable={
                                        isEditable &&
                                        financeInformationRequired &&
                                        taxClassificationRequired &&
                                        (isDisregardedEntity || EINAndGIINCreationRequired)
                                    }
                                />
                                <BooleanField
                                    label='Ownership Information Required'
                                    {...getValue('ownershipInfoRequired')}
                                />
                            </StackPanel>
                        </DoubleColumnLayout>
                    }
                />
                <RejectReason mode={mode} taskType='TransitionToPostProcess1TaskType' tasks={workItem?.tasks} />
            </Form>
            {props.mode !== 'ReadOnly' && (
                <Footer>
                    <ActionPanel {...props} />
                </Footer>
            )}
        </>
    );
}
