import { Button, Component, Dropdown, DropdownItem } from './export-data.styled';
import { FontIcon, ThemeManager } from '@bxgrandcentral/controls';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { LegalEntityRequest } from '../../../../models';
import { TreeListData } from '../../model';
import { isNil } from 'lodash';
import { legalEntityColumnNames } from '../../../../modules/RequestsDashboard/RequestsDashboardGrid.config';
import useExport from '../../hooks/use-export';
import useOnClickOutside from '../../../../hooks/use-click-outside';

type Props<T> = {
    treeListData: TreeListData<T>;
};

export default function ExportPendingRequests({
    treeListData: { nodes, visibleColumns, isStandaloneOnly },
}: Props<LegalEntityRequest>) {
    const [isOpen, setIsOpen] = useState(false);

    const {
        activeTheme: {
            colors: { accentColor },
        },
    } = ThemeManager;

    const allData = useMemo(
        () =>
            nodes.reduce<LegalEntityRequest[]>((result, node) => {
                const { data, children } = node;
                return data.isStandalone === isStandaloneOnly
                    ? [...result, data, ...(children && children.map((child) => child.data))]
                    : result;
            }, []) ?? [],
        [nodes, isStandaloneOnly]
    );

    const filter = useCallback(
        (data: LegalEntityRequest) =>
            visibleColumns.reduce<Partial<LegalEntityRequest>>(
                (partialData, key) => ({
                    ...partialData,
                    [`${key}`]: data[key],
                }),
                {}
            ),
        [visibleColumns]
    );

    const filteredData = useMemo(
        () =>
            nodes?.reduce<Partial<LegalEntityRequest>[]>((result, node) => {
                const { visible, children, data } = node;
                if (!visible) {
                    return result;
                }
                return [
                    ...result,
                    filter(data),
                    ...(children && children.filter(({ visible }) => visible).map(({ data }) => filter(data))),
                ];
            }, []) ?? [],
        [filter, nodes]
    );

    const { save } = useExport();

    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const onClickOutside = useCallback(() => setIsOpen(false), []);

    useOnClickOutside({ ref: [dropdownRef, buttonRef], onClickOutside });

    function exportData(dataSet: Partial<LegalEntityRequest>[]) {
        const data = dataSet.map((data: Partial<LegalEntityRequest>) =>
            Object.keys(data).reduce<Partial<LegalEntityRequest>>((result, key) => {
                const entityKey = key as keyof LegalEntityRequest;
                return !isNil(legalEntityColumnNames[entityKey])
                    ? {
                          ...result,
                          [legalEntityColumnNames[entityKey]]: data[entityKey] ?? '',
                      }
                    : result;
            }, {})
        );
        save({ data });
        setIsOpen(false);
    }

    return (
        <Component>
            <Button ref={buttonRef} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <FontIcon
                    toolTip='Export to Excel'
                    iconName='ExcelDocument'
                    foregroundColor={accentColor}
                    fontSize={20}
                />
            </Button>
            {isOpen && (
                <Dropdown ref={dropdownRef}>
                    <DropdownItem onClick={() => exportData(allData)}>Export all data</DropdownItem>
                    <DropdownItem onClick={() => exportData(filteredData)}>Export filtered data</DropdownItem>
                </Dropdown>
            )}
        </Component>
    );
}
