import { StackPanel, TextBlock } from '@bxgrandcentral/controls';

import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { useCreationViewContext } from '../../context/creation-view-context';
import useViewport from 'hooks/use-viewport';

export default function TitleBar() {
    const {
        state: { sections },
    } = useCreationViewContext();
    const { isMediumScreen } = useViewport();

    const legalName = sections.Details?.legalName ?? 'Legal Entity Creation';
    const entityOId = sections.target?.entity.entityOId;

    return (
        <StackPanel
            itemGap={SPACING.XXS}
            orientation='horizontal'
            verticalAlignment='center'
            horizontalAlignment='center'>
            <TextBlock textAlignment='center' fontSize={isMediumScreen ? 16 : 18} textWrapping='wrap'>
                {legalName}
                {entityOId && (
                    <TextBlock styleName='captionStyle' styleOverrides={{ position: 'relative', top: '-1px' }}>
                        {` (${entityOId})`}
                    </TextBlock>
                )}
            </TextBlock>
        </StackPanel>
    );
}
