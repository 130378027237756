import { LegalEntityRequestPaths } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { useHistory } from 'react-router-dom';

export default function useBackToDashboard() {
    const historyApi = useHistory();

    const backToDashboard = () => {
        historyApi.push(LegalEntityRequestPaths.dashboard);
    };

    return backToDashboard;
}
