import { StateVariant } from 'Utilities/Layout';
import useColors from 'api/hooks/use-theme';
import { useMemo } from 'react';

type Props = {
    variant?: StateVariant;
};

export default function useDialogState({ variant }: Props) {
    const colors = useColors();
    const { systemRedColor, systemYellowColor, accentColor } = colors;

    const result = useMemo(() => {
        switch (variant) {
            case 'error': {
                return {
                    icon: 'ErrorBadge',
                    color: systemRedColor,
                };
            }
            case 'warning': {
                return {
                    icon: 'Warning',
                    color: systemYellowColor,
                };
            }
            default: {
                return {
                    icon: 'Info',
                    color: accentColor,
                };
            }
        }
    }, [accentColor, systemRedColor, systemYellowColor, variant]);

    return result;
}
