import { setResetLegalEntityCreationForm, useAppContext } from 'context/app-context';

import { Capabilities } from '../RequestStepsView';
import { DISSOLUTION_STATE } from 'models/LegalEntityRequest/Workflow';
import LegalEntityDissolutionApprovalSection from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionApproval/LegalEntityDissolutionApprovalSection';
import { LegalEntityDissolutionRequestData } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionDetails/model';
import React from 'react';
import { isEmpty } from 'lodash';
import { mapToSectionData as mapToLegalEntityDissolutionApproval } from '../mappers/LegalEntityDissolutionApproval';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useHasCapability from '../hooks/use-has-capability';

export default function DissolutionApprovalStepRenderer() {
    const { completeTask, updateDissolutionSection } = useApiServices();

    const { dispatch: appContextDispatch } = useAppContext();

    const {
        state: { isWorkItemsRefreshing, sections, legalEntityDissolutionParentWorkItem: workItem },
    } = useCreationViewContext();

    const data = mapToLegalEntityDissolutionApproval(sections);

    const hasCapability = useHasCapability();

    if (isEmpty(data)) {
        return null;
    }

    if (
        !hasCapability(Capabilities.approveDissolutionRequest) ||
        isWorkItemsRefreshing ||
        workItem?.workItemState !== DISSOLUTION_STATE.DISSOLUTION_APPROVAL
    ) {
        return <LegalEntityDissolutionApprovalSection data={data} mode='Readonly' workItem={workItem} />;
    }

    return (
        <LegalEntityDissolutionApprovalSection
            mode='Edit'
            data={data}
            workItem={workItem}
            onSave={(dataToSave) => {
                const toSave = {
                    ...sections.DissolutionRequest,
                    approval: dataToSave,
                } as LegalEntityDissolutionRequestData;
                return updateDissolutionSection(sections, 'DissolutionRequest', workItem?.workItemId, toSave, {
                    ignoreSaveProps: true,
                }).then((e) => e?.workflowId ?? 0);
            }}
            onCompleteTask={async (task, exitCode, rejectReason) => {
                if (workItem) {
                    if (workItem) {
                        await completeTask(workItem, task, exitCode, undefined, rejectReason, {
                            skipRequestNotesUpdate: true,
                        });
                        setResetLegalEntityCreationForm(appContextDispatch, true);
                    }
                }
            }}
        />
    );
}
