import { validateCompleted, validateRequiredField } from './utils';

import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityDissolutionBDG } from '../context/Provider';

export default function useDissolutionBDGValidation() {
    const {
        state: {
            data: {
                values: {
                    dissolutionDate,
                    finalConfirmations,
                    appSupport,
                    registeredAgent,
                    signatures,
                    stateJurisdiction,
                    updateDetails,
                },
            },
            version,
        },
        dispatch,
    } = useLegalEntityDissolutionBDG();

    useEffect(() => {
        updateValidation(dispatch, {
            finalConfirmations: validateCompleted(finalConfirmations),
            appSupport: validateCompleted(appSupport),
            registeredAgent: validateCompleted(registeredAgent),
            signatures: validateCompleted(signatures),
            stateJurisdiction: validateCompleted(stateJurisdiction),
            updateDetails: validateCompleted(updateDetails),
            dissolutionDate: validateRequiredField(dissolutionDate),
        });
    }, [
        dispatch,
        dissolutionDate,
        finalConfirmations,
        appSupport,
        registeredAgent,
        signatures,
        stateJurisdiction,
        updateDetails,
        version,
    ]);
}
