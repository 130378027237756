import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import { Button, StackPanel, TextBlock, ThemeManager, ToolBarButton } from '@bxgrandcentral/controls';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Expander, Text } from 'components';
import {
    LegalEntityOwnershipDetailProvider,
    useLegalEntityOwnerShipInformation,
} from 'modules/LegalEntityCreation/context/Provider';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import GridNoRowsOverlay from 'modules/RequestsDashboard/components/Grid/components/GridNoRowsOverlay';
import OwnershipDetail from './OwnershipDetail';
import { OwnershipDetailsModel } from '../../model';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SPACING } from 'Utilities/Layout';
import { delay } from 'Utilities/Delay';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { useCreationViewContext } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import useOwnershipDetailsColumnDefs from './use-ownership-details-column-def';
import useOwnershipDetailsValidation from 'modules/LegalEntityCreation/validation/use-ownership-detail-validation';

type Props = {
    mode: string;
    isStandaloneWorkflow: boolean;
    setFormHasChanges: (isOpen: boolean) => void;
};

export default function OwnershipDetailsGrid({ mode, isStandaloneWorkflow, setFormHasChanges }: Props) {
    const {
        state: {
            data: {
                values: { ownershipDetails = [], legalType },
                noPendingValues,
                validationErrors,
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityOwnerShipInformation();

    const {
        state: { loadingStates },
    } = useCreationViewContext();

    useOwnershipDetailsValidation({ legalType });

    const { accentColor } = useColors();

    const [selectedRowIndexes, setSelectedRowIndexes] = useState<string[]>([]);
    const [selectedRowData, setSelectedRowData] = useState<OwnershipDetailsModel | undefined>(undefined);
    const [selectedNoPendingData, setSelectedNoPendingData] = useState<OwnershipDetailsModel | undefined>(undefined);
    const [isNewFlow, setIsNewFlow] = useState(false);
    const [hideForm, setHideForm] = useState(false);

    const gridRef = useRef<AgGridReact>(null);

    const [gridApi, setGridApi] = useState<GridApi>();

    const defaultColumnDef = useMemo<ColDef>(
        () => ({
            sortable: false,
            filter: false,
            resizable: false,
            sortingOrder: ['asc', 'desc'],
            minWidth: 150,
        }),
        []
    );

    const theme = ThemeManager.activeTheme.name === 'Dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
        params.api.setRowData(ownershipDetails);
    };

    const selectRow = (rowIndex: string | number) => {
        clearRowSelection();
        setSelectedRowIndexes([String(rowIndex)]);
    };

    const onCellClicked = (params: any) => {
        if (params.column.colId === 'isDeleted') {
            params.event.stopPropagation();
        } else {
            selectRow(params.rowIndex);
            setSelectedRowData(params.data);

            if (noPendingValues?.ownershipDetails) {
                setSelectedNoPendingData(noPendingValues.ownershipDetails[params.rowIndex]);
            }
        }
    };

    const addNewItem = async (item: OwnershipDetailsModel) => {
        closeSelectedElement();

        const updated = [...ownershipDetails];
        updated.push(item);
        setValue('ownershipDetails', updated);

        setTimeout(() => {
            gridApi?.paginationGoToLastPage();
        }, 100);
    };

    const clearRowSelection = () => {
        selectedRowIndexes.forEach((id) => {
            gridApi?.getRowNode(id)?.setSelected(false);
        });
        setSelectedRowIndexes([]);

        setSelectedRowData(undefined);
        setSelectedNoPendingData(undefined);
    };

    const handleRowSelect = useCallback(() => {
        if (selectedRowIndexes.length) {
            selectedRowIndexes.forEach((id) => {
                gridApi?.getRowNode(id)?.setSelected(true);
            });
        }
    }, [selectedRowIndexes, gridApi]);

    const closeSelectedElement = () => {
        clearRowSelection();
        setFormHasChanges(false);
        setIsNewFlow(false);
    };

    const handleAddButton = () => {
        closeSelectedElement();
        setTimeout(() => {
            setIsNewFlow(true);
        }, 50);
    };

    const handleSelectedRow = useCallback(async () => {
        if (!selectedRowData) {
            return;
        }

        setHideForm(true);

        await delay(100);
        setHideForm(false);
    }, [selectedRowData]);

    useEffect(() => {
        setIsNewFlow(false);
        handleSelectedRow();
    }, [selectedRowData, handleSelectedRow]);

    useEffect(() => {
        setIsNewFlow(false);
        if (selectedRowIndexes.length) {
            handleRowSelect();
        }
    }, [selectedRowIndexes, handleRowSelect]);

    useEffect(() => {
        gridApi?.setRowData(ownershipDetails);
        handleRowSelect();
    }, [ownershipDetails]);

    useEffect(() => {
        if (!isEditable && isNewFlow) {
            setIsNewFlow(false);
        }
    }, [isEditable]);

    const selectedRowId = useMemo(() => parseInt(selectedRowIndexes[0]), [selectedRowIndexes]);

    const { ownershipDetailsColumnDefs } = useOwnershipDetailsColumnDefs({
        clearRowSelection,
        mode,
        isStandaloneWorkflow,
    });

    const isFormVisible = !hideForm && isEmpty(loadingStates) && (selectedRowData || isNewFlow);

    return (
        <Wrapper>
            {isEditable && !isNewFlow && (
                <AddButtonContainer>
                    <Button content='Add New Ownership Detail' isEnabled={isEditable} onClick={handleAddButton} />
                </AddButtonContainer>
            )}
            <Expander
                header='Ownership Details'
                requestStep={RequestStepsView.Ownership_Information}
                padding={0}
                content={
                    <>
                        <Layout>
                            <GridContainer className={theme} id='ownership-details-table'>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={ownershipDetailsColumnDefs}
                                    onGridReady={onGridReady}
                                    rowClass='custom-row pointer-cursor'
                                    noRowsOverlayComponentParams={{
                                        noRowsMessage: 'There are no Ownership Details.',
                                        isError: false,
                                    }}
                                    noRowsOverlayComponent={GridNoRowsOverlay}
                                    pagination={true}
                                    paginationPageSize={10}
                                    defaultColDef={defaultColumnDef}
                                    onCellClicked={onCellClicked}
                                    suppressCellFocus={true}
                                    rowHeight={40}
                                    headerHeight={35}
                                />
                            </GridContainer>
                            {validationErrors.ownershipDetails && (
                                <StackPanel
                                    orientation='horizontal'
                                    styleOverrides={{ justifyContent: 'center' }}
                                    padding={`${SPACING.MD}px 0`}>
                                    <Text variant='errorBold' horizontalAlignment='center' textAlignment='center'>
                                        {validationErrors.ownershipDetails}
                                    </Text>
                                </StackPanel>
                            )}
                            {isFormVisible ? (
                                <FormContainer>
                                    <Divider />
                                    <ToolBarButton
                                        icon='Cancel'
                                        toolTip='Unsaved changes will not be added to the table'
                                        fontSize={18}
                                        foregroundColor={accentColor}
                                        horizontalAlignment='right'
                                        onClick={closeSelectedElement}
                                    />
                                    <LegalEntityOwnershipDetailProvider
                                        storedValues={selectedRowData}
                                        noPendingValues={selectedNoPendingData}
                                        mode={mode}>
                                        <OwnershipDetail
                                            index={selectedRowId}
                                            addNewItem={addNewItem}
                                            closeSelectedElement={closeSelectedElement}
                                            isNewFlow={isNewFlow}
                                            setFormHasChanges={setFormHasChanges}
                                        />
                                    </LegalEntityOwnershipDetailProvider>
                                </FormContainer>
                            ) : (
                                !!ownershipDetails.length && (
                                    <TextBlock
                                        text={
                                            mode === 'Readonly' || mode === 'Approval'
                                                ? 'Click on a row to view detailed ownership information'
                                                : 'Click on a row to edit existing ownership information'
                                        }
                                        styleName='captionStyle'
                                        horizontalAlignment='center'
                                        verticalAlignment='center'
                                        padding={`${SPACING.MD}px ${SPACING.XS}px`}
                                    />
                                )
                            )}
                        </Layout>
                    </>
                }
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
`;

const AddButtonContainer = styled.div`
    position: absolute;
    right: 30px;
    top: 28px;
`;

const Layout = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 0 ${SPACING.MD}px;
    min-height: 305px;
    position: relative;
`;

const GridContainer = styled.div`
    min-height: 305px;
`;

const FormContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    padding: ${SPACING.MD}px 0 0 0;
`;

const Divider = styled.div`
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
    margin: 0 0 ${SPACING.XS}px 0;
`;
