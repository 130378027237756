import { ColumnOptionChanged, isSingleSortOptionChange } from '../../hooks/grid-app-settings-hook';

import { Column } from 'devextreme-react/data-grid';
import { ColumnSettingType } from '../../models/shared/devExpress/ColumnSettingType';
import { IStorageService } from '@bxgrandcentral/shell';
import { assertNever } from '../../Utilities/ReflectionUtil';

type GridSetting = {
    id: string;
    visible: boolean | undefined;
    visibleIndex: number | undefined;
    sortOrder: string | undefined;
};

const columnSettingsTypeValues = new Set(Object.values<string>(ColumnSettingType));

export default class TreeListDevExpressHelper {
    private static GridSettingsKey: string = 'GridSettings';
    public static mergeFromGridSettings(gridColumnConfig: Column[], gridSettings: GridSetting[]): Column[] {
        return gridColumnConfig.map((column) => {
            return new Column({ ...column.props, ...gridSettings.find((e) => e.id === column.props.name) });
        });
    }

    public static async getGridSettings(storageService: IStorageService, setting: string) {
        const settingsValue = await storageService.getValue<GridSetting[] | undefined>(
            setting,
            TreeListDevExpressHelper.GridSettingsKey
        );
        if (!settingsValue) {
            console.log(
                `did not find grid settings using ${{ feature: TreeListDevExpressHelper.GridSettingsKey, setting }}`
            );
            return undefined;
        }
        return settingsValue;
    }

    public static handleGridSettingsChange(
        storageService: IStorageService,
        gridSettingChanged: ColumnOptionChanged,
        currentColumnsConfig: Column[],
        feature: string
    ) {
        const newSettings = this.getSettings(gridSettingChanged, currentColumnsConfig);
        if (newSettings) {
            this.saveGridSettings(storageService, newSettings, feature);
            return this.mergeFromGridSettings(currentColumnsConfig, newSettings);
        }
    }

    private static getSettings(
        gridSettingChanged: ColumnOptionChanged,
        currentColumnsConfig: Column[]
    ): GridSetting[] | undefined {
        if (columnSettingsTypeValues.has(gridSettingChanged.type)) {
            const newSettings = currentColumnsConfig.map((column) => {
                return {
                    id: column.props.name as string,
                    visible: column.props.visible as boolean | undefined,
                    visibleIndex: column.props.visibleIndex as number | undefined,
                    sortOrder: column.props.sortOrder as string | undefined,
                };
            });
            const columnDef = newSettings[gridSettingChanged.index];
            const columnSettingType = gridSettingChanged.type as ColumnSettingType;
            switch (columnSettingType) {
                case ColumnSettingType.Visible:
                    columnDef.visible = gridSettingChanged.value;
                    break;
                case ColumnSettingType.VisibleIndex:
                    newSettings.forEach((e) => {
                        if (gridSettingChanged.value > gridSettingChanged.previousValue) {
                            if (
                                (e.visibleIndex || e.visibleIndex === 0) &&
                                e.visibleIndex > gridSettingChanged.previousValue &&
                                e.visibleIndex <= gridSettingChanged.value
                            ) {
                                e.visibleIndex--;
                            }
                        } else if (
                            (e.visibleIndex || e.visibleIndex === 0) &&
                            e.visibleIndex < gridSettingChanged.previousValue &&
                            e.visibleIndex >= gridSettingChanged.value
                        ) {
                            e.visibleIndex++;
                        }
                    });
                    columnDef.visibleIndex = gridSettingChanged.value;
                    break;
                case ColumnSettingType.SortOrder:
                    columnDef.sortOrder = gridSettingChanged.value;
                    if (isSingleSortOptionChange(gridSettingChanged)) {
                        newSettings.forEach((col, idx) => {
                            if (idx !== gridSettingChanged.index) {
                                delete col.sortOrder;
                            }
                        });
                    }
                    break;
                default:
                    assertNever(columnSettingType, `'${columnSettingType}' is unhandled.`);
            }
            return newSettings;
        }
    }

    private static saveGridSettings(storageService: IStorageService, settings: GridSetting[], feature: string) {
        const settingsValue = JSON.stringify(settings);
        storageService.setValue(feature, settingsValue, TreeListDevExpressHelper.GridSettingsKey);
    }
}
