import LegalEntityOwnershipInformation from './LegalEntityOwnershipInformation';
import { LegalEntityOwnershipInformationProps } from './model';
import { LegalEntityOwnershipInformationProvider } from '../context/Provider';
import React from 'react';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import useOwnershipInformationAutopopulateData from './hooks/use-ownership-information-autopopulate-data';

export default function LegalEntityOwnershipInformationSection(props: LegalEntityOwnershipInformationProps) {
    const { calculateDefaultValues } = useOwnershipInformationAutopopulateData();

    return (
        <LegalEntityOwnershipInformationProvider
            storedValues={props.data}
            noPendingValues={props.noPendingData}
            mode={props.mode}
            calculateDefaultValues={calculateDefaultValues}
            requestStep={RequestStepsView.Ownership_Information}>
            <LegalEntityOwnershipInformation {...props} />
        </LegalEntityOwnershipInformationProvider>
    );
}
