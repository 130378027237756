import { TextBlock, WaitingIndicator } from '@bxgrandcentral/controls';

import React from 'react';
import { isNil } from 'lodash';

type Props = {
    canCreate?: boolean;
};

export default function NeedPermission({ canCreate }: Props) {
    if (!isNil(canCreate)) {
        return (
            <TextBlock
                text='You do not have permission do a request. If you believe this is incorrect, contact the System Administrator to review your permissions.'
                styleName='bodyTextStyle'
                textWrapping='wrap'
                isVisible
                verticalAlignment='center'
                horizontalAlignment='center'
            />
        );
    }

    return <WaitingIndicator id='spinner' isModalToShell={true} isVisible={true} />;
}
