import { Grid, SelectorField, StackPanel, TextBlock } from '@bxgrandcentral/controls';
import React, { useCallback, useState } from 'react';
import useThirdPartyUsers, { ThirdPartyUser } from 'hooks/use-third-party-users';

import { FormControl } from '../controls.styled';
import { SPACING } from 'Utilities/Layout';
import ValidationMessage from '../ValidationMessage/ValidationMessage';
import { isEqual } from 'lodash';
import useColors from 'api/hooks/use-theme';

export default function ThirdPartyUserSelect({ validationError, ...props }: any) {
    const { data, isLoading } = useThirdPartyUsers();
    const [filteredData, setFilteredData] = useState<ThirdPartyUser[]>();
    const { accentColor } = useColors();

    const itemTemplate = useCallback(
        ({ firstName, lastName, userOId }: ThirdPartyUser) => (
            <StackPanel>
                <TextBlock text={`${firstName} ${lastName}`} styleName='fieldValueStyle' />
                <TextBlock styleName='captionStyle' text={`ID: ${userOId}`} />
            </StackPanel>
        ),
        []
    );

    const searchFilter = useCallback(
        (value = '') => {
            if (!value || !data || value.length < 3) {
                return [];
            }
            const lowCaseValue = value.toLowerCase().replace(/  +/g, ' ').trim();
            setFilteredData(
                data.filter(
                    ({ firstName, lastName, entityOId }) =>
                        `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(lowCaseValue) ||
                        entityOId.toString().includes(lowCaseValue) ||
                        (props.value && props.value.toString().includes(lowCaseValue))
                )
            );
            return [];
        },
        [data, props.value]
    );

    return isLoading ? (
        <TextBlock text='Fetching Third Party Users' />
    ) : (
        <FormControl height={80} className='form'>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <TextBlock
                    styleName='fieldLabelStyle'
                    text={props.label}
                    toolTip={props.labelToolTip}
                    margin={`0 0 ${SPACING.XXS}px 0`}
                />
                {props.isReadOnly && props.isPendingChange && (
                    <TextBlock
                        styleName='fieldLabelStyle'
                        fontSize='16px'
                        text='PENDING'
                        foregroundColor={accentColor}
                        textAlignment='right'
                    />
                )}
            </Grid>
            <SelectorField
                itemTemplate={itemTemplate}
                itemsSource={filteredData}
                showSearchBox
                searchFilter={searchFilter}
                canResetValue
                maxDropDownHeight={275}
                value={props.value && data ? [data.find((item) => isEqual(item.entityOId, props.value))] : []}
                onValueChanged={(selectedValues: ThirdPartyUser[]) => {
                    const [value] = selectedValues;
                    props.onValueChanged(value?.entityOId ?? null);
                }}
                isEnabled={props.isEnabled}
                isReadOnly={props.isReadOnly}
                isRequired={props.isRequired}
                validationError={props.validationError}
            />
            {props.isEditable && <ValidationMessage message={validationError} />}
        </FormControl>
    );
}
