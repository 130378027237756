import { ColorManager, ThemeManager, WaitingIndicator } from '@bxgrandcentral/controls';
import React, { Component, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    message?: string;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error) {
        this.setState({ message: error.message });
    }

    public render() {
        const {
            activeTheme: {
                colors: { systemRedColor },
            },
        } = ThemeManager;
        if (this.state.hasError) {
            return (
                <WaitingIndicator
                    isModalToShell
                    isVisible
                    backgroundColor={ColorManager.fade(systemRedColor, 0.65)}
                    foregroundColor={systemRedColor}
                    iconName={'AlertSolid'}
                    message={this.state.message || 'Something went wrong'}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
