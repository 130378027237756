import React, { FC } from 'react';

import { SPACING } from 'Utilities/Layout';
import styled from 'styled-components';

const Form: FC = ({ children }) => <Component>{children}</Component>;

const Component = styled.div`
    display: grid;
    grid-gap: ${SPACING.XXL}px;
    min-width: 400px;
    max-width: 1100px;
    margin: 0 auto;
    padding: ${SPACING.XL}px;
    padding-bottom: 100px;
`;

export default Form;
