import { FontIcon, Popup } from '@bxgrandcentral/controls';
import React, { useRef, useState } from 'react';

import { CreationViewContextProvider } from 'modules/LegalEntityCreation/LegalEntityCreationView/context/creation-view-context';
import { ICellRendererParams } from 'ag-grid-community';
import PageOptionsPopup from './PageOptionsPopup';
import { SPACING } from 'Utilities/Layout';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

export default function EditCellRenderer({ value, data }: ICellRendererParams) {
    const { entityOId } = data;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const iconRef = useRef<HTMLDivElement>(null);

    const colors = useColors();

    const onClick = (e: React.MouseEvent) => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <ButtonContainer>
            <FontIcon
                ref={iconRef}
                iconName='Edit'
                onClick={(e) => onClick(e)}
                foregroundColor={colors.foregroundColor}
                fontSize={20}
            />
            <Popup
                isOpen={isPopupOpen}
                placementTarget={iconRef}
                onPropertyChanged={(propertyName, newValue) => {
                    if (propertyName === 'isOpen') {
                        setIsPopupOpen(newValue);
                    }
                }}>
                <CreationViewContextProvider>
                    <PageOptionsPopup entityOId={entityOId} />
                </CreationViewContextProvider>
            </Popup>
        </ButtonContainer>
    );
}

const ButtonContainer = styled.div`
    padding: ${SPACING.XXS}px;
    &:hover {
        cursor: pointer;
    }
`;
