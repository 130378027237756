import { FontIconNames, StackPanel, TextBlock, ToolBarButton } from '@bxgrandcentral/controls';
import React, { useState } from 'react';

import { SPACING } from 'Utilities/Layout';
import { supportedExtensions } from 'models/shared/refData/NewDocumentType';
import useColors from 'api/hooks/use-theme';

type Props = { filesMoreThan100Mb?: File[]; filesWithInvalidExtensions?: File[] };

export default function FileUploadErrorMessage({ filesMoreThan100Mb = [], filesWithInvalidExtensions = [] }: Props) {
    const [isOpenExtensions, setIsOpenExtensions] = useState(false);
    const { foregroundColor, secondaryForegroundColor } = useColors();
    return (
        <StackPanel itemGap={SPACING.LG} margin={`${SPACING.MD}px 0 0`}>
            {filesWithInvalidExtensions.length > 0 && (
                <StackPanel itemGap={SPACING.SM}>
                    <TextBlock text='The following files have an unsupported file extension' styleName='captionStyle' />
                    <StackPanel itemGap={SPACING.XXS}>
                        {filesWithInvalidExtensions.map(({ name }) => (
                            <TextBlock text={name} />
                        ))}
                    </StackPanel>
                </StackPanel>
            )}
            {filesMoreThan100Mb.length > 0 && (
                <StackPanel itemGap={SPACING.SM}>
                    <TextBlock
                        text='The following files exceed the maximum file size of 100 MB'
                        styleName='captionStyle'
                    />
                    <StackPanel itemGap={SPACING.XXS}>
                        {filesMoreThan100Mb.map(({ name }) => (
                            <TextBlock text={name} foregroundColor={foregroundColor} />
                        ))}
                    </StackPanel>
                </StackPanel>
            )}
            {filesWithInvalidExtensions.length > 0 && (
                <StackPanel>
                    <StackPanel orientation='horizontal' styleOverrides={{ alignItems: 'center' }}>
                        <TextBlock
                            text={`${isOpenExtensions ? 'Hide' : 'Show'} supported extensions`}
                            styleName='captionStyle'
                        />
                        <ToolBarButton
                            fontSize={10}
                            foregroundColor={secondaryForegroundColor}
                            icon={isOpenExtensions ? FontIconNames.ChevronUp : FontIconNames.ChevronDown}
                            onClick={() => setIsOpenExtensions(!isOpenExtensions)}
                        />
                    </StackPanel>
                    {isOpenExtensions && (
                        <TextBlock
                            text={supportedExtensions.join(', ')}
                            fontSize={11}
                            styleName='captionStyle'
                            textAlignment='justify'
                            textWrapping='wrap'
                        />
                    )}
                </StackPanel>
            )}
        </StackPanel>
    );
}
