import { LegalEntityRequestPaths } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { showHideLinksByUrl } from 'Utilities/domManipulation';
import useCanCreateRequest from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-can-create-request';
import { useEffect } from 'react';

// Workarounds for customizing GC left navbar
export default function useControlLeftNavBar() {
    const canCreateRequest = useCanCreateRequest();

    useEffect(() => {
        showHideLinksByUrl(LegalEntityRequestPaths.newLegalEntityRequest, canCreateRequest);
    }, [canCreateRequest]);
}
