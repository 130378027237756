import { LegalEntityOwnershipInformationData } from 'modules/LegalEntityCreation/LegalEntityOwnershipInformation/model';
import LegalEntityOwnershipInformationSection from 'modules/LegalEntityCreation/LegalEntityOwnershipInformation/LegalEntityOwnershipInformationSection';
import React from 'react';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';
import useOwnershipWithIsPending from 'modules/LegalEntityCreation/LegalEntityOwnershipInformation/hooks/use-ownership-with-is-pending';

export default function OwnershipInformationStepRenderer() {
    const { saveSection, completeTask, createStandalone, reloadEntityAndWorkItems } = useApiServices();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            ownershipWorkItem: workItem,
            selectedPanel,
            noPendingSections,
        },
    } = useCreationViewContext();

    const data = sections.OwnershipInformation;
    const noPendingData = noPendingSections.OwnershipInformation;

    const dataWithIsPending = useOwnershipWithIsPending({ data, noPendingData });

    if (isWorkItemsRefreshing || (!selectedPanel?.hasActions && !editWorkFlow)) {
        return <LegalEntityOwnershipInformationSection mode='Readonly' workItem={workItem} data={data} />;
    }

    if (!selectedPanel?.hasActions && editWorkFlow) {
        return (
            <LegalEntityOwnershipInformationSection
                mode='Create'
                data={dataWithIsPending}
                noPendingData={noPendingData}
                onSave={(dataToSave: Partial<LegalEntityOwnershipInformationData>) =>
                    saveSection(sections, 'OwnershipInformation', dataToSave).then((e) => e?.entityOId ?? 0)
                }
                onCreateWorkItem={async (entityOId: number, type: string) => {
                    await createStandalone(entityOId, type);
                }}
                workItem={workItem}
            />
        );
    }

    switch (workItem?.workItemState) {
        case 'OwnershipRequest':
            return (
                <LegalEntityOwnershipInformationSection
                    mode='Edit'
                    data={dataWithIsPending}
                    noPendingData={noPendingData}
                    workItem={workItem}
                    onSave={async (dataToSave) => {
                        return saveSection(sections, 'OwnershipInformation', dataToSave).then((e) => e.entityOId ?? 0);
                    }}
                    onCompleteTask={async (task, exitCode) => {
                        await completeTask(workItem, task, exitCode, '');
                        if (exitCode?.toLowerCase() === 'withdraw' || exitCode?.toLowerCase() === 'submit') {
                            await reloadEntityAndWorkItems(2000);
                        }
                    }}
                />
            );
        case 'OwnershipApproval':
            return (
                <LegalEntityOwnershipInformationSection
                    mode='Approval'
                    data={dataWithIsPending}
                    noPendingData={noPendingData}
                    workItem={workItem}
                    onCompleteTask={async (task, exitCode, rejectReason) => {
                        const section = exitCode === 'Approve' ? '' : undefined;
                        await completeTask(workItem, task, exitCode, section, rejectReason);
                        await reloadEntityAndWorkItems(2000);
                    }}
                />
            );
        default:
            return (
                <LegalEntityOwnershipInformationSection
                    mode='Readonly'
                    noPendingData={noPendingData}
                    workItem={workItem}
                />
            );
    }
}
