import { Button, StackPanel } from '@bxgrandcentral/controls';
import { setIsLoading, setShouldReload } from 'modules/LegalEntityCreation/context/actions';

import { LegalEntityFinanceDetailsProps } from '../../model';
import React from 'react';
import { SubmitButton } from 'components';
import { TaskActions } from 'modules/LegalEntityCreation/Shared/TaskActions';
import { isOpen } from 'models/Workflow';
import useApiServices from 'modules/LegalEntityCreation/LegalEntityCreationView/hooks/use-api-services';
import { useLegalEntityFinanceDetails } from 'modules/LegalEntityCreation/context/Provider';
import { useRequestContext } from 'context/request-context';

export default function ActionPanel(props: LegalEntityFinanceDetailsProps) {
    const {
        state: { canSave, canSubmit },
        getChanges,
        dispatch,
    } = useLegalEntityFinanceDetails();

    const { isRequestNotesChanged } = useRequestContext();
    const { updateWorkItemNotes } = useApiServices();

    if (props.mode === 'Create') {
        return (
            <SubmitButton
                label='Save'
                isEnabled={canSave}
                onClick={() => {
                    setIsLoading(dispatch, true);
                    props.onSave(getChanges()).then((entityOId: number) => {
                        props.onCreateWorkItem(entityOId, 'FinanceChildProcess');
                        setShouldReload(dispatch, true);
                    });
                }}
            />
        );
    }

    if (props.mode === 'Edit') {
        const task = props.workItem?.tasks?.find(
            ({ taskType, status }) => taskType === 'RequestTaskType' && isOpen(status)
        );

        return (
            <StackPanel
                orientation='horizontal'
                horizontalAlignment='right'
                styleOverrides={{ justifyContent: 'space-between' }}>
                <Button
                    content='Save'
                    styleName='textOnlyButtonStyle'
                    isEnabled={canSave || isRequestNotesChanged(props.workItem)}
                    onClick={async () => {
                        setIsLoading(dispatch, true);
                        if (isRequestNotesChanged(props.workItem)) {
                            await updateWorkItemNotes(props.workItem);
                        }
                        await props.onSave(getChanges());
                        setShouldReload(dispatch, true);
                    }}
                />
                {task && (
                    <TaskActions
                        task={task}
                        isSubmitEnabled={canSubmit}
                        onCompleteTask={async (task, exitCode) => {
                            setIsLoading(dispatch, true);
                            await props.onSave(getChanges());
                            props.onCompleteTask(task, exitCode as string);
                            setShouldReload(dispatch, true);
                        }}
                    />
                )}
            </StackPanel>
        );
    }

    if (props.mode === 'Approval') {
        const task = props.workItem.tasks?.find(
            ({ taskType, status }) => taskType === 'UpdateRequestTaskType' && isOpen(status)
        );

        return task ? (
            <TaskActions
                onCompleteTask={(task, exitCode, rejectReason) => {
                    props.onCompleteTask(task, exitCode as string, rejectReason);
                    setShouldReload(dispatch, true);
                }}
                task={task}
            />
        ) : null;
    }
    return null;
}
