import { Border, HORIZONTAL_FORM_PADDING, SPACING } from '../../../../../../Utilities/Layout';
import { Button, StackPanel, TextBlock } from '@bxgrandcentral/controls';
import React, { Fragment, useMemo } from 'react';

import { Expander } from 'components';
import Registration from './Registration';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { isEmpty } from 'lodash';
import useColors from 'api/hooks/use-theme';
import useForeignRegistrationUpdateValidation from '../../../../validation/use-foreign-registration-update-validation';
import { useLegalEntityRegistrationUpdate } from 'modules/LegalEntityCreation/context/Provider';

type Props = {
    mode?: string;
};

export default function Registrations({ mode }: Props) {
    const {
        state: {
            data: {
                values: { RegistrationData = [] },
                noPendingValues,
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityRegistrationUpdate();

    function addRegistration() {
        setValue('RegistrationData', [...RegistrationData.concat({ isDeleted: false })]);
    }

    const { validations } = useForeignRegistrationUpdateValidation();

    const { normalControlBorderColor } = useColors();

    const registrations = useMemo(() => {
        // workaround to hide the stuck temporary items after approval
        // (it takes seconds to getting removed them on the API side, when the permanent ones are already created)
        if (mode === 'Readonly') {
            return RegistrationData.filter(({ taxRegistrationId }) => taxRegistrationId && taxRegistrationId > 0);
        }

        return RegistrationData;
    }, [mode, RegistrationData]);

    const existingKeys = registrations.map(({ key }) => key);
    const removedRegistrations =
        noPendingValues?.RegistrationData?.map(({ key }) => key).filter((key) => !existingKeys.includes(key)) || [];

    return (
        <Expander
            header='Foreign Registration Details'
            requestStep={RequestStepsView.Foreign_Registration}
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL}>
                    {mode === 'Approval' && !!removedRegistrations.length && (
                        <TextBlock styleName='heading4Style' padding={HORIZONTAL_FORM_PADDING}>
                            {`REMOVED ${removedRegistrations.length} FOREIGN REGISTRATION${
                                removedRegistrations.length > 1 ? '(S)' : ''
                            }`}
                        </TextBlock>
                    )}
                    {isEmpty(registrations) && !removedRegistrations.length ? (
                        <TextBlock styleName='captionStyle' padding={HORIZONTAL_FORM_PADDING}>
                            No Foreign Registration added
                        </TextBlock>
                    ) : (
                        registrations.map((_, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Border color={normalControlBorderColor} />}
                                <Registration index={index} validation={validations[index]} mode={mode} />
                            </Fragment>
                        ))
                    )}
                </StackPanel>
            }
            {...(isEditable && {
                footer: <Button content='Add Foreign Registration' isEnabled={isEditable} onClick={addRegistration} />,
            })}
        />
    );
}
