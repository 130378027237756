import {
    LegalEntityRequestPaths,
    Page,
    getSectionPathName,
    getSectionPathNameForSearchedItem,
} from '../modules/RequestsDashboard/LegalEntityRequestPaths';
import { useCallback, useState } from 'react';

import { IWorkflowService } from '../services/interfaces';
import { LegalEntityRequest } from '../models';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { constants } from '../constants/env-config';
import { useHistory } from 'react-router-dom';

export default function useNavigation() {
    const history = useHistory();
    const [isNavigationLoading, setIsNavigationLoading] = useState(false);
    const workflowService = ServiceLocator.container.resolve(IWorkflowService);
    const { editLegalEntityRequestPattern, editLegalEntityRequest } = LegalEntityRequestPaths;

    const getExternalUrlForMdmWorkflowItem = useCallback(
        async (entityOId: number, entityWorkItemState: string, entityWorkItemType: string) => {
            const mdmWorkflowItemStates = [
                'EIN In Progress',
                'EIN Setup Complete',
                'Bank Account In Progress',
                'Bank Account Setup Complete',
            ];

            if (!entityOId || !mdmWorkflowItemStates.includes(entityWorkItemState)) {
                return;
            }

            setIsNavigationLoading(true);
            const { childItems } = await workflowService.GetWorkItemForEntity(entityOId);
            setIsNavigationLoading(false);

            const childWithMatchingTypeAndState = childItems.find(
                ({ workflowType, workItemState }) =>
                    workflowType === entityWorkItemType && workItemState === entityWorkItemState
            );

            const workflowId = childWithMatchingTypeAndState?.customProperties?.workflowId;

            return workflowId ? `${constants.MDM_WORKFLOWS_URL}${workflowId}` : '';
        },
        [workflowService]
    );

    const linkToRequest = useCallback(
        async (request?: LegalEntityRequest) => {
            if (!request) {
                return;
            }
            const { entityOId, workItemState, workItemType } = request;

            const externalUrlToMdm = await getExternalUrlForMdmWorkflowItem(entityOId, workItemState, workItemType);

            if (externalUrlToMdm) {
                window.open(externalUrlToMdm, '_blank');
                return;
            }

            return editLegalEntityRequest(
                `${editLegalEntityRequestPattern}/${getSectionPathName(workItemState, workItemType).replace(
                    /\s/g,
                    ''
                )}`,
                { legalEntityId: entityOId.toString() }
            );
        },
        [getExternalUrlForMdmWorkflowItem, editLegalEntityRequest, editLegalEntityRequestPattern]
    );

    const navigateTo = useCallback(
        (page: Page) => {
            history.push(page);
        },
        [history]
    );

    const navigateToRequest = useCallback(
        async (request?: LegalEntityRequest) => {
            const link = await linkToRequest(request);
            if (!link) {
                return;
            }
            history.push(link);
        },
        [history, linkToRequest]
    );

    const navigateToSearchedRequest = useCallback(
        (entityOId: number, hasLegalRole: boolean, hasTaxRole: boolean, hasFinanceRole: boolean) => {
            const link = editLegalEntityRequest(
                `${editLegalEntityRequestPattern}/${getSectionPathNameForSearchedItem(
                    hasLegalRole,
                    hasTaxRole,
                    hasFinanceRole
                ).replace(/\s/g, '')}`,
                { legalEntityId: entityOId.toString() }
            );

            if (!link) {
                return;
            }
            history.push(link);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history]
    );

    return { navigateTo, navigateToRequest, navigateToSearchedRequest, isNavigationLoading };
}
