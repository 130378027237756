import { FontIcon, TextBlock, ThemeManager } from '@bxgrandcentral/controls';

import { FONT_FAMILIES } from '../../../Utilities/Layout';
import React from 'react';
import styled from 'styled-components';

type Props = {
    message?: string;
    variant?: 'warning' | 'error';
};
export default function ValidationMessage({ message, variant = 'error' }: Props) {
    const {
        activeTheme: {
            colors: { systemRedColor, systemYellowColor },
        },
    } = ThemeManager;

    const foregroundColor = variant === 'error' ? systemRedColor : systemYellowColor;

    return message ? (
        <ValidationError>
            <FontIcon fontSize={14} foregroundColor={foregroundColor} iconName='Warning' minWidth={15} />
            <TextBlock
                fontSize={13}
                foregroundColor={foregroundColor}
                fontFamily={FONT_FAMILIES.ERROR}
                text={message}
            />
        </ValidationError>
    ) : null;
}

const ValidationError = styled.div`
    display: flex;
    margin-top: 4px;

    span:first-child {
        margin-right: 5px;
    }
`;
