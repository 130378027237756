import { DocumentType } from 'models/shared/refData/NewDocumentType';
import { DocumentUpdate } from 'modules/LegalEntityCreation/context/model';
import { SectionName } from 'modules/LegalEntityCreation/models';

export const DOCUMENT_DATA_IDS = {
    SECTION_NAME: 14999,
    EFFECTIVE_DATE: 4972,
};

export type DocumentElement = {
    documentElementValue: string;
    dataItemId: string;
};

export type PendingDocument = {
    id: number;
    entityOid: number;
    documentTypeId: number;
    effectiveDate?: Date;
    documentData: any;
    fileName: string;
    sectionName: string;
    taxRegistration?: number;
};

export type StoredDocument = {
    documentOId: {
        value: number;
    };
    originalFileName: string;
    entity: {
        entityOId: number;
    };
    documentElements: DocumentElement[];
    coreDocumentTypeId: number;
    taxRegistrationIds: number[];
    effectiveDateString: Date;
    createdDateUTC: Date;
};

export type StoredDocumentModel = {
    document: StoredDocument;
    updates: DocumentUpdate;
};

export type EffectiveDateProp = 'required' | 'optional' | 'none';

export type FileUploadProps = {
    entityId?: number;
    sectionName: SectionName;
    treeViewSectionNames?: SectionName[];
    treeViewDocumentTypes?: DocumentType[];
    documentTypes?: DocumentType[];
    stampedDocumentTypes?: DocumentType[];
    isRequired?: boolean;
    documentTypeError?: string;
    taxRegistrationId?: number;
    isDisabled?: boolean;
    onlyOneDocumentPerTypeAllowed?: boolean;
    effectiveDate?: EffectiveDateProp;
    isStampedDocumentUpload?: boolean;
    documentApproveDate?: Date;
    showStoredDocuments?: boolean;
    showStampedDocuments?: boolean;
    isDeleteDisabledWhen?: (document: StoredDocumentModel) => boolean;
    context?: any;
    keepDocumentTypes?: boolean;
    showDocumentsInReadOnlyTreeView?: boolean;
    hideEffectiveDate?: boolean;
    standaloneCreateMode?: boolean;
};

export type FileListProps = {
    entityOId: number;
    isEditable?: boolean;
    header?: string;
    documents: StoredDocumentModel[];
    useEffectiveDatePlaceholder?: boolean;
    noDocumentLabel?: string;
    isFetching?: boolean;
    sectionName: SectionName;
    errorMessage?: string;
    isStampedDocumentUpload?: boolean;
    onDocumentStateChanged: (id: number, update: Partial<DocumentUpdate>) => void;
    documentApproveDate?: Date;
    hideEffectiveDate?: boolean;
    dispatch?: any;
};

export const SECTION_NAME_SEPARATOR = '#';
