import { IWorkflowService, workItemUpdate } from '../interfaces';
import { utilCompleteTaskServiceCall, utilUpdateTaskServiceCall } from 'services/utils';

import ApiService from '../ApiService';
import { GenericWorkflow } from 'models/LegalEntityRequest/Workflow/GenericWorkflow';
import { LogError } from '../../models/shared/LogError';
import { Task } from '../../models/Workflow';
import { WorkItem } from '../../models/Workflow/WorkItem';
import { constants } from '../../constants/env-config';

export class WorkflowService implements IWorkflowService {
    private logInfo: LogError = {
        service: 'Workflow Request Service',
        module: 'Global',
    };

    private entityMasterBaseUrl = constants.ENTITY_MASTER_API;

    public CreateNewEntityWorkItem(entityOId: number) {
        const logInfo = Object.assign({ method: this.CreateNewEntityWorkItem.name }, this.logInfo);
        return ApiService.Post(`${this.entityMasterBaseUrl}workitem/for-entity/${entityOId}`, logInfo);
    }

    public CreateNewEntityWorkItemStandalone(entityOId: number, workItemType: string): Promise<GenericWorkflow> {
        const logInfo = Object.assign({ method: this.CreateNewEntityWorkItem.name }, this.logInfo);
        return ApiService.Post(`${this.entityMasterBaseUrl}workitem/for-entity/${entityOId}/${workItemType}`, logInfo);
    }

    public async GetWorkItemForEntity(entityOId: number) {
        const logInfo = Object.assign({ method: this.GetWorkItemForEntity.name }, this.logInfo);
        const url = `${this.entityMasterBaseUrl}workitem/for-entity/${entityOId}`;

        const response = (await ApiService.Get(url, logInfo)) as
            | ReturnType<IWorkflowService['GetWorkItemForEntity']>
            | undefined;

        return response ?? { childItems: [], standaloneItems: [] };
    }

    public async UpdateWorkItem(workItemDto: workItemUpdate) {
        const logInfo = Object.assign({ method: this.UpdateWorkItem.name }, this.logInfo);
        return ApiService.Patch(utilUpdateTaskServiceCall(), logInfo, workItemDto, true).catch((e) => {
            return Promise.reject(e);
        });
    }

    public async CompleteTask(task: Task, workItem: WorkItem, exitCode?: string, rejectReason?: string): Promise<void> {
        const logInfo = Object.assign({ method: this.CompleteTask.name }, this.logInfo);
        const updateDto: any = {
            workItemId: workItem.workItemId,
            taskId: task.taskId,
            taskVersion: task.taskVersion,
            status: 'Complete',
        };

        if (exitCode !== undefined) {
            updateDto.exitCode = exitCode;
        }

        if (rejectReason !== undefined) {
            updateDto.customProperties = { rejectReason };
        }
        return ApiService.Post(utilCompleteTaskServiceCall(), logInfo, [updateDto], true);
    }
}
