import {
    CREATE_ENTITY_STATE,
    CreateLegalEntityWorkflowTask,
} from '../../../../models/LegalEntityRequest/Workflow/CreateLegalEntityWorkflow';

import LegalEntityDomesticRegistrationSection from 'modules/LegalEntityCreation/LegalEntityRegistration/LegalEntityDomesticRegistration/LegalEntityDomesticRegistrationSection';
import { LegalEntityRegistrationData } from '../../LegalEntityRegistration/model';
import React from 'react';
import { RequestStepsView } from '../RequestStepsView';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';

export default function DomesticRegistrationStepRenderer() {
    const { saveSection, completeTask } = useApiServices();

    const {
        state: { isWorkItemsRefreshing, sections, entityCreationWorkItem: workItem, selectedPanel },
    } = useCreationViewContext();

    const data = sections.DomesticRegistration;

    if (isWorkItemsRefreshing || !selectedPanel?.hasActions) {
        return <LegalEntityDomesticRegistrationSection data={data} mode='Readonly' workItem={workItem} />;
    }

    switch (workItem?.workItemState) {
        case CREATE_ENTITY_STATE.REGISTRATION:
        case CREATE_ENTITY_STATE.UPDATE_DOMESTIC_REGISTRATION:
            return (
                <LegalEntityDomesticRegistrationSection
                    data={data}
                    workItem={workItem}
                    mode='Edit'
                    onSave={(data: Partial<LegalEntityRegistrationData>) =>
                        saveSection(sections, 'DomesticRegistration', data).then((e) => e.entityOId ?? 0)
                    }
                    onCompleteTask={(task: CreateLegalEntityWorkflowTask, exitCode) =>
                        completeTask(workItem, task, exitCode, RequestStepsView.Approval, undefined, undefined,  CREATE_ENTITY_STATE.UPDATE_DOMESTIC_REGISTRATION ? 5000 : undefined)
                    }
                />
            );
        default:
            return <LegalEntityDomesticRegistrationSection data={data} mode='Readonly' />;
    }
}
