import { useEffect } from 'react';

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
    return (event.returnValue = 'Pending changes will not be saved. Do you want to proceed?');
};

export default function useBeforeUnloadListener(addListener = true) {
    useEffect(() => {
        if (addListener) {
            window.addEventListener('beforeunload', beforeUnloadListener);
        } else {
            window.removeEventListener('beforeunload', beforeUnloadListener);
        }
    }, [addListener]);
}
