import { IStorageService, ServiceLocator } from '@bxgrandcentral/shell';

import { useCallback } from 'react';

const MODULE_ID = 'legal-entity-request-central';

export default function useApplicationStorage() {
    const service = ServiceLocator.container.resolve(IStorageService);

    const getValue = useCallback(
        async (key: string) => {
            return await service.getValue(key, MODULE_ID);
        },
        [service]
    );

    const setValue = useCallback(
        (key: string, value: any) => {
            service.setValue(key, value, MODULE_ID);
        },
        [service]
    );

    return { getValue, setValue };
}
