import { ApplicationConfig } from '@bxgrandcentral/shell';
import { constants } from '../constants/env-config';

export const securityProviderId: string = 'com.bx.grandcentral.securityprovider';
export const appId: string = 'com.bx.bxt.bx-legal-entities-ui';

export const appConfig: ApplicationConfig = {
    appID: appId,
    appName: 'Request Center 2.0',
    userProfileServiceEndpoint: constants.USERS_API.replace(/\/$/, ''),
    environmentName: constants.ENVIRONMENT,
    showEnvironmentName: constants.ENVIRONMENT.toUpperCase() !== 'PROD',
    securityProviders: [
        {
            providerID: securityProviderId,
            settings: {
                authority: constants.IDENTITYSERVER_BASEURL,
                client_id: constants.IDENTITYSERVER_CLIENTID,
                scope: constants.IDENTITYSERVER_SCOPE,
                redirect_uri: constants.SWAGGER_REDIRECT_URL,
                response_type: constants.RESPONSE_TYPE,
                silent_redirect_uri: constants.SILENT_REDIRECT_URL,
                userInfoPath: constants.USERINFO_API,
                automaticSilentRenew: true,
                autoLogin: true,
                whiteListedDomains: ['*.bxt.com', '*.blackstone.com', 'localhost'],
            },
        },
    ],
};
