import { LegalEntityTaxClassificationData } from 'modules/LegalEntityCreation/LegalEntityTaxClassification/model';
import LegalEntityTaxClassificationSection from 'modules/LegalEntityCreation/LegalEntityTaxClassification/LegalEntityTaxClassificationSection';
import React from 'react';
import { TaxClassificationTask } from '../../../../models/LegalEntityRequest/Workflow';
import { handleObjectAttributesForSave } from 'modules/LegalEntityCreation/utils';
import useApiServices from '../hooks/use-api-services';
import { useCreationViewContext } from '../context/creation-view-context';

export default function TaxClassificationStepRenderer() {
    const { saveSection, reloadEntityAndWorkItems, completeTask, createStandalone } = useApiServices();

    const {
        state: {
            editWorkFlow,
            isWorkItemsRefreshing,
            sections,
            noPendingSections,
            taxClassificationWorkItem: workItem,
            selectedPanel,
        },
    } = useCreationViewContext();

    const storedData = sections.TaxClassification;
    const noPendingStoredData = noPendingSections.TaxClassification;

    if (isWorkItemsRefreshing || (!selectedPanel?.hasActions && !editWorkFlow))
        return <LegalEntityTaxClassificationSection mode='Readonly' data={storedData} workItem={workItem} />;

    if (!selectedPanel?.hasActions && editWorkFlow) {
        return (
            <LegalEntityTaxClassificationSection
                mode='Create'
                data={storedData}
                onSave={async (changes: Partial<LegalEntityTaxClassificationData>) => {
                    return saveSection(
                        sections,
                        'TaxClassification',
                        handleObjectAttributesForSave(storedData, changes)
                    ).then((e) => e?.entityOId ?? 0);
                }}
                onCreateWorkItem={(entityOId: number, type: string) => createStandalone(entityOId, type)}
                workItem={workItem}
            />
        );
    }

    switch (workItem?.workItemState) {
        case 'TaxRequest':
            return (
                <LegalEntityTaxClassificationSection
                    mode='Edit'
                    data={storedData}
                    noPendingData={noPendingStoredData}
                    workItem={workItem}
                    onSave={async (changes: Partial<LegalEntityTaxClassificationData>) => {
                        return saveSection(sections, 'TaxClassification', changes).then((e) => e.entityOId ?? 0);
                    }}
                    onCompleteTask={async (task: TaxClassificationTask, exitCode: string | undefined) => {
                        await completeTask(workItem, task, exitCode, '');
                        if (exitCode?.toLowerCase() === 'withdraw' || exitCode?.toLowerCase() === 'submit') {
                            await reloadEntityAndWorkItems(2000);
                        }
                    }}
                />
            );
        case 'TaxApproval':
            return (
                <LegalEntityTaxClassificationSection
                    mode='Approval'
                    data={storedData}
                    workItem={workItem}
                    noPendingData={noPendingStoredData}
                    onCompleteTask={async (task: TaxClassificationTask, exitCode: string | undefined, rejectReason) => {
                        const section = exitCode === 'Approve' ? '' : undefined;
                        await completeTask(workItem, task, exitCode, section, rejectReason);
                        await reloadEntityAndWorkItems(2000);
                    }}
                />
            );
        default:
            return (
                <LegalEntityTaxClassificationSection
                    mode='Readonly'
                    data={storedData}
                    workItem={workItem}
                    noPendingData={noPendingStoredData}
                />
            );
    }
}
