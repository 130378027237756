import { setForceEditedEntityId, useAppContext } from 'context/app-context';

import { FontIcon } from '@bxgrandcentral/controls';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { SPACING } from 'Utilities/Layout';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { useHistory } from 'react-router-dom';

export default function EditCellRenderer({ value, data }: ICellRendererParams) {
    const { entityOId } = data;
    const history = useHistory();

    const colors = useColors();

    const { dispatch } = useAppContext();

    const onClick = (e: React.MouseEvent) => {
        setForceEditedEntityId(dispatch, { entityId: entityOId, section: RequestStepsView.Dissolution_Details });
        history.push(`${entityOId}/DissolutionDetails`);
    };

    return (
        <ButtonContainer>
            <FontIcon
                iconName='Archive'
                onClick={(e) => onClick(e)}
                foregroundColor={colors.foregroundColor}
                fontSize={20}
            />
        </ButtonContainer>
    );
}

const ButtonContainer = styled.div`
    margin: auto;
    padding: ${SPACING.XXS}px;
    &:hover {
        cursor: pointer;
    }
`;
