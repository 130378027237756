import { DocumentSection, DocumentSections, FormState } from './model';
import { isArray, isEmpty, isNil, isNull, isObject, omitBy } from 'lodash';

import { VALIDATION_MESSAGES } from '../validation/utils';
import { getKeysOf } from 'Utilities/object';

const {
    DOCUMENTS_MANDATORY_FIELD,
    DOCUMENTS_REQUIRED_FIELDS_ARE_MISSING,
    DOCUMENTS_REQUIRED_FIELDS_ARE_NOT_SET_PROPERLY,
    DOCUMENTS_INVALID_MULTIPLE_DOCUMENTS,
} = VALIDATION_MESSAGES;

export const validateDocumentSection = (section: DocumentSection) => {
    const {
        isRequired = false,
        effectiveDate = 'none',
        pendingDocuments = [],
        storedDocuments = [],
        additionalErrorMessage,
    } = section;

    const numberOfDocuments =
        pendingDocuments.length + storedDocuments.filter(({ updates: { isDeleted } }) => !isDeleted).length;

    if (numberOfDocuments === 0) {
        return {
            ...section,
            canSave: true,
            canSubmit: !isRequired,
            errorMessage: isRequired ? DOCUMENTS_MANDATORY_FIELD : '',
        };
    }

    const isMissingRequiredFields = pendingDocuments.some(
        ({ fileName, documentTypeId, effectiveDate: date }) =>
            !fileName || documentTypeId === 0 || (effectiveDate === 'required' && isNil(date))
    );
    const isInvalidRequiredFields = pendingDocuments.some(
        ({ effectiveDate: effectiveDateValue }) => effectiveDateValue && effectiveDateValue > new Date()
    );

    const errorMessage = isMissingRequiredFields
        ? DOCUMENTS_REQUIRED_FIELDS_ARE_MISSING
        : isInvalidRequiredFields
        ? DOCUMENTS_REQUIRED_FIELDS_ARE_NOT_SET_PROPERLY
        : '';

    return {
        ...section,
        canSave: !errorMessage && additionalErrorMessage !== DOCUMENTS_INVALID_MULTIPLE_DOCUMENTS,
        canSubmit: !errorMessage && !additionalErrorMessage,
        errorMessage,
    };
};

export function validateDocumentSections(sections: DocumentSections) {
    const existingSections = getKeysOf(sections).reduce<DocumentSections>(
        (result, key) => ({ ...result, ...(!sections[key].isDeleted && { [key]: sections[key] }) }),
        {}
    );
    return {
        sections,
        canSave: Object.values(existingSections).every(({ canSave = false }) => canSave),
        canSubmit: Object.values(existingSections).every(({ canSubmit = false }) => canSubmit),
    };
}

export function updateState<T>(state: FormState<T>) {
    const {
        data: { changes, validationErrors },
        documents: { sections, canSave: canSaveDocuments, canSubmit: canSubmitDocuments },
    } = state;

    const hasFormChanges =
        getKeysOf(changes).length > 0 ||
        Object.values(sections).some(
            ({ pendingDocuments = [], storedDocuments = [] }) =>
                pendingDocuments.length ||
                storedDocuments.some(
                    ({ document: { effectiveDateString }, updates: { isDeleted, sectionName, effectiveDate } }) =>
                        isDeleted ||
                        !isNil(sectionName) ||
                        !isNil(effectiveDate) ||
                        (isNull(effectiveDate) && effectiveDateString)
                )
        );

    const hasOnlyRequiredFieldErrors = getKeysOf(validationErrors).every(
        (key) =>
            isNil(validationErrors[key]) ||
            validationErrors[key] === VALIDATION_MESSAGES.REQUIRED_FIELD ||
            validationErrors[key] === VALIDATION_MESSAGES.MUST_COMPLETED
    );

    const onlyRequiredFieldErrors = getKeysOf(validationErrors).every(
        (key) => validationErrors[key] === VALIDATION_MESSAGES.REQUIRED_FIELD
    );

    const hasValidationError = getKeysOf(validationErrors).some((key) => !isNil(validationErrors[key]));

    return {
        ...state,
        canCreate: !hasValidationError && canSubmitDocuments,
        canSave: hasOnlyRequiredFieldErrors && canSaveDocuments && hasFormChanges,
        canSubmit: !hasValidationError && canSubmitDocuments,
        canApprove: !hasValidationError && !onlyRequiredFieldErrors,
    };
}
export function hasUserChanges<T>({ data: { changes }, documents: { sections } }: FormState<T>) {
    return getKeysOf(changes).length > 0 || Object.values(sections).some(({ isDeleted }) => isDeleted);
}

export function cleanObjectValues(value: any): any {
    if (isArray(value)) {
        return value.map((item: any) => cleanObjectValues(item));
    }

    if (isObject(value) && !(value instanceof Date)) {
        return omitBy(value, isNil);
    }

    return value;
}

export function isEmptyStoredValue(value: any) {
    return isNil(value) || (Array.isArray(value) && isEmpty(value));
}
