import { isExternalAdmin, shouldHaveSubBusinessUnit } from '../utils';

import { updateValidation } from '../context/actions/form-actions';
import { useEffect } from 'react';
import { useLegalEntityInformation } from '../context/Provider';
import { validateRequiredField } from './utils';

export default function useInformationValidation() {
    const {
        state: {
            data: {
                values: {
                    administeredBy,
                    businessUnitOId,
                    externalAdminOId,
                    legalTypeId,
                    subBusinessUnitOId,
                    structureType,
                },
            },
            isEditable,
        },
        dispatch,
    } = useLegalEntityInformation();

    useEffect(() => {
        if (!isEditable) {
            return;
        }

        updateValidation(dispatch, {
            administeredBy: validateRequiredField(administeredBy),
            businessUnitOId: validateRequiredField(businessUnitOId),
            externalAdminOId: validateRequiredField(externalAdminOId, {
                isRequired: isExternalAdmin(administeredBy),
            }),
            legalTypeId: validateRequiredField(legalTypeId),
            structureType: validateRequiredField(structureType),
            subBusinessUnitOId: validateRequiredField(subBusinessUnitOId, {
                isRequired: shouldHaveSubBusinessUnit(businessUnitOId),
            }),
        });
    }, [
        isEditable,
        administeredBy,
        dispatch,
        businessUnitOId,
        externalAdminOId,
        legalTypeId,
        subBusinessUnitOId,
        structureType,
    ]);
}
