import { GlobalState } from 'GlobalState';
import { useCallback } from 'react';

export default function useUnsavedChangesConfirmation(canSave: boolean) {
    const getUnsavedChangesConfirmation = useCallback(
        async (content = 'Pending changes will not be saved. Do you want to proceed?') => {
            if (!canSave) {
                return true;
            }

            return await GlobalState.openDialog({
                variant: 'warning',
                title: 'You have unsaved changes!',
                content,
                okButtonLabel: 'Yes',
                cancelButtonLabel: 'No',
            });
        },
        [canSave]
    );

    return getUnsavedChangesConfirmation;
}
