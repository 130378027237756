import { Button, StackPanel } from '@bxgrandcentral/controls';
import { Expander, Text } from 'components/';
import React, { Fragment } from 'react';

import { Border } from '../../../../../Utilities/Layout';
import ForeignRegistration from './ForeignRegistration';
import { SPACING } from '../../../../../Utilities/Layout';
import { isEmpty } from '../../../../../Utilities/Validations';
import useColors from 'api/hooks/use-theme';
import useForeignRegistrationDetailsValidation from '../../../validation/use-foreign-registration-details-validation';
import { useLegalEntityDocumentPreparation } from '../../../context/Provider';

export default function ForeignRegistrationDetails() {
    const { state, setValue } = useLegalEntityDocumentPreparation();

    const {
        data: {
            values: { foreignRegistrations = [] },
        },
        isEditable,
    } = state;

    function addForeignRegistration() {
        setValue('foreignRegistrations', [...foreignRegistrations, { isDeleted: false }]);
    }

    const { normalControlBorderColor, secondaryForegroundColor } = useColors();

    const { validations } = useForeignRegistrationDetailsValidation();

    return (
        <Expander
            header='Foreign Registration Details'
            padding={0}
            content={
                <StackPanel itemGap={SPACING.XL}>
                    {!isEmpty(foreignRegistrations) &&
                        foreignRegistrations.map((_, index) => (
                            <Fragment key={index}>
                                {index > 0 && <Border color={normalControlBorderColor} />}
                                <ForeignRegistration index={index} validation={validations[index]} />
                            </Fragment>
                        ))}
                </StackPanel>
            }
            {...(isEditable && {
                footer: (
                    <>
                        {foreignRegistrations.filter(({ isDeleted }) => !isDeleted).length < 3 ? (
                            <Button
                                content='Add Foreign Registration'
                                isEnabled={isEditable}
                                onClick={addForeignRegistration}
                            />
                        ) : (
                            <Text
                                variant='body-small'
                                width='100%'
                                textAlignment='center'
                                foregroundColor={secondaryForegroundColor}>
                                A maximum of three foreign registrations can be added at this time. To add more, please
                                resubmit another request.
                            </Text>
                        )}
                    </>
                ),
            })}
        />
    );
}
