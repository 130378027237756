import { DocumentSections, FormState, ValidationErrors } from './model';
import {
    cleanObjectValues,
    isEmptyStoredValue,
    updateState,
    validateDocumentSection,
    validateDocumentSections,
} from './utils';
import { cloneDeep, cloneDeepWith, isEqual, isNil, isNull } from 'lodash';
import { getKeysOf, mergeObjects } from 'Utilities/object';

import { LegalEntityCreationActions } from './actions/index';
import { SECTION_NAME_SEPARATOR } from 'components/controls/FileUpload/models';
import { getSectionName } from 'components/controls/FileUpload/utils';

export const createReducer =
    <T>() =>
    (state: FormState<T>, action: LegalEntityCreationActions<T>): FormState<T> => {
        switch (action.type) {
            case 'RESET_STATE': {
                const {
                    data: { values, calculateDefaultValues, noPendingValues },
                    isEditable,
                    version,
                } = state;
                const newValues = action.values ?? values;
                const newNoPendingValues = action.noPendingValues ?? noPendingValues;

                const autoPopulatedValues = calculateDefaultValues(newValues);

                return {
                    data: {
                        storedValues: cloneDeep(newValues),
                        noPendingValues: cloneDeep(newNoPendingValues),
                        values: mergeObjects(newValues, autoPopulatedValues),
                        changes: {},
                        autoPopulatedValues,
                        validationErrors: {} as ValidationErrors<T>,
                        calculateDefaultValues,
                    },
                    documents: {
                        sections: {} as DocumentSections,
                        canSave: true,
                        canSubmit: true,
                    },
                    canSave: false,
                    canSubmit: false,
                    canCreate: false,
                    canApprove: false,
                    isEditable: isEditable,
                    isLoading: false,
                    shouldReload: false,
                    isSubmitted: false,
                    shouldUpdateNewDocuments: false,
                    version: version + 1,
                };
            }
            case 'SET_VALUE': {
                const {
                    data: { changes, storedValues, autoPopulatedValues },
                } = state;

                const { key, value, isUserChange } = action;

                if (isUserChange) {
                    const newValue = cleanObjectValues(value);
                    const storedValue = cleanObjectValues(storedValues[key]);
                    const autoPopulatedValue = cleanObjectValues(autoPopulatedValues[key]);
                    if (
                        isEqual(newValue, storedValue) ||
                        (isEmptyStoredValue(storedValue) && isEqual(newValue, autoPopulatedValue)) ||
                        (isNil(newValue) && isNil(storedValue))
                    ) {
                        delete changes[key];
                    } else {
                        changes[key] = newValue;
                    }
                }

                return updateState({
                    ...state,
                    data: {
                        ...state.data,
                        values: {
                            ...state.data.values,
                            [key]: action.value,
                        },
                        changes,
                    },
                });
            }
            case 'SET_VALUES': {
                const {
                    data: { changes, storedValues, autoPopulatedValues },
                } = state;

                const { values, isUserChange } = action;

                if (isUserChange) {
                    getKeysOf(action.values).forEach((key) => {
                        if (
                            isEqual(action.values[key], storedValues[key]) ||
                            (isEmptyStoredValue(storedValues[key]) &&
                                isEqual(action.values[key], autoPopulatedValues[key])) ||
                            (isNil(action.values[key]) && isNil(storedValues[key]))
                        ) {
                            delete changes[key];
                        } else {
                            changes[key] = values[key];
                        }
                    });
                }

                return updateState({
                    ...state,
                    data: {
                        ...state.data,
                        values: {
                            ...state.data.values,
                            ...values,
                        },
                        changes,
                    },
                });
            }
            case 'SET_IS_LOADING': {
                return {
                    ...state,
                    isLoading: action.value,
                };
            }
            case 'UPDATE_VALIDATION': {
                return updateState({
                    ...state,
                    data: {
                        ...state.data,
                        validationErrors: {
                            ...state.data.validationErrors,
                            ...action.validationErrors,
                        },
                    },
                });
            }
            case 'SET_IS_EDITABLE': {
                return {
                    ...state,
                    isEditable: action.value,
                };
            }
            case 'SET_SHOULD_RELOAD': {
                return {
                    ...cloneDeepWith(state),
                    shouldReload: action.value,
                };
            }
            case 'SET_IS_SUBMITTED': {
                return { ...state, isSubmitted: action.value };
            }
            case 'SET_SHOULD_UPDATE_NEW_DOCUMENTS': {
                return { ...state, shouldUpdateNewDocuments: action.value };
            }
            case 'UPDATE_DOCUMENT_SECTION': {
                const { sectionName, values } = action;
                const section = validateDocumentSection({ ...state.documents.sections[sectionName], ...values });

                return updateState({
                    ...state,
                    documents: validateDocumentSections({ ...state.documents.sections, [sectionName]: section }),
                });
            }
            case 'ADD_PENDING_DOCUMENTS': {
                const { sectionName, documents } = action;
                const section = state.documents.sections[sectionName];

                const updatedSection = validateDocumentSection({
                    ...section,
                    pendingDocuments: (section.pendingDocuments ?? []).concat(...documents),
                });

                return updateState({
                    ...state,
                    documents: validateDocumentSections({ ...state.documents.sections, [sectionName]: updatedSection }),
                });
            }
            case 'REMOVE_PENDING_DOCUMENT': {
                const { sectionName, id } = action;
                const section = state.documents.sections[sectionName];

                const updatedSection = validateDocumentSection({
                    ...section,
                    pendingDocuments: section.pendingDocuments.filter((document) => document.id !== id),
                });

                return updateState({
                    ...state,
                    documents: validateDocumentSections({ ...state.documents.sections, [sectionName]: updatedSection }),
                });
            }
            case 'UPDATE_PENDING_DOCUMENT': {
                const { sectionName, document } = action;
                const section = state.documents.sections[sectionName];

                const updatedSection = validateDocumentSection({
                    ...section,
                    pendingDocuments: section.pendingDocuments.map((pendingDocument) =>
                        pendingDocument.id === document.id ? document : pendingDocument
                    ),
                });

                return updateState({
                    ...state,
                    documents: validateDocumentSections({ ...state.documents.sections, [sectionName]: updatedSection }),
                });
            }
            case 'ADD_STORED_DOCUMENTS': {
                const { sectionName, value } = action;

                const updatedSection = validateDocumentSection({
                    ...state.documents.sections[sectionName],
                    storedDocuments: value.map((value) => ({ document: value, updates: { isDeleted: false } })),
                });

                return updateState({
                    ...state,
                    documents: validateDocumentSections({ ...state.documents.sections, [sectionName]: updatedSection }),
                });
            }
            case 'UPDATE_STORED_DOCUMENT': {
                const { sectionName, id, update } = action;
                const section = state.documents.sections[sectionName];

                const updatedSection = validateDocumentSection({
                    ...section,
                    storedDocuments: section.storedDocuments.map((model) => ({
                        ...model,
                        ...(model.document.documentOId.value === id && {
                            updates: {
                                ...((isNull(update.effectiveDate) && model.document.effectiveDateString) ||
                                update.effectiveDate ||
                                update.effectiveDate === null
                                    ? model.document.effectiveDateString !== update.effectiveDate && {
                                          effectiveDate: update.effectiveDate,
                                      }
                                    : {
                                          effectiveDate: model.updates.effectiveDate,
                                      }),
                                ...(update.sectionName
                                    ? getSectionName(model.document.documentElements) !== update.sectionName && {
                                          sectionName: update.sectionName,
                                      }
                                    : { sectionName: model.updates.sectionName }),
                                ...(!isNil(update.isDeleted)
                                    ? { isDeleted: update.isDeleted }
                                    : { isDeleted: model.updates.isDeleted }),
                            },
                        }),
                    })),
                });

                return updateState({
                    ...state,
                    documents: validateDocumentSections({ ...state.documents.sections, [sectionName]: updatedSection }),
                });
            }
            case 'REMOVE_DOCUMENT_SECTION': {
                const { sectionName } = action;
                const {
                    documents: { sections },
                } = state;

                let updatedSections;

                if (sectionName.includes(SECTION_NAME_SEPARATOR)) {
                    const deletedIndex = sectionName.slice(sectionName.indexOf(SECTION_NAME_SEPARATOR) + 1);
                    updatedSections = Object.keys(sections).reduce((result, key) => {
                        const [prefix, index] = key.split(SECTION_NAME_SEPARATOR);

                        return index === deletedIndex
                            ? result
                            : {
                                  ...result,
                                  [`${
                                      index > deletedIndex
                                          ? `${prefix}${SECTION_NAME_SEPARATOR}${parseInt(index) - 1}`
                                          : key
                                  }`]: sections[key],
                              };
                    }, {} as DocumentSections);
                } else {
                    updatedSections = getKeysOf(sections).reduce<DocumentSections>(
                        (result, name) => ({
                            ...result,
                            ...(name !== sectionName && { [`${name}`]: sections[name] }),
                        }),
                        {}
                    );
                }

                return updateState({
                    ...state,
                    documents: {
                        ...state.documents,
                        ...validateDocumentSections(updatedSections),
                    },
                });
            }
            default:
                return state;
        }
    };
