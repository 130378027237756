import {
    LegalEntityDissolutionDetailsWorkflow,
    LegalEntityDissolutionFinanceWorkflow,
    LegalEntityDissolutionLegalWorkflow,
    LegalEntityDissolutionLitigationWorkflow,
    LegalEntityDissolutionTask,
    LegalEntityDissolutionTaskType,
    LegalEntityDissolutionTaxWorkflow,
    LegalEntityDissolutionTreasuryWorkflow,
} from 'models/LegalEntityRequest/Workflow';
import { useEffect, useState } from 'react';

type SavedData = {
    savedBy?: string;
    savedAt?: string;
};

type Props = {
    taskType?: LegalEntityDissolutionTaskType;
    workItem?:
        | LegalEntityDissolutionDetailsWorkflow
        | LegalEntityDissolutionLitigationWorkflow
        | LegalEntityDissolutionTaxWorkflow
        | LegalEntityDissolutionFinanceWorkflow
        | LegalEntityDissolutionLegalWorkflow
        | LegalEntityDissolutionTreasuryWorkflow;
    saved?: SavedData;
};

export default function useLastCompletedTask({
    workItem,
    taskType = 'ApprovalRequestType',
    saved = { savedAt: undefined, savedBy: undefined },
}: Props) {
    const [lastPageUpdate, setLastPageUpdate] = useState<LegalEntityDissolutionTask>();

    useEffect(() => {
        if (workItem?.tasks) {
            const lastCompletedTask = [...workItem?.tasks].reverse().find((task) => {
                return (
                    task.completed &&
                    task.taskType === taskType &&
                    !['com.bx.bxt.entity-request-api@blackstone.com'].includes(task?.modifier ?? '')
                );
            });
            const hasSaveAfterLastUpdate =
                (!!saved && !!saved.savedAt && saved.savedAt) >
                (!!lastPageUpdate && !!lastPageUpdate.modified && lastPageUpdate.modified);
            const hasOnlySaveData = !lastPageUpdate && !!saved && !!saved.savedAt;

            const hasNewUpdate =
                !!lastCompletedTask &&
                !!lastCompletedTask.modified &&
                lastCompletedTask?.modified >
                    (!!lastPageUpdate && !!lastPageUpdate.modified && lastPageUpdate.modified);
            const hasOnlyNewUpdate = !lastPageUpdate && !!lastCompletedTask?.modified;

            if (hasSaveAfterLastUpdate || hasOnlySaveData) {
                setLastPageUpdate({
                    ...lastCompletedTask,
                    modified: saved.savedAt,
                    modifier: saved.savedBy,
                } as LegalEntityDissolutionTask);
            } else if (hasNewUpdate || hasOnlyNewUpdate) {
                setLastPageUpdate(lastCompletedTask);
            }
        }
    }, [lastPageUpdate, saved, taskType, workItem]);

    return lastPageUpdate;
}
