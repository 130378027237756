import { FontIcon, Grid, ListBox, TextBlock } from '@bxgrandcentral/controls';
import React, { useCallback, useEffect, useState } from 'react';
import {
    setEditWorkFlow,
    setLegalEntityCreationSelectedPanel,
    useCreationViewContext,
} from '../../context/creation-view-context';

import { LegalEntityRequestPaths } from 'modules/RequestsDashboard/LegalEntityRequestPaths';
import { RequestStepPanel } from '../../RequestStepsView';
import { SPACING } from 'Utilities/Layout';
import { isEqual } from 'lodash';
import { useAppContext } from 'context/app-context';
import useGenerateKey from 'hooks/use-generate-key';
import { useHistory } from 'react-router-dom';
import useSideBarItems from '../../hooks/use-sidebar-items';
import useUnsavedChangesConfirmation from '../../hooks/use-unsaved-changes-confirmation';

export default function SideBar({ isStandalone = false }: { isStandalone?: boolean }) {
    const { editLegalEntityRequestPattern, editLegalEntityRequest } = LegalEntityRequestPaths;
    const [pendingSelectedPanel, setPendingSelectedPanel] = useState<RequestStepPanel | null>(null);

    const {
        state: {
            legalEntityCreation: { canSave },
        },
    } = useAppContext();
    const {
        state: { legalEntityOId, selectedPanel },
        dispatch,
    } = useCreationViewContext();
    const historyApi = useHistory();
    const listBoxKey = useGenerateKey([canSave, legalEntityOId]);

    const sideBarItems = useSideBarItems({ isStandalone });

    const getUnsavedChangesConfirmation = useUnsavedChangesConfirmation(canSave);

    const handlePendingSelectedPanel = useCallback(async () => {
        if (!pendingSelectedPanel) {
            return;
        }

        const confirmation = await getUnsavedChangesConfirmation();

        if (confirmation) {
            setLegalEntityCreationSelectedPanel(dispatch, pendingSelectedPanel);
        }

        setPendingSelectedPanel(null);
    }, [pendingSelectedPanel, getUnsavedChangesConfirmation, dispatch]);

    useEffect(() => {
        handlePendingSelectedPanel();
    }, [pendingSelectedPanel, handlePendingSelectedPanel]);

    const itemTemplate = useCallback(
        (panel: RequestStepPanel) => {
            const textBlockFontWeight = selectedPanel?.title === panel.title ? 600 : 300;

            return (
                <Grid columnDefinitions='auto * auto' itemGap={SPACING.XS} padding={`${SPACING.XXS}px 0`}>
                    <FontIcon gridColumn={0} fontSize={16} iconName='TextDocument' />
                    <TextBlock text={panel.title} fontWeight={textBlockFontWeight} />
                    {panel.hasActions && <FontIcon gridColumn={2} fontSize={16} iconName='PageEdit' />}
                    {!panel.hasActions && panel.customIcon && (
                        <FontIcon gridColumn={2} fontSize={16} iconName={panel.customIcon} />
                    )}
                </Grid>
            );
        },
        [selectedPanel]
    );

    const handleSelectedItemsChanging = useCallback(
        (panels: RequestStepPanel[]) => {
            console.log({ selectedPanel, panels0: panels[0] });

            if (!canSave || isEqual(selectedPanel, panels[0])) {
                return true;
            }

            setPendingSelectedPanel(panels[0]);
            return false;
        },
        [canSave, selectedPanel]
    );

    const handleSelectedItemsChanged = useCallback(
        (panels: RequestStepPanel[]) => {
            if (legalEntityOId && panels[0]) {
                historyApi.push(
                    editLegalEntityRequest(
                        `${editLegalEntityRequestPattern}/${panels[0].requestStepView.replace(/\s/g, '')}`,
                        { legalEntityId: legalEntityOId.toString() }
                    )
                );
            }

            setLegalEntityCreationSelectedPanel(dispatch, panels[0]);
            setEditWorkFlow(dispatch, false);
        },
        [dispatch, editLegalEntityRequest, editLegalEntityRequestPattern, historyApi, legalEntityOId]
    );
    return (
        <ListBox
            key={listBoxKey}
            margin={`${SPACING.SM}px 0 0 0`}
            itemsSource={sideBarItems}
            selectedValues={[selectedPanel]}
            itemTemplate={itemTemplate}
            onSelectedItemsChanging={handleSelectedItemsChanging}
            onSelectedItemsChanged={handleSelectedItemsChanged}
        />
    );
}
