import { IWorkflowService } from '../../../../services/interfaces';
import LegalEntityRequestSection from '../../LegalEntityRequest/LegalEntityRequestSection';
import React from 'react';
import { RequestStepsView } from '../RequestStepsView';
import { ServiceLocator } from '@bxgrandcentral/shell';
import useApiServices from '../hooks/use-api-services';
import useBackToDashboard from '../hooks/use-back-to-dashboard';
import { useCreationViewContext } from '../context/creation-view-context';
import useReloadPage from '../hooks/use-reload-page';

export default function RequestStepRenderer() {
    const { saveSection, reloadUserScopes, completeTask } = useApiServices();
    const backToDashboard = useBackToDashboard();
    const reloadPage = useReloadPage();

    const {
        state: { isWorkItemsRefreshing, sections, entityCreationWorkItem: workItem, selectedPanel },
    } = useCreationViewContext();
    const workflowService = ServiceLocator.container.resolve(IWorkflowService);

    const data = sections.Details;

    if (isWorkItemsRefreshing || !selectedPanel?.hasActions)
        return <LegalEntityRequestSection data={data} mode='Readonly' />;

    switch (workItem?.workItemState) {
        case null:
        case undefined:
            return (
                <LegalEntityRequestSection
                    mode='Create'
                    data={data}
                    onSave={(dataToSave) =>
                        saveSection(sections, 'Details', dataToSave).then((e) => reloadUserScopes(e.entityOId ?? 0))
                    }
                    onCreateWorkItem={(entityOId) =>
                        workflowService
                            .CreateNewEntityWorkItem(entityOId)
                            .then(() => reloadPage(entityOId, 0, RequestStepsView.Document_Preparation))
                    }
                />
            );
        case 'Update Request':
            return (
                <LegalEntityRequestSection
                    mode='Edit'
                    data={data}
                    onSave={(dataToSave) =>
                        saveSection(sections, 'Details', dataToSave).then((e) => reloadUserScopes(e.entityOId ?? 0))
                    }
                    onCompleteTask={async (task, exitCode) => {
                        await completeTask(workItem, task, exitCode, RequestStepsView.Document_Preparation, undefined, {
                            skipRequestNotesUpdate: true,
                        });

                        if (exitCode === 'Withdraw') {
                            setTimeout(() => {
                                backToDashboard();
                            }, 3000);
                        }
                    }}
                    workItem={workItem}
                />
            );
        case 'Review':
            return (
                <LegalEntityRequestSection
                    mode='Approval'
                    data={data}
                    workItem={workItem}
                    onCompleteTask={(task, exitCode) => completeTask(workItem, task, exitCode, '')}
                />
            );
        default:
            return <LegalEntityRequestSection data={data} mode='Readonly' />;
    }
}
