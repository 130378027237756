import { setLatestRejectedDissolutionWorkflow, useCreationViewContext } from '../context/creation-view-context';

import { useEffect } from 'react';

export default function useLatestRejectedDissolutionWorkflow() {
    const {
        state: {
            legalEntityDissolutionParentWorkItem,
            legalEntityDissolutionLitigationWorkItem,
            legalEntityDissolutionTaxWorkItem,
            legalEntityDissolutionFinanceWorkItem,
            legalEntityDissolutionLegalWorkItem,
            legalEntityDissolutionTreasuryWorkItem,
        },
        dispatch,
    } = useCreationViewContext();

    useEffect(() => {
        const workitems = [
            legalEntityDissolutionParentWorkItem,
            legalEntityDissolutionLitigationWorkItem,
            legalEntityDissolutionTaxWorkItem,
            legalEntityDissolutionFinanceWorkItem,
            legalEntityDissolutionLegalWorkItem,
            legalEntityDissolutionTreasuryWorkItem,
        ];

        const latestRejectedDissolutionWorkflow = workitems?.reduce(
            (agg, workItem) => {
                if (workItem?.tasks) {
                    const rejectTask = [...workItem.tasks]
                        .reverse()
                        .find(({ exitCode, customProperties, taskType }) => {
                            return (
                                taskType === 'ApprovalRequestType' &&
                                exitCode === 'Reject' &&
                                customProperties?.rejectReason?.length
                            );
                        });

                    if (rejectTask?.modified && (!agg.modified || rejectTask?.modified > agg.modified)) {
                        agg.modified = rejectTask?.modified;
                        agg.workflowType = workItem.workflowType;
                        agg.rejectReason = rejectTask?.customProperties?.rejectReason ?? '';
                    }
                }

                return agg;
            },
            { modified: '', workflowType: '', rejectReason: '' }
        );

        if (latestRejectedDissolutionWorkflow?.modified) {
            setLatestRejectedDissolutionWorkflow(dispatch, latestRejectedDissolutionWorkflow);
        }
    }, [
        legalEntityDissolutionParentWorkItem,
        legalEntityDissolutionLitigationWorkItem,
        legalEntityDissolutionTaxWorkItem,
        legalEntityDissolutionFinanceWorkItem,
        legalEntityDissolutionLegalWorkItem,
        legalEntityDissolutionTreasuryWorkItem,
        dispatch,
    ]);
}
