import { IStorageService, ServiceLocator } from '@bxgrandcentral/shell';
import { useCallback, useEffect, useState } from 'react';

import { Column } from 'devextreme-react/data-grid';
import { ColumnSettingType } from '../models/shared/devExpress/ColumnSettingType';
import DevExpressDataGridHelper from '../components/treeListDevExpress/TreeListDevExpressHelper';
import { devExpressGridOptionChanged } from '../components/treeListDevExpress/TreeListDevExpress';
import { nameOf } from '../Utilities/ReflectionUtil';

interface GridAppSettings {
    columnsConfig: Column[];
    onOptionChanged: (...args: any) => void;
}

export type ColumnOptionChanged = {
    type: string;
    index: number;
    value: any;
    previousValue?: any;
};

export type SingleSortOptionChanged = ColumnOptionChanged & {
    type: ColumnSettingType.SortOrder;
    isSingleSort: true;
};

export function isSingleSortOptionChange(
    columnOptionChanged: ColumnOptionChanged
): columnOptionChanged is SingleSortOptionChanged {
    return nameOf<SingleSortOptionChanged>('isSingleSort') in columnOptionChanged;
}

export function useGridAppSetting(
    initialColumnsConfig: Column[],
    appSettingKey: string,
    singleSort: boolean
): GridAppSettings {
    const appSettingsService = ServiceLocator.container.resolve(IStorageService);
    const [columnsConfig, setColumnsConfig] = useState<Column[]>(initialColumnsConfig);
    useEffect(() => {
        if (appSettingsService) {
            DevExpressDataGridHelper.getGridSettings(appSettingsService, appSettingKey).then((settings) => {
                if (settings) {
                    const fetchedSettings = DevExpressDataGridHelper.mergeFromGridSettings(columnsConfig, settings);
                    if (fetchedSettings) {
                        setColumnsConfig(fetchedSettings);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSettingsService]);

    const onColumnsSettingsChanged = useCallback(
        (settings: ColumnOptionChanged) => {
            const savedSettings = DevExpressDataGridHelper.handleGridSettingsChange(
                appSettingsService,
                settings,
                columnsConfig,
                appSettingKey
            );
            if (savedSettings) {
                setColumnsConfig(savedSettings);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appSettingsService, columnsConfig]
    );

    const toColumnSettings = useCallback(
        (optionChanged: devExpressGridOptionChanged): ColumnOptionChanged | undefined => {
            if (optionChanged.name === 'columns' && optionChanged.fullName) {
                const optionInfoMatch = optionChanged.fullName.match(/columns\[(?<index>\d+)\]\.(?<type>\w+)/);
                if (optionInfoMatch) {
                    const options = {
                        type: optionInfoMatch.groups!.type,
                        index: parseInt(optionInfoMatch.groups!.index),
                        value: optionChanged.value,
                        previousValue: optionChanged.previousValue,
                    };
                    return options.type === ColumnSettingType.SortOrder && singleSort
                        ? Object.assign(options, { isSingleSort: true })
                        : options;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    let timeout: NodeJS.Timeout;
    const onOptionChanged = useCallback(
        (optionChanged: devExpressGridOptionChanged) => {
            const asColumnSetting = toColumnSettings(optionChanged);
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                asColumnSetting && onColumnsSettingsChanged(asColumnSetting);
            });
        },
        [onColumnsSettingsChanged]
    );

    return { columnsConfig, onOptionChanged };
}
